import React from "react";
import './career.css'
import Arrow from "../../assets/images/service-network/downArrow.svg";

function Career() {
  return (
    <section className="career">
      <div className="container">
        <div className="commonHeading">
          <h2>
            Join <span>our team</span>
          </h2>
        </div>
        <div className="careerContainer">
          <div className="careerContent">
            <p>Sales</p>
            <label>2 Open Roles</label>
          </div>
          <div className="careerDetail">
            <div className="careerDetailCard">
              <div>
                <h4>Backend-Engineer</h4>
                <ul>
                  <li>Bengaluru</li>
                  <li>
                    |
                  </li>
                  <li>Full time</li>
                </ul>
              </div>
              <img className="arrowRight" src={Arrow} alt="Arrow" />
            </div>
            <div className="careerDetailCard">
              <div>
                <h4>Backend-Engineer</h4>
                <ul>
                  <li>Bengaluru</li>
                  <li>
                    |
                  </li>
                  <li>Full time</li>
                </ul>
              </div>
              <img className="arrowRight" src={Arrow} alt="Arrow" />
            </div>
            <div className="careerDetailCard">
              <div>
                <h4>Backend-Engineer</h4>
                <ul>
                  <li>Bengaluru</li>
                  <li>
                    |
                  </li>
                  <li>Full time</li>
                </ul>
              </div>
              <img className="arrowRight" src={Arrow} alt="Arrow" />
            </div>
          </div>
          <div className="careerRole">
            <p>Backend Java developer</p>
            <label>2 Open Roles</label>
          </div>
          <div className="careerRole">
            <p>Audio metrics engineers <span>4+ Years Experience</span></p>
            <label>2 Open Roles</label>
          </div>
          <div className="careerRole">
            <p>Design</p>
            <label>2 Open Roles</label>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Career;
