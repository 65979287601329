import React from "react";
import { Link } from "react-router-dom";
import RightGrediantArrow from "../../assets/images/home/rightArrowGrediant.svg";
import RightArrow from "../../assets/images/home/rightArrow.svg";
import Star from "../../assets/images/pricing-plans/priceStar.svg";
import Tick from "../../assets/images/home/right.svg";
import MostPopular from "../../components/mostPopular/mostPopular";

function Compare() {
  let stick;
  window.addEventListener("scroll", () => {
    let Height = document.getElementsByClassName("pricePlanBanner")[0].offsetHeight + document.getElementsByClassName("comparePlans")[0].offsetHeight;
    stick = document.getElementById("sticky");
    if (window.scrollY > Height) {
      stick.classList.add("stop");
    } else {
      stick.classList.remove("stop");
    }
  });
  //document.getElementsByClassName('pricePlanBanner')[0].offsetHeight
  //document.getElementsByClassName('comparePlans')[0].offsetHeight

  return (
    <section className="compare">
      <div className="tableSticky" id="sticky">
        <div className="container">
          <div className="tableCard">
            <h3>Comprehensive Warranty with Buyback Guarantee</h3>

            <Link to="" className=" contactUsBtnModify" style={{ marginTop: "8px" }}>
              <div className="contactUsBtnModifyLine1">₹ 4999</div>
              <div className="contactUsBtnModifyLine2">onWards</div>
            </Link>
          </div>
          <div className="tableCard">
            <MostPopular />
            <h3>Service & Maintenance Coverage</h3>

            <Link to="" className=" contactUsBtnModifyGrediant">
              <div className="contactUsBtnModifyLine1">₹ 9999</div>
              <div className="contactUsBtnModifyLine2">onWards</div>
            </Link>
          </div>
          <div className="tableCard">
            <h3>All-in-One Delight Coverage plan</h3>

            <Link to="" className="contactUsBtn contactUsBtnModifyTwo" style={{ marginTop: "32.5px" }}>
              Coming Soon
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="comparePlans">
          <div className="comparePlansCard">
            <h3>Comprehensive Warranty with Buyback Guarantee</h3>
            <p>Covers upto Rs 5,00,000 for engine and transmission warranty with 100% Buyback Guarantee at Invoice price.</p>
            <a href="#Compare_Pricing_plans" className="contactUsBtn">
              View More <img src={RightGrediantArrow} alt="Arrow" />
            </a>
          </div>

          <div className="comparePlansCard">
            <h3 style={{ marginTop: "-9%" }}>
              Service & Maintenance Coverage
              <span>
                <img src={Star} alt="Star" />
                Most popular
              </span>
            </h3>
            <p>Covers Periodic Maintenance at OEM authorised showroom with Extended Warranty and Buyback Guarantee.</p>
            <a href="#Compare_Pricing_plans" className="contactUsBtnGrediant">
              View More <img src={RightArrow} alt="Arrow" />
            </a>
          </div>

          <div className="comparePlansCard">
            <h3>All-in-One Delight Coverage plan</h3>
            <p>Convers Motor Insurance, Periodic Maintenance, Extended Warranty with Buyback guarantee.</p>
            <a href="#Compare_Pricing_plans" className="contactUsBtn">
              View More <img src={RightGrediantArrow} alt="Arrow" />
            </a>
          </div>
        </div>

        <div className="commonHeading">
          <h2>
            Compare <span>Pricing plans</span>
          </h2>
        </div>

        <div className="table" id="Compare_Pricing_plans">
          <h3>Price</h3>
          <div className="tableData">
            <div className="tableCard ">
              <h3 className="tableCardheading3one" style={{ lineHeight: "28px" }}>
                Comprehensive Warranty with Buyback Guarantee
              </h3>
              <p> 100% Buyback Guarantee</p>

              <Link to="" className=" contactUsBtnModify" style={{ cursor: "default" }}>
                <div className="contactUsBtnModifyLine1">₹ 4999</div>
                <div className="contactUsBtnModifyLine2">onwards</div>
              </Link>
            </div>
            <div className="tableCard">
              <MostPopular />
              <h3 className="tableCardheading3two" style={{ lineHeight: "28px" }}>
                Service & Maintenance Coverage
              </h3>

              <p> 100% Showroom Warranty </p>

              <Link to="" className=" contactUsBtnModifyGrediant" style={{ cursor: "default" }}>
                <div className="contactUsBtnModifyLine1">₹ 9999</div>
                <div className="contactUsBtnModifyLine2">onwards</div>
              </Link>
            </div>
            <div className="tableCard tableCardResp">
              <h3 className="tableCardheading3three" style={{ lineHeight: "28px" }}>
                All-in-One Delight Coverage plan
              </h3>
              <p className="tableCardheading3threePara">₹ 10,000 Save P.a</p>
              <Link to="" className="contactUsBtn contactUsBtnModifyTwo" style={{ cursor: "default" }}>
                Coming Soon
              </Link>
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>
            Engine Warranty
            {/* <span>(Select parts)</span> */}
          </h4>
          <div className="tableData">
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>Transmission Warranty</h4>
          <div className="tableData">
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>

        <div className="tableContent">
          <h4>
            General Service <span>(OEM Authorised Service Centre)</span>
          </h4>
          <div className="tableData">
            <div className="tableCard">-</div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>
            AC Service <span>(OEM Authorised Service Centre) </span>
          </h4>
          <div className="tableData">
            <div className="tableCard">-</div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>
            Health Checkup <span> (OEM Authorised Service Centre)</span>
          </h4>
          <div className="tableData">
            <div className="tableCard">-</div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>Puncture Repair Service</h4>
          <div className="tableData">
            <div className="tableCard">-</div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>Battery Jump Start</h4>
          <div className="tableData">
            <div className="tableCard">-</div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>
            Turbo warranty <span>(AddOn)</span>
          </h4>
          <div className="tableData">
            <div className="tableCard">-</div>
            <div className="tableCard">-</div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>
            Battery Warranty<span>(AddOn)</span>
          </h4>
          <div className="tableData">
            <div className="tableCard">-</div>
            <div className="tableCard">-</div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>
            AC Compressor Warranty <span>(AddOn)</span>
          </h4>
          <div className="tableData">
            <div className="tableCard">-</div>
            <div className="tableCard">-</div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>
            Towing <span>(up to 50Km)</span>
            <span>(AddOn)</span>
          </h4>
          <div className="tableData">
            <div className="tableCard">-</div>
            <div className="tableCard">-</div>
            <div className="tableCard">
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
        <div className="tableContent">
          <h4>Motor Insurance </h4>
          <div className="tableData">
            <div className="tableCard">-</div>
            <div className="tableCard">-</div>
            <div className="tableCard">
              {" "}
              <img src={Tick} alt="Tick" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Compare;
