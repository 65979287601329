import React, { useState, useRef, useEffect, useContext } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import RangeSlider from "./utils/RangeSlider";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import styles from "../../containers/searchcars/SearchPage.module.css";
// import Overlay from "./utils/Overlay";
import CloseIcon from "@mui/icons-material/Close";
import { BorderColor } from "@mui/icons-material";
import MultiSelectDropdown from "./utils/MultiSelectDropdown";
import SliderDropdown from "./utils/RangeSliderDropdown";
import { GetBrandList } from "./utils/Api/SearchApis";
import { GetFilters } from "../../Api/PortalApis";
import { PortalContext } from "../../context/carPortalContext";
import { useLocation } from "react-router-dom";
import { minPrice, maxPrice, minKms, maxKms, minYear, maxYear, minOwners, maxOwners } from "../../context/constants";

// import { YEAR_LIST, TRANSMISSION_LIST } from "./data/filterData";

// export const YEAR_LIST = [
//   { id: "2023", year: "2023" },
//   { id: "2022", year: "2022" },
//   { id: "2021", year: "2021" },
//   { id: "2020", year: "2020" },
//   { id: "2019", year: "2019" },
//   { id: "2018", year: "2018" },
//   { id: "2017", year: "2017" },
//   { id: "2016", year: "2016" },
//   { id: "2015", year: "2015" },
//   { id: "2014", year: "2014" },
//   { id: "2013", year: "2013" },
// ];

export const TRANSMISSION_LIST = [
  { id: "Automatic", type: "Automatic" },
  { id: "Manual", type: "Manual" },
];

export const FUAL_TYPE_LIST = [
  { id: "Petrol", type: "Petrol" },
  { id: "Diesel", type: "Diesel" },
];

export const COLORS_LIST = [
  { id: "WHITE", color: "WHITE" },
  { id: "BLUE", color: "BLUE" },
  { id: "RED", color: "RED" },
  { id: "GRAY", color: "GRAY" },
  { id: "BLACK", color: "BLACK" },
];

export const CATEGORY_LIST = [
  { id: "Hatch", category: "Hatch" },
  { id: "Sedan", category: "Sedan" },
  { id: "SUV", category: "SUV" },
  { id: "MUV", category: "MUV" },
];

function SearchFilters({ isFilterOpen, toggleFilter }) {
  const location = useLocation();
  // console.log(location.state);

  

  useEffect(() => {
    if (location.state) {
      if (location.state.category) {
        ctx.setIsFromCarByModal(true);
        ctx.setSelectedCategories([location.state.category]);
        // ctx.setNonCategoryListEmpty();
      }
      if (location.state.make) {
        ctx.setIsFromCarByModal(true);
        ctx.setSelectedCarMakes([location.state.make]);
        // ctx.setNonMakeFilterEmpty();
      }
    } else {
      ctx.setIsFromCarByModal(false);
      ctx.setSelectedCategories([]);
      ctx.setSelectedCarMakes([]);
    }
  }, [location.state]);

  const checkBoxHeight = 32;
  const filterPaddingHeight = 0;

  const ctx = useContext(PortalContext);

  const [filterList, setFilterList] = useState([]);
  const [carMakeList, setCarMakeList] = useState([]);
  const [carModelList, setCarModelList] = useState([]);

  // const [count, setCount] = useState(0);
  const shouldUpdateRef = useRef(false);
  // console.log(filterList)
  // console.log(selectedCarMakes);
  // console.log(carModelList);

  async function getFilters() {
    try {
      //
      const resp = await GetFilters();
      if (resp.status === 200) {
        // console.log(resp.data);
        setFilterList(resp.data);
        const carMakeList = Object.keys(resp.data).map((key) => ({ id: key, car_brand: key }));
        setCarMakeList(carMakeList);
        // setCarModelList([]);
      }
    } catch (error) {
      console.error("Error getting filters", error);
    }
  }

  useEffect(() => {
    let modelList = [];
    for (const makeName in filterList) {
      if (ctx.selectedCarMakes.includes(makeName)) {
        const modelNames = filterList[makeName];
        modelNames.forEach((makeName) => {
          modelList.push({ id: makeName, model: makeName });
        });
      }
    }
    // console.log(modelList);
    setCarModelList(modelList);
  }, [ctx.selectedCarMakes]);

  // console.log(carModelList);

  useEffect(() => {
    getFilters();
  }, []);

  const [width, setWidth] = useState(0);
  // console.log(width);

  useEffect(() => {
    const rootEl = document.getElementById("root");
    setWidth(rootEl.clientWidth);
  }, []);

  return (
    <>
      <div
        className={styles.overlay}
        style={{
          opacity: isFilterOpen ? 1 : 0,
          visibility: isFilterOpen ? "visible" : "hidden",
          width: isFilterOpen ? "130vw" : "100%",
        }}
        onClick={toggleFilter}
      ></div>
      <div className={styles.filters} style={{ transform: isFilterOpen ? "translateX(0%)" : "" }}>
        <h3 className={styles.fitlerHeader}>
          <TuneIcon style={{ width: "20px", height: "20px" }} />
          <span>Filter By</span>
          {isFilterOpen && <CloseIcon className={styles.closeFilterIcon} onClick={toggleFilter} />}
        </h3>
        {/* <Overlay /> */}
        <div className={styles.filterMenu}>
          <MultiSelectDropdown
            label={"Category"}
            itemsList={CATEGORY_LIST}
            boxHeight={checkBoxHeight}
            paddingHeight={filterPaddingHeight}
            selectedItems={ctx.selectedCategories}
            setSelectedItems={ctx.setSelectedCategories}
            id_name={"id"}
            value_name={"category"}
          />

          <MultiSelectDropdown
            label={"Car Make"}
            itemsList={carMakeList}
            boxHeight={checkBoxHeight}
            paddingHeight={filterPaddingHeight}
            selectedItems={ctx.selectedCarMakes}
            setSelectedItems={ctx.setSelectedCarMakes}
            id_name={"id"}
            value_name={"car_brand"}
            hasSearch={true}
          />

          <MultiSelectDropdown
            label={"Car Model"}
            itemsList={carModelList}
            boxHeight={checkBoxHeight}
            paddingHeight={filterPaddingHeight}
            selectedItems={ctx.selectedCarModels}
            setSelectedItems={ctx.setSelectedCarModels}
            id_name={"id"}
            value_name={"model"}
            hasSearch={true}
          />

          <SliderDropdown
            label={"Year"}
            minVal={minYear}
            maxVal={maxYear}
            values={ctx.yearValues}
            setValues={ctx.setYearValues}
          />

          <MultiSelectDropdown
            label={"Transmission Type"}
            itemsList={TRANSMISSION_LIST}
            boxHeight={checkBoxHeight}
            paddingHeight={filterPaddingHeight}
            selectedItems={ctx.selectedTransmissionList}
            setSelectedItems={ctx.setSelectedTransmissionList}
            id_name={"id"}
            value_name={"type"}
          />

          <MultiSelectDropdown
            label={"Fuel Type"}
            itemsList={FUAL_TYPE_LIST}
            boxHeight={checkBoxHeight}
            paddingHeight={filterPaddingHeight}
            selectedItems={ctx.selectedFuelList}
            setSelectedItems={ctx.setSelectedFualList}
            id_name={"id"}
            value_name={"type"}
          />

          <SliderDropdown
            label={"Price"}
            minVal={minPrice}
            maxVal={maxPrice}
            values={ctx.priceValues}
            setValues={ctx.setPriceValues}
          />

          <SliderDropdown
            label={"Kms"}
            minVal={minKms}
            maxVal={maxKms}
            values={ctx.kmsValues}
            setValues={ctx.setKmsValues}
          />

          <SliderDropdown
            label={"No of owners"}
            minVal={minOwners}
            maxVal={maxOwners}
            values={ctx.noOwners}
            setValues={ctx.setNoOwners}
          />

          <MultiSelectDropdown
            label={"Color"}
            itemsList={COLORS_LIST}
            boxHeight={checkBoxHeight}
            paddingHeight={filterPaddingHeight}
            selectedItems={ctx.selectedColors}
            setSelectedItems={ctx.setSelectedColors}
            id_name={"id"}
            value_name={"color"}
          />
        </div>
      </div>
    </>
  );
}

export default SearchFilters;
