import React from "react";
import Sorry from "../../assets/images/paymentSucess/Sorry.png";
import Icon from "../../assets/images/paymentSucess/30.png";
import { useNavigate } from "react-router-dom";

const PaymentFailModal = ({ onClose, children }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    sessionStorage.clear();
    navigate(`/wiseassist`);
  };
  const styles = {
    modal: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    },
    modalContent: {
      backgroundColor: "white",
      padding: "1rem",
      borderRadius: "5px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      width: "900px",
      height: "500px",
      maxWidth: "90%",
      maxHeight: "90%",
      overflow: "auto",
    },
    imageWrapper: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    secondImageWrapper: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },
    textWrapper: {
      marginTop: "20px",
      marginRight: "0%",
      textAlign: "center",
    },
  };

  const mediaQueries = {
    smallScreen: "@media (max-width: 767px)",
    tablet: "@media (min-width: 768px)",
    desktop: "@media (min-width: 992px)",
    largeDesktop: "@media (min-width: 1200px)",
  };

  const modalContentStyles = {
    ...styles.modalContent,
    [mediaQueries.smallScreen]: {
      width: "80%",
      height: "auto",
    },
    [mediaQueries.tablet]: {
      maxWidth: "700px",
      maxHeight: "80%",
    },
    [mediaQueries.desktop]: {
      maxWidth: "800px",
    },
    [mediaQueries.largeDesktop]: {
      maxWidth: "900px",
    },
  };

  return (
    <div style={styles.modal}>
      <div style={modalContentStyles}>
        <div style={styles.imageWrapper}>
          <div>
            <img src={Sorry} alt="thankyou" style={{ height: "auto", width: "100%", marginTop: "100px" }} />
          </div>
          <div style={styles.secondImageWrapper}>
            <img
              src={Icon}
              alt="Icon"
              style={{
                height: "auto",
                width: "30%",
                transform: "rotate(11deg)",
                marginTop: "90px",
                marginLeft: "9%",
                opacity: "0.6",
              }}
            />
          </div>
        </div>
        <div style={styles.textWrapper}>
          <h3 style={{ color: "#5c5e5d" }}>Your Payment is failed</h3>
          <p style={{ color: "#7a7d7b", marginTop: "12px" }}>Please try Again</p>
          <p
            style={{ color: "#f05d35", marginTop: "24px", textDecoration: "underline", cursor: "pointer" }}
            onClick={handleNavigate}
          >
            Back to Home
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailModal;
