import React, { useEffect, useState } from "react";
import Header from "../../components/header/headerWA";
import Banner from "./banner";
import Customers from "../../components/customers/customers";
import Saving from "./saving";
import Price from "../../components/price/price";
import { useContext } from "react";
import { MyContext } from "../../context";
import "./wiseAssistHomeNew.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Technology from "./technology";
import { useRef } from "react";
import SuitableFacebookPlan from "../SuitableFacebookPlan";
import WisedriveProgram from "../warranty-benefits/WisedriveProgram";
import CustomerStoryFacebookLanding from "./CustomerStoryFacebookLanding";
import PeaceOfmind from "./PeaceOfmind";
import PricingTablesFacebookLandingPage from "../../components/PricingTableFacebookLanding";
import WarrantyFacebookLandingPage from "./WarrantyFacebookLandingPage";
import FooterFacebookLandingPage from "../../components/footerFacebookLandingPage";
import PeaceOfmindAllImage from "./PeaceOfmindAllImage";
import { GetPageList, SendTrackingCount } from "../../Api/Apis";
import { useLocation } from "react-router";
import axios from "axios";

const FacebookLandingPageNew = () => {
  const { setHeightAct, setPlansContent } = useContext(MyContext);
  const [pageData, setPageData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [ipAddress, setIpAddress] = useState("");
  const location = useLocation();

  // console.log(pageData);

  // call the pagelist api and set conter accordigly based on route,(not when redirected from succuss page)
  async function getPageList() {
    try {
      const resp = await GetPageList();
      if (resp.status === 200) {
        const pageInfo = resp.data.response.PageList;
        setPageData(pageInfo);
      }
    } catch (error) {
      // console.log(error, "error getting page list");
    }
  }

  async function sendTrackingCount(data) {
    try {
      const resp = await SendTrackingCount(data);
      if (resp.status === 200) {
        //
        // console.log("tracking count sent successfully!");
      }
    } catch (error) {
      console.error(error, "error sending tracking count");
    }
  }

  async function getIpAddress() {
    const res = await axios.get("https://api.ipify.org/?format=json");

    if (res.status === 200) {
      const userIp = res.data.ip;
      localStorage.setItem("userIp", userIp);
      setIpAddress(userIp);
    } else {
      console.error("Error getting ip address of user");
    }
  }

  useEffect(() => {
    getPageList();
    if (!localStorage.getItem("userIp")) {
      getIpAddress();
    }
  }, []);

  useEffect(() => {
    // console.log(location.state);

    if (!location?.state?.fromSuccess) {
      const ipAddr = localStorage.getItem("userIp");

      // console.log(ipAddr, "ip address");

      if (pageData.length > 0 && ipAddr) {
        //
        let srcId = "";
        const pageId = pageData.filter((page) => page.name === "Landing_page")[0].id;
        if (location.pathname === "/wiseassist") {
          srcId = 26;
        }
        if (location.pathname === "/wiseassist_fb") {
          srcId = 28;
        }

        const data = {
          ip: ipAddr,
          sourceId: srcId,
          pageTypeId: pageId,
        };

        sendTrackingCount(data);
      }
    }
  }, [pageData, location.state, location.pathname, ipAddress]);

  window.addEventListener("scroll", () => {
    let bannerHeight = document.getElementsByClassName("userSection")[0]?.clientHeight;
    let saveTimeHeight = document.getElementsByClassName("technology")[0]?.clientHeight;
    let planHeight = document.getElementsByClassName("suitablePlans")[0]?.clientHeight;
    let savingHeight = document.getElementsByClassName("CS-container")[0]?.clientHeight;
    let warrantyTableHeight = document.getElementsByClassName("engineProtection")[0]?.clientHeight;
    let priceHeight = document.getElementsByClassName("inspection")[0]?.clientHeight;
    let serviceHeight = document.getElementsByClassName("partnerLocation")[0]?.clientHeight;
    let calculateHeight = document.getElementsByClassName("checkUp")[0]?.clientHeight;
    let customersHeight = document.getElementsByClassName("guarenty")[0]?.clientHeight;

    let progress = document.getElementById("progress10");

    let sectionsHeight =
      bannerHeight +
      saveTimeHeight +
      planHeight +
      savingHeight +
      warrantyTableHeight +
      priceHeight +
      serviceHeight +
      calculateHeight;
    if (window.pageYOffset >= sectionsHeight) {
      setHeightAct(true);
      progress.classList.add("warrantyProgress");
    }
  });

  const checkTimer = () => {
    let hours = 24;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem("setupTime");
    if (setupTime == null) {
      localStorage.setItem("setupTime", now);
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem("setupTime", now);
      }
    }
  };

  const secondComponentRef = useRef(null);

  const handleClick = () => {
    secondComponentRef.current.scrollIntoView({ behavior: "smooth" });
    // console.log(secondComponentRef);
  };

  return (
    <div>
      <Header pageData={pageData} />
      <Banner pageData={pageData} />
      <PeaceOfmind />
      <WisedriveProgram />
      <SuitableFacebookPlan pageData={pageData} />
      <Technology pageData={pageData} />
      <Saving />
      <CustomerStoryFacebookLanding pageData={pageData} />
      <PeaceOfmindAllImage />
      <PricingTablesFacebookLandingPage secondComponentRef={secondComponentRef} pageData={pageData} />
      <WarrantyFacebookLandingPage pageData={pageData} />
      <Price pageData={pageData} />
      <Customers />
      <FooterFacebookLandingPage />
    </div>
  );
};

export default FacebookLandingPageNew;
