import React from "react";
import PricingPlanHead from "./pricingPlanHead";
import PlanTables from "./planTables";
import PlanTablesNew from "./planTablesNew";

function PricingTables() {
  return (
    <>
      <PricingPlanHead />
      {/* <PlanTables /> */}
      <PlanTablesNew />
    </>
  );
}

export default PricingTables;
