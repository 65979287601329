import React, { useState, useEffect } from "react";
import shield from "../../assets/images/checkot-plans/Shield.svg";
import tick from "../../assets/images/checkot-plans/tick.svg";
import Avatar from "../../assets/images/checkot-plans/Avatar.svg";
import PurchasePlan from "../../components/popups/purchasePlan";
import Slider from "react-slick";
import InspectionPlanModalWiseAssist from "../../components/popups/InspectionPlanModalWiseAssist";
import { GetAllReviewsInfo } from "../../Api/Apis";
import userimage from "../../assets/icons/usericon.png";

function getRandomObjectsFromArray(array, count) {
  if (array.length > count) {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  } else {
    return array;
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-IN", options);
}

function CustomerStoryFacebookLanding({ pageData }) {
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };
  let sliderConfig = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    infiniteScroll: true,
  };
  const [openPurchasePlanModal, setOpenPurchasePlanModal] = useState(false);
  const [active, setActive] = useState(0);
  const PlansContent = "buyer";

  const [userReviews, setUserReviews] = useState([]);

  async function getAllReviews() {
    try {
      const resp = await GetAllReviewsInfo();
      if (resp.status === 200) {
        // console.log(resp.data);
        const reviews = getRandomObjectsFromArray(resp.data.response.ReviewMaster, 4);
        setUserReviews(reviews);
        // setReviewSummary(resp.data.response.ReviewList);
      }
    } catch (error) {
      console.error("error getting all reviews", error);
    }
  }

  useEffect(() => {
    getAllReviews();
  }, []);

  return (
    <section className="userAssuredBuyBack">
      <div className="container">
        <div className="CS-container">
          <div className="CS-titles-container">
            <div className="CS-Shield">
              <img src={shield} alt="" />
              <span>We guarantee you the BEST car</span>
            </div>
            <div className="CS-title-Wrapper">
              <h3>WiseAssist is </h3>
              <p> India’s most authenticated inspection program</p>
            </div>
            <div className="CS-WD-Container">
              <ul>
                <li>
                  {" "}
                  <img src={tick} alt="" /> We guarantee our inspection through Wisedrive warranty.
                </li>

                <li>
                  {" "}
                  <img src={tick} alt="" /> We save your time, effort and money in identifying the BEST car
                </li>
                <li>
                  {" "}
                  <img src={tick} alt="" /> We protect your investment with our 365 days buyback guarantee program.
                </li>
              </ul>
            </div>
            <div className="CS-BTN-container userBuyBackCsBtn">
              <button
                style={{
                  backgroundColor: "rgb(235,109,71)",
                  color: "white",
                  borderRadius: "10px",
                  border: "none",
                  padding: "10px 20px",
                  fontWeight: "600",
                  width: "300px",
                  height: "52px",
                  letterSpacing: -"-0.1",
                  lineHeight: "21px",
                  fontSize: "14px",
                  marginTop: "20px",
                  marginLeft: "5px",
                  cursor: "context-menu",
                  marginBottom: "30px",
                  cursor: "pointer",
                }}
                onClick={toggleModal}
              >
                {/* Just{" "} */}
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontWeight: "700",
                    fontSize: "28px",
                    color: "#fff",
                  }}
                >
                  Rs 749/-{" "}
                </span>
                <span
                  style={{
                    textDecoration: "line-through",
                    fontWeight: "400",
                    fontSize: "25px",
                    color: "#fff",
                  }}
                >
                  Rs 999/-{" "}
                </span>
              </button>
            </div>
          </div>
          <div className="CS-data-container">
            <div>
              <h2>Customer Story</h2>
            </div>
            <div className="CS-Slider-card">
              <Slider {...sliderConfig}>
                {userReviews &&
                  userReviews.length > 0 &&
                  userReviews.map((review) => (
                    <div className="CS-slider">
                      <div>
                        <p className="slider-comment">{review?.description}</p>
                      </div>
                      <div className="slider-avatar-container">
                        {/* <img className="slider-Avatar" src={Avatar} alt="" /> */}
                        {review?.image ? (
                          <img src={review.image} className="slider-Avatar" alt="Profile" />
                        ) : (
                          <img
                            style={{ borderRadius: "50%" }}
                            className="slider-Avatar"
                            src={userimage}
                            alt="Profile"
                          />
                        )}
                        <div>
                          <h3 className="slider-comment-name">{review?.reviewer_name}</h3>
                          <span className="slider-carBrand">{formatDate(review.date_of_review)}</span>
                        </div>
                      </div>
                    </div>
                  ))}

                {/* <div className="CS-slider">
                  <div>
                    <p className="slider-comment">
                      “One of the best service I have received from wisedrive. Definitely I am going to recommend for my
                      friends and family.”
                    </p>
                  </div>
                  <div className="slider-avatar-container">
                    <img className="slider-Avatar" src={Avatar} alt="" />
                    <div>
                      <h3 className="slider-comment-name">Madhu Naik</h3>
                      <span className="slider-carBrand">Skoda Rapid</span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="CS-slider">
                  <div>
                    <p className="slider-comment">
                      “Wisedrive has been fantastic experience for me. Very trustworthy and quick service. Without a
                      doubt, I would use them once more and suggest them to anyone looking for a top-notch warranty
                      provider.”
                    </p>
                  </div>
                  <div className="slider-avatar-container">
                    <img className="slider-Avatar" src={Avatar} alt="" />
                    <div>
                      <h3 className="slider-comment-name">Abhilash</h3>
                      <span className="slider-carBrand">Skoda Rapid</span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="CS-slider">
                  <div>
                    <p className="slider-comment">
                      “My car has been brought back by Wisedrive as promised at the same price i had bought it for.
                      Thanks to wisedrive”
                    </p>
                  </div>
                  <div className="slider-avatar-container">
                    <img className="slider-Avatar" src={Avatar} alt="" />
                    <div>
                      <h3 className="slider-comment-name">Arjun Kumar</h3>
                      <span className="slider-carBrand">Skoda Rapid</span>
                    </div>
                  </div>
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {openPurchasePlanModal ? (
        <PurchasePlan PlansContent={PlansContent} setOpenPurchasePlanModal={setOpenPurchasePlanModal} active={active} />
      ) : (
        ""
      )}
      <InspectionPlanModalWiseAssist isOpen={isModalOpen} toggler={toggleModal} pageData={pageData} />
    </section>
  );
}

export default CustomerStoryFacebookLanding;
