import React from 'react';
import Career from '../../components/career/career';
import Footer from '../../components/footer';
import Header from '../../components/header';


const Careers = () => {
    return (
        <div>
            <Header />
            <Career />
            <Footer />
        </div>
    )
}

export default Careers