import React from "react";
import BannerImage from "../../assets/images/service-network/serviceNetworkBanner.png";
import PNP from "../../assets/pmp.png";
import styles from "./serviceNetwork.module.css";
import { useEffect } from "react";
import { SendTrackingCount } from "../../Api/Apis";
import axios from "axios";
import Form from "./form";
import NewForm from "./NewForm";
import Batery from "../../assets/CarService.jpg";

function Banner() {
  const getIpAddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");

    if (res.status === 200) {
      const userIp = res.data.ip;
      TrackVisit(userIp);
    } else {
      console.error("Error getting ip address of user");
    }
  };

  const TrackVisit = async (ip) => {
    const data = {
      ip: ip,
      sourceId: 30,
      pageTypeId: 1,
    };
    try {
      const resp = await SendTrackingCount(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIpAddress();
  }, []);
  return (
    <section className={"serviceBanner"} style={{ display: "flex" }}>
      <div className={`container ${styles.NewContainer}`}>
        <div className={`bannerHeading ${styles.new}`}>
          <label>
            {/* 😍 */} &#9742; Contact us at{""}
            <a href="tel:+91-80509-25379"> +91 8050925379</a>{" "}
          </label>
          <h1>
            Get Your Car Service & Repair <br />
            <span>(Upto 50% Discount)</span>
          </h1>
        </div>

        <div className={styles.serviceBannerImage}>
          <img src={Batery} height="250px" width="500px" style={{ borderRadius: "8px" }} />
        </div>
      </div>
      <div className={styles.NewForm}>
        <NewForm />
      </div>
    </section>
  );
}

export default Banner;
