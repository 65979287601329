import React, { useEffect, useState } from "react";
import "./planDetails.css";

import useMediaQuery from "@mui/material/useMediaQuery";
import DoneIcon from "@mui/icons-material/Done";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import axios from "axios";
import { basicAuth } from "../../Api/crmPortal";
import { ChevronRightIcon } from "@radix-ui/react-icons";

const planDetailsData = [
  {
    planTitle: "Basic",
    planHeadeer: "Only Inspection, NO Warranty",
    PlanDescription: "",
    price: "699",
    priceDesc: "/ Per Inspection",
    validDays: "30",
    inspection: "24 hrs",
    planBenifitsSub: [
      { isNew: false, desc: "Engine Health Check & Diagnostic" },
      { isNew: false, desc: "Transmission Assessment" },
      { isNew: false, desc: "Electrical System Analysis" },
      { isNew: false, desc: "Mechanical Components Review" },
      { isNew: false, desc: "Interior Evaluation (With Photos) " },
      { isNew: false, desc: "Exterior Evaluation (With Photos)" },
    ],
    planBenifits: [
      {
        header: "Inspection",
        description: [
          {
            isNew: false,
            desc: "Engine, Transmission, Electrical, Mechanical, Exterior & Interior",
          },
        ],
      },
      {
        header: "No Warranty",
        description: [
          { isNew: false, desc: "No Warranty" },
          { isNew: false, desc: "No Loan protection Gaurantee" },
          { isNew: false, desc: "No Buyback Gaurantee" },
        ],
      },
    ],
    popUpdata: [
      {
        planBenifitsBasic: [
          { isNew: false, desc: "Engine Health Check & Diagnostic" },
          { isNew: false, desc: "Transmission Assessment" },
          { isNew: false, desc: "Electrical System Analysis" },
          { isNew: false, desc: "Mechanical Components Review" },
          { isNew: false, desc: "Interior Evaluation (With Photos) " },
          { isNew: false, desc: "Exterior Evaluation (With Photos)" },
        ],
      },
      {
        planBenifitsPremium: [
          { isNew: false, desc: "OBD-2 Diagnostic Report" },
          { isNew: false, desc: "Engine Control Module Analysis" },
          { isNew: false, desc: "Body Control Module Inspection" },
          { isNew: false, desc: "Accident History Inspection" },
          { isNew: false, desc: "Repair Cost Estimate" },
          { isNew: false, desc: "Finance Validity & Clearance Check" },
          { isNew: false, desc: "Insurance Validity & Expiry Check" },
          { isNew: false, desc: "Traffic Challans Verification" },
          { isNew: false, desc: "Vehicle Crime Records Check" },
        ],
      },
      {
        freeWithPremium: [
          "⁠Expert Advise on Inspection report",
          "⁠Price Recommendation",
          "⁠Repair Estimate ",
        ],
      },
    ],
  },
  {
    planTitle: "Premium",
    planHeadeer: "Inspection with FREE Warranty",
    PlanDescription: "",
    price: "899",
    priceDesc: "/ Per Inspection",
    validDays: "45",
    inspection: "6 hrs",
    planBenifitsSub: [
      { isNew: false, desc: "Engine Health Check & Diagnostic" },
      { isNew: false, desc: "Transmission Assessment" },
      { isNew: false, desc: "Electrical System Analysis" },
      { isNew: false, desc: "Mechanical Components Review" },
      { isNew: false, desc: "Interior Evaluation (With Photos) " },
      { isNew: false, desc: "Exterior Evaluation (With Photos)" },
      { isNew: false, desc: "OBD-2 Diagnostic Report" },
      { isNew: false, desc: "Engine Control Module Analysis" },
      { isNew: false, desc: "Body Control Module Inspection" },
      { isNew: false, desc: "Accident History Inspection" },
      { isNew: false, desc: "Finance Validity & Clearance Check" },
      { isNew: false, desc: "Insurance Validity & Expiry Check" },
      { isNew: false, desc: "Traffic Challans Verification" },
      { isNew: false, desc: "Vehicle Crime Records Check" },
    ],
    planBenifits: [
      {
        header: "Engine Evaluation using Generative Sound AI",
        description: [
          {
            isNew: false,
            desc: "Engine, Transmission, Electrical, Mechanical, Exterior & Interior",
          },
          {
            isNew: true,
            desc: "OBD Report, ECM Analysis, BCM Analysis, TCM Analysis, Police verification, Accident verification & Showroom Service History ",
          },
        ],
      },
      {
        header: "OBD-2 Report",
        description: [
          { isNew: true, desc: "6 Months Warranty" },
          { isNew: true, desc: "6 Months Loan protection Gaurantee" },
          {
            isNew: true,
            desc: "3 Months Buyback Gaurantee (75% of invoice value)",
          },
        ],
      },
      {
        header: "Engine Control Module Check",
        description: [
          { isNew: true, desc: "Buying Price Recommendation" },
          { isNew: true, desc: "Price negotiation with Seller/Dealer" },
          { isNew: true, desc: "Insurance Purchase assistance" },
        ],
      },
      {
        header:
          "Transmission Control Module Check (For automatic transmission)",
        description: [
          {
            isNew: true,
            desc: "State wide RSA for Toeing, minor repair, punture, service & battery jup start (BOA)",
          },
        ],
      },
      {
        header: "Body Control Module Check",
        description: [
          {
            isNew: true,
            desc: "State wide RSA for Toeing, minor repair, punture, service & battery jup start (BOA)",
          },
        ],
      },
      {
        header: " Accident Check (Physical Inspection)",
        description: [
          {
            isNew: true,
            desc: "State wide RSA for Toeing, minor repair, punture, service & battery jup start (BOA)",
          },
        ],
      },
    ],
    freeWithPremium: [
      "⁠Expert Advise on Inspection report",
      "⁠Price Recommendation",
      "⁠Repair Estimate ",
    ],
    popUpdata: [
      {
        planBenifitsBasic: [
          { isNew: false, desc: "Engine Health Check & Diagnostic" },
          { isNew: false, desc: "Transmission Assessment" },
          { isNew: false, desc: "Electrical System Analysis" },
          { isNew: false, desc: "Mechanical Components Review" },
          { isNew: false, desc: "Interior Evaluation (With Photos) " },
          { isNew: false, desc: "Exterior Evaluation (With Photos)" },
        ],
      },
      {
        planBenifitsPremium: [
          { isNew: false, desc: "OBD-2 Diagnostic Report" },
          { isNew: false, desc: "Engine Control Module Analysis" },
          { isNew: false, desc: "Body Control Module Inspection" },
          { isNew: false, desc: "Accident History Inspection" },
          { isNew: false, desc: "Repair Cost Estimate" },
          { isNew: false, desc: "Finance Validity & Clearance Check" },
          { isNew: false, desc: "Insurance Validity & Expiry Check" },
          { isNew: false, desc: "Traffic Challans Verification" },
          { isNew: false, desc: "Vehicle Crime Records Check" },
        ],
      },
      {
        freeWithPremium: [
          "⁠Expert Advise on Inspection report",
          "⁠Price Recommendation",
          "⁠Repair Estimate ",
        ],
      },
    ],
  },
  {
    planTitle: "Super Saver Plan",
    planHeadeer: "Inspection, Warranty and Maintenance",
    PlanDescription: "",
    price: "999",
    priceDesc: "/ per inspection in any 1 state",
    validDays: "45",
    inspection: "6 hrs",
    planBenifits: [
      {
        header: "Inspection",
        description: [
          {
            isNew: false,
            desc: "Engine, Transmission, Electrical, Mechanical, Exterior & Interior",
          },
          {
            isNew: true,
            desc: "OBD Report, ECM Analysis, BCM Analysis, TCM Analysis, Police verification, Accident verification & Showroom Service History",
          },
        ],
      },
      {
        header: "Warranty for 12 Months",
        description: [
          { isNew: true, desc: "1 Year Warranty (E & T)" },
          { isNew: true, desc: "1 Year Loan protection Gaurantee" },
          { isNew: true, desc: "6 Months Buyback Gaurantee" },
        ],
      },
      {
        header: "Pre Purchase Support",
        description: [
          { isNew: true, desc: "Buying Price Recommendation" },
          { isNew: true, desc: "Price negotiation with Seller/Dealer" },
          { isNew: true, desc: "Insurance Purchase assistance" },
        ],
      },
      {
        header: "Post Purchase Support",
        description: [
          { isNew: true, desc: "Insurance claim assistance" },
          { isNew: true, desc: "Documents transfer assistance" },
          { isNew: true, desc: "fastag Recharge" },
        ],
      },
      {
        header: "Road side Assistance",
        description: [
          {
            isNew: true,
            desc: "PAN India RSA for Toeing, minor repair, punture, service & battery jup start (BOA)",
          },
        ],
      },
      {
        header: "Service and Maintenance support",
        description: [
          {
            isNew: true,
            desc: "Flat 30% off on labour charge for any service at Network garage",
          },
          {
            isNew: true,
            desc: "Upto 20% off on Labour charge, wheel balancing & alignment, Consumables, Accessories at Partner Authorised service center",
          },
          {
            isNew: true,
            desc: "Upto 15% discount on Battery, Tyres, Accessories, Minor repair, wheel balancing & alignment at partner outlets",
          },
          {
            isNew: true,
            desc: "Flat 10% discount on Spare Parts, Consumables, Doorstep carwash, 3M car care",
          },
        ],
      },
    ],
  },
];

function PlanDetails() {
  const [isMobile, setIsMobile] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const [openPurchasePlanModal, setOpenPurchasePlanModal] = useState(false);
  const [subscribedPlan, setSubscribedPlan] = useState();
  const [viewMore, setViewMore] = useState();
  const [comparePlanModalOpen, setComparePlanModelOpen] = useState(false);
  const [openBuyNowModal, setBuyNowModalOpen] = useState(false);
  const [viewPlan, setViewPlan] = useState("Inspection");
  const [plantoBuy, setPlanToBuy] = useState(null);
  const [planDetails, setPlanDetails] = useState();
  const [selectedInspection, setSelectedInspection] = useState("premium");

  const [mobileDisplayPlan, setMobileDisplayPlan] = useState(
    planDetailsData?.[0]
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleMediaQueryChange(mediaQuery);
  }, []);

  const filteredPlans = planDetailsData?.filter(
    (plan) => plan.planTitle === viewPlan
  );
  useEffect(() => {
    setMobileDisplayPlan(filteredPlans?.[0]);
  }, [filteredPlans]);

  function parseInspectionFeatures(packages) {
    return packages?.map((item) => ({
      ...item,
      smallPackage: item?.smallPackage
        ? {
            ...item.smallPackage,
            inspectionFeatures: item?.smallPackage?.inspectionFeatures
              ? JSON.parse(item?.smallPackage?.inspectionFeatures)
              : null,
            addonFeatures: item?.smallPackage?.addonFeatures
              ? JSON.parse(item?.smallPackage?.addonFeatures)
              : null,
          }
        : null,
      highPackage: item?.highPackage
        ? {
            ...item.highPackage,
            inspectionFeatures: item?.highPackage?.inspectionFeatures
              ? JSON.parse(item?.highPackage?.inspectionFeatures)
              : null,
            addonFeatures: item?.highPackage?.addonFeatures
              ? JSON.parse(item?.highPackage?.addonFeatures)
              : null,
          }
        : null,
    }));
  }
  console.log(
    "planDetails?.[0]?.highPackage",
    planDetails?.[0]?.highPackage?.addonFeatures?.features[0]
  );

  const getInspectionPlans = async () => {
    const apiUrl = `${process.env.REACT_APP_CRM_URL}/inspectionpackagepairs/activepackagesfortenantid?tenantId=6`;
    try {
      const response = await axios.get(apiUrl, basicAuth);
      const inspectionData = parseInspectionFeatures(response?.data);
      setPlanDetails(inspectionData);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getInspectionPlans();
  }, []);
  return (
    <section className="planDetails">
      <div id="inspection-plans" className="container">
        <div>
          {
            <div className="planDetailsCardsCorosul">
              <div className="plan-container">
                <h4 className="corosulHeader">
                  Protect your car with Wisedrive’s top-rated inspection plans.
                </h4>
                <span className="corosulHeaderRight">
                  <Button
                    className="hero-button-green "
                    sx={{
                      width: "100%",
                      height: "50px",
                      fontSize: "18px",
                      fontFamily: "gilroyMedium",
                      backgroundColor: "#0c286a !important",
                      color: "white !important",
                      "&:hover": {
                        backgroundColor: "#0c286a !important",
                        color: "white",
                      },
                    }}
                    variant="contained"
                    startIcon={<DifferenceOutlinedIcon />}
                    // onClick={() => setComparePlanModelOpen(true)}
                  >
                    <RouterLink
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontSize: "18px",
                      }}
                      target="_blank"
                      to={"/sample-report"}
                    >
                      Sample Inspection Report
                    </RouterLink>
                  </Button>
                </span>
              </div>
              <div className="plan-details-benifits-mobile">
                {planDetails?.map?.((btns, index) => (
                  <>
                    {btns?.packTitle.toLowerCase() !==
                      selectedInspection.toLowerCase() && (
                      <Button
                        className={
                          selectedInspection.toLowerCase() ===
                          btns?.packTitle.toLowerCase()
                            ? "btn-insurance insurance-active"
                            : "btn-insurance"
                        }
                        key={index}
                        onClick={() => setSelectedInspection(btns?.packTitle)}
                        endIcon={
                          <ChevronRightIcon
                            sx={{ width: "25px", height: "25px" }}
                          />
                        }
                      >
                        <div>
                          <h6 style={{ textAlign: "left", color: "#0c286a" }}>
                            {btns?.packTitle}
                          </h6>
                          <h6
                            style={{
                              display: "flex",
                              color: "#0c286a",
                            }}
                          >
                            <h5 style={{ color: "#0c286a" }}>
                              <strong>₹</strong>
                              {Math.round(
                                btns?.highPackage?.totalPrice /
                                  btns?.highPackage?.inspectionCount
                              )}
                              <span className="plan-taxCss">
                                /Per Inspection
                              </span>
                            </h5>
                          </h6>
                        </div>
                      </Button>
                    )}
                    {btns?.packTitle.toLowerCase() ===
                      selectedInspection.toLowerCase() && (
                      <div
                        className="card"
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <div className="corosulCard-header">
                          <div className="corosulcard-title">
                            <div>
                              <h6 className="headerCss">{btns?.packTitle}</h6>
                            </div>
                          </div>
                          <div className="corosulcard-price">
                            <h6
                              style={{
                                display: "flex",
                              }}
                            >
                              <h5>
                                <strong>₹</strong>
                                {Math.round(
                                  btns?.highPackage?.totalPrice /
                                    btns?.highPackage?.inspectionCount
                                )}
                                <span className="plan-taxCss">
                                  /Per Inspection
                                </span>
                              </h5>
                            </h6>
                            <RouterLink
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "18px",
                              }}
                              target="_blank"
                              // to={"/plan-details-buy-now"}
                              to={`/plan-details-buy-now?${new URLSearchParams({
                                InspectionType: btns?.packTitle || "",
                              }).toString()}`}
                            >
                              <Button
                                sx={{
                                  width: "100%",
                                  height: "50px",
                                  fontSize: "18px",
                                  fontFamily: "gilroyBold",
                                  backgroundColor: "#0c286a ",
                                  color: "white",
                                  "&:hover": {
                                    backgroundColor: "#0c286a !important",
                                    color: "white",
                                  },
                                }}
                                variant="contained"
                                // onClick={() => handleBuyNow(planDetailsData?.[0])}
                              >
                                Buy Now
                              </Button>
                            </RouterLink>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "9px",
                            }}
                          >
                            {/* <p>Valid: {planDetailsData?.[0]?.validDays} days </p> */}
                          </div>
                        </div>

                        <div>
                          {btns?.smallPackage?.inspectionFeatures.map(
                            (feature, subIndex) =>
                              feature.isvalid === "Yes" ? (
                                <Typography key={subIndex}>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "nowrap",
                                      fontFamily: "gilroyMedium",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <DoneIcon className="doneIcon" />
                                    {feature?.feature_name}
                                    {/* {descSub?.isvalid} */}
                                  </span>
                                </Typography>
                              ) : null
                          )}
                        </div>
                        <p
                          style={{
                            fontFamily: "gilroySemiBold",
                            marginBottom: "5px",
                            marginTop: "24px",
                          }}
                        >
                          Additional Benifits *
                        </p>
                        <div>
                          <div
                            style={{
                              background: "#DCEEFF",
                              borderRadius: "5px",
                              padding: "2px",
                              paddingBottom: "8px",
                            }}
                          >
                            <p
                              style={{
                                paddingLeft: "20px",
                                fontFamily: "gilroyBold",
                                margin: "2px",
                                marginTop: "5px",
                              }}
                            >
                              {
                                btns?.highPackage?.addonFeatures?.features[0]?.[
                                  "sub-title"
                                ]
                              }
                            </p>
                            {btns?.highPackage?.addonFeatures?.features[0]?.features?.map(
                              (feature, ind) => (
                                <Typography key={ind}>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                      fontFamily: "gilroyMedium",
                                    }}
                                  >
                                    <DoneIcon className="doneIcon" />
                                    {feature?.name}
                                  </span>
                                </Typography>
                              )
                            )}
                          </div>
                        </div>
                        {btns?.packTitle !== "Basic" && (
                          <div
                            style={{
                              background: "#cceed1",
                              borderRadius: "5px",
                              padding: "2px",
                              paddingBottom: "8px",
                            }}
                          >
                            <p
                              style={{
                                paddingLeft: "20px",
                                fontFamily: "gilroyBold",
                                margin: "2px",
                                marginTop: "5px",
                                color: "#25632d !important",
                              }}
                            >
                              {
                                btns?.highPackage?.addonFeatures?.features[1]?.[
                                  "sub-title"
                                ]
                              }
                            </p>
                            {btns?.highPackage?.addonFeatures?.features[1]?.features?.map(
                              (feature, ind) => (
                                <Typography
                                  key={ind}
                                  sx={{ color: "#25632d !important" }}
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                      fontFamily: "gilroyMedium",
                                    }}
                                  >
                                    <DoneIcon className="doneIcon" />
                                    {feature?.name}
                                  </span>
                                </Typography>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ))}
              </div>
              <div className="card-container-two">
                <div className="card">
                  <div className="corosulCard-header">
                    <div className="corosulcard-title">
                      <div>
                        <h6 className="headerCss">
                          {planDetails?.[0]?.packTitle}
                        </h6>
                      </div>
                    </div>
                    <div className="corosulcard-price">
                      <h6
                        style={{
                          display: "flex",
                        }}
                      >
                        <h5>
                          <strong>₹</strong>
                          {Math.round(
                            planDetails?.[0]?.highPackage?.totalPrice /
                              planDetails?.[0]?.highPackage?.inspectionCount
                          )}
                          <span className="plan-taxCss">/Per Inspection</span>
                        </h5>
                      </h6>
                      <RouterLink
                        style={{
                          textDecoration: "none",
                          color: "white",
                          fontSize: "18px",
                        }}
                        target="_blank"
                        // to={"/plan-details-buy-now"}
                        to={`/plan-details-buy-now?${new URLSearchParams({
                          InspectionType: planDetails?.[0]?.packTitle || "",
                        }).toString()}`}
                      >
                        <Button
                          sx={{
                            width: "100%",
                            height: "50px",
                            fontSize: "18px",
                            fontFamily: "gilroyBold",
                            backgroundColor: "#0c286a ",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#0c286a !important",
                              color: "white",
                            },
                          }}
                          variant="contained"
                          // onClick={() => handleBuyNow(planDetailsData?.[0])}
                        >
                          Buy Now
                        </Button>
                      </RouterLink>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "9px",
                      }}
                    >
                      {/* <p>Valid: {planDetailsData?.[0]?.validDays} days </p> */}
                    </div>
                  </div>

                  <div>
                    {planDetails?.[0]?.smallPackage?.inspectionFeatures.map(
                      (feature, subIndex) =>
                        feature.isvalid === "Yes" ? (
                          <Typography key={subIndex}>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "nowrap",
                                fontFamily: "gilroyMedium",
                                flexDirection: "row",
                              }}
                            >
                              <DoneIcon className="doneIcon" />
                              {feature?.feature_name}
                              {/* {descSub?.isvalid} */}
                            </span>
                          </Typography>
                        ) : null
                    )}
                    {/* {planDetails?.[0]?.highPackage?.inspectionFeatures.map(
                      (descSub, ind) => {
                        feature.isvalid === "Yes" ? (
                          <>
                            <Typography key={ind}>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "nowrap",
                                  fontFamily: "gilroyMedium",
                                  flexDirection: "row",
                                }}
                              >
                                <DoneIcon className="doneIcon" />
                                {descSub?.feature_name}
                                {descSub?.isvalid}
                              </span>
                            </Typography>
                          </>
                        ) : null;
                      }
                    )} */}
                  </div>
                  <p
                    style={{
                      fontFamily: "gilroySemiBold",
                      marginBottom: "5px",
                      marginTop: "24px",
                    }}
                  >
                    Additional Benifits *
                  </p>
                  <div>
                    <div
                      style={{
                        background: "#DCEEFF",
                        borderRadius: "5px",
                        padding: "2px",
                        paddingBottom: "8px",
                      }}
                    >
                      <p
                        style={{
                          paddingLeft: "20px",
                          fontFamily: "gilroyBold",
                          margin: "2px",
                          marginTop: "5px",
                        }}
                      >
                        {
                          planDetails?.[0]?.highPackage?.addonFeatures
                            ?.features[0]?.["sub-title"]
                        }
                      </p>
                      {planDetails?.[0]?.highPackage?.addonFeatures?.features[0]?.features?.map(
                        (feature, ind) => (
                          <Typography key={ind}>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                fontFamily: "gilroyMedium",
                              }}
                            >
                              <DoneIcon className="doneIcon" />
                              {feature?.name}
                            </span>
                          </Typography>
                        )
                      )}
                      {/* <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            fontFamily: "gilroyMedium",
                          }}
                        >
                          <DoneIcon className="doneIcon" />
                          Buying price recommendation
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            fontFamily: "gilroyMedium",
                          }}
                        >
                          <DoneIcon className="doneIcon" />
                          Repair Estimate
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            fontFamily: "gilroyMedium",
                          }}
                        >
                          <DoneIcon className="doneIcon" />
                          Discount on insurance purchase
                        </span>
                      </Typography> */}
                    </div>
                  </div>
                </div>
                <div className="card1">
                  <div className="corosulCard-header">
                    <div className="header-section-split">
                      <div style={{ width: "100%" }}>
                        <div className="corosulcard-title">
                          <div>
                            <h6 className="headerCss">
                              {planDetails?.[1]?.packTitle}
                            </h6>
                          </div>
                        </div>
                        <div
                          style={{ marginBottom: "15px" }}
                          className="corosulcard-price"
                        >
                          <h6
                            style={{
                              display: "flex",
                            }}
                          >
                            <h5>
                              <strong>₹</strong>
                              {Math.round(
                                planDetails?.[1]?.highPackage?.totalPrice /
                                  planDetails?.[1]?.highPackage?.inspectionCount
                              )}
                              <span className="plan-taxCss">
                                /Per Inspection
                              </span>
                            </h5>
                          </h6>
                          <RouterLink
                            style={{
                              textDecoration: "none",
                              color: "white",
                              fontSize: "18px",
                            }}
                            target="_blank"
                            // to={"/plan-details-buy-now"}
                            to={`/plan-details-buy-now?${new URLSearchParams({
                              InspectionType: planDetails?.[1]?.packTitle || "",
                            }).toString()}`}
                            // to={{
                            //   pathname: "/plan-details-buy-now",
                            //   state: { key: planDetails?.[1]?.packTitle },
                            // }}
                          >
                            <Button
                              sx={{
                                width: "100%",
                                height: "50px",
                                fontSize: "18px",
                                fontFamily: "gilroyBold",
                                backgroundColor: "#0c286a ",
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#0c286a !important",
                                  color: "white",
                                },
                              }}
                              variant="contained"
                              // onClick={() => handleBuyNow(planDetailsData?.[1])}
                            >
                              Buy Now
                            </Button>
                          </RouterLink>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ width: "50%" }}>
                            {planDetails?.[1]?.smallPackage?.inspectionFeatures?.map(
                              (descSub, ind) => {
                                return (
                                  <div key={ind}>
                                    {ind <
                                      planDetails?.[1]?.smallPackage
                                        ?.inspectionFeatures.length /
                                        2 && (
                                      <Typography key={ind}>
                                        <span
                                          style={{
                                            display: "flex",
                                            fontFamily: "gilroyMedium",
                                            alignItems: "center",
                                            color: "#0c286a",
                                          }}
                                        >
                                          <DoneIcon className="doneIcon" />
                                          {descSub?.feature_name}
                                        </span>
                                      </Typography>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div style={{ width: "50%" }}>
                            {planDetails?.[1]?.smallPackage?.inspectionFeatures?.map(
                              (descSub, ind) => {
                                return (
                                  <div key={ind}>
                                    {ind >=
                                      planDetails?.[1]?.smallPackage
                                        ?.inspectionFeatures.length /
                                        2 && (
                                      <Typography key={ind}>
                                        <span
                                          style={{
                                            display: "flex",
                                            fontFamily: "gilroyMedium",
                                            alignItems: "center",
                                            color: "#0c286a",
                                          }}
                                        >
                                          <DoneIcon className="doneIcon" />
                                          {descSub?.feature_name}
                                        </span>
                                      </Typography>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          paddingTop: "10px",
                          gap: "10px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <h6 style={{ color: "#DCEEFF", fontSize: "20px" }}>
                          FREE
                          <sup className="sup-star">
                            <img src={FreeDoodle} width="30px" height="30px" />
                            
                          </sup>{" "}
                          <span> With 3 Inspection Package</span>
                        </h6>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "flex-start",
                            alignItems: "center",
                            gap: "35px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              borderStyle: "dashed",
                              borderColor: "#C1FF72",
                              width: "50%",
                              height: "100%",
                            }}
                          >
                            <h6
                              style={{
                                color: "#C1FF72",
                                fontSize: "22px",
                                marginTop: "10px",
                              }}
                            >
                              Pre-Purchase support
                            </h6>
                            <h6 style={{ color: "#C1FF72", fontSize: "22px" }}>
                              for all 3 cars
                            </h6>
                            <div style={{ display: "flex" }}>
                              <img
                                src={PPriceAdvice}
                                style={{ margin: "15px" }}
                                width="75px"
                                height="75px"
                                alt="SVG"
                              />
                              <img
                                src={PRepairEstimate}
                                style={{ margin: "15px" }}
                                width="75px"
                                height="75px"
                                alt="SVG"
                              />
                              <img
                                src={PDiscount}
                                style={{ margin: "15px" }}
                                width="75px"
                                height="75px"
                                alt="SVG"
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              height: "100%",
                              width: "50%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              borderStyle: "dashed",
                              borderColor: "#FFE008",
                            }}
                          >
                            <h6
                              style={{
                                color: "#FFE008",
                                fontSize: "22px",
                                marginTop: "10px",
                              }}
                            >
                              Post-Purchase support
                            </h6>
                            <h6 style={{ color: "#FFE008", fontSize: "22px" }}>
                              for ONE approved* cars
                            </h6>
                            <div style={{ display: "flex" }}>
                              <img
                                src={PWarranty}
                                style={{ margin: "15px" }}
                                width="75px"
                                height="75px"
                                alt="SVG"
                              />
                              <img
                                src={PBuyBack}
                                style={{ margin: "15px" }}
                                width="75px"
                                height="75px"
                                alt="SVG"
                              />

                              <img
                                src={PClaimAssist}
                                style={{ margin: "15px" }}
                                width="75px"
                                height="75px"
                                alt="SVG"
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <p
                    style={{
                      fontFamily: "gilroySemiBold",
                      marginBottom: "5px",
                    }}
                  >
                    Additional Benifits *
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "20px",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <div
                        style={{
                          background: "#DCEEFF",
                          borderRadius: "5px",
                          padding: "2px",
                          paddingBottom: "8px",
                        }}
                      >
                        <p
                          style={{
                            paddingLeft: "20px",
                            fontFamily: "gilroyBold",
                            margin: "2px",
                            marginTop: "5px",
                          }}
                        >
                          {
                            planDetails?.[1]?.highPackage?.addonFeatures
                              ?.features[0]?.["sub-title"]
                          }
                          {/* Pre-Purchase Support */}
                        </p>
                        {planDetails?.[1]?.highPackage?.addonFeatures?.features[0]?.features?.map(
                          (feature, ind) => (
                            <Typography key={ind}>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  fontFamily: "gilroyMedium",
                                  color: "#0c286a",
                                }}
                              >
                                <DoneIcon className="doneIcon" />
                                {feature?.name}
                              </span>
                            </Typography>
                          )
                        )}
                        {/* <Typography>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              fontFamily: "gilroyMedium",
                              color: "#0c286a",
                            }}
                          >
                            <DoneIcon className="doneIcon" />
                            Buying price recommendation
                          </span>
                        </Typography>
                        <Typography>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              fontFamily: "gilroyMedium",
                              color: "#0c286a",
                            }}
                          >
                            <DoneIcon className="doneIcon" />
                            Repair Estimate
                          </span>
                        </Typography>
                        <Typography>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              fontFamily: "gilroyMedium",
                              color: "#0c286a",
                            }}
                          >
                            <DoneIcon className="doneIcon" />
                            Discount on insurance purchase
                          </span>
                        </Typography> */}
                      </div>
                    </div>
                    <div style={{ width: "45%" }}>
                      <div
                        style={{
                          background: "#cceed1",
                          borderRadius: "5px",
                          padding: "2px",
                          paddingBottom: "8px",
                        }}
                      >
                        <p
                          style={{
                            paddingLeft: "20px",
                            fontFamily: "gilroyBold",
                            margin: "2px",
                            marginTop: "5px",
                            color: "#25632d !important",
                          }}
                        >
                          {
                            planDetails?.[1]?.highPackage?.addonFeatures
                              ?.features[1]?.["sub-title"]
                          }
                        </p>
                        {planDetails?.[1]?.highPackage?.addonFeatures?.features[1]?.features?.map(
                          (feature, ind) => (
                            <Typography
                              key={ind}
                              sx={{ color: "#25632d !important" }}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  fontFamily: "gilroyMedium",
                                }}
                              >
                                <DoneIcon className="doneIcon" />
                                {feature?.name}
                              </span>
                            </Typography>
                          )
                        )}
                        {/* <Typography sx={{ color: "#25632d !important" }}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              fontFamily: "gilroyMedium",
                            }}
                          >
                            <DoneIcon className="doneIcon" />
                            90 Days Warranty
                          </span>
                        </Typography>
                        <Typography sx={{ color: "#25632d !important" }}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              fontFamily: "gilroyMedium",
                            }}
                          >
                            <DoneIcon className="doneIcon" />
                            30 Days Buyback Gaurantee
                          </span>
                        </Typography>
                        <Typography sx={{ color: "#25632d !important" }}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              fontFamily: "gilroyMedium",
                            }}
                          >
                            <DoneIcon className="doneIcon" />
                            Insurance claim assistance
                          </span>
                        </Typography> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </section>
  );
}

export default PlanDetails;
