import React from "react";
import styles from "../../containers/car-details/car-details.module.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import makemodel from "../../assets/icons/makemodel.png";
import year from "../../assets/icons/year.png";
import fuel from "../../assets/icons/fuel.png";
import noowners from "../../assets/icons/noowners.png";
import kms from "../../assets/icons/kms.png";
import transmission from "../../assets/icons/transmission.png";
import { formatPrice } from "../../utils/commonFunctions";

function CarInfo({ carData }) {
  return (
    <div className={styles.carInfoContainer}>
      <div className={styles.infoTop}>
        <div className={styles.infoTopHeader}>
          <h1 className={styles.h1}>{`${carData?.make} ${carData?.model}`}</h1>
          <h2 className={styles.h2} style={{ display: "flex", alignItems: "center" }}>
            <CurrencyRupeeIcon /> <span>{formatPrice(carData?.price)}</span>
          </h2>
        </div>
        <div className={styles.infoTopTabs} style={{ paddingBottom: 15 }}>
          {carData?.body ? (
            <div className={styles.carInfoPointItem}>
              <span className={styles.infoTopIcon}>
                {/* <DirectionsCarIcon /> */}
                <img src={makemodel} alt="car type" />
              </span>
              <div className={styles.infoPointTexts}>
                <p className={styles.textNormalBold}>{carData?.body}</p>
                <p className={styles.textFade}>Car Type</p>
              </div>
            </div>
          ) : null}
          {carData?.make ? (
            <div className={styles.carInfoPointItem}>
              <span className={styles.infoTopIcon}>
                {/* <AirlineSeatReclineNormalIcon /> */}
                <img src={makemodel} alt="car make" />
              </span>
              <div className={styles.infoPointTexts}>
                <p className={styles.textNormalBold}>{carData?.make}</p>
                <p className={styles.textFade}>Make</p>
              </div>
            </div>
          ) : null}
          {carData?.model ? (
            <div className={styles.carInfoPointItem}>
              <span className={styles.infoTopIcon}>
                {/* <LocalGasStationIcon /> */}
                <img src={makemodel} alt="car model" />
              </span>
              <div className={styles.infoPointTexts}>
                <p className={styles.textNormalBold}>{carData?.model}</p>
                <p className={styles.textFade}>Model</p>
              </div>
            </div>
          ) : null}
          {carData?.manuyear ? (
            <div className={styles.carInfoPointItem}>
              <span className={styles.infoTopIcon}>
                <img src={year} alt="year" />
                {/* <AdjustIcon /> */}
              </span>
              <div className={styles.infoPointTexts}>
                <p className={styles.textNormalBold}>{carData?.manuyear}</p>
                <p className={styles.textFade}>Year</p>
              </div>
            </div>
          ) : null}
          {carData?.fuel ? (
            <div className={styles.carInfoPointItem}>
              <span className={styles.infoTopIcon}>
                {/* <DirectionsCarIcon /> */}
                <img src={fuel} alt="fuel" />
              </span>
              <div className={styles.infoPointTexts}>
                <p className={styles.textNormalBold}>{carData?.fuel}</p>
                <p className={styles.textFade}>Fuel</p>
              </div>
            </div>
          ) : null}
          {carData?.odometer ? (
            <div className={styles.carInfoPointItem}>
              <span className={styles.infoTopIcon}>
                <img src={kms} alt="kms" />
                {/* <AirlineSeatReclineNormalIcon /> */}
              </span>
              <div className={styles.infoPointTexts}>
                <p className={styles.textNormalBold}>{carData?.odometer} Kms</p>
                <p className={styles.textFade}>Km</p>
              </div>
            </div>
          ) : null}
          {carData?.transmission ? (
            <div className={styles.carInfoPointItem}>
              <span className={styles.infoTopIcon}>
                {/* <AdjustIcon /> */}
                <img src={transmission} alt="transmission" />
              </span>
              <div className={styles.infoPointTexts}>
                <p className={styles.textNormalBold}>{carData?.transmission}</p>
                <p className={styles.textFade}>Transmission</p>
              </div>
            </div>
          ) : null}
          {carData?.owner ? (
            <div className={styles.carInfoPointItem}>
              <span className={styles.infoTopIcon}>
                {/* <LocalGasStationIcon /> */}
                <img src={noowners} alt="noowners" style={{ width: "50%", height: "50%" }} />
              </span>
              <div className={styles.infoPointTexts}>
                <p className={styles.textNormalBold}>{carData?.owner}</p>
                <p className={styles.textFade}>{carData?.owner == 1 ? "Owner" : "Owners"}</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CarInfo;
