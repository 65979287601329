import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Arrow from "../../assets/images/home/rightArrow.svg";
import Explore from "../../assets/images/reports/explore5cars.svg";
import ExploreImg from "../../assets/images/reports/exploreImg.png";
import Approved from "../../assets/images/reports/approvedWarranty.png";
import Warranty from "../../assets/images/reports/warranty.svg";

const DreamCar = () => {
  const [count, setCount] = useState(0);

  return (
    <>
      <section className="dreamCar">
        <div className="container">
          <label className="dreamCarLabel">buy Only wisedrive approved cars!</label>
          <div className="commonHeading">
            <h2>
              We know you'll look at <span>5 cars</span> to land on your <span>dream car.</span>
            </h2>
            <Link to={`/payment/3`} className="orangeBtn desktop">
              <span>Subscribe now</span>
              <img src={Arrow} alt="next" />
            </Link>
          </div>
          <div className="dreamCarContainer">
            <div className="dreamCarTitles">
              <div className={`${count === 0 ? "dreamCarCard active" : "dreamCarCard"}`} onClick={() => setCount(0)}>
                <img className="desktop" src={Explore} alt="explore 5 cars" />
                <div>
                  <h4>
                    <img className="mobile" src={Explore} alt="" /> Explore 5 cars
                  </h4>
                  {count === 0 && (
                    <div className="mobile">
                      <img src={ExploreImg} alt="Explore 5 cars" />
                    </div>
                  )}
                  <p>15,000 + Cars available to explore</p>
                </div>
              </div>
              <div className={`${count === 1 ? "dreamCarCard active" : "dreamCarCard"}`} onClick={() => setCount(1)}>
                <img className="desktop" src={Warranty} alt="Wisedrive warranty approved cars" />
                <div>
                  <h4>
                    <img className="mobile" src={Warranty} alt="" /> Wisedrive warranty approved cars
                  </h4>
                  {count === 1 && (
                    <div className="mobile">
                      <img src={Approved} alt="Wisedrive warranty approved cars" />
                    </div>
                  )}
                  <p>We do necessary checks done by unnecessary people</p>
                </div>
              </div>
            </div>
            <div className="dreamCarimages desktop">
              {count === 0 && (
                <div>
                  <img src={ExploreImg} alt="Explore 5 cars" />
                </div>
              )}
              {count === 1 && (
                <div>
                  <img src={Approved} alt="Wisedrive warranty approved cars" />
                </div>
              )}
            </div>
          </div>
          <Link to="/payment/3" className="orangeBtn mobile">
            <span>Subscribe now</span>
            <img src={Arrow} alt="next" />
          </Link>
        </div>
      </section>
    </>
  );
};

export default DreamCar;
