import React from "react";
import { Link } from "react-router-dom";
import StepOne from '../../assets/images/claims/stepOne.png';
import StepTwo from '../../assets/images/claims/stepTwo.png';
import StepThree from '../../assets/images/claims/stepThree.png';
import StepFour from '../../assets/images/claims/stepFour.png';
import StepOneBg from '../../assets/images/claims/stepOneBg.png';
import StepTwoBg from '../../assets/images/claims/stepTwoBg.png';
import RightArrow from '../../assets/images/claims/rightArrow.svg';

const ClaimSteps = () => {

  return (
    <section className="claimSteps">
      <div className="container">
        <div className="commonHeading">
          <h2>We made it simple, transparent and <span>Trustworthy</span> for you</h2>
          <p>If we cannot repair it, we will buy it.</p>
        </div>
        <div className="claimContent">
          <div className="stepsWrapper stepsLeft">
            <div className="stepsImage">
              <div className="imageWrapper">
                <img src={StepOne} alt="step 1" />                
              </div>
              <p className="textMobile">As per the extended warranty policy you can apply for claim of Engine and Transmission. We made it easy. You can apply through our mobile app (available on app store and play strore) or call us at <a href="tel:+917353343536">+917353343536</a> anytime between 8am to 8pm from Monday to Saturday.</p>
            </div>            
            <div className="stepsText">
              <h3>Step 1</h3>
              <h4>Apply for engine or transmission claim</h4>
              <p className="textDesk">As per the extended warranty policy you can apply for claim of Engine and Transmission. We made it easy. You can apply through our mobile app (available on app store and play strore) or call us at <a href="tel:+917353343536">+917353343536</a> anytime between 8am to 8pm from Monday to Saturday.</p>
            </div>
          </div>
          <div className="stepOneRoadmap">                      
            <img src={StepOneBg} className='stepOneBg' alt="step 1" />
          </div>
          <div className="stepsWrapper stepsRight">                      
            <div className="stepsText">
              <h3>Step 2</h3>
              <h4>Claim has to be approved</h4>
              <p className="textDesk">Once you apply for warranty claim our team will check the vehicle and approve the claim based on extended warranty policy. Along with the approval you will also receive the timeline for repair / replace parts for your warranty claim. This will not exceed 60 days from the date of approval by customer.</p>
            </div>
            <div className="stepsImage">
              <div className="imageWrapper">
                <img src={StepTwo} alt="step 1" />                
              </div>
              <p className="textMobile">Once you apply for warranty claim our team will check the vehicle and approve the claim based on extended warranty policy. Along with the approval you will also receive the timeline for repair / replace parts for your warranty claim. This will not exceed 60 days from the date of approval by customer.</p>
            </div>  
          </div>
          <div className="stepOneRoadmap">                      
            <img src={StepTwoBg} className='stepTwoBg' alt="step 1" />
          </div>
          <div className="stepsWrapper stepsLeft mt-0 stepThree">
            <div className="stepsImage">
              <div className="imageWrapper">
                <img src={StepThree} alt="step 1" />                
              </div>
              <p className="textMobile">Instant buyback approval agreement will be generated and sent to you for approval If your vehicle cannot be repaired with the timeline provided during the claim approval process (which should not exceed 60 days) WE WILL BUYBACK YOUR CAR AT THE PRICE YOU BOUGHT IT!</p>
            </div>            
            <div className="stepsText">
              <h3>Step 3</h3>
              <h4>If can’t repair in 60 days</h4>
              <p className="textDesk">Instant buyback approval agreement will be generated and sent to you for approval If your vehicle cannot be repaired with the timeline provided during the claim approval process (which should not exceed 60 days) WE WILL BUYBACK YOUR CAR AT THE PRICE YOU BOUGHT IT!</p>
            </div>
          </div>
          <div className="stepOneRoadmap">                      
            <img src={StepOneBg} className='stepOneBg' alt="step 1" />
          </div>
          <div className="stepsWrapper stepsRight">                      
            <div className="stepsText">
              <h3>Step 4</h3>
              <h4>We’ll buyback</h4>
              <p className="textDesk">Buy another car and Enjoy the drive ...</p>
            </div>
            <div className="stepsImage">
              <div className="imageWrapper">
                <img src={StepFour} alt="step 1" />                
              </div>
              <p className="textMobile">Buy another car and Enjoy the drive ...</p>
            </div>  
          </div>
        </div>
        <div className="terms">
          <h2>Terms & Conditions</h2>
          <p>At the time of purchase your seller will create the activation code and share it with you.</p>
          <ul>
            <li>A Car should be eligible for Extended Warranty claim as per the extended warranty policy of Wisedrive Technologies Pvt Ltd.</li>
            <li>Car should be approved for Extended Warranty claim by claim management team.</li>
            <li>Buyback guarantee is triggered from the day when the customer gives approval for start of warranty repairs for the car.</li>
            <li>Customer should activate buyback guarantee and extended warranty within 48 hrs of purchase of the product.</li>
            <li>Customer should provide the original purchase receipt and signed buyback guarantee agreement to activate the program.</li>
          </ul>
          <Link className="termsLink" to='/terms-and-conditions' >
            Read Complete T&C <img src={RightArrow} alt="read complete t&c" />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default ClaimSteps;