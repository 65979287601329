import React from "react";
import "./payment-success.css";
import previous from "../../assets/images/payment-success/previousArrow.svg";
import checkTick from "../../assets/images/payment-success/checkCircle.svg";
import creditScore from "../../assets/images/payment/creditScore.svg";
import purchaseScan from "../../assets/images/payment/purchaseScan.svg";
import rightArrow from "../../assets/images/payment/rightArrow.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { paymentConfirmationApi } from "../../Api/Apis";
import { useEffect } from "react";
import { useState } from "react";
import CryptoJS from "crypto-js";

function PaymentCompleted() {
  const navigate = useNavigate();
  const data = useParams();
  const [packageList, setPackageList] = useState([]);
  const [expiryDate, setExpiryDate] = useState("");
  const transactionId = sessionStorage.getItem("refeId");
  const vehicleNumber = sessionStorage.getItem("vehicleNumber");
  const vehicleMake = sessionStorage.getItem("vehicleMake");
  const vehicleModel = sessionStorage.getItem("vehicleModel");
  const message = sessionStorage.getItem("message");

  const paymentConfirmation = async () => {
    try {
      const resp = await paymentConfirmationApi(data.id);

      if (resp.status === 200) {
        console.log(resp);
        setExpiryDate(resp.data.response.ConfirmationList[0].valid_to);
        setPackageList(resp.data.response.ConfirmationList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    paymentConfirmation();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

  useEffect(() => {
    paymentConfirmation();
  }, []);

  const handleLinkClick = (event) => {
    event.preventDefault();
    sessionStorage.clear();
    navigate(`/`);
  };

  return (
    <section className="paymentSuccess">
      <div className="container">
        <div className="purchaseSuccessArrowMobile mobile">
          {/* <img onClick={() => {navigate('/owner')}} className="previousArrow" src={previous} alt="previous" /> */}
          <div>
            <h2>Your purchase was Successful</h2>
            <p>{message}</p>
          </div>
        </div>
        <div className="purchaseSuccess">
          <img
            className="previousArrow"
            src={previous}
            alt="previous"
            onClick={() => {
              navigate("/");
            }}
          />
          <div className="purchaseIncludes">
            <div className="desktop">
              <h2>Your purchase was Successful</h2>
              <p>{message}</p>
            </div>
            <h4>What’s Included</h4>
            <ul>
              {packageList &&
                packageList.map((each, index) => {
                  return (
                    <li key={index}>
                      <img src={checkTick} alt="tick" />
                      {`${each.validity_days} days`} {each.product_name}
                    </li>
                  );
                })}
              {/* <li>
                <img src={checkTick} alt="tick" />6 Months Comprehensive Warranty
              </li>
              <li>
                <img src={checkTick} alt="tick" />6 Months Buyback Guarantee
              </li>
              <li>
                <img src={checkTick} alt="tick" />1 Year Free Service at Authorised Service center
              </li>
              <li>
                <img src={checkTick} alt="tick" />
                24/7 Free Roadside assistance
              </li>
              <li>
                <img src={checkTick} alt="tick" />
                Engine Gear box, Turbo & AC Compressor warranty
              </li>
              <li>
                <img src={checkTick} alt="tick" />1 Year Free Towing Service
              </li> */}
            </ul>
          </div>

          <div className="purchaseDone">
            <div className="purchaseWelcome">
              <div className="creditScoreImage">
                <img src={creditScore} alt="image" />
              </div>
              <h3>Welcome to Wisedrive Family</h3>
            </div>
            <div className="purchaseDoneContent">
              <div className="purchaseText">
                <div className="purchaseInfo">
                  <p>{vehicleNumber}</p>
                  <h4>
                    {vehicleMake} {vehicleModel}
                  </h4>
                </div>
                <div className="purchaseInfo">
                  <p>Transaction ID</p>
                  <h4>{transactionId}</h4>
                </div>
                {/* <div className="purchaseInfo">
                  <p>Paid on</p>
                  <h4></h4>
                </div> */}
                {/* <div className="purchaseInfo">
                  <p>Expire Date</p>
                  <h4>{formatDate(expiryDate)}</h4>
                </div> */}
              </div>
              <div className="purchaseScan" style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                {/* <img className="desktop" src={purchaseScan} alt="image" /> */}
                <div className="purchaseDownloadApp">
                  {/* <h4>Scan to Download</h4> */}
                  <span onClick={handleLinkClick}>Back to home</span> <img src={rightArrow} alt="arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PaymentCompleted;
