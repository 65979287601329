import React, { useRef, useEffect, useState } from "react";
import Tabs from "react-responsive-tabs";
import "react-responsive-tabs/styles.css";
import Check from "../../assets/images/warranty-benefits/Check.png";
import General from "../../assets/images/warranty-benefits/generalService.png";
import AC from "../../assets/images/warranty-benefits/acService.png";
import Batery from "../../assets/images/warranty-benefits/battery.png";
import Puncture from "../../assets/images/warranty-benefits/puncture.png";
import Minor from "../../assets/images/warranty-benefits/minor.png";
import Shield from "../../assets/images/owner-home/shield.svg";
import slideArrow from "../../assets/images/warranty-benefits/scrollArrow.svg";
import logo from "../../assets/images/priceplan-logos/logo-4.png";

function CheckUp() {
  useEffect(() => {
    const Div = document.getElementsByClassName("RRT__tab");
    const free = document.createElement("span");

    free.innerHTML = "Free";
    for (let index = 0; index < Div.length; index++) {
      Div[index].innerHTML = `<span>Free </span>${tabs[index].title}`;
    }

    const RRT__tabs = elementRef?.current?.children[0]?.children[0];

    RRT__tabs.onscroll = () => {
      const LeftArrow = document.getElementsByClassName("scrollArrowLeft")[0];

      if (RRT__tabs.scrollLeft > RRT__tabs.scrollWidth - RRT__tabs.clientWidth - 2) {
        RRT__tabs.parentElement.nextSibling.style.display = "none";
        LeftArrow.style.display = "";
      } else {
        RRT__tabs.parentElement.nextSibling.style.display = "";
        LeftArrow.style.display = "none";
      }
    };
  }, []);

  const tabs = [
    {
      title: "General Service",
      content: {
        img: General,
        list: ["Engine Oil", "Oil Filter", "Air Filter", "Coolant", "Brake Oil"],
        list2: ["Wiper Fluid", "Brakes Servicing", "Tyres check up", "Car Wash"],
      },
    },
    {
      title: "AC Service",
      content: {
        img: AC,
        list: ["AC Gas Top up (upto 400 gm)n", "AC Filter Cleaning", "AC System checkup"],
        list2: ["AC Vent Cleaning", "AC Duct Cleaning"],
      },
    },
    {
      title: "Complete Checkup",
      content: {
        img: Check,
        list: [
          "Coolant (upto 500 ml)",
          "Brake Oil (upto 100ml)",
          "Wiper Fluid",
          "Air Filter Cleaning",
          "AC Filter Cleaning",
        ],
        list2: ["Radiator Fan Check", "Engine Oil Check", "Tyre Pressure Check", "Electrical Check"],
      },
    },
    {
      title: "Battery Jump Start",
      content: {
        img: Batery,
        list: ["Battery Jump Start at Doorstep"],
      },
    },
    {
      title: "Puncture Repair",
      content: {
        img: Puncture,
        list: ["Puncture Repair at your Doorstep"],
      },
    },
    {
      title: "Minor Repair",
      content: {
        img: Minor,
        list: ["Mechanic assistance at Roadside within city limits"],
      },
    },
  ];

  const elementRef = useRef(null);
  const sectionRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    // check here the bug
    // console.log("checking horizontal scroll");
    const ele = elementRef?.current?.children[0]?.children[0];

    let scrollAmount = 0;
    // console.log(element);

    // if (element) {
    //   const slideTimer = setInterval(() => {
    //     // element.scrollLeft += step;
    //     // element.scrollLeft += step;
    //     element.scrollLeft += step;

    //     console.log(element.scrollLeft);

    //     scrollAmount += Math.abs(step);
    //     if (scrollAmount >= distance) {
    //       clearInterval(slideTimer);
    //     }
    //     if (element.scrollLeft === 0) {
    //       setArrowDisable(true);
    //     } else {
    //       setArrowDisable(false);
    //     }
    //   }, speed);
    // }

    if (ele) {
      const slideTimer = setInterval(() => {
        // ele.scrollLeft += step;
        // ele.scrollLeft += step;
        ele.scrollLeft += step;

        // console.log(ele.scrollLeft);

        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
        if (ele.scrollLeft === 0) {
          setArrowDisable(true);
        } else {
          setArrowDisable(false);
        }
      }, speed);
    }

    // if (element) {
    //   element.scrollLeft = element.scrollLeft + step;
    //   // console.log("element.scrollLeft: " + element.scrollLeft);
    // }
  };

  function getTabs() {
    return tabs.map((tab, index) => ({
      title: tab.title,
      showMore: false,
      getContent: () => {
        return (
          <>
            <div className="checkUpContainerImgSkeleton">
              <img src={tab.content.img} alt="" />
            </div>
            <div className="checkUpContainerAnswerList">
              <ul>
                {tab.content.list?.map((li, i) => {
                  return <li key={i}>{li}</li>;
                })}
              </ul>
              <ul>
                {tab.content.list2?.map((li, i) => {
                  return <li key={i}>{li}</li>;
                })}
              </ul>
            </div>
          </>
        );
      },
      /* Optional parameters */
      key: index,
      tabClassName: "tab",
      panelClassName: "checkUpContainerAnswer",
    }));
  }

  return (
    <section className="checkUp" style={{ paddingTop: 60 }} ref={sectionRef}>
      <div className="container">
        {/* <h4>
          <img src={Shield} alt="Shield" />
          <span>
            Serviced at OEM Authorised Service center Quality & Resale value
            Guaranteed
          </span>
        </h4> */}
        <div className="commonHeading">
          <h2>
            Service and Maintenance made <span>Simple & Trustworthy</span>
          </h2>
          <img src={logo} alt="oem authorised service center" className="checkout-logo" />
        </div>
        <div className="forwardArrow" ref={elementRef}>
          <Tabs items={getTabs()} />
          <img
            onClick={() => {
              handleHorizantalScroll(elementRef.current, 25, 100, 10);
            }}
            className="scrollArrow"
            src={slideArrow}
            alt="arrow"
          />

          <img className="scrollArrowLeft" style={{ display: "none" }} src={slideArrow} alt="arrow" />
        </div>
      </div>
    </section>
  );
}

export default CheckUp;
