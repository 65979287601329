import React from 'react';

import Header from '../../components/header';
import Footer from '../../components/footer';

import './about.css';
import Banner from './banner';
import Mission from './mission';
import Members from './members';
import Career from '../../components/career/career';

const About = () => {
  return (
    <div>
      <Header />
      <Banner />
      <Mission />
      <Members />
      <Career />
      <Footer />
    </div>
  );
};

export default About;
