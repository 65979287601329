
const markerArray = [
    {
        latitudeCoordinate : 18.489467,
        longitudeCoordinate : 78.046755,
    },
    {
        latitudeCoordinate : 18.509467,
        longitudeCoordinate : 78.946755,
    },
    {
        latitudeCoordinate : 18.569467,
        longitudeCoordinate : 78.56755,
    },
    {
        latitudeCoordinate : 18.409467,
        longitudeCoordinate : 78.446755,
    },
    {
        latitudeCoordinate : 18.609467,
        longitudeCoordinate : 78.121755,
    },
    {
        latitudeCoordinate : 18.719467,
        longitudeCoordinate : 78.816755,
    },
    {
        latitudeCoordinate : 18.019467,
        longitudeCoordinate : 78.006755,
    },
    {
        latitudeCoordinate : 18.124467,
        longitudeCoordinate : 78.806755,
    },
    {
        latitudeCoordinate : 18.559467,
        longitudeCoordinate : 78.556755,
    },
    {
        latitudeCoordinate : 18.369467,
        longitudeCoordinate : 78.366755,
    },

    {
        latitudeCoordinate : 18.779467,
        longitudeCoordinate : 78.776755,
    },
    {
        latitudeCoordinate : 18.426940,
        longitudeCoordinate : 79.222292,
    },
    {
        latitudeCoordinate : 17.444427,
        longitudeCoordinate : 79.024538,
    },
    {
        latitudeCoordinate : 18.406093,
        longitudeCoordinate : 80.178102,
    },
    {
        latitudeCoordinate : 19.579936,
        longitudeCoordinate : 78.683962,
    },
    {
        latitudeCoordinate : 18.752,
        longitudeCoordinate : 78.025,
    },
    {
        latitudeCoordinate : 18.9652,
        longitudeCoordinate : 78.78465,
    },
    {
        latitudeCoordinate : 18.86,
        longitudeCoordinate : 78.8689,
    },
    {
        latitudeCoordinate : 18.845485,
        longitudeCoordinate : 78.3543,
    },
    {
        latitudeCoordinate : 18.519847,
        longitudeCoordinate : 78.653,
    },
    {
        latitudeCoordinate : 18.548948,
        longitudeCoordinate : 78.357753,
    },
    {
        latitudeCoordinate : 18.6874531,
        longitudeCoordinate : 78.084657,
    },
    {
        latitudeCoordinate : 18.35,
        longitudeCoordinate : 78.9782,
    },
    {
        latitudeCoordinate : 18.2895,
        longitudeCoordinate : 78.4298,
    },
    {
        latitudeCoordinate : 18.38795,
        longitudeCoordinate : 78.9483,
    },

    {
        latitudeCoordinate : 18.7589,
        longitudeCoordinate : 78.463,
    },
    {
        latitudeCoordinate : 18.029834,
        longitudeCoordinate : 79.2093487,
    },
    {
        latitudeCoordinate : 17.587,
        longitudeCoordinate : 79.2345,
    },
    {
        latitudeCoordinate : 18.65908,
        longitudeCoordinate : 80.634,
    },
    {
        latitudeCoordinate : 19.631683,
        longitudeCoordinate :  78.749880,
    },
    {
        latitudeCoordinate : 19.489,
        longitudeCoordinate :  78.537,
    },
    {
        latitudeCoordinate : 19.61849,
        longitudeCoordinate :  78.316,
    },
    {
        latitudeCoordinate : 19.375,
        longitudeCoordinate :  78.9408275,
    },
    {
        latitudeCoordinate : 18.63184,
        longitudeCoordinate : 80.897,
    },
    {
        latitudeCoordinate : 19.32684,
        longitudeCoordinate :  78.252,
    },
    {
        latitudeCoordinate : 18.34,
        longitudeCoordinate : 80.09,
    },
    {
        latitudeCoordinate : 19.357,
        longitudeCoordinate :  78.876,
    },
    {
        latitudeCoordinate : 19.34, 
        longitudeCoordinate :  79.5678,
    },
    {
        latitudeCoordinate : 19.4865, 
        longitudeCoordinate :  79.25,
    },
    {
        latitudeCoordinate : 19.0215, 
        longitudeCoordinate :  79.568,
    },
    {
        latitudeCoordinate : 19.47856, 
        longitudeCoordinate :  79.3256,
    },
    {
        latitudeCoordinate : 17.779510, 
        longitudeCoordinate :  78.387331,
    },
    {
        latitudeCoordinate : 17.125, 
        longitudeCoordinate :  78.8965,
    },
    {
        latitudeCoordinate : 17.3254, 
        longitudeCoordinate :  78.02356,
    },
    {
        latitudeCoordinate : 17.87465, 
        longitudeCoordinate :  78.236,
    },
    {
        latitudeCoordinate : 17.95, 
        longitudeCoordinate :  78.235,
    },
    {
        latitudeCoordinate : 17.968, 
        longitudeCoordinate :  78.5847,
    },
    {
        latitudeCoordinate : 17.987, 
        longitudeCoordinate :  78.47,
    },
    {
        latitudeCoordinate : 17.859, 
        longitudeCoordinate :  78.658,
    },
    {
        latitudeCoordinate : 17.358, 
        longitudeCoordinate :  78.48956,
    },
    {
        latitudeCoordinate : 17.98, 
        longitudeCoordinate :  78.6485,
    },
    {
        latitudeCoordinate : 17.821352, 
        longitudeCoordinate :  80.068239,
    },

    {
        latitudeCoordinate : 17.53, 
        longitudeCoordinate :  80.875,
    },{
        latitudeCoordinate : 17.8647, 
        longitudeCoordinate :  80.8573,
    },
    {
        latitudeCoordinate : 17.8764, 
        longitudeCoordinate :  80.23154,
    },
    {
        latitudeCoordinate : 17.821352, 
        longitudeCoordinate :  80.068239,
    },
    {
        latitudeCoordinate : 17.32154, 
        longitudeCoordinate :  80.98745,
    },
    {
        latitudeCoordinate : 16.625098, 
        longitudeCoordinate :  78.156618,
    },
    {
        latitudeCoordinate : 16.986, 
        longitudeCoordinate :  78.2365,
    },
    {
        latitudeCoordinate : 16.896, 
        longitudeCoordinate :  78.798,
    },
    {
        latitudeCoordinate : 16.625098, 
        longitudeCoordinate :  78.156618,
    },
    {
        latitudeCoordinate : 16.26, 
        longitudeCoordinate :  78.14,
    },
    {
        latitudeCoordinate : 16.865, 
        longitudeCoordinate :  78.4268594,
    },
    {
        latitudeCoordinate : 16.9654, 
        longitudeCoordinate :  78.23145,
    },
    {
        latitudeCoordinate : 16.965, 
        longitudeCoordinate :  78.365,
    },
    {
        latitudeCoordinate : 17.192709, 
        longitudeCoordinate :  79.398073,
    },
    {
        latitudeCoordinate : 17.968, 
        longitudeCoordinate :  79.256,
    },
    {
        latitudeCoordinate : 17.14, 
        longitudeCoordinate :  79.3125,
    },
    {
        latitudeCoordinate : 17.8254, 
        longitudeCoordinate :  79.214,
    },
    {
        latitudeCoordinate : 17.9784, 
        longitudeCoordinate :  79.7845,
    },
    {
        latitudeCoordinate : 17.98, 
        longitudeCoordinate :  79.2154,
    },
    {
        latitudeCoordinate : 17.2365, 
        longitudeCoordinate :  79.03256,
    },
    {
        latitudeCoordinate : 17.037427, 
        longitudeCoordinate :  76.148089,
    },
    {
        latitudeCoordinate : 17.87, 
        longitudeCoordinate :  76.98765,
    },
    {
        latitudeCoordinate : 17.02356, 
        longitudeCoordinate :  76.231564,
    },
    {
        latitudeCoordinate : 17.3265, 
        longitudeCoordinate :  76.78456,
    },
    {
        latitudeCoordinate : 17.985, 
        longitudeCoordinate :  76.98765,
    },
    {
        latitudeCoordinate : 17.21564, 
        longitudeCoordinate :  76.3256,
    },
    {
        latitudeCoordinate : 16.184843, 
        longitudeCoordinate :  75.982208,
    },
    {
        latitudeCoordinate : 16.897465, 
        longitudeCoordinate :  75.8975,
    },
    {
        latitudeCoordinate : 16.215, 
        longitudeCoordinate :  75.0256,
    },
    {
        latitudeCoordinate : 16.02, 
        longitudeCoordinate :  75.01245,
    },
    {
        latitudeCoordinate : 16.78456, 
        longitudeCoordinate :  75.78456,
    },
    {
        latitudeCoordinate : 16.985, 
        longitudeCoordinate :  75.95,
    },
    {
        latitudeCoordinate : 16.806593, 
        longitudeCoordinate :  76.826693,
    },
    {
        latitudeCoordinate : 16.8956, 
        longitudeCoordinate :  76.9561,
    },
    {
        latitudeCoordinate : 16.7845, 
        longitudeCoordinate :  76.78452,
    },
    {
        latitudeCoordinate : 16.025, 
        longitudeCoordinate :  76.025,
    },
    {
        latitudeCoordinate : 16.2154, 
        longitudeCoordinate :  76.32154,
    },
    {
        latitudeCoordinate : 16.3256, 
        longitudeCoordinate :  76.3256,
    },
    {
        latitudeCoordinate : 16.9846, 
        longitudeCoordinate :  76.69845,
    },
    {
        latitudeCoordinate : 14.935516,  
        longitudeCoordinate :  75.620286,
    },
    {
        latitudeCoordinate : 14.2356,  
        longitudeCoordinate :  75.23568,
    },
    {
        latitudeCoordinate : 14.98465,  
        longitudeCoordinate :  75.986453,
    },
    {
        latitudeCoordinate : 14.784562,  
        longitudeCoordinate :  75.7845,
    },
    {
        latitudeCoordinate : 14.325,  
        longitudeCoordinate :  75.65284,
    },
    {
        latitudeCoordinate : 14.654,  
        longitudeCoordinate :  75.3625,
    },
    {
        latitudeCoordinate : 14.3254,  
        longitudeCoordinate :  75.254,
    },
    {
        latitudeCoordinate : 14.5784,  
        longitudeCoordinate :  75.3980,
    },
    {
        latitudeCoordinate : 14.09842375,  
        longitudeCoordinate :  75.897432,
    },
    {
        latitudeCoordinate : 13.444327,   
        longitudeCoordinate :  75.891728,
    },
    {
        latitudeCoordinate : 13.7845,   
        longitudeCoordinate :  75.2145,
    },
    {
        latitudeCoordinate : 13.346,   
        longitudeCoordinate :  75.356,
    },
    {
        latitudeCoordinate : 13.8745,   
        longitudeCoordinate :  75.7845,
    },
    {
        latitudeCoordinate : 13.98465,   
        longitudeCoordinate :  75.98465,
    },
    {
        latitudeCoordinate : 13.6584,   
        longitudeCoordinate :  75.25,
    },
    {
        latitudeCoordinate : 13.8576435,   
        longitudeCoordinate :  75.8574,
    },
    {
        latitudeCoordinate : 12.867136,    
        longitudeCoordinate :  76.626075,
    },
    {
        latitudeCoordinate : 12.9856,    
        longitudeCoordinate :  76.03256,
    },
    {
        latitudeCoordinate : 12.49685,    
        longitudeCoordinate :  76.21,
    },
    {
        latitudeCoordinate : 12.02154,    
        longitudeCoordinate :  76.3256,
    },
    {
        latitudeCoordinate : 12.26589,    
        longitudeCoordinate :  76.85631,
    },
    {
        latitudeCoordinate : 12.9875,    
        longitudeCoordinate :  76.531,
    },
    {
        latitudeCoordinate : 12.6584,    
        longitudeCoordinate :  76.3625,
    },
    {
        latitudeCoordinate : 14.848883,  
        longitudeCoordinate :   74.468155,
    },
    {
        latitudeCoordinate : 14.498,  
        longitudeCoordinate :   74.789456,
    },
    {
        latitudeCoordinate : 14.95826,  
        longitudeCoordinate :   74.96825,
    },
    {
        latitudeCoordinate : 14.87945,  
        longitudeCoordinate :   74.35,
    },
    {
        latitudeCoordinate : 14.8745,  
        longitudeCoordinate :   74.9586,
    },
    {
        latitudeCoordinate : 14.7845,  
        longitudeCoordinate :   74.3657,
    },
    {
        latitudeCoordinate : 14.9856,  
        longitudeCoordinate :   74.32157,
    },
    {
        latitudeCoordinate : 13.364118, 
        longitudeCoordinate :   77.659524,
    },
    {
        latitudeCoordinate : 13.6489, 
        longitudeCoordinate :   77.2547,
    },
    {
        latitudeCoordinate : 13.784356, 
        longitudeCoordinate :   77.3246,
    },
    {
        latitudeCoordinate : 13.9865, 
        longitudeCoordinate :   77.0125,
    },
    {
        latitudeCoordinate : 13.7845, 
        longitudeCoordinate :   77.356,
    },
    {
        latitudeCoordinate : 13.8671, 
        longitudeCoordinate :   77.2154,
    },
    {
        latitudeCoordinate : 13.98561, 
        longitudeCoordinate :   77.2154,
    },
    {
        latitudeCoordinate : 12.708105, 
        longitudeCoordinate :   77.142816,
    },
    {
        latitudeCoordinate : 12.79846, 
        longitudeCoordinate :   77.32564,
    },
    {
        latitudeCoordinate : 12.32165, 
        longitudeCoordinate :   77.9451,
    },
    {
        latitudeCoordinate : 12.852, 
        longitudeCoordinate :   77.258,
    },
    {
        latitudeCoordinate : 12.357, 
        longitudeCoordinate :   77.65213,
    },
    {
        latitudeCoordinate : 12.226, 
        longitudeCoordinate :   77.896425,
    },
    {
        latitudeCoordinate : 12.364, 
        longitudeCoordinate :   77.8521,
    },
    {
        latitudeCoordinate : 12.625, 
        longitudeCoordinate :   77.83465,
    },
    {
        latitudeCoordinate : 15.553139, 
        longitudeCoordinate :   75.494295,
    },
    {
        latitudeCoordinate : 15.9648, 
        longitudeCoordinate :   75.494295,
    },
    {
        latitudeCoordinate : 15.21458, 
        longitudeCoordinate :   75.75,
    },
    {
        latitudeCoordinate : 15.9585, 
        longitudeCoordinate :   75.326,
    },
    {
        latitudeCoordinate : 15.62581, 
        longitudeCoordinate :   75.2684,
    },
    {
        latitudeCoordinate : 15.26548, 
        longitudeCoordinate :   75.8956,
    },
    {
        latitudeCoordinate : 15.3625, 
        longitudeCoordinate :   75.63284,
    },
    {
        latitudeCoordinate : 12.119915, 
        longitudeCoordinate :   78.533745,
    },
    {
        latitudeCoordinate : 12.876453, 
        longitudeCoordinate :   78.021564,
    },
    {
        latitudeCoordinate : 12.98652, 
        longitudeCoordinate :   78.3265,
    },
    {
        latitudeCoordinate : 12.5986, 
        longitudeCoordinate :   78.3256,
    },
    {
        latitudeCoordinate : 12.6537, 
        longitudeCoordinate :   78.56,
    },
    {
        latitudeCoordinate : 12.5985, 
        longitudeCoordinate :   78.533745,
    },
    {
        latitudeCoordinate : 12.119915, 
        longitudeCoordinate :   78.5284,
    },
    {
        latitudeCoordinate : 12.658, 
        longitudeCoordinate :   78.6528,
    },
    {
        latitudeCoordinate : 12.48591, 
        longitudeCoordinate :   78.3524,
    },
    {
        latitudeCoordinate : 11.345433, 
        longitudeCoordinate :   78.335991,
    },
    {
        latitudeCoordinate : 11.584, 
        longitudeCoordinate :   78.632254,
    },
    {
        latitudeCoordinate : 11.78456, 
        longitudeCoordinate :   78.95,
    },
    {
        latitudeCoordinate : 11.3256, 
        longitudeCoordinate :   78.2154,
    },
    {
        latitudeCoordinate : 11.639854, 
        longitudeCoordinate :   78.68475,
    },
    {
        latitudeCoordinate : 11.315648, 
        longitudeCoordinate :   78.2156,
    },
    {
        latitudeCoordinate : 11.87645, 
        longitudeCoordinate :   78.0215,
    },
    {
        latitudeCoordinate : 11.215, 
        longitudeCoordinate :   78.21543,
    },
    {
        latitudeCoordinate : 10.978964,
        longitudeCoordinate :  77.347221,
    },
    {
        latitudeCoordinate : 10.9865,
        longitudeCoordinate :  77.02,
    },
    {
        latitudeCoordinate : 10.235,
        longitudeCoordinate :  77.2345,
    },
    {
        latitudeCoordinate : 10.9685,
        longitudeCoordinate :  77.0251,
    },
    {
        latitudeCoordinate : 10.32524,
        longitudeCoordinate :  77.15,
    },
    {
        latitudeCoordinate : 10.9865,
        longitudeCoordinate :  77.368,
    },
    {
        latitudeCoordinate : 10.3687,
        longitudeCoordinate :  77.1589,
    },
    {
        latitudeCoordinate : 10.875964,
        longitudeCoordinate :  77.2548,
    },
    {
        latitudeCoordinate : 10.58914,
        longitudeCoordinate :  77.58,
    },
    {
        latitudeCoordinate : 11.539256, 
        longitudeCoordinate :  77.303276,
    },
    {
        latitudeCoordinate : 11.598, 
        longitudeCoordinate :  77.689,
    },
    {
        latitudeCoordinate : 11.9685, 
        longitudeCoordinate :  77.6285,
    },
    {
        latitudeCoordinate : 11.98, 
        longitudeCoordinate :  77.368,
    },
    {
        latitudeCoordinate : 11.98651, 
        longitudeCoordinate :  77.251,
    },
    {
        latitudeCoordinate : 11.3628, 
        longitudeCoordinate :  77.3681,
    },
    {
        latitudeCoordinate : 11.865, 
        longitudeCoordinate :  77.2504,
    },
    {
        latitudeCoordinate : 11.652, 
        longitudeCoordinate :  77.251,
    },
    {
        latitudeCoordinate : 12.431234,
        longitudeCoordinate :  79.181938,
    },
    {
        latitudeCoordinate : 12.6851,
        longitudeCoordinate :  79.389,
    },
    {
        latitudeCoordinate : 12.3261,
        longitudeCoordinate :  79.251,
    },
    {
        latitudeCoordinate : 12.63,
        longitudeCoordinate :  79.31546,
    },
    {
        latitudeCoordinate : 12.752,
        longitudeCoordinate :  79.023156,
    },
    {
        latitudeCoordinate : 12.164,
        longitudeCoordinate :  79.2146,
    },
    {
        latitudeCoordinate : 12.2154,
        longitudeCoordinate :  79.1565,
    },
    {
        latitudeCoordinate : 10.169769,
        longitudeCoordinate : 78.614052,
    },
    {
        latitudeCoordinate : 10.9784,
        longitudeCoordinate : 78.23156,
    },
    {
        latitudeCoordinate : 10.789465,
        longitudeCoordinate : 78.3265,
    },
    {
        latitudeCoordinate : 10.021564,
        longitudeCoordinate : 78.87946,
    },
    {
        latitudeCoordinate : 10.31256,
        longitudeCoordinate : 78.032165,
    },
    {
        latitudeCoordinate : 10.357,
        longitudeCoordinate : 78.7845,
    },
    {
        latitudeCoordinate : 10.8956,
        longitudeCoordinate : 78.5434,
    },
    {
        latitudeCoordinate : 10.665,
        longitudeCoordinate : 78.32564,
    },
    {
        latitudeCoordinate : 9.617801,
        longitudeCoordinate : 77.899940,
    },
    {
        latitudeCoordinate : 9.879435,
        longitudeCoordinate : 77.3215648,
    },
    {
        latitudeCoordinate : 9.23156,
        longitudeCoordinate : 77.254,
    },
    {
        latitudeCoordinate : 9.32156,
        longitudeCoordinate : 77.32156,
    },
    {
        latitudeCoordinate : 9.5648,
        longitudeCoordinate : 77.31564,
    },
    {
        latitudeCoordinate : 9.79846,
        longitudeCoordinate : 77.2356,
    },
    {
        latitudeCoordinate : 9.96485,
        longitudeCoordinate : 77.54,
    },
    {
        latitudeCoordinate : 9.3256,
        longitudeCoordinate : 77.245,
    },
    {
        latitudeCoordinate : 9.281847,
        longitudeCoordinate : 78.020790,
    },
    {
        latitudeCoordinate : 9.79846,
        longitudeCoordinate : 78.53864,
    },
    {
        latitudeCoordinate : 9.8645,
        longitudeCoordinate : 78.256,
    },
    {
        latitudeCoordinate : 9.8965,
        longitudeCoordinate : 78.568,
    },
    {
        latitudeCoordinate : 11.324665,
        longitudeCoordinate : 79.141395,
    },
    {
        latitudeCoordinate : 11.968453,
        longitudeCoordinate : 79.3256,
    },
    {
        latitudeCoordinate : 11.15648,
        longitudeCoordinate : 79.21,
    },
    {
        latitudeCoordinate : 11.3265,
        longitudeCoordinate : 79.132,
    },
    {
        latitudeCoordinate : 11.2136,
        longitudeCoordinate : 79.3156,
    },
    {
        latitudeCoordinate : 8.468010, 
        longitudeCoordinate : 77.525590,
    },
    {
        latitudeCoordinate : 8.49865, 
        longitudeCoordinate : 77.2315,
    },
    {
        latitudeCoordinate : 8.2154, 
        longitudeCoordinate : 77.2145,
    },
    {
        latitudeCoordinate : 8.968465, 
        longitudeCoordinate : 77.136,
    },
    {
        latitudeCoordinate : 8.2156, 
        longitudeCoordinate : 77.21356,
    },
    {
        latitudeCoordinate : 8.256, 
        longitudeCoordinate : 77.3156,
    },
]

export default markerArray
