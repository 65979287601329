import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import fiveStar from "../../assets/images/home/fiveStar.svg";
import customerImg from "../../assets/images/home/lakshmi.svg";
import close from "../../assets/images/home/closeBtn.svg";
import arrowForward from "../../assets/images/home/arrow_forward.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KycPopup from "./kycPopup";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useNavigate } from "react-router-dom";
import DotsLoader from "../../assets/Loader/dotsloader.gif";
import CloseIcon from "@mui/icons-material/Close";
import Styles from "../../styles/PurchasePlan.module.css";

import RightArrow from "../../assets/images/home/rightArrow.svg";
import { getVehicleDetailsApi, getWarrantyPlanOtpApi, verifyOtpWarrantyPlanApi } from "../../Api/Apis";
import { AcceptOnlyAlphabate, AcceptOnlyNumber } from "../../utils/commonFunctions";
import { GetAllReviewsInfo } from "../../Api/PortalApis";

function getRandomObjectsFromArray(array, count) {
  if (array.length > count) {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  } else {
    return array;
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-IN", options);
}

const PurchasePlan = ({ toggleModal, plan, planName, tabId }) => {
  const inputRef = useRef(null);
  const [vehicleCheckResponse, setVehicleCheckResponse] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleMessage, setVehicleMessage] = useState("");
  const [enableCheckButton, setEnableCheckButton] = useState(false);
  const [vehicleLoader, setVehicleLoader] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const [leadVehicleId, setLeadVehicleId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [leadId, setLeadId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [wvid, setWvid] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [otpMessage, setOtpMessage] = useState("");
  const navigate = useNavigate();
  const [disableInput, setDisableInput] = useState(true);
  const [validateName, setValidateName] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [disbaledNumberCheck, setDisblednumberCheck] = useState(true);
  const [disableProceedButton, setDisableProceedButton] = useState(true);
  const [hideCheckbutton, setHideCheckbutton] = useState(false);
  const [disableCheckBox, setDisableCheckbox] = useState(true);
  const [allReviews, setAllReviews] = useState([]);
  const [userReviews, setUserReviews] = useState([]);
  const [otpverifiled, setOtpverifiled] = useState(false);
  const [checkedValue, setCheckedValue] = useState(false);
  const [disableOtp, setDisableOtp] = useState(false);

  async function getAllReviews() {
    try {
      const resp = await GetAllReviewsInfo();
      if (resp.status === 200) {
        // console.log(resp.data);
        const reviews = getRandomObjectsFromArray(resp.data.response.ReviewMaster, 3);
        setUserReviews(reviews);
        // setReviewSummary(resp.data.response.ReviewList);
      }
    } catch (error) {
      console.error("error getting all reviews", error);
    }
  }

  useEffect(() => {
    getAllReviews();
  }, []);

  const Review = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState(false);

  const getVehicleDetails = async () => {
    const data = {
      vehicleNo: vehicleNumber,
      leadId: leadId,
      customerId: customerId ?? "",
    };
    setVehicleLoader(true);
    try {
      const resp = await getVehicleDetailsApi(data);
      if (resp.status === 200) {
        setVehicleLoader(false);
        if (resp.data.responseType === "300") {
          setVehicleCheckResponse(resp.data.responseType);
          setVehicleMessage(resp.data.response.message);
        } else if (resp.data.responseType === "200") {
          setVehicleCheckResponse(resp.data.responseType);
          setVehicleMessage("");
          // setVehicleId(resp.data.response.getDetails.vehicle_id);
          // setLeadVehicleId(resp.data.response.getDetails.lead_vehicle_id);
          // setCategoryId(resp.data.response.getDetails.selected_categoryId);

          sessionStorage.setItem("leadVehicleId", resp.data.response.getDetails.lead_vehicle_id || "");
          sessionStorage.setItem("vehicleId", resp.data.response.getDetails.vehicle_id || "");
          sessionStorage.setItem("categoryId", resp.data.response.getDetails.selected_categoryId || "");
          sessionStorage.setItem("phone", phone || "");
          sessionStorage.setItem("customerId", customerId || "");
          sessionStorage.setItem("leadId", leadId || "");
          navigate(`/checkout-plans/${plan}/${tabId}`);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setVehicleLoader(false);
    }
  };

  // const handleNavigate = () => {
  //   if (vehicleCheckResponse === "200") {
  //     sessionStorage.setItem("leadVehicleId", leadVehicleId ?? "");
  //     sessionStorage.setItem("vehicleId", vehicleId ?? "");
  //     sessionStorage.setItem("categoryId", categoryId ?? "");
  //     sessionStorage.setItem("phone", phone ?? "");
  //     sessionStorage.setItem("customerId", customerId ?? "");
  //     sessionStorage.setItem("leadId", leadId ?? "");
  //     navigate(`/checkout-plans/${plan}/${tabId}`);
  //   }
  // };

  const handleName = (e) => {
    setValidateName(false);
    const value = e.target.value;
    const isValid = AcceptOnlyAlphabate(value);
    if (isValid) {
      setName(value);
    }
  };

  const handleNumber = (e) => {
    setValidateNumber(false);
    setNumberError(false);
    const value = e.target.value;
    const isValid = AcceptOnlyNumber(value);
    if (isValid) {
      setPhone(value);
    }
  };

  const getOtp = async (event) => {
    event.preventDefault();
    if (!name) {
      setValidateName(true);
    } else if (!phone) {
      setValidateNumber(true);
    } else if (phone.length < 10) {
      setNumberError(true);
    } else {
      try {
        setOpenOtpModal(true);
        const resp = await getWarrantyPlanOtpApi(phone, name, wvid, vehicleId ?? "", leadVehicleId ?? "");
        if (resp.status === 200) {
          console.log(resp, "otp");
          setLeadId(resp.data.response.Details.lead_id);
          setCustomerId(resp.data.response.Details.customer_id);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const resendOtp = async () => {
    try {
      setOpenOtpModal(true);
      const resp = await getWarrantyPlanOtpApi(
        phone,
        name,
        wvid,
        vehicleId ?? vehicleId,
        leadVehicleId ?? leadVehicleId,
      );
      if (resp.status === 200) {
        console.log(resp, "otp");
        sessionStorage.setItem("customerId", resp.data.response.Details.customer_id);
        sessionStorage.setItem("leadId", resp.data.response.Details.lead_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (vehicleNumber.length > 4) {
      setDisableCheckbox(false);
    } else {
      setDisableCheckbox(true);
    }
  }, [vehicleNumber]);

  const verifyOtp = async () => {
    try {
      const resp = await verifyOtpWarrantyPlanApi(phone, otp, vehicleId ?? "", leadVehicleId ?? "");
      if (resp.status === 200) {
        console.log(resp, "verifyOtp");
        if (resp.data.responseType === "300") {
          setOtpErr(true);
        } else if (resp.data.responseType === "200") {
          document.body.style.overflow = "auto";
          setDisableOtp(true);
          setDisblednumberCheck(false);
          // setOtpverifiled(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    if (vehicleNumber.length === 0) {
      inputRef.current.focus();
      return;
    }
    if (disableInput) {
      // Increase the size of the "Check" text
      const checkText = document.getElementById("check-text");
      if (checkText) {
        checkText.style.transform = "scale(1.2)";
        checkText.style.transition = "transform 0.3s ease,color 0.3s ease";
        checkText.style.color = "red";
      }

      // Reset the size of the "Check" text after a delay
      setTimeout(() => {
        if (checkText) {
          checkText.style.transform = "scale(1)";
          checkText.style.color = "#f2c5a7";
        }
      }, 500);
    }
  };

  // useEffect(() => {
  //   if (vehicleNumber.length === 0) {
  //     setDisableInput(true);
  //   }
  // }, [vehicleNumber]);

  const handleChangeOtp = () => {
    setOtpErr(false);
    setDisblednumberCheck(true);
    if (otp.length === 4) {
      verifyOtp();
    }
  };

  useEffect(() => {
    handleChangeOtp();
  }, [otp]);

  // console.log(plan);

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setDisableProceedButton(false); // Toggle the state when the checkbox changes
      setCheckedValue(true);
    } else {
      setDisableProceedButton(true);
      setCheckedValue(false);
    }
  };

  return (
    <div className="purchaseContainer">
      <div className="purchaseContent purchaseContentResponsive">
        <div className="purchaseSection">
          <div className="reviews">
            <Slider {...Review}>
              {/* <div className="review">
                <img src={fiveStar} alt="star" />
                <p>
                  It was wonderfull experince. They have done showroom service for my Ecosport vehicle. Customer support
                  has been done amazing job by providing eacha and every update. Thanks to wisedrive
                </p>
                <div className="customerProfile">
                  <img src={customerImg} alt="customerImg" />
                  <div className="customerDetails">
                    <h4>Laxmi</h4>
                    <h5>FORD ECOSPORT</h5>
                  </div>
                </div>
              </div> */}
              {userReviews.map((review) => (
                <div className="review">
                  <img src={fiveStar} alt="star" />
                  <p>{review.description}</p>
                  <div className="customerProfile">
                    <img src={customerImg} alt="customerImg" />
                    <div className="customerDetails">
                      <h4>{review.reviewer_name}</h4>
                      <h5>{review?.date_of_review && formatDate(review?.date_of_review)}</h5>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="review">
                <img src={fiveStar} alt="star" />
                <p>
                  It was wonderfull experince. They have done showroom service for my Ecosport vehicle. Customer support
                  has been done amazing job by providing eacha and every update. Thanks to wisedrive
                </p>
                <div className="customerProfile">
                  <img src={customerImg} alt="customerImg" />
                  <div className="customerDetails">
                    <h4>Laxmi</h4>
                    <h5>FORD ECOSPORT</h5>
                  </div>
                </div>
              </div> */}

              {/* <div className="review">
                <img src={fiveStar} alt="star" />
                <p>
                  It was wonderfull experince. They have done showroom service for my Ecosport vehicle. Customer support
                  has been done amazing job by providing eacha and every update. Thanks to wisedrive
                </p>
                <div className="customerProfile">
                  <img src={customerImg} alt="customerImg" />
                  <div className="customerDetails">
                    <h4>Laxmi</h4>
                    <h5>FORD ECOSPORT</h5>
                  </div>
                </div>
              </div> */}
            </Slider>
          </div>
          {!openOtpModal ? (
            <div className="planRegister">
              <div className="headAndClose">
                <h3 className="mobileSpacePhase3">
                  <span>{planName}</span>
                  <img src={close} alt="close" onClick={() => toggleModal()} />
                </h3>
              </div>
              <form>
                <div>
                  <label>Your Name</label>
                  <input type="text" value={name} onChange={handleName} />
                  {validateName && <p style={{ color: "red" }}>Please enter name</p>}
                </div>
                <div>
                  <label>Your Mobile Number</label>
                  <input type="text" value={phone} onChange={handleNumber} />
                  {validateNumber && <p style={{ color: "red" }}>Please enter number</p>}
                  {numberError && <p style={{ color: "red" }}>Number is less than 10 digit</p>}
                </div>

                <button type="submit" onClick={getOtp}>
                  <span>Get Otp </span> <img src={arrowForward} alt="arrowForward" />
                </button>
              </form>
            </div>
          ) : (
            <div className={"kycForm otp active"}>
              <div className="kycFormTitle kycFormTitleDesk">
                {!otpverifiled ? <h3>OTP Verification</h3> : <h3></h3>}

                <img src={close} onClick={() => toggleModal()} alt="close" />
              </div>
              {!otpverifiled && (
                <div className="kycFormContent">
                  <div className="kycInputGroup">
                    <p className="inputLabel">
                      We've sent a 4-digital code to your phone <strong>{phone}</strong>{" "}
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setOpenOtpModal(false);
                          setOtp("");
                          setDisableOtp(false);
                        }}
                      >
                        Edit
                      </span>
                    </p>
                    <OTPInput
                      className="otpInput"
                      value={otp}
                      onChange={(e) => {
                        setOtp(e);
                      }}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={disableOtp}
                    />
                    <div>
                      {/* <div className="otpErrorContainer"> */}
                      {otpErr && <p className="error otpError active"> Invalid OTP!</p>}
                      {/* </div> */}
                      <p className="resend">
                        You didn't recieved OTP?{" "}
                        <ResendOTP
                          className="resendBtn "
                          onResendClick={() => {
                            resendOtp();
                            setDisableOtp(false);
                          }}
                        />
                      </p>
                    </div>
                    <div className="planRegister" style={{ position: "relative" }}>
                      <form style={{ padding: "0px" }}>
                        <input
                          ref={inputRef}
                          type="text"
                          value={vehicleNumber}
                          onChange={(e) => {
                            setVehicleNumber(e.target.value.toUpperCase());
                            setVehicleMessage("");
                            setDisableProceedButton(true);
                            setDisableCheckbox(true);
                            setCheckedValue(false);
                          }}
                          placeholder="Car Number"
                          disabled={disbaledNumberCheck}
                          style={{ cursor: disbaledNumberCheck ? "not-allowed" : "pointer" }}
                        />
                      </form>

                      <span
                        className={Styles.clearButton}
                        onClick={() => {
                          setVehicleNumber("");
                          setDisableProceedButton(true);
                          setDisableCheckbox(true);
                          setCheckedValue(false);
                          setVehicleMessage("");
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                    <br />
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "0.5rem" }}>
                        <input
                          type="checkbox"
                          name="check"
                          id="check"
                          onChange={handleCheckBox}
                          disabled={disableCheckBox}
                          checked={checkedValue}
                        />{" "}
                      </div>
                      <div>
                        <label for="check" style={{ color: "gray" }}>
                          This is my car Number
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mbt20">
                    <button
                      className="cta-button"
                      onClick={getVehicleDetails}
                      disabled={disableProceedButton}
                      style={{
                        cursor: disableProceedButton ? "not-allowed" : "pointer",
                        background: disableProceedButton ? "lightgray" : "#eb6d47",
                      }}
                    >
                      <span className="cta-btn-payment">Proceed to payment</span> <img src={RightArrow} alt="Arrow" />
                    </button>
                    <div style={{ color: "green", marginTop: "9px" }}>{vehicleMessage}</div>
                  </div>
                </div>
              )}
              {/* {otpverifiled && (
                <div
                  style={{
                    padding: "3rem 2.6rem",
                    display: "flex",
                    height: "67%",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <h2 style={{ textAlign: "center" }}>
                    Thank you for contacting Wisedrive. Our support team will reach you shortly
                  </h2>
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchasePlan;
