import React from "react";
import Slider from "react-slick";
import { Button, Rating } from "@mui/material";
import FiveStar from "../../assets/images/home/fiveStar.svg";
// import FourStar from "../../assets/images/home/fourStar.svg";
import Profile from "../../assets/images/home/profile.svg";
import Jeevan from "../../assets/images/home/jeevan.svg";
import Arjuna from "../../assets/images/home/arjuna.svg";
import Lakshmi from "../../assets/images/home/lakshmi.svg";
import Akhil from "../../assets/images/home/akhil.svg";
import "./customers.css";
import { GetAllReviewsInfo } from "../../Api/PortalApis";
import { useState, useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import userimage from "../../assets/icons/usericon.png";
import CallMadeIcon from "@mui/icons-material/CallMade";

function getRandomObjectsFromArray(array, count) {
  if (array.length > count) {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  } else {
    return array;
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-IN", options);
}

function Customers() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [activate, setActivate] = useState(0);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 0,
    variableWidth: true,
    cssEase: "linear",
    afterChange: (index) => setActiveSlideIndex(index),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          sliderToShow: 0.8,
          variableWidth: false,
        },
      },
      {
        breakpoint: 550,
        settings: {
          sliderToShow: 1.3,
        },
      },
    ],
  };
  // let settings = {
  //   arrows: false,
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         sliderToShow: 0.8,
  //         variableWidth: false,
  //       },
  //     },
  //     {
  //       breakpoint: 550,
  //       settings: {
  //         sliderToShow: 1.3,
  //       },
  //     },
  //   ],
  // };

  const [userReviews, setUserReviews] = useState([]);

  async function getAllReviews() {
    try {
      const resp = await GetAllReviewsInfo();
      if (resp.status === 200) {
        const reviews = getRandomObjectsFromArray(
          resp.data.response.ReviewMaster,
          5
        );
        setUserReviews(reviews);
      }
    } catch (error) {
      console.error("error getting all reviews", error);
    }
  }

  useEffect(() => {
    getAllReviews();
  }, []);

  useEffect(() => {
    if (activeSlideIndex === userReviews?.length - 1) setActivate(0);
    else setActivate(activeSlideIndex + 1);
  }, [activeSlideIndex]);
  return (
    <section className="customers">
      <div className="container" width="70% !important">
        <h2 style={{ marginBottom: "0px" }}>
          Customer Experiences and Reviews
        </h2>
        <p>
          See How Our Expertise Ensures Confidence in Every Pre-Owned Vehicle
          Purchase
        </p>
        <Slider {...settings}>
          {userReviews.map((review, index) => (
            <div className="customer-card-wraper" key={index}>
              <div
                className={
                  index === activate
                    ? "customer-card customer-card-active"
                    : "customer-card"
                }
              >
                <div className="customer-card-profile">
                  <p>{review?.description}</p>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "4rem",
                    }}
                  >
                    <div>
                      <h5>{review?.reviewer_name}</h5>
                      {/* <label>Maruti swift</label> */}
                      <label>{formatDate(review.date_of_review)}</label>
                    </div>
                    <Rating
                      name="half-rating-read"
                      defaultValue={5}
                      precision={0.5}
                      style={{ color: "white" }}
                      readOnly
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="customer-review-footer">
          <div className="customer-review-footer-p1" style={{ display: "flex" }}>
            <div>
              <h2>
                4.8 <StarIcon fontSize="large" />
              </h2>
              <p>Stars out of 5</p>
            </div>
            <div>
              <h2>3 minutes</h2>
              <p> Avg. Response Time</p>
            </div>
          </div>
          <div>
            <Button
              variant="contained"
              endIcon={<CallMadeIcon fontSize="large" />}
              sx={{
                width: "100%",
                // height: "60px",
                // fontSize: "24px",
                backgroundColor: "#003cc4",
                textTransform: "none",
                fontFamily: "gilroy !important",
              }}
              className="reviews-on-Google"
            >
              See 1500+ reviews on Google
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Customers;
