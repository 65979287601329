import React, { useState, useEffect, useContext } from "react";
import "./header.css";
import companyLogo from "../../assets/images/home/logo.svg";
import Bars from "../../assets/images/home/bars.svg";
import Close from "../../assets/images/home/close.svg";
// import Expand from "../../assets/images/header/expandMore.svg";
import Expand from "../../assets/images/header/menuIcon.png";
import { Link, useNavigate } from "react-router-dom";
import WiseDriveLogoNew from "../../assets/images/header/wiseDrivelogoNew.png";
import FairCarLogo from "../../assets/images/header/FairCarLogo.png";
import { MyContext } from "../../context";
import InspectionPlanModalWiseAssist from "../popups/InspectionPlanModalWiseAssist";
import FetchCarReport from "../popups/FetchCarReport";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Header = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const path = window.location.href.split("/")[3];
  const [active, setActive] = useState("");
  const [sticky, setSticky] = useState("");
  const [headerDropDownText, setHeaderDropDownText] = useState("");
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [openKycPopup, setOpenKycPopup] = useState(false);
  const { setOwnerPlanTabActive } = useContext(MyContext);
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const [carNumber, setCarNumber] = useState("");
  const [disableButton, setDisableButton] = useState(true);

  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };

  useEffect(() => {
    const handleScroll = () => {
      // console.log(window.pageYOffset, "hello");
      // if (window.pageYOffset <= 500) {
      //   setSticky("sticky");
      // } else {
      //   setSticky("sticky");
      // }
      // let moving = window.pageYOffset;
      // if (window.pageYOffset > 100) {
      //   setVisible(position > moving);
      // }
      // setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const cls = visible ? "" : "hidden";

  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(!isOpen);

    // document.addEventListener("click", handleDocumentClick);
  };

  const closeNav = () => {
    setIsOpen(false);
    document.getElementById("myNav").style.height = "0%";
    // document.removeEventListener("click", handleDocumentClick);
  };

  const closeKycPopup = () => {
    setOpenKycPopup(false);
  };

  useEffect(() => {
    ShowHeaderDropdown();
  }, [path]);

  const ShowHeaderDropdown = () => {
    if (path === "buyer") {
      setHeaderDropDownText("Buyer");
    } else if (path === "owner") {
      setHeaderDropDownText("Owner");
    } else {
      setHeaderDropDownText("Owner");
    }
  };

  const ExplorePlansSwitchTabs = () => {
    if (path === "buyer") {
      setOwnerPlanTabActive(1);
    } else if (path === "owner") {
      setOwnerPlanTabActive(0);
    } else {
      setOwnerPlanTabActive(0);
    }

    navigate("/owner-pricing-plans");
    setActive("");
  };

  const handleCarNumber = (e) => {
    setCarNumber(e.target.value);
    if (e.target.value.length >= 6) {
      setDisableButton(false);
    } else if (e.target.value.length < 6) setDisableButton(true);
  };

  const fixBackground = () => {
    document.body.style.overflow = openKycPopup ? "auto" : "hidden";
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementsByTagName("header")[0];

      if (window.pageYOffset <= 10) {
        header.style.backgroundColor = "#000";
      } else {
        header.style.backgroundColor = "#fff";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header>
        <div className="container">
          <div className="logo">
            {path === "car_general_service" || path === "submission_success" ? (
              // <img src={wisedDrivelogo} alt="company logo" className="company-logo" />
              <img
                src={WiseDriveLogoNew}
                alt="company logo"
                className="company-logo"
              />
            ) : (
              <Link to="/">
                <img
                  src={WiseDriveLogoNew}
                  alt="company logo"
                  className="company-logo"
                />
              </Link>
            )}

            <div className="menuLinkAndOwner">
              <div className="mobile">
                <div id="myNav" className={`overlay ${isOpen ? "open" : ""}`}>
                  <ul>
                    <li>
                      <a onClick={openNav} href="#inspection-plans">
                        Inspection Plans
                      </a>
                    </li>
                    <li>
                      <a onClick={openNav} href="#how-it-works">
                        How it works
                      </a>
                    </li>
                    <li>
                      <Link onClick={openNav} to="/about-wisedrive">
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link onClick={openNav} to="">
                        ⁠⁠Why Wisedrive
                      </Link>
                    </li>
                    <li>
                      <Link onClick={openNav} to="">
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
                <span onClick={openNav}>
                  <img src={Expand} alt="Expand" />
                </span>
              </div>
            </div>
          </div>

          <div className="navLinks">
            <ul>
              <li className="headerDropdownList"></li>
              <li>
                <a href="#inspection-plans">Inspection Plans</a>
              </li>
              <li>
                <a href="#how-it-works">How it works</a>
              </li>
              <li>
                <Link to="/about-wisedrive">About us</Link>
              </li>
              <li>
                <Link to="">⁠⁠Why Wisedrive</Link>
              </li>
              <li>
                <Link to="">Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {openKycPopup ? (
        <FetchCarReport closeKycPopup={closeKycPopup} carNumber={carNumber} />
      ) : (
        ""
      )}
      <InspectionPlanModalWiseAssist
        isOpen={isModalOpen}
        toggler={toggleModal}
      />
    </>
  );
};

export default Header;
