import React, { useEffect } from "react";
import styles from "../../containers/car-details/car-details.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import { useState } from "react";
import ContactForm from "./ContactForm";
import { features } from "caniuse-lite";
import useWindowDimensions from "../util-hooks/useWindowDimentions";

const getIcons = (id) => {
  if (id === 1) {
    return "";
  }
  if (id === 2) {
    return "";
  }
  if (id === 3) {
    return "";
  }
};

function CarInfoDesc({ carData }) {
  const [activeTabName, setActiveTabName] = useState(null);
  const [activeList, setActiveList] = useState(null);

  const { width, height } = useWindowDimensions();

  // const itemList = infoDetails.find((item) => item.id === activeTabName);

  // console.log(carData); // sometime features coming as null

  useEffect(() => {
    let validItem;

    // features.forEach((featureName) => {
    //   const isEmpty = Object.keys(carData?.features[featureName]).length === 0;
    //   const hasItems =
    //     Object.keys(carData.features[featureName]).filter(
    //       (item) => carData.features[featureName][item].toUpperCase() === "Y"
    //     ).length === 0;

    //   if (!isEmpty && hasItems) {
    //     if (!validItem) {
    //       validItem = featureName;
    //       return;
    //     }
    //   }
    // });

    if (carData && carData?.features) {
      for (const featureName in carData?.features) {
        const isEmpty = Object.keys(carData?.features[featureName]).length === 0;
        const hasItems =
          Object.keys(carData?.features[featureName]).filter(
            (item) => carData?.features[featureName][item].toUpperCase() === "Y"
          ).length > 0;

        // console.log(featureName, hasItems);
        if (hasItems && !validItem) {
          validItem = featureName;
        }
      }
    }

    // console.log(validItem);

    setActiveTabName(validItem);
    if (carData && carData?.features) {
      setActiveList(carData?.features[validItem]);
    }

  }, [carData]);

  // console.log(carData);

  return (
    <div className={styles.carInfoBox}>
      {carData && carData.features && (
        <div className={styles.carInfoDesc}>
          <h3 className={styles.h3}>{`${carData?.make} ${carData?.model}`}</h3>
          <p className={styles.p}>{carData?.carDescription}</p>
          <div className={styles.carInfoTabs}>
            {carData &&
              carData.features &&
              Object.keys(carData.features).map((featureName, i) => {
                if (i > 2) return;
                // console.log(Object.keys(carData.features[featureName]).length === 0);
                const isEmpty = Object.keys(carData.features[featureName]).length === 0;
                const hasItems =
                  Object.keys(carData.features[featureName]).filter(
                    (item) => carData.features[featureName][item].toUpperCase() === "Y"
                  ).length === 0;
                if (isEmpty || hasItems) {
                  return;
                }
                // if (Object.keys(carData.features[featureName]).length === 0) return;
                return (
                  <div key={featureName}>
                    <div
                      className={`${activeTabName === featureName ? styles.infoTabActive : styles.infoTab}`}
                      key={featureName}
                      onClick={() => {
                        setActiveTabName(featureName);
                        setActiveList(carData.features[featureName]);
                      }}
                    >
                      {/* <DirectionsCarIcon style={{ width: 14, height: 14 }} /> */}
                      <p>{featureName}</p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={styles.infoDesc}>
            {activeList &&
              Object.keys(activeList).length > 0 &&
              Object.keys(activeList).map((feature, i) => {
                if (activeList[feature]?.toUpperCase() === "Y") {
                  return (
                    <div key={i} className={styles.descItem}>
                      <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                        <NoCrashIcon style={{ width: 20, height: 20, color: "#9A9EA7" }} />
                        <p>{feature}</p>
                      </div>
                      <CheckCircleIcon style={{ width: 34, height: 22, color: "#2f9e44" }} />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
      )}
      <ContactForm />
    </div>
  );
}

export default CarInfoDesc;
