import React, { useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { Button } from "@mui/material";
import { basicAuth } from "../../Api/crmPortal";
import BuyNowModal from "../popups/buyNowModal";

const PaymentForm = ({ userData, packageDetails, isButtondisabled }) => {
  console.log("isButtondisabled", isButtondisabled);
  const [openProceedTopay, setOpenProceedToPay] = useState(false);
  const [loaderOpen, setLoader] = useState(false);
  const handleClose = () => {
    setOpenProceedToPay(false);
  };
  const handleSubmit = async (event) => {
    setLoader(true);
    setOpenProceedToPay(true);
    try {
      const formData = {
        amount: packageDetails?.amount,
        productinfo: packageDetails?.productinfo,
        phone: packageDetails?.phone,
        firstName: packageDetails?.firstName,
        packageInstanceId: packageDetails?.packageInstanceId,
        couponCode: packageDetails?.couponCode,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_CRM_PAY_URL}/paymentgw/initpay`,
        formData,
        {
          auth: {
            username: "prasad",
            password: "prasad",
          },
        }
      );
      if (response?.data) {
        // setOpenProceedToPay(true);
        setLoader(false);
      }
      console.log("response", response?.data);
      // window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Button
        variant="contained"
        sx={{
          height: "50px",
          width: "100%",
          backgroundColor: "#003CC5",
          fontFamily: "gilroyMedium",
          fontSize: "18px",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "#003CC5",
            boxShadow: "none",
          },
        }}
        onClick={(e) => handleSubmit(e)}
        disabled={!isButtondisabled}
      >
        Proceed to Pay
      </Button>
      <BuyNowModal
        open={openProceedTopay}
        handleClose={handleClose}
        loaderOpen={loaderOpen}
      />
    </div>
  );
};

export default PaymentForm;
