import React, { useRef, useEffect, useState } from "react";
import Tabs from "react-responsive-tabs";
import "react-responsive-tabs/styles.css";
import Check from "../../assets/images/warranty-benefits/Check.png";
import General from "../../assets/images/warranty-benefits/generalService.png";
import AC from "../../assets/images/warranty-benefits/acService.png";
import Batery from "../../assets/images/warranty-benefits/battery.png";
import Puncture from "../../assets/images/warranty-benefits/puncture.png";
import Minor from "../../assets/images/warranty-benefits/minor.png";
import Shield from "../../assets/images/owner-home/shield.svg";
import slideArrow from "../../assets/images/warranty-benefits/scrollArrow.svg";
import logo from "../../assets/images/priceplan-logos/logo-4.png";
import Style from "../../styles/checkUpPmp.module.css";

function CheckUpPnp() {
  useEffect(() => {
    const Div = document.getElementsByClassName("RRT__tab");
    const free = document.createElement("span");

    free.innerHTML = "";
    free.style.color = "grey";
    const containerDiv = document.createElement("div");
    containerDiv.appendChild(free);

    containerDiv.innerHTML += ` Comprehensive General Service ${tabs[0].title}`;
    Div[0].innerHTML = containerDiv.outerHTML;

    const containerDiv2 = document.createElement("div");
    containerDiv2.appendChild(free);
    containerDiv2.innerHTML += ` Other Repaires and Fitment ${tabs[4].title}`;
    Div[4].innerHTML = containerDiv2.outerHTML;

    const RRT__tabs = elementRef?.current?.children[0]?.children[0];

    RRT__tabs.onscroll = () => {
      const LeftArrow = document.getElementsByClassName("scrollArrowLeft")[0];

      if (RRT__tabs.scrollLeft > RRT__tabs.scrollWidth - RRT__tabs.clientWidth - 2) {
        RRT__tabs.parentElement.nextSibling.style.display = "none";
        LeftArrow.style.display = "";
      } else {
        RRT__tabs.parentElement.nextSibling.style.display = "";
        LeftArrow.style.display = "none";
      }
    };
  }, []);

  const tabs = [
    {
      title: "",
      content: {
        price: "₹ 3,999/-",
        img: Batery,
        list: [
          "Engine Oil Replacement",
          "Oil Filter Replacement",
          "Air Filter Replacement",
          "Wiper Fluid Replacement",
          "Coolant - Top up",
          "Brake Oil - Top up",
          "Gear Oil- Top up",
          "Battery Water-Top up",
          "Front Brake Pad Service",
          "Rear Brake Shoes Service",
          "Caliper Greasing",
        ],
        list2: [
          "AC Filter Cleaning",
          "Heater/Spark Plugs-Check up",
          "Suspension Lubrication",
          "AC Cooling Check up",
          "Fuel Filter Check up",
          "Complete Electrical Check up",
          "Tyre Rotation",
          "Interior Vacuuming",
          "Water Washing",
          "All Labour Included",
        ],
      },
    },
    {
      title: "General Service",
      content: {
        price: "₹ 2,499/-",
        img: General,
        list: ["Engine Oil Change", "Air Filter Clean", "Oil Filter Change", "Coolant-Topup"],
        list2: ["Brake Oil-Topup", "Wiper Fluid-Topup", "Front Brake Pads-Cleaning/Servicing", "Car System-Checkup"],
      },
    },
    {
      title: "AC Service",
      content: {
        price: "₹ 1,499/-",
        img: AC,
        list: ["AC Filter - Cleaning", "Condensor - Clean", "AC Vents - Clean"],
        list2: ["Compressor Oil", "Leakage Check"],
      },
    },
    {
      title: "Complete Health Checkup",
      content: {
        price: "₹ 999/-",

        img: Check,
        list: [
          "Battery - Check (Jump start if required)",
          "Coolant - Top up upto 500ml",
          "Brake Oil - Top up upto 100ml",
          "Wiper Fluid - Top up",
          "Air Filter - Clean",
          "AC Filter - Clean",
          "Brakes - Check",
        ],
        list2: [
          "Spark Plugs - Clean",
          "Radiator Fan - Check",
          "Engine Oil - Check",
          "Lights/Indicators - Check",
          "Horn - Check",
          "Tyre Air Pressure - Check",
        ],
      },
    },
    {
      title: "",
      content: {
        img: Puncture,
        price: "₹ 999/-",

        list: [
          "Suspension Issue",
          "Clutch Issue",
          "Steering Issue",
          "Axel Issue",
          "Noise Issue",
          "Leakage Issue",
          "Rat-bite Damage Issue",
        ],
        list2: [
          "Tyre Replacement",
          "Dash cam",
          "Parking Camera with sensor",
          "Floor mats",
          "Infotainment System",
          "Windsheild Replacement",
        ],
      },
    },
    {
      title: "Electrical Repaires",
      content: {
        price: "₹ 999/-",
        img: Minor,
        list: ["Electrical Issues", "MIL Light Issue", "Head Light Issue"],
        list2: ["Tail Light Issue", "Warning Light Issue"],
      },
    },
  ];

  const elementRef = useRef(null);
  const sectionRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    // check here the bug
    // console.log("checking horizontal scroll");
    const ele = elementRef?.current?.children[0]?.children[0];

    let scrollAmount = 0;
    // console.log(element);

    // if (element) {
    //   const slideTimer = setInterval(() => {
    //     // element.scrollLeft += step;
    //     // element.scrollLeft += step;
    //     element.scrollLeft += step;

    //     console.log(element.scrollLeft);

    //     scrollAmount += Math.abs(step);
    //     if (scrollAmount >= distance) {
    //       clearInterval(slideTimer);
    //     }
    //     if (element.scrollLeft === 0) {
    //       setArrowDisable(true);
    //     } else {
    //       setArrowDisable(false);
    //     }
    //   }, speed);
    // }

    if (ele) {
      const slideTimer = setInterval(() => {
        // ele.scrollLeft += step;
        // ele.scrollLeft += step;
        ele.scrollLeft += step;

        // console.log(ele.scrollLeft);

        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
        if (ele.scrollLeft === 0) {
          setArrowDisable(true);
        } else {
          setArrowDisable(false);
        }
      }, speed);
    }

    // if (element) {
    //   element.scrollLeft = element.scrollLeft + step;
    //   // console.log("element.scrollLeft: " + element.scrollLeft);
    // }
  };

  function getTabs() {
    return tabs.map((tab, index) => ({
      title: tab.title,
      showMore: false,
      getContent: () => {
        return (
          <>
            <div className="checkUpContainerImgSkeleton">
              <img src={tab.content.img} alt="" />
            </div>
            <div className={Style.new}>
              <div style={{ fontWeight: "600" }}>Starts from {tab.content.price}</div>
              <div className="checkUpContainerAnswerList">
                <ul>
                  {tab.content.list?.map((li, i) => {
                    return (
                      <>
                        <li key={i}>{li}</li>
                      </>
                    );
                  })}
                </ul>
                <ul>
                  {tab.content.list2?.map((li, i) => {
                    return <li key={i}>{li}</li>;
                  })}
                </ul>
              </div>
            </div>
          </>
        );
      },
      /* Optional parameters */
      key: index,
      tabClassName: "tab",
      panelClassName: "checkUpContainerAnswer",
    }));
  }

  return (
    <section className="checkUp" style={{ paddingTop: 60 }} ref={sectionRef}>
      <div className="container">
        {/* <h4>
          <img src={Shield} alt="Shield" />
          <span>
            Serviced at OEM Authorised Service center Quality & Resale value
            Guaranteed
          </span>
        </h4> */}
        <div className="commonHeading">
          <h2>
            Service and Maintenance made <span>Simple & Trustworthy</span>
          </h2>
          <img src={logo} alt="oem authorised service center" className="checkout-logo" />
        </div>
        <div className="forwardArrow" style={{ width: "100%" }} ref={elementRef}>
          <Tabs items={getTabs()} />
          <img
            onClick={() => {
              handleHorizantalScroll(elementRef.current, 25, 100, 10);
            }}
            className="scrollArrow"
            src={slideArrow}
            alt="arrow"
          />

          <img className="scrollArrowLeft" style={{ display: "none" }} src={slideArrow} alt="arrow" />
        </div>
      </div>
    </section>
  );
}

export default CheckUpPnp;
