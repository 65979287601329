import React from 'react';

import Header from '../../components/header';
import Footer from '../../components/footer';

import ContactUs from '../../components/contact-us/contact-us';
import './blog.css';
import Banner from './banner';
import Discover from './discover';


const Blog = () => {
    return (
        <div>
            <Header />
            <Banner />
            <Discover />
            <ContactUs />
            <Footer />
        </div>
    );
};

export default Blog;
