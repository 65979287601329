import styles from "../../containers/car-details/car-details.module.css";
import image1 from "../../assets/CarImages/warranyimage.png";
import { useState } from "react";
import { useEffect } from "react";
import { GetWarrantyData } from "../../Api/PortalApis";
import useWindowDimensions from "../util-hooks/useWindowDimentions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// dummy icons
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

import mainIcon from "../../assets/icons/iconmain.png";
import wicon from "../../assets/icons/icon1.png";
import bbgicon from "../../assets/icons/icon2.png";
import offIcon from "../../assets/icons/icon3.png";

import wdlogo from "../../assets/CarImages/wdlogo.jpg";
import InfoAccordion from "./childs/InfoAccordion";

function truncate(str, maxlength) {
  return str.length > maxlength ? str.slice(0, maxlength - 1) + "…" : str;
}

function WarrentyDetails({ carData }) {
  const [warrantyData, setWarrantyData] = useState({});
  const [warrantyList, setWarrantyList] = useState([]);

  const { width, height } = useWindowDimensions();

  // console.log(warrantyList);

  function getWarrantyItems(warrantyData) {
    let listData = [];
    const cwbbg = warrantyData["Comprehensive Warranty With Buy Back Guarantee"]; // for test and live
    // const cwbbg = warrantyData["Comprehensive Warranty +  Buy Back Guarantee"]; // for live
    const image = cwbbg?.image;
    for (const itemName in cwbbg) {
      if (itemName != "image") {
        const data = {
          name: itemName,
          image: image,
          desc: cwbbg[itemName],
        };
        // console.log(data);
        listData.push(data);
      }
    }
    return listData;
  }

  useEffect(() => {
    // if (warrantyData) {
    const list = getWarrantyItems(warrantyData);
    setWarrantyList(list);
    // }
  }, [warrantyData]);

  async function getWarrantyData() {
    try {
      //
      const resp = await GetWarrantyData();
      if (resp?.status === 200) {
        console.log(resp.data);
        setWarrantyData(resp?.data);
      }
    } catch (error) {
      console.error("error getting warranty data", error);
    }
  }

  useEffect(() => {
    getWarrantyData();
  }, [carData]);

  return (
    <div className={styles.warrentyContact}>
      <div className={styles.warrentyDetails}>
        {width > 540 && <h3 className={styles.h3}>Warranty and Buyback Guarantee</h3>}
        <div className={styles.warrentyPointsGrid}>
          {width > 540 &&
            warrantyList.map((warrenty, i) => {
              return (
                <div style={{ display: "flex", flexDirection: "column" }} key={i}>
                  <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <div style={{ width: 48, height: 48, display: "grid", placeItems: "center" }}>
                      <img src={warrenty.image} alt="" style={{ width: "50%", height: "50%" }} />
                    </div>
                    <p className={styles.textNormalBold}>{warrenty.name}</p>
                  </div>
                  <DescText warranty={warrenty} />
                </div>
              );
            })}
        </div>
        {width <= 540 && (
          <>
            <section className={styles.warrantyNew}>
              <div className={styles.mainIcon}>
                <img src={mainIcon} alt="warrantydetails" />
                {/* <CreditScoreIcon /> */}
              </div>
              <div className={styles.warrantyNewheaders}>
                <h1>Warranty & Buyback Guarantee</h1>
                <h3>Get free warranty and buyback guarantee upto 2 years from wisedrive partnered dealer network</h3>
              </div>
              <div className={styles.warrantyNewItems}>
                <div className={styles.warrantyNewItem}>
                  <div className={styles.newIcon}>
                    <img src={wicon} alt="warranty" />
                    {/* <DirectionsCarIcon /> */}
                  </div>
                  <div className={styles.warrantyNewItemContent}>
                    <h2>Free Warranty</h2>
                    <p>Up to Rs 5 Lakh Engine and Transmission warranty</p>
                  </div>
                </div>
                <div className={styles.warrantyNewItem}>
                  <div className={styles.newIcon}>
                    <img src={bbgicon} alt="warranty" />
                    {/* <DirectionsCarIcon /> */}
                  </div>
                  <div className={styles.warrantyNewItemContent}>
                    <h2>Free Buyback Guarantee</h2>
                    <p>Up to Rs 15 Lakhs buyback guarantee if we cannot repair your car in 60 days</p>
                  </div>
                </div>
                <div className={styles.warrantyNewItem}>
                  <div className={styles.newIcon}>
                    <img src={offIcon} alt="warranty" />
                    {/* <DirectionsCarIcon /> */}
                  </div>
                  <div className={styles.warrantyNewItemContent}>
                    <h2>Showroom Service</h2>
                    <p>FLAT 50% off on OEM authorised service and maintenance</p>
                  </div>
                </div>
              </div>
            </section>

            {/* RTO SECTION */}
            <section className={styles.rtoCard}>
              <div className={styles.rtoHeader}>
                <div className={styles.rtoLogo}>
                  <img src={wdlogo} alt="wisedrive" />
                </div>
                <h3 style={{ fontWeight: "600", fontSize: 20 }}>Wisedrive Faircar Certification</h3>
              </div>
              <div className={styles.rtoInfo}>
                <div className={styles.rtoInfoItem}>
                  <CheckCircleIcon style={{ color: "#2f9e44", fontSize: 20 }} />
                  <span>RTO check</span>
                </div>
                <div className={styles.rtoInfoItem}>
                  <CheckCircleIcon style={{ color: "#2f9e44", fontSize: 20 }} />
                  <span>Finance and insurance check</span>
                </div>
                <div className={styles.rtoInfoItem}>
                  <CheckCircleIcon style={{ color: "#2f9e44", fontSize: 20 }} />
                  <span>Blacklist and Traffic Fines check</span>
                </div>
                <div className={styles.rtoInfoItem}>
                  <CheckCircleIcon style={{ color: "#2f9e44", fontSize: 20 }} />
                  <span>PUC & Permit check</span>
                </div>
              </div>
            </section>

            {/* DEALER SECTION */}
            {/* <div className={styles.infoAcc}>
              <InfoAccordion />
            </div> */}
          </>
        )}
      </div>
      <div className={styles.warrentyRight}>
        <img src={image1} alt="car" />
      </div>
    </div>
  );
}

export function DescText({ warranty }) {
  const [showText, setShowText] = useState(false);
  const [showMore, setShowMore] = useState(false);

  function toggleText() {
    setShowText((prevState) => !prevState);
  }

  useEffect(() => {
    if (warranty.desc.length > 168) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, [warranty]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginLeft: 58 }}>
      {!showText ? (
        <p className={styles.p}>
          {warranty?.desc && truncate(warranty.desc, 168)}{" "}
          {showMore && (
            <span onClick={toggleText} style={{ color: "blue", cursor: "pointer" }}>
              {!showText ? "more" : "hide"}
            </span>
          )}
        </p>
      ) : (
        <p className={styles.p}>{warranty.desc}</p>
      )}
      {/* {showMore && (
        <span className={styles.smallLabel} onClick={toggleText}>
          {!showText ? "more" : "hide"}
        </span>
      )} */}
    </div>
  );
}

export default WarrentyDetails;
