import React from "react";
import BannerImage from "../../assets/images/service-network/serviceNetworkBanner.png";
import Satisfaction from "../../assets/images/service-network/satisfactionLogo.svg";
import Time from "../../assets/images/service-network/timeLogo.svg";
import Days from "../../assets/images/service-network/365daysLogo.svg";
import styles from "./Service_network.module.css";

function Banner() {
  return (
    <section className={`${styles.serviceBanner} ${styles.serviceNetwork}`}>
      <div className={styles.container}>
        <div className={`bannerHeading`}>
          <label>😍 100% Service Satisfaction</label>
          <h1 className={styles.bannerHeadingH1}>
            FREE Service <span>at authorised service center</span>
          </h1>
          <p>
            We know what your car exactly requries to be in good condition. Our <b>3-in-1</b> warranty plan covers just
            more than what you need.
          </p>
          <div className={styles.bannerService}>
            <div className={styles.bannerServiceCard}>
              <img src={Satisfaction} alt="Logo" />
              <div>
                <h4>100%</h4>
                <p>Customer Satisfaction</p>
              </div>
            </div>
            <div className="bannerServiceCard">
              <img src={Time} alt="Logo" />
              <div>
                <h4>24 Hours</h4>
                <p>Service Guarantee</p>
              </div>
            </div>
            <div className="bannerServiceCard">
              <img src={Days} alt="Logo" />
              <div>
                <h4>365 Days</h4>
                <p>Warranty on spare parts</p>
              </div>
            </div>
          </div>
        </div>
        <img className="serviceBannerImage" src={BannerImage} alt="banner" />
      </div>
    </section>
  );
}

export default Banner;
