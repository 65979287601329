import React, { useState } from "react";
import Slider from "react-slick";
import Ford from "../../assets/images/home/ford.svg";
import Nissan from "../../assets/images/home/nissanBanner.svg";
import Hyundai from "../../assets/images/home/hyundaiBanner.svg";
import Toyota from "../../assets/images/home/toyota.svg";
import Honda from "../../assets/images/home/honda.svg";
import Suzuki from "../../assets/images/home/suzuki.svg";
import Skoda from "../../assets/images/home/skoda.svg";
import Kia from "../../assets/images/home/kia.svg";
import Volvo from "../../assets/images/home/volvo.svg";
import Benz from "../../assets/images/home/benz.svg";
import Tata from "../../assets/images/home/tata.svg";
import Audi from "../../assets/images/home/audi.svg";
import Mg from "../../assets/images/home/mg.svg";
import Force from "../../assets/images/home/force.svg";
import userCarIcon from "../../assets/images/home/userCarIcon.svg";
import userBanner from "../../assets/images/home/userBanner.png";
import freeMaintainence from "../../assets/images/owner-home/freeMaintenance.png";
// import Tick from "../../assets/images/home/tickWhiteNew.svg";
import Tick from "../../assets/images/owner-home/tickGrey.svg";

import userBannerTick from "../../assets/images/home/userBannerTick.svg";
import InspectionPlanModalWiseAssist from "../../components/popups/InspectionPlanModalWiseAssist";

function Banner({ pageData }) {
  const [isModalOpen, setIsmodalOpen] = useState(false);

  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 25000,
    slidesToShow: 5,
    slidesToScroll: 6,
    autoplaySpeed: 0,
    cssEase: "linear",
    autoplay: true,
    variableWidth: true,
    infiniteScroll: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var props = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    vertical: true,
    slidesToShow: 3.99,
    slidesToScroll: 1,
    adaptiveHeight: false,
    speed: 5000,
    autoplaySpeed: 0,
    verticalSwiping: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 993,
        settings: {
          vertical: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 850,
        settings: {
          vertical: false,
          slidesToShow: 2.2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          vertical: false,
          slidesToShow: 1.4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          vertical: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 374,
        settings: {
          vertical: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="banner userSection">
      <div className="userSectionHead">
        <div className="container">
          <div className="userContent1 newContent ">
            <div>
              <label>
                {/* <img src={userCarIcon} alt="Icon" />  */}
                Buying used car in Bangalore ?
              </label>
              <h1 className="userBannerHeading1">
                <span className={{ display: "block", lineHeight: 30 }}>
                  <b></b> Used car inspection{" "}
                </span>{" "}
                <br />
                <span style={{ color: "#eb6d47", fontWeight: 600 }}>with Wisedrive</span>
              </h1>
              <div
                className="wabannerText"
                style={{
                  color: "rgba(38, 44, 110, 0.8)",
                  fontSize: 20,
                  fontWeight: 500,
                  lineHeight: "150%",
                  marginTop: 20,
                }}
              >
                We <b style={{ color: "#eb6d47" }}>Guarantee</b> our Inspection, with{" "}
                <b style={{ color: "#eb6d47" }}>Warranty</b> up to 2 years.
              </div>

              <div className="banner_button">
                <button
                  style={{
                    background: "linear-gradient(107.01deg, #2836d7 0%, #8389d7 100%)",
                    borderRadius: 8,
                    padding: "14px 20px",
                    display: "flex",
                    alignItems: "center",
                    color: "#ffffff",
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: 24,
                    border: "none",
                    height: 54,
                  }}
                  onClick={toggleModal}
                >
                  {/* Just{" "} */}
                  <span
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      fontWeight: "700",
                      fontSize: "22px",
                      color: "#fff",
                    }}
                  >
                    Book For Rs 749/-{" "}
                  </span>
                  <span
                    style={{
                      textDecoration: "line-through",
                      fontWeight: "400",
                      fontSize: "15px",
                      color: "#fff",
                    }}
                  >
                    Rs 999/-{" "}
                  </span>
                </button>
              </div>
            </div>

            <div className="desktopSize">
              <div className="bannerImage bannerImagePhase3">
                {/* <img src={userBanner} alt="userBanner" /> */}
                <img src={freeMaintainence} alt="userBanner" />
              </div>
            </div>
            <div className="tabSize">
              {/* <img src={userBanner} alt="userBanner" /> */}
              <img src={freeMaintainence} alt="userBanner" />
            </div>
          </div>

          <p className="bannerUnbiased2">
            <div className="userBannerContent">
              {/* <img src={userBannerTick} alt="userBannerTick" /> */}
              <img src={Tick} alt="userBannerTick" />
              <div>
                <strong>Inspection</strong> of Engine, Transmission, and Electrical Systems
              </div>
            </div>

            <div className="userBannerContent">
              {/* <img src={userBannerTick} alt="userBannerTick" /> */}
              <img src={Tick} alt="userBannerTick" />
              <div>
                <strong>Comprehensive</strong> Examination of Suspension, Brakes, and Steering
              </div>
            </div>

            <div className="userBannerContent">
              {/* <img src={userBannerTick} alt="userBannerTick" /> */}
              <img src={Tick} alt="userBannerTick" />
              <div>
                <strong>Diagnostic</strong> Tests using OBD2 Scanner
              </div>
            </div>

            <div className="userBannerContent">
              {/* <img src={userBannerTick} alt="userBannerTick" /> */}
              <img src={Tick} alt="userBannerTick" />
              <div>
                <strong>RTO</strong> Verification
              </div>
            </div>
            <div className="userBannerContent">
              {/* <img src={userBannerTick} alt="userBannerTick" /> */}
              <img src={Tick} alt="userBannerTick" />
              <div>
                <strong>Check Blacklist</strong> status of Vehicle
              </div>
            </div>
            <div className="userBannerContent">
              {/* <img src={userBannerTick} alt="userBannerTick" /> */}
              <img src={Tick} alt="userBannerTick" />
              <div>
                <strong>Check all Pending</strong> Challan of Vehicle
              </div>
            </div>
          </p>

          <div className="bannerWarranty1 userBannerWarrent">
            <p>Extended warranty provided for all brands</p>
            <div className="userBannerSlicker">
              <Slider {...settings}>
                <img src={Ford} alt="ford" />
                <img src={Hyundai} alt="hyundai" />
                <img src={Toyota} alt="toyota" />
                <img src={Nissan} alt="nissan" />
                <img src={Suzuki} alt="suzuki" />
                <img src={Honda} alt="honda" />
                <img src={Skoda} alt="Skoda" />
                <img src={Kia} alt="Kia" />
                <img src={Volvo} alt="Volvo" />
                <img src={Benz} alt="Benz" />
                <img src={Tata} alt="Tata" />
                <img src={Audi} alt="audi" />
                <img src={Force} alt="Force" />
                <img src={Mg} alt="Mg" />
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <InspectionPlanModalWiseAssist isOpen={isModalOpen} toggler={toggleModal} pageData={pageData} />
    </section>
  );
}

export default Banner;
