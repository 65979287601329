import React from "react";
import styles from "../../styles/additionalFooter.module.css";

const AdditionalFooter = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.section}>
          <h4>Most Trusted Car Repair & Services in Bangalore</h4>
          <p>
            Looking for Car Repair and Services in Bangalore? Find best Car Service & Repair Center and workshop for
            General Service, General Checkup, Accident Repair and Painting, Running Repairs, Car AC Repair, Car Wheel
            Alignment, Car Wheel Balancing, AC Servicing, AC Repair, AC Gas Filling. Pickup & drop All at comfort of
            your home Best Car Body Repair Services in Bangalore at Reasonable price. Expert Technicians. Get Free
            Quotes on Online.
          </p>

          <p>
            Wisedrive is leading Multi Brand Car Service Workshop Garage Located in HSR Layout Bangalore .Car repair and
            car service free doorstep pickup and drop available in car service in HSR layout, car service in begur, car
            service in Bommanahalli, Car repair in ITPL, car repair in Whitefield, car service in kr puram, car service
            in cv Raman nagar, car repair in kaggadasapura, car service in harlur, Car service & Repair in Mahadevapura,
            car service in garudacharpalya
          </p>
        </div>
        <br />

        <div className={styles.section}>
          <h4>Car Service Center Bangalore | Doorstep Pickup & Drop.</h4>
          <p>
            Wisedrive offers upfront estimation. Wisedrive offers car repair and car service at affordable rates. Auto
            Repair and Service Bangalore by Car Experts. Book Your Car repair Service Now!
          </p>
          <br />
        </div>
        <br />

        <div className={styles.section}>
          <h4>Multibrand Car Service Center, Car Mechanic, Auto Repair Shops</h4>
          <p>
            Car wheel alignment & balancing, car denting & painting, car clutch replacement, car suspension, car
            electrical issues or any other car repairs. Multi Brand Car Service, save up-to 40%. Free Online Estimator,
            Doorstep Pickup & Drop.
          </p>
          <br />
        </div>
        <br />

        <div className={styles.section}>
          <h4>Expert Mechanics Best Car Body Repair Services in Bangalore all at Affordable prices.</h4>
          <p>
            Car General Service | Car engine Oil replacement | Car Engine Oil Filter Replacement | Car Airfilter
            Replacement | Car Brake Pad Change | Car Clutch Replacement | Car Accident Repair | Car Painting | Car
            Timing Belt Replacement | Maruti Swift Petrol Oil Service | Maruti Swift Diesel Periodic Service | Hyundai
            i10 General Service | Hyundai i20 Clutch Replacement | Ford Figo Petrol General Service | Ford Figo Diesel
            General Service | Car Service In Electronic City |car wash in electronic city | Car Repair in Electronic
            City | Car Body & Paint Electronic City | Car Service in South Bangalore |Hyundai Car service | Maruti Car
            service| Ford car service |Car care center Car Service In Mahadevapura | Car Repair in Mahadevapura | Car
            Body & Paint Mahadevapura | Car Repair Services | Hyundai Service | Car Service In Hoodi | Car Repair In
            Hoodi | Car Service In Whitefield | Car Repair In Whitefiled | Car service In ITPL | Car Repair In ITPL |
            Car Service in Marathahalli | Car Repair In Marathahalli | Car Repair In Bellandur | Car Repair In
            Brookfield | Car Battery Replacement Near me | Car JumpStart Near me.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default AdditionalFooter;
