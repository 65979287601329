import React, { useEffect, useState } from "react";
import Value from "../../assets/images/about/valueForMoney.png";
import Maintenance from "../../assets/images/about/maintenance.png";
import Peace from "../../assets/images/about/peaceOfMind.png";
import Trust from "../../assets/images/about/buildingTrust.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Mission() {
  const [isLoaded, setLoaded] = useState(false);
  let maxWidth = 0;
  let innerWidth = 0;
  let cards;

  const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth())
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  const getMaxWidth = () => {
    if (isLoaded) {
      cards = gsap.utils.toArray(".missionCard");
      cards.forEach((card) => {
        maxWidth += card.offsetWidth + 40;
      });
      maxWidth = maxWidth - 40;
      innerWidth = document.querySelector(".mission").clientWidth;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", getMaxWidth);
    ScrollTrigger.addEventListener("refreshInit", getMaxWidth);
    return () => {
      window.removeEventListener("resize", getMaxWidth);
    };
  }, [width]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (isLoaded) {
      getMaxWidth();
      gsap.to(cards, {
        x: () => `-${maxWidth - innerWidth}`,
        ease: "none",
        scrollTrigger: {
          trigger: ".missionContainer",
          pin: ".mission",
          start: "top 40%",
          scrub: true,
          end: () => `+=${maxWidth}`,
          invalidateOnRefresh: true,
          pinSpacing: true,
        },
      });
      cards.forEach((card) => {
        ScrollTrigger.create({
          trigger: card,
          start: () =>
            "top top-=" +
            (card.offsetLeft - innerWidth / 2) *
              (maxWidth / (maxWidth - innerWidth)),
          end: () =>
            "+=" + card.offsetWidth * (maxWidth / (maxWidth - innerWidth)),
        });
      });
    }

  }, [isLoaded]);

  return (
    <>
    <section className="missionMobile">
      <div className="container">
        <div className="commonHeading">
          <h2>
            Better <span>mission and vision</span> will lead to success
          </h2>
        </div>

        <div className="missionContainerMobile">
        <div className="missionCard">
          <img
            src={Value}
            onLoad={() => setLoaded(true)}
            alt="valye for money"
          />
          <div>
            <h4>Value for money</h4>
            <p>Save customers money excites us and makes our customers happy</p>
          </div>
        </div>
        <div className="missionCard">
          <img src={Maintenance} alt="valye for money" />
          <div>
            <h4>No Cost Maintenance</h4>
            <p>Save customers money excites us and makes our customers happy</p>
          </div>
        </div>
        <div className="missionCard">
          <img src={Peace} alt="valye for money" />
          <div>
            <h4>Peace of mind</h4>
            <p>Save customers money excites us and makes our customers happy</p>
          </div>
        </div>
        <div className="missionCard">
          <img src={Trust} alt="valye for money" />
          <div>
            <h4>Building trust</h4>
            <p>Save customers money excites us and makes our customers happy</p>
          </div>
        </div>
      </div>
      </div>
      
    </section>

    <section className="mission">
      <div className="container">
        <div className="commonHeading">
          <h2>
            Better <span>mission and vision</span> will lead to success
          </h2>
        </div>
      </div>
      <div className="missionContainer">
        <div className="missionCard">
          <img
            src={Value}
            onLoad={() => setLoaded(true)}
            alt="valye for money"
          />
          <div>
            <h4>Value for money</h4>
            <p>Save customers money excites us and makes our customers happy</p>
          </div>
        </div>
        <div className="missionCard">
          <img src={Maintenance} alt="valye for money" />
          <div>
            <h4>No Cost Maintenance</h4>
            <p>Save customers money excites us and makes our customers happy</p>
          </div>
        </div>
        <div className="missionCard">
          <img src={Peace} alt="valye for money" />
          <div>
            <h4>Peace of mind</h4>
            <p>Save customers money excites us and makes our customers happy</p>
          </div>
        </div>
        <div className="missionCard">
          <img src={Trust} alt="valye for money" />
          <div>
            <h4>Building trust</h4>
            <p>Save customers money excites us and makes our customers happy</p>
          </div>
        </div>
      </div>
    </section>
    </>
    
  );
}

export default Mission;
