import React, { useState } from "react";
import styles from "../../containers/car-details/car-details.module.css";
import ReviewCard from "./ReviewCard";
import user1 from "../../assets/CustomerImages/user1.jpg";
import user2 from "../../assets/CustomerImages/user2.jpg";
import user3 from "../../assets/CustomerImages/user3.jpg";

import StarIcon from "@mui/icons-material/Star";
import { GetAllReviewsInfo } from "../../Api/PortalApis";
import { useEffect } from "react";

// const reviewList = [
//   {
//     heading: "Toyota Urban Cruiser Hyryder Hybrid Road Test",
//     rating: "4.6",
//     text: "After a couple of rebadging schemes, the Urban Cruiser Hyryder is the first product born out of the collaboration between two automotive giants. It was co-developed alongside the Maruti Suzuki Grand Vitara and this collaboration brought together the best of many worlds – hybrid, mild-hybrid, AWD, CNG alternative,",
//     userImage: user1,
//     name: "Visaal",
//     date: "March 01, 2023",
//   },
//   {
//     heading: "Toyota Urban Cruiser Hyryder Hybrid Road Test",
//     rating: "4.6",
//     text: "The Urban Cruiser Hyryder is the first product born out of the collaboration between two automotive giants. It was co-developed alongside the Maruti Suzuki Grand Vitara and this collaboration brought together the best of many worlds – hybrid, mild-hybrid, AWD, CNG alternative,",
//     userImage: user2,
//     name: "Bilal Ahmed Firfiray",
//     date: "February 01, 2023",
//   },
//   {
//     heading: "Toyota Urban Cruiser Hyryder Hybrid Road Test",
//     rating: "4.6",
//     text: "After a couple of rebadging schemes, the Urban Cruiser Hyryder is the first product born out of the collaboration between two automotive giants. It was co-developed alongside the Maruti Suzuki Grand Vitara and this collaboration brought together the best of many world",
//     userImage: user3,
//     name: "Bilal Ahmed Firfiray",
//     date: "March 22, 2023",
//   },
// ];

function getRandomObjectsFromArray(array, count) {
  if (array.length > count) {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  } else {
    return array;
  }
}

function ReviewSection() {
  const [allReviews, setAllReviews] = useState([]);
  const [userReviews, setUserReviews] = useState([]);
  const [reviewSummary, setReviewSummary] = useState([]);

  async function getAllReviews() {
    try {
      const resp = await GetAllReviewsInfo();
      if (resp.status === 200) {
        // console.log(resp.data);
        const reviews = getRandomObjectsFromArray(resp.data.response.ReviewMaster, 3);
        setUserReviews(reviews);
        setReviewSummary(resp.data.response.ReviewList);
      }
    } catch (error) {
      console.error("error getting all reviews", error);
    }
  }

  useEffect(() => {
    getAllReviews();
  }, []);

  return (
    <div className={styles.reviewSection}>
      <div className={styles.userReviews}>
        <h3 className={styles.h3} style={{ marginBottom: 5 }}>
          Latest reviews
        </h3>
        <div className={styles.reviewCards}>
          {userReviews.map((review) => (
            <ReviewCard review={review} key={review.id} />
          ))}
        </div>
      </div>
      {/*  */}
      <div className={styles.overallReviews}>
        <div className={styles.reviewTop}>
          <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
            <StarIcon style={{ width: 24, height: 24, color: "#EF8354" }} />
            <span className={styles.textBigBold}>{reviewSummary[0]?.avg_rating}</span>
          </div>
          <p className={styles.textFadeBig}>Average score</p>
        </div>
        <div className={styles.reviewInfo}>
          <div className={styles.reviewStats}>
            {reviewSummary.map((review) => (
              <div key={review.id} className={styles.reviewStat}>
                <div>
                  <h4 className={styles.h4}>{review.review_name}</h4>
                  <p className={styles.textFade}>{review.description}</p>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: 4, alignItems: "flex-end" }}>
                  <span className={styles.h4}>{review.review_rating}</span>
                  {review.review_name === "Positive" && (
                    <div className={styles.barGreen}>
                      <div className={styles.barGreenInner} style={{ width: `${review.review_rating}` }}></div>
                    </div>
                  )}
                  {review.review_name === "Neutral" && (
                    <div className={styles.barNeutral}>
                      <div className={styles.barNeutralInner} style={{ width: `${review.review_rating}` }}></div>
                    </div>
                  )}
                  {review.review_name === "Negative" && (
                    <div className={styles.barNegetive}>
                      <div className={styles.barNeutralInner} style={{ width: `${review.review_rating}` }}></div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {/* <div className={styles.reviewStat}>
              <div>
                <h4 className={styles.h4}>Neutral</h4>
                <p className={styles.textFade}>3 stars and above</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 4, alignItems: "flex-end" }}>
                <span className={styles.h4}>15%</span>
                <div className={styles.barNeutral}>
                  <div className={styles.barNeutralInner} style={{ width: "15%" }}></div>
                </div>
              </div>
            </div> */}
            {/* <div className={styles.reviewStat}>
              <div>
                <h4 className={styles.h4}>Negetive</h4>
                <p className={styles.textFade}>under 2 stars</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 4, alignItems: "flex-end" }}>
                <span className={styles.h4}>5%</span>
                <div className={styles.barNegetive}>
                  <div className={styles.barNegetiveInner} style={{ width: "5%" }}></div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <button className={styles.allReviewBtn}>All Reviews</button> */}
        </div>
      </div>
    </div>
  );
}

export default ReviewSection;
