import React from "react";
import Banner from "../../assets/images/blog/blogBanner.png";
import BannerImage from "../../assets/images/blog/bannerRightNewImage.png";
import Banner1 from "../../assets/images/blog/blogBanner1.png";
import ArrowLeft from "../../assets/images/blog/arrowLeft.svg";
import twitter from "../../assets/images/blog/twitter.svg";
import link from "../../assets/images/blog/link.svg";
import fb from "../../assets/images/blog/fb.svg";

import { Link } from "react-router-dom";

function Description() {
  return (
    <section className="blogSection">
      <div className="container">
        <div className="blogContainer">
          <div className="blogTop">
            <Link to="/blog">
              <img src={ArrowLeft} alt="Car" />
              back
            </Link>
            <div className="head">
              {/* <h3>FEB 9, 2023</h3> */}
              {/* <label></label> */}
              {/* <span>Volta</span> */}
            </div>
            <h1>Which is better for Your Vehicle: Car Insurance or Car Warranty in India?</h1>
            <div className="blogImg" style={{ minHeight: "40px" }}>
              <img src={BannerImage} alt="Banner" style={{ borderRadius: "12px" }} />
            </div>
            <p>Car insurance covers accidental damages, while a car warranty covers manufacturing defects. Both are important for protecting your vehicle and ensuring peace of mind while driving</p>
          </div>

          {/* <div className="blogText">
            <h2>Shifts towards Industry 4.0</h2>
            <p>
              As the world gradually shifts towards Industry4.0, laborious
              manual jobs are being eliminated and people are getting re-trained
              and educated to adjust to these new technologies. Indeed, we have
              been noticing a growing trend of educational opportunities in
              local institutions driving interest towards specialized
              engineering fields. Earlier this year, the Singapore Institute of
              Technology (SIT) announced the launch of a degree program-
              Engineering in Robotics Systems (RSE) that focuses on design and
              development of service fields robotics systems, a deliberate move
              that anticipates an increasing number of job opportunities within
              the robotics sector.
            </p>
            <div className="blogImg">
              <img src={Banner1} alt="Banner" />
            </div>
            <p>
              As more specialized curriculums are being developed to nurture
              roboticists for the future, we want to be part of this amazing
              opportunity to encourage the acceleration of robotics learning and
              up-skilling of the workforce. At Botsync Labs, our robots are
              built to be uniquely positioned as a resource to support the
              education, research, and development fields. With that purpose in
              mind, we have designed VOLTA to be a compact and flexible toolkit
              that facilitates ROS learning, teaching, and research.
            </p>
          </div> */}
          {/* <div className="blogText">
            <h2>Plug-and-play architecture</h2>
            <p>
              VOLTA is our indoor mobile platform with a plug-and-play
              architecture that makes it a great fit for hands-on learning. For
              teaching purposes, educators may opt for the Odriod XU4 and a RP
              Lidar A2, the simplest PC and sensor configuration of VOLTA to
              educate students on the basics of ROS and upgrade it later for
              higher levels of difficulty. With our detailed tutorials,
              open-sourced source code and software packages on our ROS Wiki
              page, VOLTA can be easily set up and customized for varying
              use-cases.
            </p>
          </div> */}
          {/* <div className="blogText">
            <h2>Uses of VOLTA</h2>
            <p>
              The benefits and uses of VOLTA go way beyond learning in the
              classroom. It is a powerful robot with a sturdy hardware base,
              suitable for industrial research and development. In respective to
              your varying requirements, VOLTA is built with the flexibility
              that allows you to change PCs and sensors easily. Earlier this
              month, we introduced the Nvidia Jetson developer kit, a great PC
              option for users who want to engage in AI developments, and added
              a proximity package onto VOLTA to increase its object detection
              field.
            </p>
            <p>
              Innovation is a continuous process and the core of our work. Here
              at Botsync, we constantly seek to improve our products to cater to
              more flexible developments.
            </p>
            <p>
              If you are curious about how our mobile robots can support your
              project, feel free to drop us an email at{" "}
              <span>contact@botsync.co</span> and we will be more than excited
              to support your robotics journey.
            </p>
          </div> */}
          <div className="blogText">
            <h2>Car insurance and car warranty are two different things in India.</h2>
            <p>Car insurance is a legal requirement and provides financial protection against accidental damage to your vehicle or third-party property damage, injury or death.</p>
            <p>Car warranty, on the other hand, is a promise by the manufacturer or dealer to repair or replace certain parts of your vehicle in case of manufacturing defects or malfunctions within a specified time period.</p>
            <p>In summary, car insurance covers accidental damages, while car warranty covers manufacturing defects. Both are important for protecting your vehicle and ensuring peace of mind while driving.</p>
          </div>
          <div className="blogText">
            <h2>Here are ten differences between car insurance and car warranty in India:</h2>
            <p>
              <ol type="1">
                <li>
                  <b>1. Purpose: </b>Car insurance covers accidental damages, while a car warranty covers manufacturing defects.
                </li>
                <li>
                  <b> 2. Coverage:</b> Car insurance covers damages to the vehicle and third-party property, while a car warranty covers repairs and replacement of specific parts of the vehicle.
                </li>
                <li>
                  <b> 3. Legal Requirement:</b> Car insurance is a legal requirement in India, while a car warranty is optional.
                </li>
                <li>
                  <b>4. Premiums:</b> Car insurance premiums are based on factors such as the type of vehicle, driving habits, and location, while the cost of a car warranty is included in the price of the vehicle.
                </li>
                <li>
                  <b> 5. Time Period:</b> Car insurance is typically purchased for a one-year term, while a car warranty has a specified time period, often ranging from 3 to 7 years.
                </li>
                <li>
                  <b>6. Renewal:</b> Car insurance can be renewed annually, while a car warranty has a fixed time period and does not require renewal.
                </li>
                <li>
                  <b>7. Coverage Limits:</b> Car insurance has coverage limits, while a car warranty has limits on the number and type of repairs covered.
                </li>
                <li>
                  <b> 8. Claims Process:</b> The process for making a claim on car insurance and a car warranty can vary, with car insurance requiring an insurance claim and car warranty requiring a repair claim.
                </li>
                <li>
                  <b> 9. Exclusions:</b> Car insurance and car warranty both have exclusions, such as intentional damage, normal wear and tear, and damages due to lack of maintenance.
                </li>
                <li>
                  <b> 10. Transferability: </b>Car insurance can be transferred to a new owner if the vehicle is sold, while a car warranty may not be transferable, depending on the terms and conditions.
                </li>
              </ol>
            </p>
            <p>In summary, car insurance and car warranty serve different purposes and offer different levels of protection, so it's important to understand the differences and choose the one that best suits your needs.</p>
          </div>
        </div>
        <div className="share">
          <h1>Share via</h1>
          <ul>
            <li>
              {/* <Link to=""> */}
              <a href="https://twitter.com/wisedrivehq" target="_blank">
                <img src={twitter} alt="Twitter" />
              </a>
              {/* </Link> */}
            </li>
            <li>
              {/* <Link to=""> */}
              <a href="https://in.linkedin.com/company/wisedrivewarranty" target="_blank">
                <img src={link} alt="Link" />
              </a>
              {/* </Link> */}
            </li>
            <li>
              {/* <Link to=""> */}
              <a href="https://www.facebook.com/Wisedrivewarranty" target="_blank">
                <img src={fb} alt="Facebook" />
              </a>
              {/* </Link> */}
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Description;
