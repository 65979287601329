import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, resolvePath, useParams } from "react-router-dom";
import Arrow from "../../assets/images/home/orangeArrow.svg";
import ArrowBlue from "../../assets/images/home/rightArrowGrediant.svg";
import Lock from "../../assets/images/reports/lock.svg";
import Lock2 from "../../assets/images/reports/lock2.svg";
import { comingSoon, healthReportData } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { RecommendationListApi, ReportListApi, getObdReportApi, observationListApi } from "../../Api/Apis";
import Explore from "../../assets/images/reports/explore5cars.svg";
import Styles from "./healthReport.module.css";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";

const HealthReports = ({ black_list }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [link, setLink] = useState("");
  const [observationList, setObservationList] = useState([]);
  const [recommendationListData, setRecommendationListData] = useState([]);
  const [observationComment, setObservationComment] = useState();
  const [majorCount, setMajorCount] = useState(0);
  const [minorCount, setMinorCount] = useState(0);
  const [recommendationcoment, setRecommendationComment] = useState("");

  const datas = useParams();

  const ReportLIst = async () => {
    try {
      const resp = await ReportListApi();
      if (resp.status === 200) {
        setData(resp.data.response.ReportList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    ReportLIst();
  }, []);

  const handleNavigatePage = (id, status, report_type) => {
    if (id === 3) {
      if (link === "nodata") {
        if (status === "Pending") {
          return false;
        } else {
          navigate(
            `/report-details/${datas.vehicleId === "nodata" ? "nodata" : datas.vehicleId}/${
              datas.leadvehicleId === "nodata" ? "nodata" : datas.leadvehicleId
            }/${
              datas.insprequvehiId === "nodata" ? "nodata" : datas.insprequvehiId
            }/${id}/${report_type}/${black_list}`,
          );
        }
      } else {
        window.location.assign(link);
      }
    } else {
      if (status === "Pending") {
        return false;
      } else {
        navigate(
          `/report-details/${datas.vehicleId === "nodata" ? "nodata" : datas.vehicleId}/${
            datas.leadvehicleId === "nodata" ? "nodata" : datas.leadvehicleId
          }/${datas.insprequvehiId === "nodata" ? "nodata" : datas.insprequvehiId}/${id}/${report_type}/${black_list}`,
        );
      }
    }
  };

  const getLink = async () => {
    try {
      const resp = await getObdReportApi(
        datas.vehicleId === "nodata" ? "" : datas.vehicleId,
        datas.leadvehicleId === "nodata" ? "" : datas.leadvehicleId,
        datas.insprequvehiId === "nodata" ? "" : datas.insprequvehiId,
        3,
      );
      if (resp.status === 200) {
        setLink(resp.data.response.OBDDetails.obd_report || "nodata");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLink();
  }, []);

  const handleObservation = async () => {
    try {
      const resp = await observationListApi(
        datas.vehicleId === "nodata" ? "" : datas.vehicleId,
        datas.leadvehicleId === "nodata" ? "" : datas.leadvehicleId,
        datas.insprequvehiId === "nodata" ? "" : datas.insprequvehiId,
      );
      if (resp.status === 200) {
        let obseList = resp?.data?.response?.ObservationComments;
        setObservationList(obseList);
        setObservationComment(resp.data?.response?.obscomments?.comments);
        let majorCount = 0;
        let minorCount = 0;
        obseList.forEach((comment) => {
          comment.ObservationSubCategoryList.forEach((subCategory) => {
            if (subCategory.severity === "major") {
              majorCount++;
            } else if (subCategory.severity === "minor") {
              minorCount++;
            }
          });
        });

        setMajorCount(majorCount);
        setMinorCount(minorCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleObservation();
  }, []);

  const recommendationList = async () => {
    try {
      const resp = await RecommendationListApi(
        datas.vehicleId === "nodata" ? "" : datas.vehicleId,
        datas.leadvehicleId === "nodata" ? "" : datas.leadvehicleId,
        datas.insprequvehiId === "nodata" ? "" : datas.insprequvehiId,
      );
      if (resp.status === 200) {
        const reco = resp.data?.response?.PreventionCommentList;
        setRecommendationListData(reco);
        setRecommendationComment(resp.data?.response?.Comment?.comments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    recommendationList();
  }, []);

  return (
    <>
      <section className="health phaseTwo" id="car-health" style={{ paddingTop: "36px" }}>
        <div className="container">
          {/* <div className={Styles.healthCardWrapper}> */}
          <div className="healthCard" style={{ borderRadius: "8px", border: "0.5px solid #eb6d47" }}>
            <div className={Styles.newBox}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex" }}>
                  <img src={Explore} alt="engine" style={{ borderRadius: "8px" }} />
                  <h3>Observations</h3>
                </div>
                <div className={Styles.observationMajorMinorContainer} style={{ marginLeft: "25%", display: "flex" }}>
                  <div className={Styles.majorContainer}>
                    <span>
                      <CancelIcon style={{ color: "#ff6666" }} />
                    </span>
                    <span style={{ marginBottom: "4px", display: "inline-block", marginLeft: "8px" }}>
                      {majorCount} major
                    </span>
                  </div>
                  <div className={Styles.minorContainer}>
                    <span>
                      <WarningIcon style={{ color: "#f5c311" }} />
                    </span>
                    <span style={{ marginBottom: "4px", display: "inline-block", marginLeft: "4px" }}>
                      {minorCount} minor
                    </span>
                  </div>
                </div>
              </div>
              <div className={Styles.observationMajorMinorContainerMobile}>
                <div className={Styles.majorContainer} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                  <span style={{ display: "inline-block" }}>
                    <CancelIcon style={{ color: "#ff6666" }} />
                  </span>
                  <span style={{ display: "inline-block", marginLeft: "8px" }}>{majorCount} major</span>
                </div>
                <div className={Styles.minorContainer} style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                  <span>
                    <WarningIcon style={{ color: "#f5c311" }} />
                  </span>
                  <span style={{ display: "inline-block", marginLeft: "4px" }}>{minorCount} minor</span>
                </div>
              </div>
              <div className={Styles.Observation_containar}>
                {observationList &&
                  observationList.map((each, index) => {
                    return (
                      <div key={index} style={{ paddingBottom: "9px" }}>
                        <h4 style={{ color: "#262c6c", paddingBottom: "16px" }}>
                          {index + 1}. {each.category_name}
                        </h4>
                        {each.ObservationSubCategoryList.map((subCate, Subindex) => {
                          return (
                            <div style={{ display: "flex" }} key={Subindex}>
                              <p style={{ paddingBottom: "0px" }}>
                                {subCate.severity === "minor" ? (
                                  <WarningIcon style={{ color: "#f5c311", width: "20px" }} />
                                ) : (
                                  <CancelIcon style={{ color: "#ff6666", width: "20px" }} />
                                )}
                              </p>
                              <p style={{ marginTop: "0.5%", marginLeft: "2%", paddingBottom: "0px" }}>
                                {subCate.sub_category_name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
              {!observationComment ? (
                <></>
              ) : (
                <div style={{ marginTop: "2%" }}>
                  <label style={{ color: "rgb(38, 44, 108)", fontWeight: "500" }}>Comment</label>
                  <p style={{ marginTop: "2%" }}>{observationComment}</p>
                </div>
              )}
            </div>
          </div>

          {/* <div
              className="healthCard"
              style={{
                borderRadius: "8px",
                border: "0.5px solid green",
              }}
            >
              <div className={Styles.newBox}>
                <div style={{ display: "flex" }}>
                  <img src={Explore} alt="engine" style={{ borderRadius: "8px" }} />
                  <h3>Recommendations</h3>
                </div>
                <div style={{ marginTop: "8px" }}>
                  {recommendationListData &&
                    recommendationListData.map((each, index) => (
                      <>
                        <h4 key={index} style={{ color: "#262c6c" }}>
                          {index + 1}. {each.prevention_category_name}
                        </h4>
                        <span
                          style={{
                            background: "#c9f2d2",
                            padding: "4px",
                            paddingLeft: "24px",
                            paddingRight: "24px",
                            borderRadius: "4px",
                            display: "inline-block",
                            marginTop: "2%",
                            color: "#262c6c",
                          }}
                        >
                          {each.PrevenSubCategoryList.length} Recommendations
                        </span>
                        {each.PrevenSubCategoryList.map((each, subIndex) => {
                          return (
                            <>
                              <div style={{ display: "flex", justifyContent: "center", marginTop: "4%" }}>
                                <p>
                                  <DoneIcon style={{ color: "green", width: "20px" }} />
                                </p>
                                <p style={{ marginLeft: "2%" }}>{each.prevention_sub_category_name}</p>
                              </div>
                            </>
                          );
                        })}
                      </>
                    ))}
                </div>
                {!recommendationcoment ? (
                  <></>
                ) : (
                  <div style={{ marginTop: "8%" }}>
                    <label style={{ color: "rgb(38, 44, 108)", fontWeight: "500" }}>Comment</label>
                    <p style={{ marginTop: "4%" }}>{recommendationcoment}</p>
                  </div>
                )}
              </div>
            </div> */}
          {/* </div> */}
          <div className="newCommonHeading" style={{ paddingBottom: "8px", marginTop: "24px" }}>
            <h2 style={{ color: "#262c6c" }}>Car health reports</h2>
          </div>
          <div className="healthContainer">
            {data &&
              data.map((report, i) => {
                return (
                  <div
                    className="healthCard"
                    key={i}
                    onClick={() =>
                      handleNavigatePage(report.inspection_report_type_id, report.report_status, report.report_type)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                      <img src={report.icon} alt="engine" width="50px" height="50px" style={{ borderRadius: "8px" }} />
                      <h3>{report.report_type}</h3>
                      <p>{report.description}</p>
                    </div>
                    <div
                      className="cursor-pointer"
                      style={{ color: "#ff8966", fontWeight: "500", fontSize: "16px", display: "flex" }}
                    >
                      {/* {report.inspection_report_type_id === 4 ? */}
                      <span>
                        {report.report_status === "Active" ? (
                          <span>View report</span>
                        ) : (
                          <span style={{ color: "gray" }}>{report.report_status}</span>
                        )}
                      </span>

                      {report.report_status === "Pending" ? (
                        ""
                      ) : (
                        <div>
                          <img src={Arrow} alt="link" />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

            {/* {comingSoon.map((report, i) => {
              return (
                <div className="comingSoon-Card healthCard" key={i}>
                  <div>
                    <img src={report?.icon} alt="engine" />
                    <h3>{report?.title}</h3>
                    <p>{report?.description}</p>
                  </div>
                  <a
                      className="cursor-pointer"
                    >
                      <span>View report</span>
                      <img src={Arrow} alt="link" />
                    </a>
                    {!report.unlocked && (
                      <a className="comingSoon-Report">
                        <img src={Lock2} alt="" />
                        <p>{report?.unlockPrice}</p>
                      </a>
                    )}
                </div>
              );
            })} */}
          </div>
        </div>
      </section>
    </>
  );
};

export default HealthReports;
