import React, { useEffect, useState } from "react";
import Expert from "../../assets/images/home/expert.png";
import InspectionImage from "../../assets/images/home/inspectionImage.png";
import ServiceImage from "../../assets/images/home/serviceImage.png";
import FamilyImage from "../../assets/images/home/familyImage.png";
import TestDrive from "../../assets/images/home/testDrive.png";
import CentreCheck from "../../assets/images/home/centreCheck.png";
import BatteryCheck from "../../assets/images/home/batteryCheck.png";

function SaveTime() {
  const [select, setSelect] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [toggleTwo, setToggleTwo] = useState(false);
  const [toggleThree, setToggleThree] = useState(false);
  const [toggleFour, setToggleFour] = useState(false);
  const [toggleFive, setToggleFive] = useState(false);
  const [toggleSix, setToggleSix] = useState(false);
  const [toggleSeven, setToggleSeven] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 992) {
      let topHeight = document.getElementsByClassName("bannerSection")[0].clientHeight;

      window.addEventListener("scroll", () => {
        if (window.pageYOffset >= topHeight) {
          setSelect(0);
        }
      });
      let interval;

      interval = setInterval(() => {
        setSelect((co) => co + 1);
      }, 6000);
      if (select === 7) {
        setSelect(0);
      }
      return () => clearInterval(interval);
    }
  }, [select]);

  useEffect(() => {
    let topHeight = document.getElementsByClassName("bannerSection")[0].clientHeight;
    let saveSec =
      document.getElementsByClassName("saveTime")[0].clientHeight - document.getElementById("battery").clientHeight * 2;
    let scrolledHeight = topHeight + saveSec;

    window.addEventListener("scroll", () => {
      if (window.pageYOffset >= scrolledHeight) {
        setActive(true);
      } else {
        setActive(false);
      }
    });
  });

  return (
    <section className="saveTime" id="saveTime">
      <div className="container">
        <div className="commonHeading">
          <h2>
            Benefits of buying wisedrive
            <span> pre-approved cars </span>
          </h2>
        </div>
        <div className="saveTimeDetails">
          <ul className="saveTimeList">
            <li
              className={select === 0 ? "selected" : ""}
              onClick={() => {
                setSelect(0);
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_810_4739)">
                  <path d="M4 9H8.00017V17.0001H4V9Z" fill="url(#paint0_linear_810_4739)" />
                  <path
                    d="M18.3546 9.00009H16.0001V6.30089C17.1791 5.73715 18.0001 4.54468 18.0001 3.15033C18.0001 1.75598 17.1791 0.562591 16.0001 0V3.15033C16.0001 3.97865 15.3284 4.65019 14.5 4.65019C13.672 4.65019 13 3.97865 13 3.15033V0C11.8205 0.562591 11.0002 1.75598 11.0002 3.15033C11.0002 4.54468 11.8205 5.7383 13 6.30089V9.00009H10V17.0002L16.4091 18.5339C17.4794 18.7903 18.5311 18.1169 18.7472 17.0391L19.9621 10.9607C20.1779 9.88266 19.4542 9.00009 18.3546 9.00009Z"
                    fill="url(#paint1_linear_810_4739)"
                  />
                  <path
                    d="M15.9441 20.4794L12.9998 19.7744V22.4999C12.9998 23.3283 13.6718 24 14.4998 24C15.3282 24 15.9999 23.3283 15.9999 22.4999V20.4862C15.9812 20.4821 15.9629 20.4833 15.9441 20.4794Z"
                    fill="url(#paint2_linear_810_4739)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_810_4739"
                    x1="4"
                    y1="9"
                    x2="9.10439"
                    y2="9.78073"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_810_4739"
                    x1="10"
                    y1="0"
                    x2="22.7148"
                    y2="2.09242"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_810_4739"
                    x1="12.9998"
                    y1="19.7744"
                    x2="16.7412"
                    y2="20.587"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <clipPath id="clip0_810_4739">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Pre-Sale Inspection
            </li>
            <li
              className={select === 1 ? "selected" : ""}
              onClick={() => {
                setSelect(1);
              }}
            >
              {/* <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7499 3V6.4335C18.7499 7.26195 18.0783 7.93357 17.2499 7.93357C16.4214 7.93357 15.75 7.26195 15.75 6.4335V3C14.4272 3.57937 13.5 4.89698 13.5 6.4335C13.5 7.65592 14.0947 8.73188 14.9999 9.41678V18.75C14.9999 19.9922 16.0076 21 17.2499 21C18.4921 21 19.4999 19.9922 19.4999 18.75V9.41678C20.4059 8.73188 20.9999 7.65592 20.9999 6.4335C20.9999 4.8963 20.0734 3.57937 18.7499 3V3Z"
                  fill="url(#paint0_linear_1525_6429)"
                />
                <path
                  d="M13.5001 13.5001V10.5H5.25C4.00777 10.5 3 11.5071 3 12.7493L3.00068 12.75H3V18.0001H4.50007V20.2501C4.50007 20.6632 4.83757 21.0001 5.25 21.0001H6.75007C7.16227 21.0001 7.5 20.6632 7.5 20.2501V18.0001H13.5001V15H8.24992V13.5001H13.5001ZM5.99992 15.1876C5.48287 15.1876 5.06257 14.768 5.06257 14.2501C5.06257 13.7314 5.48287 13.3125 5.99992 13.3125C6.5172 13.3125 6.9375 13.7314 6.9375 14.2501C6.9375 14.768 6.5172 15.1876 5.99992 15.1876Z"
                  fill="url(#paint1_linear_1525_6429)"
                />
                <path
                  d="M11.9995 6.43341C11.9995 5.75661 12.1417 5.10636 12.3834 4.49976H7.73533C7.11433 4.49976 6.55183 4.75176 6.14458 5.15901C5.93803 5.36623 5.77108 5.61373 5.65768 5.88846L4.36865 8.99976H12.6795C12.2405 8.22711 11.9995 7.35118 11.9995 6.43341V6.43341Z"
                  fill="url(#paint2_linear_1525_6429)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1525_6429"
                    x1="13.5"
                    y1="3"
                    x2="23.1376"
                    y2="4.22835"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1525_6429"
                    x1="3"
                    y1="10.5"
                    x2="15.5387"
                    y2="14.3355"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_1525_6429"
                    x1="4.36865"
                    y1="4.49976"
                    x2="12.5959"
                    y2="9.14768"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                </defs>
              </svg> */}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 3L3 7.5V21H4.50007V9.75H19.5001V21H21V7.5L12 3Z" fill="url(#paint0_linear_1525_6450)" />
                <path
                  d="M18.0001 13.5H16.3126L16.0233 12.3412C15.8732 11.7407 15.2439 11.25 14.6251 11.25H9.375C8.75602 11.25 8.12737 11.7407 7.97707 12.3412L7.6875 13.5H6V14.2499L7.1844 14.7238L6.41602 15.8758C6.1872 16.2191 6 16.8374 6 17.2496V20.9999H7.50007L7.87515 19.125H16.1252L16.5001 20.9999H18.0001V17.2498C18.0001 16.8376 17.8125 16.2193 17.5839 15.876L16.8157 14.724L18.0001 14.2499V13.5ZM9.375 12.7498H14.6249L14.9635 14.2499H9.0375L9.375 12.7498Z"
                  fill="url(#paint1_linear_1525_6450)"
                />
                <defs>
                  {/* <linearGradient
                    id="paint0_linear_1525_6450"
                    x1="3"
                    y1="3"
                    x2="24.4948"
                    y2="9.57515"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1525_6450"
                    x1="6"
                    y1="11.25"
                    x2="19.7254"
                    y2="16.4175"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient> */}
                  <linearGradient
                    id="paint0_linear_1525_6429"
                    x1="13.5"
                    y1="3"
                    x2="23.1376"
                    y2="4.22835"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1525_6429"
                    x1="3"
                    y1="10.5"
                    x2="15.5387"
                    y2="14.3355"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_1525_6429"
                    x1="4.36865"
                    y1="4.49976"
                    x2="12.5959"
                    y2="9.14768"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                </defs>
              </svg>
              Comprehensive Warranty
            </li>
            <li
              className={select === 2 ? "selected" : ""}
              onClick={() => {
                setSelect(2);
              }}
            >
              {/* <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5625 10.9392L19.3349 9.71182C19.0435 9.42046 18.8048 8.84468 18.8048 8.43176V6.6959C18.8048 5.87016 18.1302 5.19559 17.3047 5.19515H15.5681C15.1557 5.19515 14.5792 4.95595 14.2878 4.66481L13.0604 3.4374C12.4772 2.8542 11.5223 2.8542 10.9391 3.4374L9.71171 4.66569C9.42008 4.95705 8.84298 5.19559 8.43143 5.19559H6.69557C5.87093 5.19559 5.19548 5.87016 5.19548 6.6959V8.43181C5.19548 8.84309 4.95677 9.42067 4.66536 9.71186L3.43773 10.9393C2.85409 11.5225 2.85409 12.4774 3.43773 13.0615L4.66536 14.2889C4.95694 14.5803 5.19548 15.1576 5.19548 15.569V17.3049C5.19548 18.1297 5.87093 18.8052 6.69557 18.8052H8.43148C8.84391 18.8052 9.42035 19.0439 9.71175 19.3351L10.9392 20.5629C11.5224 21.1457 12.4773 21.1457 13.0605 20.5629L14.2879 19.3351C14.5795 19.0437 15.1557 18.8052 15.5681 18.8052H17.3047C18.1302 18.8052 18.8048 18.1297 18.8048 17.3049V15.569C18.8048 15.1559 19.0437 14.5801 19.3349 14.2889L20.5626 13.0615C21.1453 12.4774 21.1453 11.5224 20.5625 10.9392ZM10.8091 15.3752L7.49952 12.0652L8.56019 11.0047L10.8093 13.2539L15.4392 8.62508L16.4996 9.68554L10.8091 15.3752Z"
                  fill="url(#paint0_linear_1525_6434)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1525_6434"
                    x1="3"
                    y1="3"
                    x2="24.4944"
                    y2="9.57488"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                </defs>
              </svg> */}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 3L3 7.5V21H4.50007V9.75H19.5001V21H21V7.5L12 3Z" fill="url(#paint0_linear_1525_6450)" />
                <path
                  d="M18.0001 13.5H16.3126L16.0233 12.3412C15.8732 11.7407 15.2439 11.25 14.6251 11.25H9.375C8.75602 11.25 8.12737 11.7407 7.97707 12.3412L7.6875 13.5H6V14.2499L7.1844 14.7238L6.41602 15.8758C6.1872 16.2191 6 16.8374 6 17.2496V20.9999H7.50007L7.87515 19.125H16.1252L16.5001 20.9999H18.0001V17.2498C18.0001 16.8376 17.8125 16.2193 17.5839 15.876L16.8157 14.724L18.0001 14.2499V13.5ZM9.375 12.7498H14.6249L14.9635 14.2499H9.0375L9.375 12.7498Z"
                  fill="url(#paint1_linear_1525_6450)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1525_6450"
                    x1="3"
                    y1="3"
                    x2="24.4948"
                    y2="9.57515"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1525_6450"
                    x1="6"
                    y1="11.25"
                    x2="19.7254"
                    y2="16.4175"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                </defs>
              </svg>
              OEM authorized Showroom maintenance
            </li>
            <li
              className={select === 3 ? "selected" : ""}
              onClick={() => {
                setSelect(3);
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.9994 3C13.3428 3 11.9995 4.34325 11.9995 5.99993C11.9995 7.6566 13.3428 8.99985 14.9994 8.99985C16.6561 8.99985 17.9996 7.6566 17.9996 5.99993C17.9996 4.34325 16.6561 3 14.9994 3V3Z"
                  fill="url(#paint0_linear_1525_6439)"
                />
                <path
                  d="M9.00015 7.5C7.34347 7.5 6 8.84325 6 10.4999C6 12.1568 7.34347 13.4999 9.00015 13.4999C10.6568 13.4999 12.0001 12.1568 12.0001 10.4999C12.0001 8.84325 10.6568 7.5 9.00015 7.5Z"
                  fill="url(#paint1_linear_1525_6439)"
                />
                <path
                  d="M12 15H5.99992C4.34325 15 3 16.3435 3 18.0002V21.0001H14.9999V18.0002C14.9999 16.3441 13.6567 15 12 15Z"
                  fill="url(#paint2_linear_1525_6439)"
                />
                <path
                  d="M18 10.5H13.5V13.7704C14.7728 14.2222 15.7851 15.2257 16.2378 16.5001H20.9999V13.5002C20.9999 11.8441 19.6567 10.5 18 10.5Z"
                  fill="url(#paint3_linear_1525_6439)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1525_6439"
                    x1="11.9995"
                    y1="3"
                    x2="19.1645"
                    y2="5.19181"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1525_6439"
                    x1="6"
                    y1="7.5"
                    x2="13.165"
                    y2="9.69181"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_1525_6439"
                    x1="3"
                    y1="15"
                    x2="14.4029"
                    y2="21.976"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_1525_6439"
                    x1="13.5"
                    y1="10.5"
                    x2="22.0449"
                    y2="13.7672"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                </defs>
              </svg>
              PAN India Roadside Assistance
            </li>
            <li
              className={select === 4 ? "selected" : ""}
              onClick={() => {
                setSelect(4);
              }}
            >
              {/* <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 3C7.02975 3 3 7.02975 3 12C3 16.9703 7.02975 21 12 21C16.9703 21 21 16.9703 21 12C21 7.02975 16.9703 3 12 3ZM12 5.25C14.9289 5.25 17.4028 7.13663 18.3371 9.75H5.66288C6.59719 7.13663 9.07106 5.25 12 5.25ZM12 13.125C11.3784 13.125 10.875 12.6216 10.875 12C10.875 11.3784 11.3784 10.875 12 10.875C12.6216 10.875 13.125 11.3784 13.125 12C13.125 12.6216 12.6216 13.125 12 13.125ZM5.25 12C8.32406 12 10.8137 14.9621 10.866 18.6358C7.6845 18.0908 5.25 15.3334 5.25 12ZM13.134 18.6358C13.1863 14.9621 15.6759 12 18.75 12C18.75 15.3334 16.3155 18.0908 13.134 18.6358Z"
                  fill="url(#paint0_linear_1525_6445)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1525_6445"
                    x1="3"
                    y1="3"
                    x2="24.4948"
                    y2="9.57515"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                </defs>
              </svg> */}
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.5625 10.9392L19.3349 9.71182C19.0435 9.42046 18.8048 8.84468 18.8048 8.43176V6.6959C18.8048 5.87016 18.1302 5.19559 17.3047 5.19515H15.5681C15.1557 5.19515 14.5792 4.95595 14.2878 4.66481L13.0604 3.4374C12.4772 2.8542 11.5223 2.8542 10.9391 3.4374L9.71171 4.66569C9.42008 4.95705 8.84298 5.19559 8.43143 5.19559H6.69557C5.87093 5.19559 5.19548 5.87016 5.19548 6.6959V8.43181C5.19548 8.84309 4.95677 9.42067 4.66536 9.71186L3.43773 10.9393C2.85409 11.5225 2.85409 12.4774 3.43773 13.0615L4.66536 14.2889C4.95694 14.5803 5.19548 15.1576 5.19548 15.569V17.3049C5.19548 18.1297 5.87093 18.8052 6.69557 18.8052H8.43148C8.84391 18.8052 9.42035 19.0439 9.71175 19.3351L10.9392 20.5629C11.5224 21.1457 12.4773 21.1457 13.0605 20.5629L14.2879 19.3351C14.5795 19.0437 15.1557 18.8052 15.5681 18.8052H17.3047C18.1302 18.8052 18.8048 18.1297 18.8048 17.3049V15.569C18.8048 15.1559 19.0437 14.5801 19.3349 14.2889L20.5626 13.0615C21.1453 12.4774 21.1453 11.5224 20.5625 10.9392ZM10.8091 15.3752L7.49952 12.0652L8.56019 11.0047L10.8093 13.2539L15.4392 8.62508L16.4996 9.68554L10.8091 15.3752Z"
                  fill="url(#paint0_linear_1525_6434)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1525_6434"
                    x1="3"
                    y1="3"
                    x2="24.4944"
                    y2="9.57488"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                </defs>
              </svg>
              Protect your investment
            </li>
            <li
              className={select === 5 ? "selected" : ""}
              onClick={() => {
                setSelect(5);
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 3L3 7.5V21H4.50007V9.75H19.5001V21H21V7.5L12 3Z" fill="url(#paint0_linear_1525_6450)" />
                <path
                  d="M18.0001 13.5H16.3126L16.0233 12.3412C15.8732 11.7407 15.2439 11.25 14.6251 11.25H9.375C8.75602 11.25 8.12737 11.7407 7.97707 12.3412L7.6875 13.5H6V14.2499L7.1844 14.7238L6.41602 15.8758C6.1872 16.2191 6 16.8374 6 17.2496V20.9999H7.50007L7.87515 19.125H16.1252L16.5001 20.9999H18.0001V17.2498C18.0001 16.8376 17.8125 16.2193 17.5839 15.876L16.8157 14.724L18.0001 14.2499V13.5ZM9.375 12.7498H14.6249L14.9635 14.2499H9.0375L9.375 12.7498Z"
                  fill="url(#paint1_linear_1525_6450)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1525_6450"
                    x1="3"
                    y1="3"
                    x2="24.4948"
                    y2="9.57515"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1525_6450"
                    x1="6"
                    y1="11.25"
                    x2="19.7254"
                    y2="16.4175"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                </defs>
              </svg>
              Better Resale Value
            </li>
            <li
              className={select === 6 ? "selected" : ""}
              onClick={() => {
                setSelect(6);
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.8001 4.80011H19.0003L18.1001 3H16.3002L15.4001 4.80011H8.20016L7.29997 3H5.50013L4.59995 4.80011H2.80011C1.81028 4.80011 1 5.61039 1 6.59995V19.2002C1 20.1897 1.81028 21 2.80011 21H20.8001C21.7899 21 22.6002 20.1897 22.6002 19.2002V6.59995C22.6002 5.61039 21.7899 4.80011 20.8001 4.80011ZM9.10008 13.8001H3.70003V12H9.10008V13.8001ZM19.9002 13.8001H18.1001V15.5999H16.3002V13.8001H14.5001V12H16.3002V10.1999H18.1001V12H19.9002V13.8001Z"
                  fill="url(#paint0_linear_1525_6454)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1525_6454"
                    x1="1"
                    y1="3"
                    x2="25.8581"
                    y2="12.1248"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#2836D7" />
                    <stop offset="1" stopColor="#8E93D7" />
                  </linearGradient>
                </defs>
              </svg>
              Peace of Mind, Guaranteed
            </li>
          </ul>
          <div className="saveTimeContent">
            <div
              className={select === 0 ? "saveTimeContentWrapper activeDesktop" : "saveTimeContentWrapper"}
              id="mechanic"
            >
              <img src={Expert} alt="Inspection" />
              <h5>With Wisedrive: We take responsibility</h5>
              <p>
                Wisedrive pre-approved cars are thoroughly inspected and certified. Incase of any engine or transmission
                failure get FREE repair / replacement within 60 days.
              </p>
              <h4>Without Wisedrive </h4>
              <p>
                Friends and Family, Local mechanics and expert evaluation ONLY does inspection but will NOT TAKE
                RESPONSIBILITY of the inspection.
              </p>
            </div>
            <div
              className={select === 1 ? "saveTimeContentWrapper activeDesktop" : "saveTimeContentWrapper"}
              id="check"
            >
              <img src={InspectionImage} alt="Inspection" />
              <h5>With Wisedrive: Up to Rs 5,00,000</h5>
              <p>
                Wisedrive warranty customers will get FREE repair / replacement of engine or transmission parts up to Rs
                5,00,000. Our vast network of 25,000+ partners will get your car ready to drive within 60 days.
              </p>
              <h4>Without Wisedrive</h4>
              <p>
                All repairs / replacements have to be paid by the customer. No warranty No guarantee after repair /
                replacement.
              </p>
            </div>
            <div
              className={select === 2 ? "saveTimeContentWrapper activeDesktop" : "saveTimeContentWrapper"}
              id="service"
            >
              <img src={ServiceImage} alt="Inspection" />
              <h5>With Wisedrive</h5>
              <p>
                We have partnered with all brands OEM authorized service centers to service and maintain your car and
                provide CASHLESS EXPERIENCE to our warranty customers.
              </p>
              <h4>Without Wisedrive</h4>
              <p>
                Local service, local spare parts might cost less but will frequently get repairs to the car. No
                guarantee and No warranty for the service parts.
              </p>
            </div>
            <div
              className={select === 3 ? "saveTimeContentWrapper activeDesktop" : "saveTimeContentWrapper"}
              id="family"
            >
              <img src={FamilyImage} alt="Inspection" />
              <h5>With Wisedrive: Covered across 21,000+ Pin codes</h5>
              <p>
                Travel with confidence. We have covered our customers PAN India with 21,000+ Pin codes. We provide
                immediate Roadside assistance anywhere in India.
              </p>
              <h4>Without Wisedrive</h4>
              <p>
                You are on your own. Search for nearest repair centers and negotiate prices and pay the local garage
                with no options.
              </p>
            </div>
            <div
              className={select === 4 ? "saveTimeContentWrapper activeDesktop" : "saveTimeContentWrapper"}
              id="drive"
            >
              <img src={TestDrive} alt="Inspection" />
              <h5>With Wisedrive: 100% Buyback Guarantee</h5>
              <p>
                We provide 100% Buyback guarantee at 100% of invoice value for all our warranty customers protecting the
                entire investment value.
              </p>
              <h4>Without Wisedrive</h4>
              <p>
                All repairs and expenses have to be borne by customer and when you try to sell the car because of some
                issue with car you will be offered 50% (approx) of the invoice price.
              </p>
            </div>
            <div
              className={select === 5 ? "saveTimeContentWrapper activeDesktop" : "saveTimeContentWrapper"}
              id="centre"
            >
              <img src={CentreCheck} alt="Inspection" />
              <h5>With Wisedrive: Value Guarantee</h5>
              <p>
                Any car maintained at showroom always has higher resale value. Our warranty customers cars are serviced
                and maintained at OEM authorized service centers and service history is maintained which increases the
                resale value of the car.
              </p>
              <h4>Without Wisedrive</h4>
              <p>
                You have to sell the car to the highest bidder and all of them will quote less than the similar car
                maintained which has OEM service history.
              </p>
            </div>
            <div
              className={select === 6 ? "saveTimeContentWrapper activeDesktop" : "saveTimeContentWrapper"}
              id="battery"
            >
              <img src={BatteryCheck} alt="Inspection" />
              <h5>With Wisedrive: But with Trust, Drive with Confidence</h5>
              <p>
                Wisedrive Warranty provides complete peace of mind to car owners by providing superior maintenance and
                warranty solutions protecting your investment and reducing the depreciation when the car is for resale.
              </p>
              <h4>Without Wisedrive</h4>
              <p>
                Service, Maintenance, Engine repairs, Transmission repairs and breakdown expenses are all paid by the
                customer with no guarantee or warranty of future damage.
              </p>
            </div>
          </div>
        </div>

        <div className="saveTimeMobileContent">
          <div
            className={select === 0 && toggle ? "saveTimeAccordion selected" : "saveTimeAccordion"}
            onClick={() => {
              setToggle(!toggle);
              setSelect(0);
              setToggleTwo(false);
              setToggleThree(false);
              setToggleFour(false);
              setToggleFive(false);
              setToggleSix(false);
              setToggleSeven(false);
            }}
          >
            <div className="saveTimeAccordionQuestion">
              <p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_810_4739)">
                    <path d="M4 9H8.00017V17.0001H4V9Z" fill="url(#paint0_linear_810_4739)" />
                    <path
                      d="M18.3546 9.00009H16.0001V6.30089C17.1791 5.73715 18.0001 4.54468 18.0001 3.15033C18.0001 1.75598 17.1791 0.562591 16.0001 0V3.15033C16.0001 3.97865 15.3284 4.65019 14.5 4.65019C13.672 4.65019 13 3.97865 13 3.15033V0C11.8205 0.562591 11.0002 1.75598 11.0002 3.15033C11.0002 4.54468 11.8205 5.7383 13 6.30089V9.00009H10V17.0002L16.4091 18.5339C17.4794 18.7903 18.5311 18.1169 18.7472 17.0391L19.9621 10.9607C20.1779 9.88266 19.4542 9.00009 18.3546 9.00009Z"
                      fill="url(#paint1_linear_810_4739)"
                    />
                    <path
                      d="M15.9441 20.4794L12.9998 19.7744V22.4999C12.9998 23.3283 13.6718 24 14.4998 24C15.3282 24 15.9999 23.3283 15.9999 22.4999V20.4862C15.9812 20.4821 15.9629 20.4833 15.9441 20.4794Z"
                      fill="url(#paint2_linear_810_4739)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_810_4739"
                      x1="4"
                      y1="9"
                      x2="9.10439"
                      y2="9.78073"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_810_4739"
                      x1="10"
                      y1="0"
                      x2="22.7148"
                      y2="2.09242"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_810_4739"
                      x1="12.9998"
                      y1="19.7744"
                      x2="16.7412"
                      y2="20.587"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                    <clipPath id="clip0_810_4739">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Pre-Sale Inspection
              </p>
            </div>
            <div className="saveTimeAccordionAnswer">
              <img src={Expert} alt="Expert" />
              <h5>With Wisedrive: We take responsibility</h5>

              <p>
                Wisedrive pre-approved cars are thoroughly inspected and certified. Incase of any engine or transmission
                failure get FREE repair / replacement within 60 days
              </p>
              <h4>Without Wisedrive</h4>
              <p>
                Friends and Family, Local mechanics and expert evaluation ONLY does inspection but will NOT TAKE
                RESPONSIBILITY of the inspection.
              </p>
            </div>
          </div>

          <div
            className={select === 1 && toggleTwo ? "saveTimeAccordion selected" : "saveTimeAccordion"}
            onClick={() => {
              setToggleTwo(!toggleTwo);
              setToggle(false);
              setToggleThree(false);
              setToggleFour(false);
              setToggleFive(false);
              setToggleSix(false);
              setToggleSeven(false);
              setSelect(1);
            }}
          >
            <div className="saveTimeAccordionQuestion">
              <p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.7499 3V6.4335C18.7499 7.26195 18.0783 7.93357 17.2499 7.93357C16.4214 7.93357 15.75 7.26195 15.75 6.4335V3C14.4272 3.57937 13.5 4.89698 13.5 6.4335C13.5 7.65592 14.0947 8.73188 14.9999 9.41678V18.75C14.9999 19.9922 16.0076 21 17.2499 21C18.4921 21 19.4999 19.9922 19.4999 18.75V9.41678C20.4059 8.73188 20.9999 7.65592 20.9999 6.4335C20.9999 4.8963 20.0734 3.57937 18.7499 3V3Z"
                    fill="url(#paint0_linear_1525_6429)"
                  />
                  <path
                    d="M13.5001 13.5001V10.5H5.25C4.00777 10.5 3 11.5071 3 12.7493L3.00068 12.75H3V18.0001H4.50007V20.2501C4.50007 20.6632 4.83757 21.0001 5.25 21.0001H6.75007C7.16227 21.0001 7.5 20.6632 7.5 20.2501V18.0001H13.5001V15H8.24992V13.5001H13.5001ZM5.99992 15.1876C5.48287 15.1876 5.06257 14.768 5.06257 14.2501C5.06257 13.7314 5.48287 13.3125 5.99992 13.3125C6.5172 13.3125 6.9375 13.7314 6.9375 14.2501C6.9375 14.768 6.5172 15.1876 5.99992 15.1876Z"
                    fill="url(#paint1_linear_1525_6429)"
                  />
                  <path
                    d="M11.9995 6.43341C11.9995 5.75661 12.1417 5.10636 12.3834 4.49976H7.73533C7.11433 4.49976 6.55183 4.75176 6.14458 5.15901C5.93803 5.36623 5.77108 5.61373 5.65768 5.88846L4.36865 8.99976H12.6795C12.2405 8.22711 11.9995 7.35118 11.9995 6.43341V6.43341Z"
                    fill="url(#paint2_linear_1525_6429)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1525_6429"
                      x1="13.5"
                      y1="3"
                      x2="23.1376"
                      y2="4.22835"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1525_6429"
                      x1="3"
                      y1="10.5"
                      x2="15.5387"
                      y2="14.3355"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1525_6429"
                      x1="4.36865"
                      y1="4.49976"
                      x2="12.5959"
                      y2="9.14768"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                  </defs>
                </svg>
                Comprehensive Warranty
              </p>
            </div>
            <div className="saveTimeAccordionAnswer">
              <img src={Expert} alt="Expert" />
              <h5>With Wisedrive: Up to Rs 5,00,000</h5>
              <p>
                Wisedrive warranty customers will get FREE repair / replacement of engine or transmission parts up to Rs
                5,00,000. Our vast network of 25,000+ partners will get your car ready to drive within 60 days.
              </p>
              <h4>Without Wisedrive</h4>
              <p>
                All repairs / replacements have to be paid by the customer. No warranty No guarantee after repair /
                replacement.
              </p>
            </div>
          </div>

          <div
            className={select === 2 && toggleThree ? "saveTimeAccordion selected" : "saveTimeAccordion"}
            onClick={() => {
              setToggleThree(!toggleThree);
              setToggle(false);
              setToggleTwo(false);
              setToggleFour(false);
              setToggleFive(false);
              setToggleSix(false);
              setToggleSeven(false);
              setSelect(2);
            }}
          >
            <div className="saveTimeAccordionQuestion">
              <p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.5625 10.9392L19.3349 9.71182C19.0435 9.42046 18.8048 8.84468 18.8048 8.43176V6.6959C18.8048 5.87016 18.1302 5.19559 17.3047 5.19515H15.5681C15.1557 5.19515 14.5792 4.95595 14.2878 4.66481L13.0604 3.4374C12.4772 2.8542 11.5223 2.8542 10.9391 3.4374L9.71171 4.66569C9.42008 4.95705 8.84298 5.19559 8.43143 5.19559H6.69557C5.87093 5.19559 5.19548 5.87016 5.19548 6.6959V8.43181C5.19548 8.84309 4.95677 9.42067 4.66536 9.71186L3.43773 10.9393C2.85409 11.5225 2.85409 12.4774 3.43773 13.0615L4.66536 14.2889C4.95694 14.5803 5.19548 15.1576 5.19548 15.569V17.3049C5.19548 18.1297 5.87093 18.8052 6.69557 18.8052H8.43148C8.84391 18.8052 9.42035 19.0439 9.71175 19.3351L10.9392 20.5629C11.5224 21.1457 12.4773 21.1457 13.0605 20.5629L14.2879 19.3351C14.5795 19.0437 15.1557 18.8052 15.5681 18.8052H17.3047C18.1302 18.8052 18.8048 18.1297 18.8048 17.3049V15.569C18.8048 15.1559 19.0437 14.5801 19.3349 14.2889L20.5626 13.0615C21.1453 12.4774 21.1453 11.5224 20.5625 10.9392ZM10.8091 15.3752L7.49952 12.0652L8.56019 11.0047L10.8093 13.2539L15.4392 8.62508L16.4996 9.68554L10.8091 15.3752Z"
                    fill="url(#paint0_linear_1525_6434)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1525_6434"
                      x1="3"
                      y1="3"
                      x2="24.4944"
                      y2="9.57488"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                  </defs>
                </svg>
                OEM authorized Showroom maintenance
              </p>
            </div>
            <div className="saveTimeAccordionAnswer">
              <img src={Expert} alt="Expert" />
              <h5>With Wisedrive: Quality Assured</h5>
              <p>
                We have partnered with all brands OEM authorized service centers to service and maintain your car and
                provide CASHLESS EXPERIENCE to our warranty customers.
              </p>
              <h4>Without Wisedrive</h4>
              <p>
                Local service, local spare parts might cost less but will frequently get repairs to the car. No
                guarantee and No warranty for the service parts.
              </p>
            </div>
          </div>

          <div
            className={select === 3 && toggleFour ? "saveTimeAccordion selected" : "saveTimeAccordion"}
            onClick={() => {
              setToggleFour(!toggleFour);
              setToggle(false);
              setToggleThree(false);
              setToggleTwo(false);
              setToggleFive(false);
              setToggleSix(false);
              setToggleSeven(false);
              setSelect(3);
            }}
          >
            <div className="saveTimeAccordionQuestion">
              <p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.9994 3C13.3428 3 11.9995 4.34325 11.9995 5.99993C11.9995 7.6566 13.3428 8.99985 14.9994 8.99985C16.6561 8.99985 17.9996 7.6566 17.9996 5.99993C17.9996 4.34325 16.6561 3 14.9994 3V3Z"
                    fill="url(#paint0_linear_1525_6439)"
                  />
                  <path
                    d="M9.00015 7.5C7.34347 7.5 6 8.84325 6 10.4999C6 12.1568 7.34347 13.4999 9.00015 13.4999C10.6568 13.4999 12.0001 12.1568 12.0001 10.4999C12.0001 8.84325 10.6568 7.5 9.00015 7.5Z"
                    fill="url(#paint1_linear_1525_6439)"
                  />
                  <path
                    d="M12 15H5.99992C4.34325 15 3 16.3435 3 18.0002V21.0001H14.9999V18.0002C14.9999 16.3441 13.6567 15 12 15Z"
                    fill="url(#paint2_linear_1525_6439)"
                  />
                  <path
                    d="M18 10.5H13.5V13.7704C14.7728 14.2222 15.7851 15.2257 16.2378 16.5001H20.9999V13.5002C20.9999 11.8441 19.6567 10.5 18 10.5Z"
                    fill="url(#paint3_linear_1525_6439)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1525_6439"
                      x1="11.9995"
                      y1="3"
                      x2="19.1645"
                      y2="5.19181"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1525_6439"
                      x1="6"
                      y1="7.5"
                      x2="13.165"
                      y2="9.69181"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1525_6439"
                      x1="3"
                      y1="15"
                      x2="14.4029"
                      y2="21.976"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_1525_6439"
                      x1="13.5"
                      y1="10.5"
                      x2="22.0449"
                      y2="13.7672"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                  </defs>
                </svg>
                PAN India Roadside Assistance
              </p>
            </div>
            <div className="saveTimeAccordionAnswer">
              <img src={Expert} alt="Expert" />
              <h5>With Wisedrive: Covered across 21,000+ Pin codes</h5>
              <p>
                Travel with confidence. We have covered our customers PAN India with 21,000+ Pin codes. We provide
                immediate Roadside assistance anywhere in India.
              </p>
              <h4>Without Wisedrive</h4>

              <p>
                You are on your own. Search for nearest repair centers and negotiate prices and pay the local garage
                with no options.
              </p>
            </div>
          </div>

          <div
            className={select === 4 && toggleFive ? "saveTimeAccordion selected" : "saveTimeAccordion"}
            onClick={() => {
              setToggleFive(!toggleFive);
              setToggle(false);
              setToggleTwo(false);
              setToggleThree(false);
              setToggleFour(false);
              setToggleSix(false);
              setToggleSeven(false);
              setSelect(4);
            }}
          >
            <div className="saveTimeAccordionQuestion">
              <p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 3C7.02975 3 3 7.02975 3 12C3 16.9703 7.02975 21 12 21C16.9703 21 21 16.9703 21 12C21 7.02975 16.9703 3 12 3ZM12 5.25C14.9289 5.25 17.4028 7.13663 18.3371 9.75H5.66288C6.59719 7.13663 9.07106 5.25 12 5.25ZM12 13.125C11.3784 13.125 10.875 12.6216 10.875 12C10.875 11.3784 11.3784 10.875 12 10.875C12.6216 10.875 13.125 11.3784 13.125 12C13.125 12.6216 12.6216 13.125 12 13.125ZM5.25 12C8.32406 12 10.8137 14.9621 10.866 18.6358C7.6845 18.0908 5.25 15.3334 5.25 12ZM13.134 18.6358C13.1863 14.9621 15.6759 12 18.75 12C18.75 15.3334 16.3155 18.0908 13.134 18.6358Z"
                    fill="url(#paint0_linear_1525_6445)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1525_6445"
                      x1="3"
                      y1="3"
                      x2="24.4948"
                      y2="9.57515"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                  </defs>
                </svg>
                Protect your investment
              </p>
            </div>
            <div className="saveTimeAccordionAnswer">
              <img src={Expert} alt="Expert" />
              <h5>With Wisedrive: 100% Buyback Guarantee</h5>
              <p>
                We provide 100% Buyback guarantee at 100% of invoice value for all our warranty customers protecting the
                entire investment value.
              </p>
              <h4>Without Wisedrive</h4>

              <p>
                All repairs and expenses have to be borne by customer and when you try to sell the car because of some
                issue with car you will be offered 50% (approx) of the invoice price.
              </p>
            </div>
          </div>

          <div
            className={select === 5 && toggleSix ? "saveTimeAccordion selected" : "saveTimeAccordion"}
            onClick={() => {
              setToggleSix(!toggleSix);
              setToggle(false);
              setToggleTwo(false);
              setToggleThree(false);
              setToggleFour(false);
              setToggleFive(false);
              setToggleSeven(false);
              setSelect(5);
            }}
          >
            <div className="saveTimeAccordionQuestion">
              <p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 3L3 7.5V21H4.50007V9.75H19.5001V21H21V7.5L12 3Z" fill="url(#paint0_linear_1525_6450)" />
                  <path
                    d="M18.0001 13.5H16.3126L16.0233 12.3412C15.8732 11.7407 15.2439 11.25 14.6251 11.25H9.375C8.75602 11.25 8.12737 11.7407 7.97707 12.3412L7.6875 13.5H6V14.2499L7.1844 14.7238L6.41602 15.8758C6.1872 16.2191 6 16.8374 6 17.2496V20.9999H7.50007L7.87515 19.125H16.1252L16.5001 20.9999H18.0001V17.2498C18.0001 16.8376 17.8125 16.2193 17.5839 15.876L16.8157 14.724L18.0001 14.2499V13.5ZM9.375 12.7498H14.6249L14.9635 14.2499H9.0375L9.375 12.7498Z"
                    fill="url(#paint1_linear_1525_6450)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1525_6450"
                      x1="3"
                      y1="3"
                      x2="24.4948"
                      y2="9.57515"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1525_6450"
                      x1="6"
                      y1="11.25"
                      x2="19.7254"
                      y2="16.4175"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                  </defs>
                </svg>
                Better Resale Value
              </p>
            </div>
            <div className="saveTimeAccordionAnswer">
              <img src={Expert} alt="Expert" />
              <h5>With Wisedrive: Value Guarantee</h5>
              <p>
                Any car maintained at showroom always has higher resale value. Our warranty customers cars are serviced
                and maintained at OEM authorized service centers and service history is maintained which increases the
                resale value of the car.
              </p>
              <h4>Without Wisedrive</h4>

              <p>
                You have to sell the car to the highest bidder and all of them will quote less than the similar car
                maintained which has OEM service history.
              </p>
            </div>
          </div>

          <div
            className={select === 6 && toggleSeven ? "saveTimeAccordion selected" : "saveTimeAccordion"}
            onClick={() => {
              setToggleSeven(!toggleSeven);
              setToggle(false);
              setToggleTwo(false);
              setToggleThree(false);
              setToggleFour(false);
              setToggleFive(false);
              setToggleSix(false);
              setSelect(6);
            }}
          >
            <div className="saveTimeAccordionQuestion">
              <p>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20.8001 4.80011H19.0003L18.1001 3H16.3002L15.4001 4.80011H8.20016L7.29997 3H5.50013L4.59995 4.80011H2.80011C1.81028 4.80011 1 5.61039 1 6.59995V19.2002C1 20.1897 1.81028 21 2.80011 21H20.8001C21.7899 21 22.6002 20.1897 22.6002 19.2002V6.59995C22.6002 5.61039 21.7899 4.80011 20.8001 4.80011ZM9.10008 13.8001H3.70003V12H9.10008V13.8001ZM19.9002 13.8001H18.1001V15.5999H16.3002V13.8001H14.5001V12H16.3002V10.1999H18.1001V12H19.9002V13.8001Z"
                    fill="url(#paint0_linear_1525_6454)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1525_6454"
                      x1="1"
                      y1="3"
                      x2="25.8581"
                      y2="12.1248"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#2836D7" />
                      <stop offset="1" stopColor="#8E93D7" />
                    </linearGradient>
                  </defs>
                </svg>
                Peace of Mind, Guaranteed
              </p>
            </div>
            <div className="saveTimeAccordionAnswer">
              <img src={Expert} alt="Expert" />
              <h5>With Wisedrive: But with Trust, Drive with Confidence</h5>
              <p>
                Wisedrive Warranty provides complete peace of mind to car owners by providing superior maintenance and
                warranty solutions protecting your investment and reducing the depreciation when the car is for resale.
              </p>
              <h4>Without Wisedrive</h4>
              <p>
                Service, Maintenance, Engine repairs, Transmission repairs and breakdown expenses are all paid by the
                customer with no guarantee or warranty of future damage.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SaveTime;
