import React, { useEffect, useState } from "react";
// import SearchNavbar from "../components/search-cars/SearchNavbar";
import styles from "./SearchPage.module.css";
// import SearchBar from "../components/search-cars/SearchBar";
import SearchFilters from "../../components/search-cars/SearchFilters";
import SearchResults from "../../components/search-cars/SearchResults";
import Header from "../../components/header";
import SearchCarHeader from "../../components/header/SearchCarHeader";
import Footer from "../../components/footer";
import { useRef } from "react";
import SearchResultsLink from "../../components/search-cars/SearchResultsLink";
import SearchResultsNew from "../../components/search-cars/SearchResultsNew";
// import { useLocation } from "react-router-dom";

const SearchCars = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const ref = useRef(null);
  const footerRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [lid, setLid] = useState("");

  const checkTimer = () => {
    let hours = 24;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem("setupTime");
    if (setupTime == null) {
      localStorage.setItem("setupTime", now);
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem("setupTime", now);
      }
    }
  };

  useEffect(() => {
    setLid(window.location.pathname.split("/")[2]);
  }, []);

  function toggleFilter() {
    setIsFilterOpen((filterState) => !filterState);
  }

  return (
    <div
      className={styles.SearchPage}
      style={{
        height: isFilterOpen ? "100vh" : "100%",
        overflowX: isFilterOpen ? "hidden" : "auto",
      }}
    >
      <SearchCarHeader />
      <div className={styles.MainBody}>
        <SearchFilters isFilterOpen={isFilterOpen} toggleFilter={toggleFilter} />
        {lid ? (
          <SearchResultsLink toggleFilter={toggleFilter} ref={ref} lid={lid} />
        ) : (
          <SearchResults toggleFilter={toggleFilter} ref={ref} />
          // <SearchResultsNew toggleFilter={toggleFilter} ref={ref} />
        )}
      </div>
      {/* <div style={{ height: 50 }} ref={footerRef}></div> */}
      <div style={{ marginTop: -20, position: "sticky", bottom: 0 }} className=".searchcars-footer" ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
};

export default SearchCars;
