import React from "react";
import Blog1 from "../../assets/images/blog/blogs1.png";
import Blog2 from "../../assets/images/blog/blogs2.png";
import Blog3 from "../../assets/images/blog/blogs3.png";
import Arrow from "../../assets/images/blog/arrow.svg";
import { Link } from "react-router-dom";

function Discover() {
  return (
    <section className="blogSection">
      <div className="container">
        <div className="commonHeading">
          <h2>
            <span>Discover more</span> blogs
          </h2>
        </div>
        <div className="discoverCards">
          <div className="blogCard">
            <div className="blogCardImg">
              <img src={Blog1} alt="blog" />
            </div>
            {/* <label>MAY 28, 2021</label> */}
            <h3>Car Warranty vs Car Insurance – Which is Better?</h3>
            <p>Did you know that both car warranty and car insurance are equally important? When you purchase a car, you get both – a ...</p>
            <Link to="">
              Read Now
              <img src={Arrow} alt="Car" />
            </Link>
          </div>
          <div className="blogCard">
            <div className="blogCardImg">
              <img src={Blog2} alt="Blog" />
            </div>
            {/* <label>MAY 28, 2021</label> */}
            <h3>Roadside Assistance: What is It?</h3>
            <p>Did you know that both car warranty and car insurance are equally important? When you purchase a car, you get both – a ...</p>
            <Link to="">
              Read Now
              <img src={Arrow} alt="Car" />
            </Link>
          </div>
          <div className="blogCard">
            <div className="blogCardImg">
              <img src={Blog3} alt="Blog" />
            </div>
            {/* <label>MAY 28, 2021</label> */}
            <h3>How to Check Used Bike Price?</h3>
            <p>Did you know that both car warranty and car insurance are equally important? When you purchase a car, you get both – a ...</p>
            <Link to="">
              Read Now
              <img src={Arrow} alt="Car" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Discover;
