import React, { useState, useEffect, useContext } from "react";

import "./header.css";
import companyLogo from "../../assets/images/home/logo.svg";
import Bars from "../../assets/images/home/bars.svg";
import Close from "../../assets/images/home/close.svg";
import Expand from "../../assets/images/header/expandMore.svg";
import { Link, useNavigate } from "react-router-dom";
import wisedDrivelogo from "../../assets/images/header/log0.png";
import KycPopup from "../popups/kycPopup";
import { MyContext } from "../../context";
import styles from "./portalHeader.module.css";
import AuthUserPopup from "../popups/AuthUserPopup";

const Header = () => {
  const path = window.location.href.split("/")[3];
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  const [sticky, setSticky] = useState("");
  const [headerDropDownText, setHeaderDropDownText] = useState("");
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [openKycPopup, setOpenKycPopup] = useState(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const { setOwnerPlanTabActive } = useContext(MyContext);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset <= 500) {
        setSticky("sticky");
      } else {
        setSticky("sticky");
      }

      let moving = window.pageYOffset;

      if (window.pageYOffset > 100) {
        setVisible(position > moving);
      }
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const cls = visible ? "" : "hidden";

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset <= 10) {
        document.getElementsByTagName("header")[0].style.backgroundColor = "#00000000";
      } else {
        document.getElementsByTagName("header")[0].style.backgroundColor = "#fff";
      }
    });
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(!isOpen);

    // document.addEventListener("click", handleDocumentClick);
  };

  const closeNav = () => {
    setIsOpen(false);
    document.getElementById("myNav").style.height = "0%";
    // document.removeEventListener("click", handleDocumentClick);
  };

  const closeKycPopup = () => {
    setOpenKycPopup(false);
  };

  const onCloseAuthModal = () => {
    setAuthModalOpen(false);
  };

  useEffect(() => {
    ShowHeaderDropdown();
  }, [path]);

  const ShowHeaderDropdown = () => {
    if (path === "buyer") {
      setHeaderDropDownText("Buyer");
    } else if (path === "owner") {
      setHeaderDropDownText("Owner");
    } else {
      setHeaderDropDownText("Owner");
    }
  };

  const ExplorePlansSwitchTabs = () => {
    if (path === "buyer") {
      setOwnerPlanTabActive(1);
    } else if (path === "owner") {
      setOwnerPlanTabActive(0);
    } else {
      setOwnerPlanTabActive(0);
    }
    navigate("/owner-pricing-plans");
    setActive("");
  };

  return (
    <>
      <header className={`${active} ${cls} ${sticky}`}>
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img src={wisedDrivelogo} alt="company logo" />
            </Link>
            <div className="menuLinkAndOwner">
              <div className="mobile">
                {/* <div className="headerDropdown">
                  <span>
                    Owner
                    <img src={Expand} alt="Expand" />
                  </span>
                  <div className="dropdownContent">
                    <p>Owner</p>
                    <p>User</p>
                  </div>
                </div> */}
                <div id="myNav" className={`overlay ${isOpen ? "open" : ""}`}>
                  {/* <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a> */}
                  <div class="overlay-content">
                    <Link onClick={() => setIsOpen(!isOpen)} to="/owner">
                      Owner
                    </Link>
                    <Link onClick={() => setIsOpen(!isOpen)} to="/buyer">
                      Buyer
                    </Link>
                  </div>
                </div>
                {/* <span onClick={openNav}>
                  {headerDropDownText} <img src={Expand} alt="Expand" />
                </span> */}
              </div>
              {/* <img
                className="barsPhase3"
                src={Bars}
                alt="Bars"
                onClick={() => {
                  setActive("active");
                }}
              /> */}
              {/* <button className={styles.loginBtn} onClick={() => setAuthModalOpen(true)}>
                Login/SignUp
              </button> */}
            </div>
          </div>
          <div className="navLinks">
            <div className="logo">
              <Link to="/">
                <img src={companyLogo} alt="company logo" />
              </Link>
              <img
                className="closeIconPhase3"
                src={Close}
                alt="Close"
                onClick={() => {
                  setActive("");
                }}
              />
            </div>
            <ul>
              <Link onClick={ExplorePlansSwitchTabs} className="mobile mobileHeader tabListMobile" to="/owner">
                <li>Owner</li>
              </Link>

              <Link onClick={ExplorePlansSwitchTabs} className="mobile mobileHeader tabListMobile" to="/buyer">
                <li>Buyer</li>
              </Link>
            </ul>
            {/* <button className={styles.loginBtn} onClick={() => setAuthModalOpen(true)}>
              Login/SignUp
            </button> */}
          </div>
        </div>
      </header>
      {openKycPopup ? <KycPopup closeKycPopup={closeKycPopup} /> : ""}
      {authModalOpen ? <AuthUserPopup closeKycPopup={onCloseAuthModal} /> : ""}
    </>
  );
};

export default Header;
