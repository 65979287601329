import React from "react";
import "./payment-success.css";
import previous from "../../assets/images/payment-success/previousArrow.svg";
import checkTick from "../../assets/images/payment-success/checkCircle.svg";
import creditScore from "../../assets/images/payment/creditScore.svg";
import purchaseScan from "../../assets/images/payment/purchaseScan.svg";
import rightArrow from "../../assets/images/payment/rightArrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styles from "./Payment_sucess.module.css"

function PaymentCompleted() {
  var storedJsonString = sessionStorage.getItem("data");
  var storedArray = JSON.parse(storedJsonString);
  const name = sessionStorage.getItem("name");
  const refId = sessionStorage.getItem("refeId");
  const Activated = sessionStorage.getItem("activated");
  const Expires = sessionStorage.getItem("expiary");

  const navigate = useNavigate();
  const location = useLocation();

  const handleLinkClick = (event) => {
    event.preventDefault();
    sessionStorage.clear();
    // navigate(`/wiseassist`);

    if (location.pathname === "/payment-success-wiseassist") {
      navigate(`/wiseassist`, { state: { fromSuccess: true } });
    }

    if (location.pathname === "/payment-success-wiseassist_fb") {
      navigate(`/wiseassist_fb`, { state: { fromSuccess: true } });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "Invalid date";
    }

    const date = new Date(dateString);
    if (isNaN(date)) {
      return "Invalid date";
    }

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    let daySuffix;

    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    const formattedDate = `${day}${daySuffix} ${month} ${year}`;

    return formattedDate;
  };
  return (
    <section className="paymentSuccess">
      <div className="container">
        <div className="purchaseSuccessArrowMobile mobile">
          <img onClick={handleLinkClick} className="previousArrow" src={previous} alt="previous" />
          <div>
            <h2>We received your Request</h2>
            <p>Thank you for trusting Wisedrive to inspect your car. Our WiseAssist agent will call you shortly.</p>
          </div>
        </div>
        <div className="purchaseSuccess">
          <img className="previousArrow" src={previous} alt="previous" onClick={handleLinkClick} />
          <div className="purchaseIncludes">
            <div className="desktop">
              <h2>We received your Request</h2>
              <p>Thank you for trusting Wisedrive to inspect your car. Our WiseAssist agent will call you shortly.</p>
            </div>
            {/* <h4>What’s Included</h4> */}
            {/* <ul>
              {storedArray &&
                storedArray.map((each) => {
                  return (
                    <li key={each.description_id}>
                      <img src={checkTick} alt="tick" />
                      {each.description}
                    </li>
                  );
                })}
              {storedArray?.length === 0 && (
                <>
                  <li>
                    <img src={checkTick} alt="tick" />
                    Thorough Inspection of Engine, Transmission, and Electrical Systems
                  </li>
                  <li>
                    <img src={checkTick} alt="tick" />
                    Comprehensive Examination of Suspension, Brakes, and Steering
                  </li>
                  <li>
                    <img src={checkTick} alt="tick" />
                    Diagnostic Tests using latest OBD2 scanner
                  </li>
                  <li>
                    <img src={checkTick} alt="tick" />
                    RTO Verification
                  </li>
                  <li>
                    <img src={checkTick} alt="tick" />
                    Check Blacklist status of Vehicle
                  </li>
                  <li>
                    <img src={checkTick} alt="tick" />
                    Check all Pending Challan of Vehicle
                  </li>
                </>
              )}
            </ul> */}
          </div>

          <div className={`purchaseDone ${styles.new_class}`}>
            <div className="purchaseWelcome">
              <div className="creditScoreImage">
                <img src={creditScore} alt="image" />
              </div>
              <h3>Welcome to Wisedrive Family</h3>
            </div>
            <div className="purchaseDoneContent">
              <div className="purchaseText">
                <div className="purchaseInfo">
                  <p>Requested by</p>
                  <h4>{name}</h4>
                </div>
                {/* <div className="purchaseInfo">
                  <p>Request Type</p>
                  <h4>FREE</h4>
                </div> */}
                <div className="purchaseInfo">
                  {/* <p>Activation Date</p>
                  <h4>{Activated}</h4> */}
                  <p>Request Type</p>
                  <h4>Used Car Inspection</h4>
                </div>
                {/* <div className="purchaseInfo">
                  <p>Expire Date</p>
                  <h4>{formatDate(Expires)}</h4>
                </div> */}
              </div>
              <div
                className="purchaseScan"
                style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
                onClick={handleLinkClick}
              >
                {/* <img className="desktop" src={purchaseScan} alt="image" /> */}
                <div className="purchaseDownloadApp">
                  {/* <h4>Scan to Download</h4> */}
                  <span>Back to home</span> <img src={rightArrow} alt="arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PaymentCompleted;
