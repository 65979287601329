import EngineReport from "../assets/images/reports/engineReport.svg";
import Service from "../assets/images/reports/service.svg";
import Traffic from "../assets/images/reports/traffic.svg";
import Onboard from "../assets/images/reports/onboard.svg";
import Transmition from "../assets/images/reports/transmition.svg";

export const ValidationContainer = {
  carNumber: /^[A-Z0-9]*\d[A-Z0-9]$/,
  mobileNumberFormat: /^[6789]\d{9}$/,
  alphabet: /[A-Z]/,
};

export const healthReportData = [
  {
    icon: EngineReport,
    title: "Engine Inspection report",
    description: "Detailed Overall inspection of the car internal parts and paintings.",
    reportLink: "/report-details",
    module_id: 1,
    unlocked: true,
  },
  {
    icon: Transmition,
    title: "Transmission inspection report",
    description: "Complete detailed inspection report about car gearbox, clutch & gear oil.",
    reportLink: "/report-details",
    unlockLink: "/payment",
    unlockPrice: "Unlock for ₹299",
    module_id: 2,
    unlocked: true,
  },
  {
    icon: Onboard,
    title: "OBD Report",
    description: "Car detailed health report of sensors, and onboard electronics of car.",
    reportLink: "/report-details",
    unlockLink: "/payment",
    unlockPrice: "Unlock for ₹499",
    module_id: 3,
    unlocked: false,
  },
  {
    icon: Traffic,
    title: "Traffic Fines and Blacklist Details",
    description: "Get to know the car fines, blacklist status & many more legal info of car.",
    reportLink: "",
    unlockLink: "",
    module_id: 4,
    unlocked: false,
  },
  {
    icon: Service,
    title: "Finance and insurance check",
    description: "Complete service history of car in one glance.",
    reportLink: "",
    unlockLink: "",
    unlocked: false,
    module_id: 5,
  },
];
export const comingSoon = [
  {
    icon: Traffic,
    title: "Traffic fines & blacklist details",
    description: "Get to know the car fines, blacklist status & many more legal info of car.",
    reportLink: "",
    unlockLink: "",
    unlockPrice: "Coming Soon",
    unlocked: false,
  },
  {
    icon: Service,
    title: "Service history",
    description: "Complete service history of car in one glance.",
    reportLink: "",
    unlockLink: "",
    unlockPrice: "Coming Soon",
    unlocked: false,
  },
];
export const checkoutEngine = [
  { title: "Oil Pump", warranty: true, service: false, superCombo: true },
  {
    title: "Crankshaft & Pulley",
    warranty: true,
    service: true,
    superCombo: true,
  },
  {
    title: "Big end and main bearings",
    warranty: true,
    service: true,
    superCombo: true,
  },
  {
    title: "Connecting rods",
    warranty: true,
    service: false,
    superCombo: "",
  },
  { title: "Gudgeon Pins", warranty: true, service: "", superCombo: true },
  {
    title: "Piston and Rings",
    warranty: true,
    service: "",
    superCombo: true,
  },
  {
    title: "Inlet and exhaust valves",
    warranty: true,
    service: false,
    superCombo: true,
  },
];
