import React from "react";
import ContactUs from "../../components/contact-us/contact-us";
import Download from "../../components/download/download";
import Faq from "../../components/faq/faq";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Price from "../../components/price/price";
import Banner from "./banner";
import Requests from "./requests";
import Service from "./service";

function index() {
  return (
    <div>
      <Header />
      <Banner />
      <Requests />
      <Price />
      <Service />
      <Faq />
      <Download />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default index;
