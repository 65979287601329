import React from "react";
import Spiral from "../../assets/images/about/spiralVenturesBanner.svg";
import Neuva from "../../assets/images/about/nuevaBanner.svg";
import BannerHero from "../../assets/images/about/aboutBanner.png";

function Banner() {
  return (
    <section className="about">
      <div className="container">
        <div className="bannerHeading">
          <label>😍 Get to know us better</label>
          <h1>
            Our values{" "}
            <span>help us set the bar for customer satisfaction high</span>
          </h1>
          <div className="assistancePartners">
            <p>We are Backed by</p>
            <div className="assistancePartnersLogos">
              <img src={Spiral} alt="Spiral ventures" />
              <img src={Neuva} alt="Nueva" />
            </div>
          </div>
        </div>
        <img className="aboutBannerImage" src={BannerHero} alt="banner" />
      </div>
    </section>
  );
}

export default Banner;
