import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactUs from "../../components/contact-us/contact-us";
import Download from "../../components/download/download";
import Faq from "../../components/faq/faq";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Assistance from "./assistance";
import ServiceCenter from "./service-center";
import "./service-network.css";
import Form from "./form";
import Guarantee from "../warranty-benefits/guarantee";
import CheckUp from "../warranty-benefits/check-up";
import CheckUpPnp from "../warranty-benefits/check-up-pnp";
import PartnerLocation from "../../components/partnerLocation";
import PmpFooter from "../../components/Pmp_footer";
import WhatsAppWidget from "react-whatsapp-widget";
import { SendTrackingCount } from "../../Api/Apis";
import axios from "axios";
import {useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

function Pmp() {
  const [isPaymentSuccess, setPaymentSuccess] = useState(false);
  const location = useLocation();

  const [isNavigate, setIsNavigate] = useState(false);
  const navigate = useNavigate();
  const getIpAddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");

    if (res.status === 200) {
      const userIp = res.data.ip;
      TrackVisit(userIp);
    } else {
      console.error("Error getting ip address of user");
    }
  };

  const TrackVisit = async (ip) => {
    const data = {
      ip: ip,
      sourceId: 30,
      pageTypeId: 3,
    };
    try {
      const resp = await SendTrackingCount(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIpAddress();
  }, []);

  useEffect(() => {
    const paymentSuccessFlag = sessionStorage.getItem("paymentSuccessFlag");
    if (!paymentSuccessFlag) {
      sessionStorage.setItem("paymentSuccessFlag", "true");
    } else {
      if (location.pathname === "/submission_success") {
        navigate(`/car_general_service/1`, { state: { fromSuccess: true } });
      }
      sessionStorage.removeItem("paymentSuccessFlag");
    }
  }, []);

  useEffect(() => {
    const conversionValue = 40.0;
    const currency = "INR";
    const script = document.createElement("script");
    script.innerHTML = `gtag('event', 'conversion', {
      'send_to': 'AW-974302522/zE2nCI69y9IBELraytAD',
      'value': ${conversionValue},
      'currency': '${currency}'
    });`;

    document.head.appendChild(script);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Wisedrive - Success page</title>
        <meta name="description" content="Wisedrive - Interested lead  form submission success page " />
        <link rel="canonical" href="" />
      </Helmet>
      <Header />
      <Form />
      <CheckUpPnp />
      <PartnerLocation />

      {/* <CheckUp /> */}
      {/* <ServiceCenter /> */}
      <Guarantee />
      {/* <Assistance /> */}
      <Faq />
      <Download />
      <ContactUs />
      {/* <Footer /> */}
      <PmpFooter />
    </div>
  );
}

export default Pmp;
