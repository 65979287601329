import React, { useState } from "react";
import Styles from "./suitableFacebookPlan.module.css";
import arrowBlue from "../../assets/images/home/arrowBlue.svg";
import InspectionPlanModalWiseAssist from "../../components/popups/InspectionPlanModalWiseAssist";

function SuitableFacebookPlan({ pageData }) {
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };

  return (
    <section className={`${Styles.suitablePlans} suitablePlans`}>
      <div className="container">
        <div className="commonHeading">
          <h2>
            The best inspection Plan<span> for your used car</span>
          </h2>
        </div>
        <div className={`${Styles.suitablePlanContent} ${Styles.suitablePlanContentOwner}`}>
          <div className={Styles.suitablePlanCard}>
            <div className={Styles.suitablePlanCardHead} style={{ display: "flex" }}>
              <p>3. RTO, Blacklist, Traffic Fine check</p>
              <p>2. Full Inspection of Suspension, Brakes & Steering</p>
              <p>1. Full inspection of Engine, Transmission & Electrical parts</p>
              <h3>WiseAssist</h3>
            </div>
            <p>Only</p>
            <h4>
              <span>
                <span>Rs 749/- </span>
                <span
                  style={{
                    textDecoration: "line-through",
                    fontWeight: "400",
                    fontSize: "30px",
                    color: "#eb6d47",
                  }}
                >
                  ₹999/-
                </span>
              </span>
            </h4>
            <a onClick={toggleModal} className={Styles.getStarted}>
              Pay now <img src={arrowBlue} alt="arrow" />
            </a>
          </div>
        </div>
      </div>

      <InspectionPlanModalWiseAssist isOpen={isModalOpen} toggler={toggleModal} pageData={pageData} />
    </section>
  );
}
export default SuitableFacebookPlan;
