import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "./kycPopup.css";
import Close from "../../assets/images/reportDetails/videoClose.svg";
import Engine1 from "../../assets/images/reports/Engine2.png";
import VideoAnalizer from "../../assets/images/reportDetails/videoAnalizer.png";
import Verified from "../../assets/images/reportDetails/verified.svg";
import { useParams } from "react-router-dom";

const VideoPopup = ({ setVideoPop, selectedData }) => {
  const data = useParams();
  const [range, setrange] = useState(68);

  // const rangeValue = (e) => {
  //   setrange(e.target.value)
  // }

  const renderCustomThumbs = () => {
    const temp = selectedData.map(() => (
      <picture>
        <source data-srcset={VideoPopup} type="image/jpg" />
        <img key="01" src={Engine1} alt="First Thumbnail" height="70" />
      </picture>
    ));
    return temp;
  };

  const variableBackground = () => {
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <div className="kycContainer imageContainer videoContainer desktopView">
        <div className="kycContent">
          <div className="imageHeading">
            <h2>{data.moduleId === "1" ? "Engine Video" : data.moduleId === "2" ? "Transmission Video" : ""}</h2>
            <img
              src={Close}
              alt="close"
              onClick={() => {
                setVideoPop(false);
                variableBackground();
              }}
            />
          </div>

          <div className="imageWrapper">
            <div className="imageSlider">
              <Carousel
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                showThumbs={true}
                autoPlay={false}
                transitionTime={500}
                swipeable={true}
                emulateTouch={true}
                renderThumbs={renderCustomThumbs}
              >
                {selectedData.map((item, index) => (
                  // <div>
                  <div className="videoOutWrapper" key={index}>
                    <div className="videoWrapper">
                      <iframe
                        width="800"
                        height="500"
                        src={item.inspection_url}
                        frameBorder="0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                  // </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div className="kycContainer imageContainer videoContainer mobileView">
        <div className="kycContent" style={{ height: "90vh" }}>
          <div className="imageHeading">
            <h2>{data.moduleId === "1" ? "Engine Video" : data.moduleId === "2" ? "Transmission Video" : ""}</h2>
            <img
              src={Close}
              alt="close"
              onClick={() => {
                setVideoPop(false);
                variableBackground();
              }}
            />
          </div>
          <div className="" style={{ height: "90vh", overflow: "auto" }}>
            <div className="" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
              {selectedData.map((item, index) => (
                <div key={index}>
                  <div>
                    <div style={{ marginTop: "16px" }}>
                      <iframe
                        width="350"
                        height="450"
                        src={item.inspection_url}
                        frameBorder="0"
                        allowFullScreen
                        allow="false"
                      ></iframe>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPopup;
