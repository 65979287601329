import React, { useEffect, useState } from "react";
import styles from "./newPopups.module.css";
import close from "../../assets/images/reportDetails/videoClose.svg";
import Carimage from "../../assets/images/reportDetails/carVideo.png";
import ImageGallery from "react-image-gallery";
import { Carousel } from "react-responsive-carousel";
import image1 from "../../assets/CarImages/gridimages/image1.jpg";
import image2 from "../../assets/CarImages/gridimages/image2.jpeg";
import image3 from "../../assets/CarImages/gridimages/image3.jpg";
import image4 from "../../assets/CarImages/gridimages/image4.jpg";
// import "react-image-gallery/styles/css/image-gallery.css";
import "./imageGallery.css";
import defaultImg from "../../assets/CarImages/default.jpg";

const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
    // loading: "lazy",
    // originalHeight: 300
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
    // loading: "lazy",
    // originalHeight: 300
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
    // loading: "lazy",
    // originalHeight: 300
  },
];

// for carimage grid

function convertToObjectArrayGrid(imageList) {
  const keys = ["Front Side", "Back Side", "Right Side", "Left Side"];
  const objects = keys.map((key) => ({
    original: imageList[key] ? imageList[key] : defaultImg,
    thumbnail: imageList[key] ? imageList[key] : defaultImg,
    key: key,
  }));

  for (const [key, value] of Object.entries(imageList)) {
    if (!keys.includes(key)) {
      objects.push({ original: value ? value : defaultImg, thumbnail: value ? value : defaultImg, key: key });
    }
  }
  return objects;
}

const ImageGalleryPopup = ({ toggler, imageList }) => {
  const [imagesArr, setImagesArr] = useState([]);
  const [imageHeader, setImageHeader] = useState("");

  // console.log(imagesArr);
  function convertToObjectArrayPopup(imageList) {
    const keys = ["Front Side", "Back Side", "Right Side", "Left Side"];
    const objects = [];

    keys.forEach((key) => {
      if (imageList && imageList.hasOwnProperty(key)) {
        objects.push({
          original: imageList[key] ? imageList[key] : defaultImg,
          thumbnail: imageList[key] ? imageList[key] : defaultImg,
          key: key,
        });
      }
    });

    Object.entries(imageList).forEach(([key, value]) => {
      if (!keys.includes(key)) {
        objects.push({ original: value ? value : defaultImg, thumbnail: value ? value : defaultImg, key: key });
      }
    });

    return objects;
  }

  // const items = convertToObjectArrayPopup(imageList);
  // console.log(items);

  useEffect(() => {
    if (!imageList) {
      setImagesArr([
        {
          original: defaultImg,
          thumbnail: defaultImg,
        },
        {
          original: defaultImg,
          thumbnail: defaultImg,
        },
        {
          original: defaultImg,
          thumbnail: defaultImg,
        },
      ]);
    } else {
      const orderedImgs = convertToObjectArrayPopup(imageList);
      setImagesArr(orderedImgs);
    }
  }, [imageList]);

  useEffect(() => {
    if (imagesArr.length > 0) setImageHeader(imagesArr[0].key);
  }, [imagesArr]);

  function sliderHanlder(index) {
    // console.log(index);
    const imageVal = imagesArr[index];
    setImageHeader(imageVal.key);
  }

  return (
    <div className={`${styles.kycContainer} ${styles.imageContainer} ${styles.active}`}>
      <div className={styles.kycContent}>
        <div className={styles.imageHeading}>
          <h2>{imageHeader}</h2>
          <img src={close} alt="close" onClick={toggler} />
        </div>
        <div className={styles.imageWrapper}>
          {/* <div className={styles.imageSlider}> */}
          {imagesArr.length > 0 && <ImageGallery items={imagesArr} onSlide={sliderHanlder} />}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ImageGalleryPopup;
