import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Banner from "./banner";
import "./payment.css";
import FooterFacebookLandingPage from "../../components/footerFacebookLandingPage";

const PaymentWiseAssist = () => {
  return (
    <>
      <Header />
      <Banner />
      <FooterFacebookLandingPage />
    </>
  );
};

export default PaymentWiseAssist;
