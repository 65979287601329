import React, { useContext, useState, useEffect } from "react";
import { MyContext } from "../../context";
import { Link } from "react-router-dom";
import MostPopular from "../../components/mostPopular/mostPopular";
import RightGrediantArrow from "../../assets/images/home/rightArrowGrediant.svg";
import RightArrow from "../../assets/images/home/rightArrow.svg";
import Star from "../../assets/images/pricing-plans/priceStar.svg";
import Engine from "../../assets/images/owner-price-plans/engine.svg";
import Transmission from "../../assets/images/owner-price-plans/transmission.svg";
import Tick from "../../assets/images/home/right.svg";
import Wrong from "../../assets/images/owner-price-plans/wrong.svg";
import Plus from "../../assets/images/owner-price-plans/add.svg";
import Minus from "../../assets/images/owner-price-plans/minus.svg";
import Dropdown from "../../assets/images/owner-price-plans/dropdownArrow.svg";
import arrowBlue from "../../assets/images/home/arrowBlue.svg";
import starOrange from "../../assets/images/home/starOrange.svg";
import SuitablePlans from "../suitablePlans";
import { InspectionPlanApi } from "../../Api/Apis";
import InspectionPlanModal from "../popups/inspectionPlanModal";
import EngineProtectionOne from "../engineProtection/EngineProtectionOne";
import EngineProtectionTwo from "../engineProtection/EngineProtectionTwo";
import EngineProtectionThree from "../engineProtection/EngineProtectionThree";
import styles from "./palnTablesNew.module.css";

function PlanTablesNew() {
  const { ownerPlanTabActive } = useContext(MyContext);
  const [minimize, setMinimize] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [toggleTwo, setToggleTwo] = useState(false);
  const [dropdown, setDropdown] = useState("Comprehensive Warranty + Buyback Guarantee");
  const [openPurchasePlanModal, setOpenPurchasePlanModal] = useState(false);
  const [buyDropdown, setBuyDropdown] = useState("Extended Warranty");
  const [firstComponent, setFirstComponent] = useState(true);
  const [secondComponent, setSecondComponent] = useState(false);
  const [thirdComponent, setThirdComponent] = useState(false);
  const PlansContent = "buyer";

  const [isModalOpen, setIsmodalOpen] = useState(false);
  const [planId, setPlanId] = useState("");

  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };

  const [active, setActive] = useState(0);

  const [inspectionPlan, setInspectionPlan] = useState([]);

  const getInspectionPlanList = async () => {
    try {
      const resp = await InspectionPlanApi();
      if (resp.status === 200) {
        setInspectionPlan(resp.data.response.InspectionPackList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInspectionPlanList();
  }, []);

  return (
    <section className="compare ownerCompare">
      <div className="desktop">
        <div className="container">
          <div
            className={ownerPlanTabActive === 0 ? "comparePlans ownCarPlans activatePlans" : "comparePlans ownCarPlans"}
          >
            <div
              className="comparePlansCard"
              onClick={() => {
                setFirstComponent(true);
                setSecondComponent(false);
                setThirdComponent(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <div>
                <h3>Comprehensive Warranty + Buyback Guarantee</h3>
                <p>The quickest and easiest way to try wisedrive</p>
              </div>
              <a className={firstComponent ? "contactUsBtnGrediant" : "contactUsBtn"}>
                Get Started <img src={RightGrediantArrow} alt="Arrow" />
              </a>
            </div>

            <div
              className="comparePlansCard"
              onClick={() => {
                setFirstComponent(false);
                setSecondComponent(true);
                setThirdComponent(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <div>
                <h3>Service and Maintenance Plan</h3>
                <p>The quickest and easiest way to try wisedrive</p>
              </div>
              <a className={secondComponent ? "contactUsBtnGrediant" : "contactUsBtn"}>
                Get Started <img src={RightGrediantArrow} alt="Arrow" />
              </a>
            </div>

            <div
              className="comparePlansCard"
              onClick={() => {
                setFirstComponent(false);
                setSecondComponent(false);
                setThirdComponent(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <div>
                <h3>
                  Super Combo Plan
                  <span>
                    <img src={Star} alt="Star" />
                    popular
                  </span>
                </h3>
                <p>The quickest and easiest way to try wisedrive</p>
              </div>
              <a className={thirdComponent ? "contactUsBtnGrediant" : "contactUsBtn"}>
                Get Started <img src={RightGrediantArrow} alt="Arrow" />
              </a>
            </div>
          </div>
          {firstComponent && <div>{ownerPlanTabActive === 0 ? <EngineProtectionOne /> : ""}</div>}
          {secondComponent && <div>{ownerPlanTabActive === 0 ? <EngineProtectionTwo /> : ""}</div>}
          {thirdComponent && <div>{ownerPlanTabActive === 0 ? <EngineProtectionThree /> : ""}</div>}

          <div
            className={
              ownerPlanTabActive === 1
                ? "comparePlans buyingCarPlans activatePlans checkoutPlansPhase3 no-padding"
                : "comparePlans buyingCarPlans "
            }
          >
            <div className="container ">
              <section className="suitablePlans" style={{ width: "100%", background: "none" }}>
                <div className="container">
                  <div className="suitablePlanContent">
                    {inspectionPlan &&
                      inspectionPlan.map((each, index) => {
                        return (
                          <div className="suitablePlanCard">
                            <div className="suitablePlanCardHead userPlanCardHead">
                              <h3>{each.package_name}</h3>
                            </div>
                            <p>Starting from</p>
                            <h4>
                              <span>₹{each.amount}</span>
                            </h4>
                            <a
                              onClick={() => {
                                toggleModal();
                                setPlanId(each.id);
                              }}
                              className="getStarted"
                              // href="warranty-plan"
                            >
                              Get Started <img src={arrowBlue} alt="arrow" />
                            </a>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <InspectionPlanModal isOpen={isModalOpen} toggler={toggleModal} planId={planId} />
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.mobileView}>
        <div
          className="comparePlansCard"
          onClick={() => {
            setFirstComponent(true);
            setSecondComponent(false);
            setThirdComponent(false);
          }}
          style={{ paddingLeft: "4%" }}
        >
          <div>
            <h3>Comprehensive Warranty + Buyback Guarantee</h3>
            <p>The quickest and easiest way to try wisedrive</p>
          </div>
        
        </div>
        <EngineProtectionOne />

        <div
          className="comparePlansCard"
          onClick={() => {
            setFirstComponent(false);
            setSecondComponent(true);
            setThirdComponent(false);
          }}
          style={{ paddingLeft: "4%" }}
        >
          <div>
            <h3>Service and Maintenance Plan</h3>
            <p>The quickest and easiest way to try wisedrive</p>
          </div>
        
        </div>
        <EngineProtectionTwo />

        <div
          className="comparePlansCard"
          onClick={() => {
            setFirstComponent(false);
            setSecondComponent(false);
            setThirdComponent(true);
          }}
          style={{ paddingLeft: "4%" }}
        >
          <div>
            <h3>
              Super Combo Plan
              <span style={{ marginTop: "0px" }}>
                <img src={Star} alt="Star" />
                popular
              </span>
            </h3>
            <p>The quickest and easiest way to try wisedrive</p>
          </div>
        
        </div>
        <EngineProtectionThree />
      </div> */}
      <div className="mobile compareMobile">
        <div className="container no-padding">
          {ownerPlanTabActive === 0 && <label className=" padding-sides-16">Choose your plan</label>}
          <div className={ownerPlanTabActive === 0 ? "planDropdown dropdownActive " : "planDropdown"}>
            <div className=" padding-sides-16 " style={{ position: "relative" }}>
              <select name="plans" id="plans" onChange={(e) => setDropdown(e.target.value)}>
                <option value="Comprehensive Warranty + Buyback Guarantee">
                  Comprehensive Warranty + Buyback Guarantee
                </option>
                <option value="Service and Maintenance Plan">Service and Maintenance Plan</option>
                <option value="Super Combo Plan">Super Combo Plan</option>
              </select>
              <img src={Dropdown} alt="dropdown" style={{ position: "absolute", right: "6%", top: "27%" }} />
            </div>

            {dropdown === "Comprehensive Warranty + Buyback Guarantee" ? <EngineProtectionOne /> : ""}
            {dropdown === "Service and Maintenance Plan" ? <EngineProtectionTwo /> : ""}
            {dropdown === "Super Combo Plan" ? <EngineProtectionThree /> : ""}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PlanTablesNew;
