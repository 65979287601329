import React, { useEffect, useState } from "react";
import style from "../../styles/contact-us.module.css";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { addLeadApi, contactUsApi } from "../../Api/Apis";
import PaymentDoneModal from "../../components/popups/paymentDoneModal";
import ThankYou from "../../assets/images/paymentSucess/35.png";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Form() {
  const [maxLength, setMaxLength] = useState(10);
  // const [userData, setUserData] = useState({
  //   name: "",
  //   phoneNum: "",
  // });

  const [name, setName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [paymnetDoneModal, setPaymentDoneModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [invalidNumber, setInvalidNumber] = useState(false);

  // const handleInputChange = async (e) => {
  //   const { name, value } = e.target;
  //   setUserData({ ...userData, [name]: value });
  // };

  const userData = {
    fuelType: "",
    leadEmail: "",
    leadName: name,
    leadNo: phoneNum,
    manYear: "",
    odometer: "",
    sourceId: 2,
    transmissionType: "",
    vehicleNo: "",
    employeeId: "",
    modelId: "",
    address: "",
    landMark: "",
    pinCode: "",
    cityId: "",
    stateId: "",
    noOfOwner: "",
    color: "",
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log(name, phoneNum);
  //   if (!name) {
  //     setNameError(true);
  //   } else if (!phoneNum) {
  //     setPhoneError(true);
  //   } else if (phoneNum.length < 10) {
  //     setInvalidNumber(true);
  //   } else {
  //     try {
  //       const response = await addLeadApi(userData);
  //       if (response.status === 200) {
  //         console.log(response);
  //         setName("");
  //         setPhoneNum("");
  //         setNameError(false);
  //         setPhoneError(false);
  //         setTimeout(() => {
  //           setPaymentDoneModal(true);
  //         }, 100);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  const closeKycPopup = () => {
    setPaymentDoneModal(false);
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[6-9]\d{0,9}$/;
    if (inputValue === "" || regex.test(inputValue)) {
      setPhoneError(false);
      setInvalidNumber(false);
      setPhoneNum(inputValue);
    }
  };

  const styles = {
    textWrapper: {
      marginTop: "40px",
      marginRight: "4%",
      textAlign: "center",
      p: {},
    },
  };
  const phoneNumber = "8861409513";
  return (
    <section className={style.contactUsBanner}>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={ThankYou} alt="thankyou" style={{ height: "auto", width: "70%", marginTop: "100px" }} />
        </div>
        <div style={styles.textWrapper}>
          <h3 style={{ color: "#5c5e5d", fontSize: "1.3rem" }}>We received your request!</h3>
          <p style={{ color: "#7a7d7b", marginTop: "12px" }}>
            Thank you for sharing your details, you will receive call from our team. Thank you.
          </p>
          {/* <Link to="/">
            <p style={{ color: "#f05d35", marginTop: "24px", textDecoration: "underline" }}>Back to Home</p>
          </Link> */}

          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span
              style={{
                padding: "1.5rem",
                background: "#daf7c8",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90px",
                height: "80px",
              }}
            >
              <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                <WhatsAppIcon style={{ color: "green", height: "40px", width: "40px" }} />
              </a>
            </span>
          </div>
        </div>
      </div>

      <br />
    </section>
  );
}

export default Form;
