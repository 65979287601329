import React, { useRef, useEffect, useContext, useState } from "react";
import styles from "./car-details.module.css";
import SearchCarHeader from "../../components/header/SearchCarHeader";
import CarImageGrid from "../../components/car-details/CarImageGrid";
import CarInfo from "../../components/car-details/CarInfo";
import CarInfoDesc from "../../components/car-details/CarInfoDesc";
import WarrentyDetails from "../../components/car-details/WarrantyDetails";
import ContactForm from "../../components/car-details/ContactForm";
import VehicleCards from "../../components/car-details/VehicleCards";
import ReviewSection from "../../components/car-details/ReviewSection";
import Footer from "../../components/footer";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { PortalContext } from "../../context/carPortalContext";
// import { GetAllCarsList } from "../../Api/PortalApis";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import WarrentyDetailsNew from "../../components/car-details/WarrantyDetailsNew";

function CarDetails() {
  const location = useLocation();
  // console.log(location.state);
  const params = useParams();
  const ctx = useContext(PortalContext);
  const scollToRef = useRef();

  const navigate = useNavigate();
  // const [carInfoData, setCarInfoData] = useState({});

  const allCars = ctx.allCarsList;
  const carInfoData = allCars.filter((carData) => carData.vehicleId == params.id)[0];

  // useEffect(() => {
  //   // Function to handle orientation change
  //   function handleOrientationChange() {
  //     const isLandscape = window.innerWidth > window.innerHeight && window.innerWidth <= 510;

  //     if (isLandscape) {
  //       if (isLandscape && window.screen.orientation && window.screen.orientation.lock) {
  //         // Lock the screen in portrait mode if it's in landscape
  //         // Change this alert to any custom message or action you want
  //         alert("Please rotate your device to portrait mode.");
  //         // Rotate the device back to portrait
  //         window.screen.orientation.lock("portrait");
  //         window.screen.orientation.lock("portrait").catch((error) => {
  //           console.log("Failed to lock orientation:", error);
  //         });
  //       }
  //     }

  //     // Event listener for orientation change
  //     window.addEventListener("resize", handleOrientationChange);

  //     // Clean up the event listener on component unmount
  //     return () => {
  //       window.removeEventListener("resize", handleOrientationChange);
  //     };
  //   }
  // }, []);

  function clearOtpVerifiedData() {
    const lastVisitedTime = localStorage.getItem("lastVisited");
    if (lastVisitedTime) {
      const currentTime = new Date().getTime();
      const twentyFourHoursInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const timeElapsed = currentTime - Number(lastVisitedTime);

      if (timeElapsed >= twentyFourHoursInMs) {
        localStorage.removeItem("hasOtpVerified");
      }
    }
  }

  useEffect(() => {
    clearOtpVerifiedData();
  }, []);

  useEffect(() => {
    if (location.state && location.state.fromViewSeller) {
      scollToRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }, [location.state]);

  return (
    <div>
      <SearchCarHeader />
      <div className={styles.MainBody} ref={scollToRef}>
        <div onClick={() => navigate(-1)} style={{ display: "flex", alignItems: "center", gap: 0, cursor: "pointer" }}>
          <ArrowBackIosIcon style={{ color: "#7963f0", marginBottom: 12 }} />{" "}
          <span style={{ fontWeight: "bold", paddingBottom: 12 }}>Back to cars</span>
        </div>
        <CarImageGrid images={carInfoData?.imageList} />
        <CarInfo carData={carInfoData} />
        <div>
          <CarInfoDesc carData={carInfoData} />
        </div>
        <WarrentyDetails carData={carInfoData} />
        {/* <WarrentyDetailsNew carData={carInfoData} /> */}
        {/* <ContactForm /> */}
        <ReviewSection />
      </div>
      {/* <VehicleCards carData={carInfoData} /> */}
      {/* <div> */}
      <Footer />
      {/* </div> */}
    </div>
  );
}

export default CarDetails;
