import React from "react";
import Kalyandhar from "../../assets/images/about/kalyandhar.png";
import Pratibha from "../../assets/images/about/pratibha.png";
import shashank from "../../assets/images/about/shashank.png";
import Leslie from "../../assets/images/about/leslie.png";
import Arlene from "../../assets/images/about/arlene.png";
import LinkedIn from "../../assets/images/about/linkedIn.svg";
import Luv_Saxsena from "../../assets/images/about/luv_saxsens.png";

function Members() {
  return (
    <section className="members">
      <div className="container">
        <div className="commonHeading">
          <h2>
            Let us introduce <span>our team members</span>
          </h2>
        </div>
        <div className="membersTeam">
          <div className="membersProfile">
            <img src={Kalyandhar} alt="Kalyandhar" />
            <div className="membersProfileDetails">
              <div>
                <h5>Kalyandhar Vinukonda</h5>
                <p>Founder & CEO</p>
              </div>
              <img src={LinkedIn} alt="linkedIn" />
            </div>
          </div>
          <div className="membersProfile">
            <img src={Pratibha} alt="Pratibha" />
            <div className="membersProfileDetails">
              <div>
                <h5>Pratibha Shalini Yellayi</h5>
                <p>Co Founder & Customer Success</p>
              </div>
              <img src={LinkedIn} alt="linkedIn" />
            </div>
          </div>
          <div className="membersProfile">
            <img src={shashank} alt="Shashank" />
            <div className="membersProfileDetails">
              <div>
                <h5>Shashank Sharma</h5>
                <p>Co-Founder & Growth Champion</p>
              </div>
              <img src={LinkedIn} alt="linkedIn" />
            </div>
          </div>
          <div className="membersProfile">
            <img src={Leslie} alt="Leslie" />
            <div className="membersProfileDetails">
              <div>
                <h5>Puja Kumari</h5>
                <p>Co-Founder & Technology </p>
              </div>
              <img src={LinkedIn} alt="linkedIn" />
            </div>
          </div>
          <div className="membersProfile">
            <img src={Arlene} alt="Arlene" />
            <div className="membersProfileDetails">
              <div>
                <h5>Sonu Singh</h5>
                <p>Warranty Head</p>
              </div>
              <img src={LinkedIn} alt="linkedIn" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Members;
