import React, { useState } from "react";
import "./suitablePlans.css";
import PurchasePlan from "../../components/popups/purchasePlan";
import rightArrow from "../../assets/images/home/rightArrowGrediant.svg";
import arrowBlue from "../../assets/images/home/arrowBlue.svg";
import starOrange from "../../assets/images/home/starOrange.svg";
import { Link } from "react-router-dom";
import InspectionPlanModal from "../popups/inspectionPlanModal";

function SuitablePlans({ inspectionPlan }) {
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const [planId, setPlanId] = useState("");

  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };

  const [active, setActive] = useState(0);

  return (
    <section className="suitablePlans">
      <div className="container">
        <div className="commonHeading">
          <h2 className="commonHeading-header">
            Choose any <span> inspection plan </span>that suits you and <span>shortlist your dream car</span>
          </h2>
          <div className="desktop">
            <h3>
              <Link to="/owner-pricing-plans">Explore Pricing Plans</Link>
              <img src={arrowBlue} alt="arrowBlue" />
            </h3>
          </div>
        </div>
        <div className="suitablePlanContent">
          {inspectionPlan &&
            inspectionPlan.map((each, index) => {
              return (
                <div className="suitablePlanCard" key={index}>
                  <div className="suitablePlanCardHead userPlanCardHead">
                    <h3>{each.package_name}</h3>
                  </div>
                  <p>Starting from</p>
                  <h4>
                    <span>₹{each.amount}</span>
                  </h4>
                  <a
                    onClick={() => {
                      toggleModal();
                      setPlanId(each.id);
                    }}
                    className="getStarted"
                  >
                    Get Started <img src={arrowBlue} alt="arrow" />
                  </a>
                </div>
              );
            })}
        </div>

        {/* {PlansContent === "buyer" ? (
          <div className="suitablePlanContent">
            <div className="suitablePlanCard">
              <div className="suitablePlanCardHead userPlanCardHead">
                <h3>Unlock Single Car</h3>
              </div>
              <p>Starting from</p>
              <h4>
                <span>₹999</span>/Car
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>

            <div className="suitablePlanCard">
              <label className="offer">Save ₹1000</label>
              <div className="suitablePlanCardHead userPlanCardHead">
                <h3>Unlock 3 cars pack</h3>
              </div>
              <p>Starting from</p>
              <h4>
                <span>₹833</span>/Car
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>
            <div className="suitablePlanCard">
              <label className="offer">Save ₹2000</label>
              <div className="suitablePlanCardHead userPlanCardHead">
                <h3>Unlock 5 Cars Pack</h3>
                <label>
                  <img src={starOrange} alt="starOrange" />
                  Popular
                </label>
              </div>
              <p>Starting from</p>
              <h4>
                <span>₹599</span>/Car
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>
          </div>
        ) : (
          <div className="suitablePlanContent suitablePlanContentOwner">
            <div onMouseEnter={() => setActive(0)} className="suitablePlanCard">
              <div className="suitablePlanCardHead">
                <h3>Comprehensive Warranty + Buyback Guarantee Plan</h3>
              </div>
              <p>Starting from</p>
              <h4>
                <span>₹375</span>/month
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>

            <div onMouseEnter={() => setActive(1)} className="suitablePlanCard">
              <label className="offer">Save ₹1000</label>
              <div className="suitablePlanCardHead">
                <h3>Service and Maintenance Plan</h3>
              </div>
              <p>Starting from</p>
              <h4>
                <span>749</span>/month
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>
            <div onMouseEnter={() => setActive(2)} className="suitablePlanCard">
              <label className="offer">Save ₹2000</label>
              <div className="suitablePlanCardHead">
                <h3>Super Combo Plan</h3>
                <label>
                  <img src={starOrange} alt="starOrange" />
                  Popular
                </label>
              </div>
              <p>Starting from</p>
              <h4>
                <span>799</span>/month
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>
          </div>
        )} */}
        <div className="mobile">
          <h3>
            <Link to="/owner-pricing-plans">Explore Pricing Plans</Link>
            <img src={arrowBlue} alt="arrowBlue" />
          </h3>
        </div>
      </div>

      <InspectionPlanModal isOpen={isModalOpen} toggler={toggleModal} planId={planId} />
    </section>
  );
}
export default SuitablePlans;
