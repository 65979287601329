import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import OTPInput, { ResendOTP } from "otp-input-react";
import Kyc from "../../assets/images/home/kyc.png";
import Close from "../../assets/images/home/close.svg";
import RightArrow from "../../assets/images/home/rightArrow.svg";
import "react-phone-input-2/lib/style.css";
import "./kycPopup.css";
import { getInspectionPlanOtpApi, verifyOtpInspectionPlanApi } from "../../Api/Apis";
import { AcceptOnlyAlphabate, AcceptOnlyNumber } from "../../utils/commonFunctions";
import useWindowDimensions from "../util-hooks/useWindowDimentions";
import axios from "axios";

const InspectionPlanModal = ({ isOpen, toggler, planId }) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [phoneInvalidError, setPhoneInvalidError] = useState(false);
  const [nameError, setNameErr] = useState(false);
  const [otpErr, setOtpErr] = useState(false);
  const [OTP, setOTP] = useState("");
  const [visible, setVisible] = useState(false);
  const [leadId, setLeadId] = useState("");
  const [customerId, setCustomerId] = useState(null);

  const { width, height } = useWindowDimensions();

  // console.log(planId);

  const handleName = (e) => {
    setNameErr(false);
    const value = e.target.value;
    const isValid = AcceptOnlyAlphabate(value);
    if (isValid) {
      setName(value);
    }
  };
  const handleNumber = (e) => {
    setPhoneErr(false);
    setPhoneInvalidError(false);
    const value = e.target.value;
    const isValid = AcceptOnlyNumber(value);
    if (isValid) {
      setPhone(value);
    }
  };

  const handleInputChange = (e) => {
    if (visible === false) {
      setPhone(e.target.value);
      setPhoneErr(false);
    } else {
      setOTP(e);
      setOtpErr(false);
    }
  };

  const SendOtp = async () => {
    if (!name) {
      setNameErr(true);
    } else if (!phone) {
      setPhoneErr(true);
    } else if (phone.length < 10) {
      setPhoneInvalidError(true);
    } else {
      setVisible(true);
      try {
        const res = await axios.get("https://api.ipify.org/?format=json");
        if (res.status === 200) {
          const userIp = res.data.ip;
          const resp = await getInspectionPlanOtpApi(phone, name, 25, userIp);
          if (resp.status === 200) {
            setLeadId(resp.data.response.Details.lead_id);
            setCustomerId(resp.data.response.Details.customer_id);
            console.log(resp);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const clearInput = () => {
    setPhone("");
    setName("");
    setNameErr("");
    setPhoneErr("");
    setPhoneInvalidError("");
  };

  const getReport = async () => {
    try {
      const resp = await verifyOtpInspectionPlanApi(phone, OTP);
      if (resp.status === 200) {
        console.log(resp, "resp");
        if (resp.data.responseType === "300") {
          setOtpErr(true);
        } else if (resp.data.responseType === "200") {
          sessionStorage.setItem("name", name);
          sessionStorage.setItem("phone", phone);
          sessionStorage.setItem("lead_Id", leadId);
          sessionStorage.setItem("customer_Id", customerId);
          document.body.style.overflow = "auto";
          navigate(`/payment/${planId}`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = () => {
    setVisible(false);
  };

  return (
    <>
      {isOpen ? (
        <div className="kycContainer kycContainerPhase3">
          <div className="kycContent kycResponsive kycFormFixedBottom">
            {width >= 678 && (
              <>
                <div className="kycFormTitle kycFormTitleMobile">
                  <h3>Fetch car report</h3>
                  <img src={Close} alt="close" onClick={() => toggler()} />
                </div>
                <div className="kycImage">
                  <h2>
                    {" "}
                    <span>
                      Explore <b> 2 million, used </b>{" "}
                    </span>{" "}
                    🚘{" "}
                    <span>
                      <b>Car reports</b> which guarantee you peace of mind
                    </span>{" "}
                  </h2>
                  <img src={Kyc} width="438px" height="211px" alt="car kyc" />
                </div>
              </>
            )}
            {!visible && (
              <div className={visible ? "kycForm " : "kycForm active"}>
                <div className="kycFormTitle kycFormTitleDesk">
                  <h3>Step 1/3</h3>
                  <img
                    src={Close}
                    alt="close"
                    onClick={() => {
                      toggler();
                      clearInput();
                    }}
                  />
                </div>
                <div className="kycFormContent">
                  <div className="kycInputGroup">
                    <span className="inputLabel inputLabelPhone">Name</span>
                    <div className="rr-input-container">
                      <input
                        className="rr-input"
                        type="text"
                        onChange={handleName}
                        value={name}
                        style={{ paddingLeft: "0.5rem" }}
                      />
                    </div>
                    {nameError && <p className="error active mobile-error">Please enter name</p>}
                  </div>
                  <div className="kycInputGroup">
                    <span className="inputLabel inputLabelPhone">Phone number</span>
                    <div className="rr-input-container">
                      <span className="countryCode fetch-countryCode">+91</span>
                      <div className="line"></div>
                      <input
                        placeholder="ex. 9123456780"
                        className="rr-input padding-0 fetch-rr-input"
                        type="text"
                        onChange={handleNumber}
                        value={phone}
                      />
                    </div>
                    {phoneErr && <p className="error active mobile-error">Please enter phone number</p>}
                    {phoneInvalidError && <p className="error active mobile-error">please enter valid phone number</p>}
                  </div>
                  <button className="cta-button" onClick={SendOtp}>
                    Get Otp <img src={RightArrow} alt="Arrow" />
                  </button>
                </div>
              </div>
            )}
            {visible && (
              <div className={visible ? "kycForm otp active" : "kycForm otp "}>
                <div className="kycFormTitle kycFormTitleDesk">
                  <h3>Step 2/3</h3>
                  <img
                    src={Close}
                    onClick={() => {
                      toggler();
                      setVisible(false);
                      clearInput();
                    }}
                    alt="close"
                  />
                </div>
                <div className="kycFormContent">
                  <div className="kycInputGroup">
                    <p className="inputLabel">
                      We've sent a 4-digital code to your phone <strong>{phone}</strong>{" "}
                      <span className="cursor-pointer" onClick={handleEdit}>
                        Edit
                      </span>
                    </p>
                    <OTPInput
                      className="otpInput kycFormOtp"
                      value={OTP}
                      onChange={handleInputChange}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      maxlength="10"
                    />

                    <div className="">
                      <div className="otpErrorContainer">
                        {otpErr && (
                          <p className="error otpError active">
                            {" "}
                            The OTP entered is invalid/incorrect. Please try again.
                          </p>
                        )}
                      </div>

                      <p className="resend">
                        You didn't recieved OTP?{" "}
                        <ResendOTP
                          className="resendBtn "
                          onResendClick={() => {
                            SendOtp();
                          }}
                        />{" "}
                      </p>
                    </div>
                  </div>
                  <div className="mbt20">
                    <button className="cta-button" onClick={getReport}>
                      Get Inspection <img src={RightArrow} alt="Arrow" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default InspectionPlanModal;
