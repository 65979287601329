import React from "react";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import BeforeCompareImage from "../../assets/images/home/afterCompare.png";
import AfterCompareImage from "../../assets/images/home/carCompare.png";
import MobileBeforeCompare from "../../assets/images/home/mobileBeforeCompare.png";
import MobileAfterCompare from "../../assets/images/home/mobileAfterCompare.png";

function Saving() {
  const pathname = window.location.pathname;
  const { innerWidth } = window;

  const FIRST_IMAGE =
    innerWidth > 768
      ? {
          imageUrl: BeforeCompareImage,
        }
      : {
          imageUrl: MobileBeforeCompare,
        };
  const SECOND_IMAGE =
    innerWidth > 768
      ? {
          imageUrl: AfterCompareImage,
        }
      : {
          imageUrl: MobileAfterCompare,
        };

  return (
    <section className="saving">
      <div className="container">
        <div className="commonHeading">
          <h2>
            Ultimate Protection to your Investment and <span>Saving Your Money.</span>
          </h2>
        </div>
        <div className="savingImage">
          {pathname === "/wiseassist" ? (
            <ReactBeforeSliderComponent
              sliderInitialPosition={0.7}
              currentPercentPosition={50}
              firstImage={FIRST_IMAGE}
              secondImage={SECOND_IMAGE}
            />
          ) : (
            <ReactBeforeSliderComponent
              sliderInitialPosition={0.7}
              currentPercentPosition={-100}
              firstImage={FIRST_IMAGE}
              secondImage={SECOND_IMAGE}
            />
          )}

          {/* <img src={BeforeCompareImage} /> */}
        </div>
      </div>
    </section>
  );
}

export default Saving;
