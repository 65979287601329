import React from "react";
import Slider from "react-slick";
import NoMaintenance from "../../assets/images/warranty-benefits/noMaintenance.svg";
import Peace from "../../assets/images/warranty-benefits/peaceOfMind.svg";
import Increase from "../../assets/images/warranty-benefits/increased.svg";
import Smile from "../../assets/images/warranty-benefits/smile.svg";
import Flexibility from "../../assets/images/warranty-benefits/flexibility.svg";

function Guarantee() {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    autoplaySpeed: 0,
    variableWidth: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 500,
        settings: {
          sliderToShow: 1
        }
      }
    ]
    // dots: false,
    // arrows: true,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 3.36,
    // slidesToScroll: 1,
    // autoplaySpeed: 3000,
    // centerMode: false,
    // autoplay: true,
    // infiniteScroll: true,
    // adaptiveHeight: true,
    // responsive: [
    //   {
    //     breakpoint: 1350,
    //     settings: {
    //       slidesToShow: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 1200,
    //     settings: {
    //       slidesToShow: 2.5,
    //     },
    //   },
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2.2,
    //       // infiniteScroll: true,
    //       // centerMode: false,
    //     },
    //   },
    //   {
    //     breakpoint: 900,
    //     settings: {
    //       slidesToShow: 2.1,
    //       slidesToScroll: 1,
    //       // infiniteScroll: true,
    //       // centerMode: false,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1.5,
    //       slidesToScroll: 1,
    //       // infiniteScroll: true,
    //       // centerMode: false,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1.1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 350,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };
  return (
    <section className="guarenty" id="warranty">
      <div className="container">
        <div className="commonHeading">
          <h2>
            <span>Vehicle protection plan </span> guarantees Peace of mind
          </h2>
        </div>
        <div className="desktop">
          <div className="guarentyContent">
            {/* <Slider {...settings}> */}
            <div className="guarentyCard">
              <img src={NoMaintenance} alt="logo" />
              <h4>No Maintenance.</h4>
              <p>
                Our warranty program comes with free maintenance and service
                module.
              </p>
            </div>
            {/* <div className="guarentyCard">
            <img src={Peace} alt="logo" />
            <h4>Peace of Mind</h4>
            <p>
              All services are performed at authorised service centre only. So
              Peace of mind guaranteed.=
            </p>
          </div> */}
            <div className="guarentyCard">
              <img src={Smile} alt="logo" />
              <h4>Extended Warranty</h4>
              <p>Expensive engine and transmission are covered for free</p>
            </div>
            {/* <div className="guarentyCard">
            <img src={NoMaintenance} alt="logo" />
            <h4>No Maintenance.</h4>
            <p>
              Our warranty program comes with free maintenance and service
              module.
            </p>
          </div> */}
            <div className="guarentyCard">
              <img src={Increase} alt="logo" />
              <h4>Roadside Assistance</h4>
              <p>
                Pan India roadside assistance free for all Wisedrive customer
                &nbsp;
              </p>
            </div>
            {/* </Slider> */}
          </div>
        </div>
      </div>
      <div className="mobile">
        <div className="guarentyContent">
          <Slider {...settings}>
            <div className="guarentyCardParent">
              <div className="guarentyCard">
              <img src={Smile} alt="logo" />
              <h4>Extended Warranty</h4>
              <p>Expensive engine and transmission are covered for free</p>
            </div>
            </div>
            <div className="guarentyCardParent">
              <div className="guarentyCard">
            <img src={NoMaintenance} alt="logo" />
            <h4>No Maintenance.</h4>
            <p>
              Our warranty program comes with free maintenance and service
              module.
            </p>
          </div>
            </div>
            <div className="guarentyCardParent">
              <div className="guarentyCard">
              <img src={Increase} alt="logo" />
              <h4>Roadside Assistance</h4>
              <p>
                Pan India roadside assistance free for all Wisedrive customer
                &nbsp;
              </p>
            </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Guarantee;
