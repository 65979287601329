import React from "react";
import BannerImage from "../../assets/images/blog/bannerRightNew.png";
import BannerImageRight from "../../assets/images/blog/bannerLeft.png";
import BannerImageBottom from "../../assets/images/blog/bannerBottom.png";

import Arrow from "../../assets/images/blog/arrow.svg";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <section className="bannerSection blogBannerSection">
      <div className="container">
        <div className="blogBanner">
          <div className="blogBannerLeft">
            <Link to="Car-Insurance-or-Car-Warranty-in-India">
              <div className="blogCard">
                <div className="blogCardImg">
                  <img src={BannerImage} alt="Bnner" />
                </div>
                {/* <label>FEB 9, 2023</label> */}
                {/* <h3>Car Warranty vs Car Insurance – Which is Better?</h3> */}
                <h3>Which is better for Your Vehicle: Car Insurance or Car Warranty in India?</h3>
                <p>
                  Car insurance covers accidental damages, while a car warranty covers manufacturing defects. Both are
                  important for protecting your vehicle and ensuring peace of mind while driving ...
                </p>
                <Link to="Car-Insurance-or-Car-Warranty-in-India">
                  Read Now
                  <img src={Arrow} alt="Car" />
                </Link>
              </div>
            </Link>
          </div>
          <div className="blogBannerRight">
            <Link to="different-types-of-car-warranty">
              <div className="blogCard">
                <div className="blogCardImg">
                  <img src={BannerImageRight} style={{ borderRadius: "8px" }} alt="Blog" />
                </div>
                {/* <label>MAY 28, 2021</label> */}
                <h3>
                  {/* Future Value of the Bike – How to Check Resale Value of Bike? */}
                  What Are the Different Types of Car Warranties?
                </h3>
                <Link to="different-types-of-car-warranty">
                  Read Now
                  <img src={Arrow} alt="Car" />
                </Link>
              </div>
            </Link>
            <Link to="car-extended-warranty-contract">
              <div className="blogCard">
                <div className="blogCardImg">
                  <img src={BannerImageBottom} alt="Blog" style={{ borderRadius: "8px" }} />
                </div>
                {/* <label>MAY 28, 2021</label> */}
                {/* <h3>
                  Future Value of the Bike – How to Check Resale Value of Bike?
                </h3> */}
                <h3>What to Look For in a Car Extended Warranty Contract</h3>
                <Link to="car-extended-warranty-contract">
                  Read Now
                  <img src={Arrow} alt="Car" />
                </Link>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
