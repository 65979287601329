import React from "react";
import { Carousel } from "react-carousel-minimal";
import "./kycPopup.css";
import close from "../../assets/images/reportDetails/videoClose.svg";
import Carimage from "../../assets/images/reportDetails/carVideo.png";
import ImageGallery from "react-image-gallery";
import { useParams } from "react-router-dom";

const ImagePopup = ({ setImagePopup, selectedData }) => {
  const data = useParams();

  const carouselData = selectedData.map((item) => ({
    original: item.inspection_url,
    thumbnail: item.inspection_url,
  }));

  const variableBackground = () => {
    document.body.style.overflow = "auto";
  };

  return (
    <div className="kycContainer imageContainer active">
      <div className="kycContent">
        <div className="imageHeading">
          <h2>{data.moduleId === "1" ? "Engine Image" : data.moduleId === "2" ? "Transmission Image" : ""}</h2>
          <img
            src={close}
            alt="close"
            onClick={() => {
              setImagePopup(false);
              variableBackground();
            }}
          />
        </div>
        <div className="imageWrapper" style={{ paddingTop: "4px", paddingRight: "0px" }}>
          <div className="imageSlider">
            <ImageGallery
              items={carouselData}
              width="100%"
              radius="16px"
              slideImageFit="cover"
              thumbnails={true}
              thumbnailWidth="40px"
              thumbnailHeight="40px"
              swipe={true}
              style={{
                textAlign: "center",
                margin: "0px auto",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePopup;
