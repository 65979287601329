import React from "react";
import ContactUs from "../../components/contact-us/contact-us";
import Customers from "../../components/customers/customers";
import Faq from "../../components/faq/faq";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Banner from "./banner";
import Compare from "./compare";

import "./pricing-plans.css";

function PricingPlans() {
  return (
    <div>
      <Header />
      <Banner />
      <Compare />
      <Faq />
      <Customers />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default PricingPlans;
