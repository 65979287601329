import React from "react";
import { Link } from "react-router-dom";
import Arrow from "../../assets/images/claims/rightArrow.svg";

function Service() {
  return (
    <section className="claimService">
      <div className="container">
        <div className="commonHeading">
          <h2>
            We partner with the <span>best</span> to provide you the <span>best service</span>
          </h2>
        </div>
        <div className="claimServiceContainer">
          <div className="claimServiceCard">
            <h3>Warranty Benefits</h3>
            <p>
              Get upto <b>₹500,000</b> coverage for your engine and transmission repairs.
            </p>
            <Link to="/warranty-benefits">
              Explore Warranty Benefits <img src={Arrow} alt="Arrow" />
            </Link>
          </div>
          <div className="claimServiceCard">
            <h3>Reach out</h3>
            <p>Queries, complaints and feedback. We will be happy to serve you</p>
            <Link to="/contact-us">
              Contact us <img src={Arrow} alt="Arrow" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;
