import React from "react";
import ThankYou from "../../assets/images/paymentSucess/35.png";
import Icon from "../../assets/images/paymentSucess/29.png";

const PaymentDoneModal = ({ onClose, children }) => {
  const styles = {
    modal: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    },
    modalContent: {
      backgroundColor: "white",
      padding: "1rem",
      borderRadius: "5px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      width: "900px",
      height: "500px",
      maxWidth: "90%",
      maxHeight: "90%",
      overflow: "auto",
    },
    imageWrapper: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    secondImageWrapper: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },
    textWrapper: {
      marginTop: "55px",
      marginRight: "4%",
      textAlign: "center",
      p: {},
    },
  };

  const mediaQueries = {
    smallScreen: "@media (max-width: 767px)",
    tablet: "@media (min-width: 768px)",
    desktop: "@media (min-width: 992px)",
    largeDesktop: "@media (min-width: 1200px)",
  };

  const modalContentStyles = {
    ...styles.modalContent,
    [mediaQueries.smallScreen]: {
      width: "80%",
      height: "auto",
    },
    [mediaQueries.tablet]: {
      maxWidth: "700px",
      maxHeight: "80%",
    },
    [mediaQueries.desktop]: {
      maxWidth: "800px",
    },
    [mediaQueries.largeDesktop]: {
      maxWidth: "900px",
    },
  };

  return (
    <div style={styles.modal}>
      <div style={modalContentStyles}>
        <div style={styles.imageWrapper}>
          <div>
            <img src={ThankYou} alt="thankyou" style={{ height: "auto", width: "100%", marginTop: "100px" }} />
          </div>
          <div style={styles.secondImageWrapper}>
            <img
              src={Icon}
              alt="Icon"
              style={{ height: "auto", width: "50%", transform: "rotate(11deg)", marginTop: "110px", opacity: "0.6" }}
            />
          </div>
        </div>
        <div style={styles.textWrapper}>
          <h3 style={{ color: "#5c5e5d" }}>Your Payment is Successful</h3>
          <p style={{ color: "#7a7d7b", marginTop: "12px" }}>
            Thank you for payment. An automated payment receipt will be sent to your registered email.
          </p>
          <p style={{ color: "#f05d35", marginTop: "24px", textDecoration: "underline" }}>Back to Home</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentDoneModal;
