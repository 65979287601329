import React from "react";

function WarrantyTable() {
  return (
    <section className="warrantyTable">
      <div className="container">
        <div className="commonHeading">
          <h2>
            How <span>Warranty protects</span> your car even when you <span> have Insurance</span>
          </h2>
        </div>
        <div className="warrantyTableContent">
          <table>
            <tbody>
              <tr>
                <th>Car Insurance Covers</th>
                <th>Extended Warranty Covers</th>
              </tr>
              <tr>
                <td>Only During Accidents</td>
                <td>Useful for regular mechanical failures</td>
              </tr>

              <tr>
                <td>Probability of usage 3-4%</td>
                <td>Probability of usage 95% (considering used cars)</td>
              </tr>

              <tr>
                <td>Does not cover Engine & gearbox failure</td>
                <td>Engine & gearbox failure covered</td>
              </tr>

              <tr>
                <td>No Roadside assistance without accident</td>
                <td>Free roadside assistance without accident</td>
              </tr>

              <tr>
                <td>Pay for maintenance</td>
                <td>Free maintenance at authorised service centers</td>
              </tr>

              <tr>
                <td>Helpful only during accidents.</td>
                <td>Useful for every car need from maintenance to roadside assistance to engine failures.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default WarrantyTable;
