import React, { useState } from "react";
import "./engineProtection.css";
import engineView from "../../assets/images/engineProtection/engineView.png";
import expandMore from "../../assets/images/engineProtection/expandMore.svg";
import engineView2 from "../../assets/images/engineProtection/engineView2.png";
import engineView3 from "../../assets/images/engineProtection/engineView3.png";
import engineView4 from "../../assets/images/engineProtection/engineView4.png";
import engineView5 from "../../assets/images/engineProtection/engineView5.png";
import engineView6 from "../../assets/images/engineProtection/engineView6.png";
import engineView7 from "../../assets/images/engineProtection/engineView7.png";
import styles from "../../components/pricingTables/palnTablesNew.module.css";

function EngineProtectionOne() {
  const [openContent, setOpenContent] = useState();
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);
  const [seven, setSeven] = useState(false);
  const [eight, setEight] = useState(false);

  // const handleTabOpen = (v) => {
  //   setOpenContent(v);
  //   setToggle((PrevState) => !PrevState);
  // };
  return (
    <section className="engineProtection" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
      <div className="container">
        <div className="engineDetails">
          <div className="engineView">
            <div className="commonHeading">
              <h2 style={{ marginLeft: "2%" }}>
                <span>Starts from Rs 5000</span> for comprehensive warranty.
              </h2>
            </div>
            <div className="desktop">
              {one ? <img src={engineView} alt="engineView" /> : ""}
              {two ? <img src={engineView} alt="engineView" /> : ""}
              {three ? <img src={engineView2} alt="engineView" /> : ""}
              {four ? <img src={engineView3} alt="engineView" /> : ""}
              {five ? <img src={engineView4} alt="engineView" /> : ""}
              {six ? <img src={engineView5} alt="engineView" /> : ""}
              {seven ? <img src={engineView6} alt="engineView" /> : ""}
              {eight ? <img src={engineView7} alt="engineView" /> : ""}
            </div>
          </div>

          <div className="engineAccordion">
            <div
              className="accordions"
              onClick={() => {
                setOne((prevState) => !prevState);
                setTwo(false);
                setThree(false);
                setFour(false);
                setFive(false);
                setSix(false);
                setSeven(false);
                setEight(false);
              }}
            >
              <h3>
                <span>Engine Warranty </span>
                <img className={one ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {one ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Oil Pump</li>
                    <li>Connecting rods</li>
                    <li>Inlet and exhaust valves</li>
                    <li>Cylinder head</li>
                    <li>Timing gears</li>
                    <li>Crankshaft & Pulley</li>
                    <li>Big end and main bearings</li>
                    <li>Gudgeon Pins</li>
                    <li>Piston and Rings</li>
                    <li>Inlet and exhaust valves (Excluding burnt and pitted valves) Spring and guides</li>
                    <li>Cylinder block</li>
                    <li>Cylinder Head</li>
                    <li>Camshaft</li>
                    <li>rocker arms</li>
                    <li>shaft</li>
                    <li>Timing gears</li>
                    <li>Tensioner bearings</li>
                    <li>water pump (Failure due to external damage or corrosion is not covered)</li>
                    <li>inlet and exhaust manifold.</li>
                    {/* <li>+20 More</li> */}
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className="accordions"
              onClick={() => {
                setTwo((prevState) => !prevState);
                setOne(false);
                setThree(false);
                setFour(false);
                setFive(false);
                setSix(false);
                setSeven(false);
                setEight(false);
              }}
            >
              <h3>
                <span>Comprehensive Engine Warranty </span>
                <img className={two ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {two ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Oil Pump</li>
                    <li>Connecting rods</li>
                    <li>Inlet and exhaust valves</li>
                    <li>Cylinder head</li>
                    <li>Timing gears</li>
                    <li>Crankshaft & Pulley</li>
                    <li>Big end and main bearings</li>
                    <li>Gudgeon Pins</li>
                    <li>Piston and Rings</li>
                    <li>Inlet and exhaust valves (Excluding burnt and pitted valves) Spring and guides</li>
                    <li>Cylinder block</li>
                    <li>Cylinder Head</li>
                    <li>Camshaft</li>
                    <li>rocker arms</li>
                    <li>shaft</li>
                    <li>Timing gears</li>
                    <li>Tensioner bearings</li>
                    <li>water pump (Failure due to external damage or corrosion is not covered)</li>
                    <li>inlet and exhaust manifold.</li>
                    <li>Starter motor</li>
                    <li>Starter Bendix</li>
                    <li>Timing Belt</li>
                    <li>Spark Plug</li>
                    <li>Timing Belt Tensioner</li>
                    <li>Timing Belt Pulley and Multi v Belt</li>
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView2} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className="accordions"
              onClick={() => {
                setThree((prevState) => !prevState);
                setTwo(false);
                setOne(false);
                setFour(false);
                setFive(false);
                setSix(false);
                setSeven(false);
                setEight(false);
              }}
            >
              <h3>
                <span>Transmission (Manual)</span>
                <img className={three ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {three ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Gears</li>
                    <li>Shaft</li>
                    <li>Synchromesh hubs</li>
                    <li>Selectors</li>
                    <li>Bearings</li>
                    <li>Transfer Gears</li>

                    {/* <li>+20 More</li> */}
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView2} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="accordions"
              onClick={() => {
                setFour((prevState) => !prevState);
                setTwo(false);
                setThree(false);
                setOne(false);
                setFive(false);
                setSix(false);
                setSeven(false);
                setEight(false);
              }}
            >
              <h3>
                <span>Transmission (Automatic)</span>
                <img className={four ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {four ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Shaft</li>
                    <li>Gears</li>
                    <li>Brake/Clutch bands</li>
                    <li>oil pump</li>
                    <li>Bearings</li>
                    <li>Bushes</li>
                    <li>Valves</li>
                    <li>Drive plates</li>
                    <li>Transfer Gears</li>
                    <li>Transmission Gears</li>
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView3} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="accordions"
              onClick={() => {
                setFive((prevState) => !prevState);
                setTwo(false);
                setThree(false);
                setFour(false);
                setOne(false);
                setSix(false);
                setSeven(false);
                setEight(false);
              }}
            >
              <h3>
                <span>Turbo(Optional)</span>
                <img className={five ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {five ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Turbo warranty coverage includes the Turbine wheel</li>
                    <li>Compressor wheel</li>
                    <li>Turboshaft</li>
                    <li>Turbo Core</li>
                    <li>Only for cars that have driven a max of up to 70,000 km</li>
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView4} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="accordions"
              onClick={() => {
                setSix((prevState) => !prevState);
                setTwo(false);
                setThree(false);
                setFour(false);
                setFive(false);
                setOne(false);
                setSeven(false);
                setEight(false);
              }}
            >
              <h3>
                <span>Electrical Wiring(Optional)</span>
                <img className={six ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {six ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Covers the wiring of the vehicle including Headlight wiring</li>
                    <li>Parking light wiring</li>
                    <li>Roof lights wiring</li>
                    <li>Electrical parts wiring</li>
                    <li>Infotainment system</li>
                    <li>Extra wire charges during repair or replacement are included in the warranty</li>
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView5} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="accordions"
              onClick={() => {
                setSeven((prevState) => !prevState);
                setTwo(false);
                setThree(false);
                setFour(false);
                setFive(false);
                setSix(false);
                setOne(false);
                setEight(false);
              }}
            >
              <h3>
                <span>AC compressor(Optional)</span>
                <img className={seven ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {seven ? (
                <div>
                  <p></p>
                  <ul>
                    <li>AC compressor warranty includes Compressor</li>
                    <li>Thermal Expansion valve</li>
                    <li>Seals at every connection</li>
                    <li>Condenser</li>
                    <li>Receiver/Dryer or Accumulator</li>
                    <li>Magnetic Clutch</li>
                    <li>Piston</li>
                    <li>Compressor pulley</li>
                    <li>Bearings</li>
                    <li>Electrical Coil</li>
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView6} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="accordions"
              onClick={() => {
                setEight((prevState) => !prevState);
                setTwo(false);
                setThree(false);
                setFour(false);
                setFive(false);
                setSix(false);
                setSeven(false);
                setOne(false);
              }}
            >
              <h3>
                <span>Battery(Optional)</span>
                <img className={eight ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {eight ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Battery Warranty Includes Cells Repair/Replacement</li>
                    <li>Terminal Clean and Corrosions Check</li>
                    <li>Plates repair/Replacement</li>
                    <li>Battery Water replacement</li>
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView7} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default EngineProtectionOne;
