import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Map, { Marker, Popup } from "react-map-gl";
import ReadyAssist from "../../assets/images/partnerLocation/readyAssist.svg";
import Bro4u from "../../assets/images/partnerLocation/bro4u.svg";
import Rescue from "../../assets/images/partnerLocation/rescue.svg";
import Europe from "../../assets/images/home/europAssistance.svg";
import GoBumber from "../../assets/images/home/goBumper.svg";
import marker from "../../assets/images/service-network/marker.svg";
import popIcon from "../../assets/images/service-network/popupIcon.svg";
import mapboxgl from "mapbox-gl";
import markerArray from "./constant";
import "./partnerLocation.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Toyoto from "../../assets/images/partnerLocation/toyota.svg";
import Honda from "../../assets/images/service-network/honda.svg";
import HondaBrand from "../../assets/images/partnerLocation/honda.svg";
// import
import Hyundai from "../../assets/images/partnerLocation/hyundai.svg";
import Ford from "../../assets/images/service-network/ford.svg";
import Force from "../../assets/images/partnerLocation/force.svg";
import Volvo from "../../assets/images/service-network/volvo.svg";
import Suzuki from "../../assets/images/service-network/suzuki.svg";
import Tata from "../../assets/images/service-network/tata.svg";
import Kia from "../../assets/images/service-network/kia.svg";
import Skoda from "../../assets/images/service-network/skoda.svg";
import Renault from "../../assets/images/service-network/renault.svg";
import Mahindra from "../../assets/images/service-network/mahindra.svg";
import Cheverlot from "../../assets/images/service-network/cheverlot.svg";
import Volkswagen from "../../assets/images/service-network/volkswagen.svg";
import Arrow from "../../assets/images/service-network/downArrow.svg";
import freeDropCar from "../../assets/images/partnerLocation/freeDropCar.svg";
import { Link } from "react-router-dom";
import ServiceCenterMobile from "./serviceCenterMobile";

const PartnerLocation = ({ serviceNetworkPage }) => {
  const path = window.location.href.split("/")[3];

  // console.log("path", path);

  // @ts-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

  const [settings, setsettings] = useState({
    dragPan: false,
    dragRotate: false,
    scrollZoom: false,
    touchZoom: false,
    touchRotate: false,
    keyboard: false,
    doubleClickZoom: true,
  });

  const [latitudeCoord, setLatitudeCoord] = useState("");
  const [longitudeCoord, setLongitudeCoord] = useState("");

  const [view, setView] = useState({
    longitude: 76.6804,
    latitude: 15.0288,
    zoom: 5.8,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Karnataka");

  const [serviceCenter, setServiceCenter] = useState({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 3,
          infinite: true,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  });

  const get = (newView) => {
    setView(newView);
  };

  useEffect(() => {
    switch (selectedOption) {
      case "Karnataka":
        setView({
          longitude: 76.6804,
          latitude: 15.0288,
          zoom: 5.8,
        });
        // setTimeout(function() { mapref.flyTo([78.9369, 10.9222], 16) }, 0);
        break;
      case "Telangana":
        setView({
          longitude: 79.7745,
          latitude: 17.921,
          zoom: 6.3,
        });
        // setTimeout(function() { mapref.flyTo([78.9369, 10.9222], 16) }, 0);
        break;
      case "Tamilnadu":
        setView({
          longitude: 78.9369,
          latitude: 10.9222,
          zoom: 6.18,
        });
        // setTimeout(function() { mapref.flyTo([78.9369, 10.9222], 16) }, 0);
        break;
      default:
        setView({
          longitude: 77.4284,
          latitude: 14.6074,
          zoom: 6,
        });
    }
  }, [selectedOption]);

  const handleDbClick = (coords) => {
    setView(coords);
  };

  const openPopUpHandler = (value) => {
    setShowPopup(!showPopup);
    setLatitudeCoord(value.latitudeCoordinate.toString());
    setLongitudeCoord(value.longitudeCoordinate.toString());
  };

  const [toggle, setToggle] = useState(false);
  const [option, setOption] = useState("Bangalore");

  const changeOption = (e) => {
    setOption(e.target.value);
    if (e.target.value === "Chikkaballapur" || e.target.value === "Ramanagara") {
      setServiceCenter({ ...serviceCenter, arrows: false });
    } else {
      setServiceCenter({ ...serviceCenter, arrows: true });
    }
  };

  return (
    <section className={serviceNetworkPage === "true" ? "partnerLocation partnerLocationService" : "partnerLocation"}>
      <div className="serviceCenter serviceCenterPhase3">
        <div className="container">
          <div className="commonHeading">
            <div className="freeHead">
              {path === "car_general_service" ? (
                ""
              ) : (
                <h4>
                  <img src={freeDropCar} alt="freeDropCar" />
                  <span>Free Pick & Drop All over India</span>
                </h4>
              )}

              {path === "car_general_service" ? (
                <h2>
                  Get <span>Authorised Service center And Workshop</span> Near You
                </h2>
              ) : (
                <h2>
                  We partner only with <span>manufacture authorised service</span> centers
                </h2>
              )}
            </div>
            <form className="serviceForm">
              <select className="commonSelect" onChange={changeOption}>
                <option value="Bangalore">Bangalore</option>
                <option value="Tumkuru">Tumkuru</option>
                <option value="Mysore">Mysore</option>
                <option value="Mangalore">Mangalore</option>
                <option value="Chitradurga">Chitradurga</option>
                <option value="Kolar">Kolar</option>
                <option value="Chikkaballapur">Chikkaballapur</option>
                <option value="Ramanagara">Ramanagara</option>
                <option value="Davanagere">Davanagere</option>
                <option value="Dharwad">Dharwad</option>
              </select>
            </form>
          </div>

          {/* Bangalore Service Centers */}
          <div className={option === "Bangalore" ? "serviceCenterContainer" : "hide"}>
            <div className="desktop">
              <div className="serviceCenterCards">
                <Slider {...serviceCenter}>
                  {/* <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Mahindra" />
                    <p>Anant Cars</p>
                  </div> */}
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Lakshmi Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={HondaBrand} alt="Volkswagen" />
                    <p>Brigade Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Skoda} alt="Skoda" />
                    <p>Raja Skoda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Force} alt="Toyota" />
                    <p>Neo Force</p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="mobile">
              <div className="serviceCenterCards">
                <div className="carBrands">
                  {/* <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Mahindra" />
                    <p>Anant Cars</p>
                  </div> */}
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Lakshmi Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Skoda} alt="Skoda" />
                    <p>Raja Skoda</p>
                  </div>
                </div>

                <div className="carBrands">
                  {/* <div className="serrviceCenterCardDetails">
                    <img src={Skoda} alt="Skoda" />
                    <p>Raja Skoda</p>
                  </div> */}
                  <div className="serrviceCenterCardDetails">
                    <img src={Force} alt="Toyota" />
                    <p>Neo Force</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={HondaBrand} alt="Volkswagen" />
                    <p>Brigade Honda</p>
                  </div>
                </div>
                <div className="carBrands">
                  {/* <div className="serrviceCenterCardDetails">
                    <img src={HondaBrand} alt="Volkswagen" />
                    <p>Brigade Honda</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* Thumkur Service Centers */}
          <div className={option === "Tumkuru" ? "serviceCenterContainer" : "hide"}>
            {/* <ServiceCenterMobile /> */}
            <div className="desktop">
              <div className="serviceCenterCards">
                <Slider {...serviceCenter}>
                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="Tata" />
                    <p>Sree Auto</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Shantinath Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Magnum Honda</p>
                  </div>

                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Panvan Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="renault" />
                    <p>Renault</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Kia} alt="Kia" />
                    <p>Pps Kia</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Saketh Automobiles</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Ford} alt="Ford" />
                    <p>Honnasiri Ford</p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="mobile">
              <div className="serviceCenterCards">
                {/* <Slider {...serviceCenter}> */}
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="Tata" />
                    <p>Sree Auto</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Shantinath Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Magnum Honda</p>
                  </div>
                </div>

                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Panvan Hyundai</p>
                  </div>

                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="renault" />
                    <p>Renault</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Kia} alt="Kia" />
                    <p>Pps Kia</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Saketh Automobiles</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Ford} alt="Ford" />
                    <p>Honnasiri Ford</p>
                  </div>
                </div>
                {/* </Slider> */}
              </div>
            </div>
          </div>

          {/* Mysore Service Centers */}
          <div className={option === "Mysore" ? "serviceCenterContainer" : "hide"}>
            <div className="desktop">
              <div className="serviceCenterCards">
                <Slider {...serviceCenter}>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Friendly Motors</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Inspire Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Palace Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="Renault" />
                    <p>Renault</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="Renault" />
                    <p>Renault Hebbal</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Toyoto} alt="Toyota" />
                    <p>Palace Toyota</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Kia} alt="Kia" />
                    <p>Volanzo Kia</p>
                  </div>

                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="Chevrolet" />
                    <p>Dyuthi Chevrolet</p>
                  </div>

                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Mahindra} alt="Mahindra" />
                    <p>Indian Garage</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Ford} alt="Ford" />
                    <p>Cauvery Ford</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Tata} alt="Tata" />
                    <p>Tata Motors</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Advaith Hyundai</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Skoda} alt="Skoda" />
                    <p>Palace Skoda</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Mandovi Chamrajnagar</p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="mobile">
              <div className="serviceCenterCards">
                {/* <Slider {...serviceCenter}> */}
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Friendly Motors</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Inspire Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Palace Honda</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="Renault" />
                    <p>Renault</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="Renault" />
                    <p>Renault Hebbal</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Toyoto} alt="Toyota" />
                    <p>Palace Toyota</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Kia} alt="Kia" />
                    <p>Volanzo Kia</p>
                  </div>

                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="Chevrolet" />
                    <p>Dyuthi Chevrolet</p>
                  </div>

                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Mahindra} alt="Mahindra" />
                    <p>Indian Garage</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Ford} alt="Ford" />
                    <p>Cauvery Ford</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Tata} alt="Tata" />
                    <p>Tata Motors</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Advaith Hyundai</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Skoda} alt="Skoda" />
                    <p>Palace Skoda</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Mandovi Chamrajnagar</p>
                  </div>
                </div>
                {/* </Slider> */}
              </div>
            </div>
          </div>

          {/* Mangalore Service Centers */}
          <div className={option === "Mangalore" ? "serviceCenterContainer" : "hide"}>
            <div className="desktop">
              <div className="serviceCenterCards">
                <Slider {...serviceCenter}>
                  <div className="serrviceCenterCardDetails">
                    <img src={Kia} alt="kia" />
                    <p>Arm Kia Motors</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Volkswagen} alt="volkswagen" />
                    <p>Volkswagen</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Toyoto} alt="Toyota" />
                    <p>United Toyota</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Advaith Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Kanchana Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Maruti Suzuki</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Bharath Auto Cars, Kudroli</p>
                  </div>

                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Bharath Auto Cars, Thokkot</p>
                  </div>

                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Renault} alt="Renault" />
                    <p>Renault</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Cheverlot} alt="Cheverlot" />
                    <p>Vibrant Chevrolet</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Ford} alt="Ford" />
                    <p>Cauvery Ford</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Honda} alt="honda" />
                    <p>Arrta Honda</p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="mobile">
              <div className="serviceCenterCards">
                {/* <Slider {...serviceCenter}> */}
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Kia} alt="kia" />
                    <p>Arm Kia Motors</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Volkswagen} alt="volkswagen" />
                    <p>Volkswagen</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Toyoto} alt="Toyota" />
                    <p>United Toyota</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Advaith Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Kanchana Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Maruti Suzuki</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Bharath Auto Cars, Kudroli</p>
                  </div>

                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Bharath Auto Cars, Thokkot</p>
                  </div>

                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Renault} alt="Renault" />
                    <p>Renault</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Cheverlot} alt="Cheverlot" />
                    <p>Vibrant Chevrolet</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Ford} alt="Ford" />
                    <p>Cauvery Ford</p>
                  </div>
                  <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
                    <img src={Honda} alt="honda" />
                    <p>Arrta Honda</p>
                  </div>
                </div>
                {/* </Slider> */}
              </div>
            </div>
          </div>

          {/* Chitradurga Service Centers */}
          <div className={option === "Chitradurga" ? "serviceCenterContainer" : "hide"}>
            <div className="desktop">
              <div className="serviceCenterCards">
                <Slider {...serviceCenter}>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="suzuki" />
                    <p>Saketh Automobiles</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>KJ Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="tata" />
                    <p>Sree Auto - Tata Motora</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="Renault" />
                    <p>Renault</p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="mobile">
              <div className="serviceCenterCards">
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="suzuki" />
                    <p>Saketh Automobiles</p>
                  </div>

                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="tata" />
                    <p>Sree Auto - Tata Motora</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="Renault" />
                    <p>Renault</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>KJ Hyundai</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Kolar Service Centers */}
          <div className={option === "Kolar" ? "serviceCenterContainer" : "hide"}>
            <div className="desktop">
              <div className="serviceCenterCards">
                <Slider {...serviceCenter}>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="suzuki" />
                    <p>Mandovi Motors</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="Tata" />
                    <p>Tata Service</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="hyundai" />
                    <p>Advaith Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Kausthub Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Kia} alt="Kia" />
                    <p>Epitome Automobiles</p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="mobile">
              <div className="serviceCenterCards">
                {/* <Slider {...serviceCenter}> */}
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="suzuki" />
                    <p>Mandovi Motors</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="Tata" />
                    <p>Tata Service</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Kausthub Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Kia} alt="Kia" />
                    <p>Epitome Automobiles</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="hyundai" />
                    <p>Advaith Hyundai</p>
                  </div>
                </div>
                {/* </Slider>  */}
              </div>
            </div>
          </div>

          {/* Chikkaballapur Service Centers */}
          <div className={option === "Chikkaballapur" ? "serviceCenterContainer" : "hide"}>
            <div className="desktop">
              <div className="serviceCenterCards">
                <Slider {...serviceCenter}>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="suzuki" />
                    <p>Sukove</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="Tata" />
                    <p>Tata Motors ltd</p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="mobile">
              <div className="serviceCenterCards">
                {/* <Slider {...serviceCenter}> */}
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="suzuki" />
                    <p>Sukove</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="Tata" />
                    <p>Tata Motors ltd</p>
                  </div>
                </div>
                {/* </Slider> */}
              </div>
            </div>
          </div>

          {/* Ramanagara Service Centers */}
          <div className={option === "Ramanagara" ? "serviceCenterContainer" : "hide"}>
            <div className="desktop">
              <div className="serviceCenterCards">
                <Slider {...serviceCenter}>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="suzuki" />
                    <p>Kalyani Motors</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Advaith Hyundai</p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="mobile">
              <div className="serviceCenterCards">
                {/* <Slider {...serviceCenter}> */}
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="suzuki" />
                    <p>Kalyani Motors</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Advaith Hyundai</p>
                  </div>
                </div>
                {/* </Slider> */}
              </div>
            </div>
          </div>

          {/* Davanagere Service Centers */}
          <div className={option === "Davanagere" ? "serviceCenterContainer" : "hide"}>
            <div className="desktop">
              <div className="serviceCenterCards">
                <Slider {...serviceCenter}>
                  <div className="serrviceCenterCardDetails">
                    <img src={Ford} alt="Ford" />
                    <p>Metro Ford</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="Tata" />
                    <p>Adishakti Cars</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="honda" />
                    <p>Global Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>KJ Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Shruti Motors</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="Renault" />
                    <p>Renault</p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="mobile">
              <div className="serviceCenterCards">
                {/* <Slider {...serviceCenter}> */}
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Ford} alt="Ford" />
                    <p>Metro Ford</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Tata} alt="Tata" />
                    <p>Adishakti Cars</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>KJ Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Shruti Motors</p>
                  </div>
                </div>
                <div className="carBrands">
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="Renault" />
                    <p>Renault</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="honda" />
                    <p>Global Honda</p>
                  </div>
                </div>
                {/* </Slider> */}
              </div>
            </div>
          </div>

          {/* Dharwad Service Centers */}
          <div className={option === "Dharwad" ? "serviceCenterContainer" : "hide"}>
            <div className="desktop">
              <div className="serviceCenterCards">
                <Slider {...serviceCenter}>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Lakeview Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Bellad Hyundai</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Toyoto} alt="Toyoto" />
                    <p>Shodha Toyota</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Ford} alt="Ford" />
                    <p>Metro Ford</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Mysore Garage</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Kia} alt="Kia" />
                    <p>Nagshanti Kia</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="renault" />
                    <p>Renault Services Hubli</p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="mobile">
              <div className="serviceCenterCards">
                {/* <Slider {...serviceCenter}> */}
                <div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Honda} alt="Honda" />
                    <p>Lakeview Honda</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Hyundai} alt="Hyundai" />
                    <p>Bellad Hyundai</p>
                  </div>
                </div>
                <div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Ford} alt="Ford" />
                    <p>Metro Ford</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Suzuki} alt="Suzuki" />
                    <p>Mysore Garage</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Toyoto} alt="Toyoto" />
                    <p>Shodha Toyota</p>
                  </div>
                </div>
                <div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Renault} alt="renault" />
                    <p>Renault Services Hubli</p>
                  </div>
                  <div className="serrviceCenterCardDetails">
                    <img src={Kia} alt="Kia" />
                    <p>Nagshanti Kia</p>
                  </div>
                </div>
                {/* </Slider> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="assistance">
        <div className="container" style={{ display: "flex" }}>
          <div className="assistanceContainer">
            <div className="commonHeading">
              <h2>
                24/7 Dedicated <span>roadside assistance</span>
              </h2>
              {/* <p>Check assistance near you</p> */}
            </div>
            <div className="assistancePartners desktopImage">
              <p>Partnering with</p>
              <div className="assistancePartnersLogos">
                {/* <img src={ReadyAssist} alt="readyAssist" /> */}
                <img src={Bro4u} alt="bro4u" />
                <img src={Rescue} alt="rescue" />
                <img src={Europe} alt="Europe" />
                <img src={GoBumber} alt="gobumper" />
              </div>
            </div>
          </div>
          <div className="assistanceMap desktop" onDoubleClick={handleDbClick}>
            <form className="serviceForm">
              <select
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="commonSelect"
              >
                <option value="Karnataka">Karnataka</option>
                <option value="Telangana">Telangana</option>
                <option value="Tamilnadu">Tamilnadu</option>
              </select>
            </form>
            <Map
              reuseMaps={true}
              className="maps"
              mapboxAccessToken="pk.eyJ1Ijoiem9ycXVlciIsImEiOiJjbDdrMWU0eGYwbHdwM3dtem5qZGU0a2hsIn0.94peNzUtfYgRLo5HKllZ-g"
              style={{ width: "100%", height: "100%" }}
              mapStyle="mapbox://styles/zorquer/cl7k8c0ms004h14p4niok2x32"
              {...view}
              doubleClickZoom={true}
              onMove={(newView) => {}}
              onDoubleClick={(newCoords) => {
                handleDbClick(newCoords);
              }}
              dragPan={true}
            >
              {showPopup && (
                <Popup
                  latitude={latitudeCoord}
                  longitude={longitudeCoord}
                  closeButton={true}
                  closeOnClick={false}
                  onClose={() => setShowPopup(false)}
                  anchor="bottom"
                >
                  <div
                    className="popupOnMap"
                    style={{
                      maxWidth: "240px",
                      width: "100%",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      padding: "12px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {/* <img src={popIcon} alt="icon" /> */}
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          lineHeight: "24px",
                          color: " #262C6E",
                        }}
                      >
                        {/* Ready Assist */}
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        color: " #464868",
                      }}
                    >
                      {/* 105, Raheja Plaza, Richmond Rd, Ashok Nagar, Bengaluru, Karnataka 560025 */}
                    </p>
                  </div>
                </Popup>
              )}

              {markerArray.map((val, i) => {
                return (
                  <Marker
                    key={i}
                    // onClick={() => openPopUpHandler(val)}
                    latitude={val.latitudeCoordinate}
                    longitude={val.longitudeCoordinate}
                    offsetleft={-20}
                    offsettop={-30}
                  >
                    <img src={marker} style={{ cursor: "pointer", height: 20, width: 18 }} alt="marker" />
                  </Marker>
                );
              })}
            </Map>
          </div>

          <div className="assistancePartners mobileImage assistancePartnersPhase3">
            <p>Partnering with</p>
            <div className="assistancePartnersLogos">
              {/* <img src={ReadyAssist} alt="readyAssist" /> */}
              <img src={Bro4u} alt="bro4u" />
              <img src={Rescue} alt="rescue" />
              <img src={Europe} alt="Europe" />
              <img src={GoBumber} alt="gobumper" />
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="assistanceMap" onDoubleClick={handleDbClick}>
            <form className="serviceForm">
              <select
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="commonSelect"
              >
                <option value="Karnataka">Karnataka</option>
                <option value="Telangana">Telangana</option>
                <option value="Tamilnadu">Tamilnadu</option>
              </select>
            </form>
            <Map
              reuseMaps={true}
              className="maps"
              mapboxAccessToken="pk.eyJ1Ijoiem9ycXVlciIsImEiOiJjbDdrMWU0eGYwbHdwM3dtem5qZGU0a2hsIn0.94peNzUtfYgRLo5HKllZ-g"
              style={{ width: "100%", height: "100%" }}
              mapStyle="mapbox://styles/zorquer/cl7k8c0ms004h14p4niok2x32"
              {...view}
              doubleClickZoom={true}
              onMove={(newView) => {}}
              onDoubleClick={(newCoords) => {
                handleDbClick(newCoords);
              }}
              dragPan={true}
            >
              {showPopup && (
                <Popup
                  latitude={latitudeCoord}
                  longitude={longitudeCoord}
                  closeButton={true}
                  closeOnClick={false}
                  onClose={() => setShowPopup(false)}
                  anchor="bottom"
                >
                  <div
                    className="popupOnMap"
                    style={{
                      maxWidth: "240px",
                      width: "100%",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      padding: "12px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img src={popIcon} alt="icon" />
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          lineHeight: "24px",
                          color: " #262C6E",
                        }}
                      >
                        Ready Assist
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        color: " #464868",
                      }}
                    >
                      105, Raheja Plaza, Richmond Rd, Ashok Nagar, Bengaluru, Karnataka 560025
                    </p>
                  </div>
                </Popup>
              )}

              {markerArray.map((val, i) => {
                return (
                  <Marker
                    key={i}
                    onClick={() => openPopUpHandler(val)}
                    latitude={val.latitudeCoordinate}
                    longitude={val.longitudeCoordinate}
                    offsetleft={-20}
                    offsettop={-30}
                  >
                    <img src={marker} style={{ cursor: "pointer", height: 20, width: 18 }} alt="marker" />
                  </Marker>
                );
              })}
            </Map>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PartnerLocation;
