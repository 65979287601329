import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "../containers/home";
import About from "../containers/about";
import WarantyBenefits from "../containers/warranty-benefits";
import PricingPlans from "../containers/pricing-plans";
import Blog from "../containers/blog";
import BlogDetails from "../containers/blog-details";
import Claims from "../containers/claims";
import ServiceNetwork from "../containers/service-network";
import ContactUs from "../containers/contact-us";
import Privacy from "../containers/privacy";
import TermsAndConditions from "../containers/terms-and-conditions";
import Scrooltotop from "../components/scrollToTop/index";
import Careers from "../containers/careers";
import Faq from "../containers/faq";
import Report from "../containers/report";
import Payment from "../containers/payment";
import ReportNotAvailable from "../containers/report-not-available";
import ReportDetails from "../containers/report-details";
import ClaimProcess from "../containers/claim-process";
import Landing from "../containers/landing-page";
import ServiceAndMaintenance from "../containers/ServiceAndMainentanancePolicy";
import BuyBackPolicy from "../containers/buybackPolicy";
import WarrantyPolicy from "../containers/warranty-policy";
import CustomerPolicy from "../containers/customer-warranty-policy";
import DifferentCarWarranty from "../containers/blog-details-different-car-warranty";
import CarExtendedWarrantyContract from "../containers/car-extended-warranty-contract";
import ExtendWarrantyOnCar from "../containers/extended-warranty-on-car";
import Checkout from "../containers/checkout/Checkout";
import OwnerHome from "../containers/owner-home";
import CheckoutPlans from "../containers/checkout-plan/CheckoutPlans";
import OwnerPricingPlans from "../containers/owner-pricing-plans";
import PaymentSuccess from "../containers/payment-success";
import SearchCars from "../containers/searchcars/SearchCars";
import CarDetails from "../containers/car-details/CarDetails";
import track from "react-tracking";
import CheckoutWarrantyPackage from "../containers/checkout-warrantyPackage/CheckoutWarrantyPackage";
import PaymentSuccessWarrantyPackage from "../containers/Payment-success-warranty-package";
import FacebookLandingPage from "../containers/Facebook-landing-Page";
import PaymentWiseAssist from "../containers/paymentWiseAssist";
import CheckoutWiseAssist from "../containers/checkoutWiseAssist/CheckoutWiseAssist";
import PaymentSuccessWiseAssist from "../containers/payment-success-wiseAssist";
import FacebookLandingPageNew from "../containers/Facebook-landing-Page-new/FacebookLandingPageNew";
import Success from "../containers/success";
import Cancellation from "../containers/CancellationAndReturnPolicy/index";

import Pmp from "../containers/pmp";

import KnowMore from "../containers/KnowMore/knowMore";
import BuyNow from "../containers/buy-now";
import SampleReport from "../components/sampleReport/SampleReport";
import PlanDetailsBuyNow from "../components/plan-details-buy-now";
import PaymentFailure from "../components/paymentStatus/paymentFailure";
import AboutWiseDrive from "../containers/about-wisedrive/index";

const AppRoutes = () => (
  <Router>
    <Scrooltotop />
    <Routes>
      {/* <Route exact path="/" element={<Landing />} /> */}
      <Route exact path="/" element={<Home />} />

      {/* New routs Start */}
      <Route exact path="/sample-report" element={<SampleReport />} />
      <Route
        exact
        path="/plan-details-buy-now"
        element={<PlanDetailsBuyNow />}
      />
      <Route exact path="/know-more" element={<KnowMore />} />
      <Route exact path="/buy-now" element={<BuyNow />} />
      <Route exact path="/payment-failure" element={<PaymentFailure />} />
      <Route exact path="/about-wisedrive" element={<AboutWiseDrive />} />

      {/* New routes end */}
      {/* reusing routs */}
      <Route exact path="/privacy" element={<Privacy />} />
      <Route
        exact
        path="/inspection-terms-and-conditions"
        element={<TermsAndConditions />}
      />
      <Route exact path="/warrantyPolicy" element={<WarrantyPolicy />}></Route>
      <Route
        exact
        path="/cancellation_refund_policy"
        element={<Cancellation />}
      ></Route>
      <Route
        exact
        path="/customerWarrantyPolicy"
        element={<CustomerPolicy />}
      ></Route>
      <Route
        exact
        path="/service-and-maintenance"
        element={<ServiceAndMaintenance />}
      ></Route>
      {/* end reusing routes */}
      {/* <Route exact path="/wiseassist" element={<FacebookLandingPage />} /> */}
      <Route exact path="/wiseassist" element={<FacebookLandingPageNew />} />
      <Route exact path="/wiseassist_fb" element={<FacebookLandingPageNew />} />
      <Route exact path="/owner" element={<OwnerHome />} />
      <Route exact path="/about" element={<About />} />
      <Route
        exact
        path="/about_us"
        element={<Navigate to="/about" />}
        status={301}
      />
      <Route exact path="/blog/*" element={<Blog />} />
      <Route
        exact
        path="blog/Car-Insurance-or-Car-Warranty-in-India"
        element={<BlogDetails />}
      />
      <Route
        exact
        path="blog/different-types-of-car-warranty"
        element={<DifferentCarWarranty />}
      />
      <Route
        exact
        path="blog/car-extended-warranty-contract"
        element={<CarExtendedWarrantyContract />}
      />
      <Route
        exact
        path="blog/extended-warranty-on-a-car"
        element={<ExtendWarrantyOnCar />}
      />
      <Route exact path="/warranty-benefits" element={<WarantyBenefits />} />
      <Route
        exact
        path="/benefits"
        element={<Navigate to="/warranty-benefits" />}
        status={301}
      />
      <Route exact path="/pricing-plans" element={<PricingPlans />} />
      <Route
        exact
        path="/owner-pricing-plans"
        element={<OwnerPricingPlans />}
      />
      <Route exact path="/payment-success" element={<PaymentSuccess />} />
      <Route
        exact
        path="/payment-success-wiseassist"
        element={<PaymentSuccessWiseAssist />}
      />
      <Route
        exact
        path="/payment-success-wiseassist_fb"
        element={<PaymentSuccessWiseAssist />}
      />
      <Route
        exact
        path="/payment-success-warranty-package/:id"
        element={<PaymentSuccessWarrantyPackage />}
      />
      <Route
        exact
        path="/pricing"
        element={<Navigate to="/pricing-plans" />}
        status={301}
      />
      <Route
        exact
        path="/pricing"
        element={<Navigate to="/pricing-plans" />}
        status={301}
      />
      <Route exact path="/claim" element={<Claims />} />
      <Route
        exact
        path="/calculate-risk"
        element={<Navigate to="/claim" />}
        status={301}
      />
      <Route exact path="/service-network" element={<ServiceNetwork />} />
      <Route
        exact
        path="/service-coverage"
        element={<Navigate to="/service-network" />}
        status={301}
      />
      <Route exact path="/contact-us" element={<ContactUs />} />
      <Route exact path="/careers" element={<Careers />} />
      <Route exact path="/faq" element={<Faq />} />

      <Route exact path="/buybackPolicy" element={<BuyBackPolicy />}></Route>

      <Route
        exact
        path="/privacy-policy"
        element={<Navigate to="/privacy" />}
        status={301}
      />

      <Route
        exact
        path="/asda12/:customerId/:insprequvehiId/:leadId/:leadvehicleId/:vehicleId"
        element={<Report />}
      />
      <Route
        exact
        path="/checkout-plans/:plan/:tabId"
        element={<CheckoutPlans />}
      />
      {/* <Route exact path="/checkout-plans/:id" element={<CheckoutPlans />} /> */}
      <Route exact path="/payment" element={<Payment />} />
      <Route exact path="/payment-wiseassist" element={<PaymentWiseAssist />} />
      <Route exact path="/checkout/:seId" element={<Checkout />} />
      <Route
        exact
        path="/checkout-wiseAssist/:seId"
        element={<CheckoutWiseAssist />}
      />
      <Route
        exact
        path="checkout_warranty_package/:seId"
        element={<CheckoutWarrantyPackage />}
      />
      <Route exact path="/payment/:id" element={<Payment />} />
      <Route
        exact
        path="/report-not-available/:id"
        element={<ReportNotAvailable />}
      />

      <Route
        exact
        path="/report-details/:vehicleId/:leadvehicleId/:insprequvehiId/:moduleId/:reportName/:blackList"
        element={<ReportDetails />}
      />
      <Route exact path="/claim-process" element={<ClaimProcess />} />
      <Route exact path="/search-cars" element={<SearchCars />} />
      <Route exact path="/search-cars/:linkId" element={<SearchCars />} />
      <Route exact path="/car-details/:id" element={<CarDetails />} />
      <Route exact path={`/car_general_service/1`} element={<Pmp />} />
      <Route exact path={`/car_general_service/2`} element={<Pmp />} />
      <Route exact path={`/car_general_service/3`} element={<Pmp />} />
      <Route exact path={`/car_general_service/4`} element={<Pmp />} />
      <Route exact path={`/car_general_service/5`} element={<Pmp />} />
      <Route exact path={`/car_general_service/6`} element={<Pmp />} />
      <Route
        exact
        path="/submission_success"
        element={<Success />}
        status={301}
      />
    </Routes>
  </Router>
);

const TrackedAppRoutes = track(
  { app: "wisedrive website" },

  // top level options
  {
    // dispatch to console.log in addition to dataLayer
    dispatch: (data) => {
      // print data to developer console
      console.log(data);
      // console.log(window.dataLayer);
      // (window.dataLayer = window.dataLayer || []).push(data);
      // api call here to save the data
    },
  }
)(AppRoutes);

export default TrackedAppRoutes;
