import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import OTPInput, { ResendOTP } from "otp-input-react";
import Kyc from "../../assets/images/home/kyc.png";
import Close from "../../assets/images/home/close.svg";
import RightArrow from "../../assets/images/home/rightArrow.svg";
import "react-phone-input-2/lib/style.css";
import "./kycPopup.css";
import { checkIsValidFormat } from "../../utils/commonFunctions";
import { ValidationContainer } from "../../utils/constants";
import { requestOTP, verifyOTP } from "../../services/getInspectionReport";
import { SendViewSellerOtp, VerifyViewSellerOtp } from "../../Api/PortalApis";
import { PortalContext } from "../../context/carPortalContext";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import useWindowDimensions from "../util-hooks/useWindowDimentions";

const ViewSellerPopup = ({ carNumber, closeKycPopup, vehicleId, setDealerData = null, isFromShortlist = false }) => {
  const vehicleNumber = sessionStorage.getItem("vehicleNo");
  const phoneNo = localStorage.getItem("phoneNo");
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [otpErr, setOtpErr] = useState(false);
  const [OTP, setOTP] = useState("");
  const [visible, setVisible] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const [vehicleNo, setvehicleNo] = useState(null);
  const location = useLocation();
  const [ipAddress, setIpAddress] = useState("");

  const { width, height } = useWindowDimensions();

  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [isOtyVerifying, setIsOtyVerifying] = useState(false);

  const portalCtx = useContext(PortalContext);

  const userIp = localStorage.getItem("userIp");

  // console.log(location);
  // console.log("USERIP", portalCtx.userIp);

  async function getIpAddress() {
    const res = await axios.get("https://api.ipify.org/?format=json");

    if (res.status === 200) {
      const userIp = res.data.ip;
      portalCtx.setUserIp(userIp);
      localStorage.setItem("userIp", userIp);
      setIpAddress(userIp);
    } else {
      // getIpAddress();
      console.error("Error getting ip address of user");
      setIsOtpLoading(false);
      return;
    }
  }

  useEffect(() => {
    // if ()
  
    if (!userIp) {
      getIpAddress();
    }
  }, [userIp]);

  const handleInputChange = (e) => {
    if (visible === false) {
      setPhone(e.target.value);
      setPhoneErr(false);
    } else {
      setOTP(e);
      setOtpErr(false);
    }
  };

  const sendOTP = async () => {
    try {
      // for developing redirection on verify success
      // navigate("/car-details", { state: { fromViewSeller: true, sellerNumber: 9878675645 } });
      if (phone === "" || !checkIsValidFormat(phone, ValidationContainer.mobileNumberFormat)) {
        setVisible(false);
        setPhoneErr(true);
      } else {
        // let userIp = "";
        setIsOtpLoading(true);

        // if (!ipAddress) {
        //   getIpAddress();
        //   return;
        // }

        // return;

        const data = {
          ipAddress: userIp,
          phoneNumber: phone,
        };

        const response = await SendViewSellerOtp(data);

        if (response.status === 200) {
          setVisible(true);
          // localStorage.setItem("phoneNumber", phone);
          setIsOtpLoading(false);
        }
      }
    } catch (error) {
      console.error("Error in sending otp request", error);
      setIsOtpLoading(false);
    }
  };

  const verifyOtp = async () => {
    // setHasPopupSubmitted(true);
    try {
      if (OTP.trim() === "") {
        setOtpErr(true);
        return;
      } else {
        setOtpErr(false);
      }

      setIsOtyVerifying(true);

      // if (!ipAddress) {
      //   getIpAddress();
      //   return;
      // }
      // return;

      const data = {
        ipAddress: userIp,
        phoneNumber: phone,
        vehicleId: vehicleId,
        otp: OTP,
      };

      console.log(data);

      const response = await VerifyViewSellerOtp(data);
      console.log(response);

      if (response.status === 200) {
        console.log(response);
        setIsOtyVerifying(false);
        if (!response.data) {
          setOtpErr(true);
          return;
        }
        if (location.pathname === "/search-cars" && !isFromShortlist)
          navigate(`/car-details/${vehicleId}`, { state: { fromViewSeller: true, dealerData: response.data } });
        if (setDealerData) {
          setDealerData(response.data);
        }
        localStorage.setItem("hasOtpVerified", "Y");
        localStorage.setItem("phoneNumber", phone);
        localStorage.setItem("lastVisited", new Date().getTime());
        closeKycPopup();
      } else {
        setIsOtyVerifying(false);
        setOtpErr(true);
      }
    } catch (error) {
      setIsOtyVerifying(false);
      console.error("Error in verifying viewseller Otp", error);
    }
  };

  const handleEdit = () => {
    setVisible(false);
    setOTP("");
  };

  useEffect(() => {
    setLeadId(sessionStorage.getItem("lead_id"));
    setvehicleNo(carNumber ? carNumber : vehicleNumber);
    setPhone(phoneNo ?? "");
  }, []);

  return (
    <div className="kycContainer kycContainerPhase3">
      <div className="kycContent kycResponsive kycFormFixedBottom">
        {width >= 460 && (
          <>
            <div className="kycFormTitle kycFormTitleMobile">
              <h3>Fetch car report</h3>
              <img src={Close} alt="close" onClick={closeKycPopup} />
            </div>
            <div className="kycImage">
              <h2>
                {" "}
                <span>
                  Explore <b> 2 million, used </b>{" "}
                </span>{" "}
                🚘{" "}
                <span>
                  <b>Car reports</b> which guarantee you peace of mind
                </span>{" "}
              </h2>
              <img src={Kyc} alt="car kyc" />
            </div>
          </>
        )}
        {!visible && (
          <div className={visible ? "kycForm " : "kycForm active"}>
            <div className="kycFormTitle kycFormTitleDesk">
              <h3>Contact Seller</h3>
              <img src={Close} alt="close" onClick={() => closeKycPopup(carNumber)} />
            </div>
            <div className="kycFormContent">
              <div className="kycInputGroup">
                <span className="inputLabel inputLabelPhone">Phone number</span>
                <div className="rr-input-container">
                  <span className="countryCode fetch-countryCode">+91</span>
                  <div className="line"></div>
                  <input
                    placeholder="ex. 9123456780"
                    className="rr-input padding-0 fetch-rr-input"
                    type="text"
                    maxLength={10}
                    value={phone}
                    onChange={handleInputChange}
                  />
                </div>
                {phoneErr && phone.trim() !== "" && <p className="error active mobile-error">Invalid phone number</p>}
                {phoneErr && phone.trim() === "" && (
                  <p className="error active mobile-error">Please enter phone number!"</p>
                )}
              </div>
              <a onClick={sendOTP}>
                <button className="cta-button" style={{ opacity: isOtpLoading ? 0.5 : 1 }} disabled={isOtpLoading}>
                  {isOtpLoading ? "Sending Otp..." : "Get Otp"} <img src={RightArrow} alt="Arrow" />
                </button>
              </a>
            </div>
          </div>
        )}
        {visible && (
          <div className={visible ? "kycForm otp active" : "kycForm otp "}>
            <div className="kycFormTitle kycFormTitleDesk">
              <h3>OTP Verification</h3>
              <img src={Close} onClick={closeKycPopup} alt="close" />
            </div>
            <div className="kycFormContent">
              <div className="kycInputGroup">
                <p className="inputLabel">
                  We've sent a 4-digital code to your phone <strong>{phone}</strong>{" "}
                  <span className="cursor-pointer" onClick={handleEdit}>
                    Edit
                  </span>
                </p>
                <OTPInput
                  className="otpInput kycFormOtp"
                  value={OTP}
                  onChange={handleInputChange}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                />
                <div className="">
                  <div className="otpErrorContainer">
                    {otpErr && OTP.trim() !== "" && (
                      <p className="error otpError active"> The OTP entered is invalid/incorrect. Please try again.</p>
                    )}
                    {otpErr && OTP.trim() === "" && <p className="error otpError active"> Please Enter Otp!</p>}
                  </div>

                  <p className="resend">
                    You didn't recieved OTP?{" "}
                    <ResendOTP
                      className="resendBtn "
                      onResendClick={() => {
                        sendOTP();
                      }}
                    />{" "}
                  </p>
                </div>
              </div>
              <div className="mbt20">
                <button
                  className="cta-button"
                  onClick={() => verifyOtp()}
                  style={{ opacity: isOtyVerifying ? 0.7 : 1 }}
                >
                  {isOtyVerifying ? "Verifying Otp" : "Verify Otp"} <img src={RightArrow} alt="Arrow" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewSellerPopup;
