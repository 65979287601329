import React, { useContext, useState } from "react";
import { MyContext } from "../../context";
import { Link } from "react-router-dom";
import MostPopular from "../mostPopular/mostPopular";
import Engine from "../../assets/images/owner-price-plans/179.svg";
import Transmission from "../../assets/images/owner-price-plans/180.svg";
import Transmission1 from "../../assets/images/owner-price-plans/181.svg";
import Tick from "../../assets/images/home/right.svg";
import Wrong from "../../assets/images/owner-price-plans/wrong.svg";
import Plus from "../../assets/images/owner-price-plans/add.svg";
import Minus from "../../assets/images/owner-price-plans/minus.svg";
import Dropdown from "../../assets/images/owner-price-plans/dropdownArrow.svg";
import arrowBlue from "../../assets/images/home/arrowBlue.svg";
import starOrange from "../../assets/images/home/starOrange.svg";
import "./pricingTables.css";
import InspectionPlanModalWiseAssist from "../popups/InspectionPlanModalWiseAssist";

function PlanTables({ pageData }) {
  const { ownerPlanTabActive } = useContext(MyContext);
  const [minimize, setMinimize] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [toggleTwo, setToggleTwo] = useState(false);
  const [toggleThree, setToggleThree] = useState(false);
  const [dropdown, setDropdown] = useState("WiseAssist");
  const [openPurchasePlanModal, setOpenPurchasePlanModal] = useState(false);
  const [buyDropdown, setBuyDropdown] = useState("Extended Warranty");
  const PlansContent = "buyer";
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const toggleModal = () => {
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
    setIsmodalOpen((prevState) => !prevState);
  };
  return (
    <section className="compare ownerCompare" id="pricetablesection">
      <div className="desktop">
        <div className="tableSticky" id="sticky">
          <div className="container">
            <div className="tableCard">
              <h3>Comprehensive Warranty with Buyback Guarantee</h3>

              <Link to="" className=" contactUsBtnModify" style={{ marginTop: "8px" }}>
                <div className="contactUsBtnModifyLine1">₹ 4999</div>
                <div className="contactUsBtnModifyLine2">onWards</div>
              </Link>
            </div>
            <div className="tableCard">
              <MostPopular />
              <h3>Service & Maintenance Coverage</h3>

              <Link to="" className=" contactUsBtnModifyGrediant">
                <div className="contactUsBtnModifyLine1">₹ 9999</div>
                <div className="contactUsBtnModifyLine2">onWards</div>
              </Link>
            </div>
            <div className="tableCard">
              <h3>All-in-One Delight Coverage plan</h3>

              <Link to="" className="contactUsBtn contactUsBtnModifyTwo" style={{ marginTop: "32.5px" }}>
                Coming Soon
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className={ownerPlanTabActive === 0 ? "comparePlans ownCarPlans activatePlans" : "comparePlans ownCarPlans"}
            style={{ alignItems: "flex-start" }}
          >
            <div className="comparePlansCard">
              <div>
                <h3>WiseAssist</h3>
                <p>
                  Save money, time and effort in buying the best car. Get your dream car from 2800+ pre-approved cars
                  from 300+ used car dealers at the comfort of your home.
                </p>
              </div>
              {/* <a onClick={toggleModal} className="contactUsBtn">
                Pay now Rs 999/-<img src={RightGrediantArrow} alt="Arrow" />
              </a> */}
              <button
                style={{
                  backgroundColor: "rgb(235,109,71)",
                  color: "white",
                  borderRadius: "10px",
                  border: "none",
                  padding: "10px 20px",
                  fontWeight: "600",
                  width: "300px",
                  height: "52px",
                  letterSpacing: -"-0.1",
                  lineHeight: "21px",
                  fontSize: "14px",
                  marginTop: "20px",
                  marginLeft: "5px",
                  cursor: "pointer",
                  marginBottom: "30px",
                }}
                onClick={toggleModal}
              >
                {/* Just{" "} */}
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontWeight: "700",
                    fontSize: "28px",
                    color: "#fff",
                  }}
                >
                  Rs 749/-
                </span>
                <span style={{ textDecoration: "line-through", fontWeight: "400", fontSize: "25px", color: "#fff" }}>
                  Rs 999/-{" "}
                </span>
              </button>
            </div>

            <div className="comparePlansCard" style={{ marginBottom: "46px" }}>
              <div>
                <h3>WiseAssist Plus</h3>
                <p>
                  Get your dream car from 2800+ pre-approved cars from 300+ used car dealers at the comfort of your
                  home. Get RTO documentation hassle FREE with this plan
                </p>
              </div>
              {/* <a href="#Compare_Pricing_plans" className="contactUsBtnRedNew">
                Coming Soon
              </a> */}
            </div>

            <div className="comparePlansCard" style={{ marginBottom: "46px" }}>
              <div>
                <h3>WiseAssist Premium</h3>
                <p>
                  Save money, time and effort in buying the best car. Get RTO documentation hassle FREE with this plan
                  and get 3rd party Insurance FREE and loan offer FREE with Car.
                </p>
              </div>
              {/* <a onClick={() => setOpenPurchasePlanModal(true)} className="contactUsBtnRedNew">
                Coming Soon
              </a> */}
            </div>
          </div>

          <div
            className={
              ownerPlanTabActive === 1
                ? "comparePlans buyingCarPlans activatePlans checkoutPlansPhase3 no-padding"
                : "comparePlans buyingCarPlans "
            }
          >
            <div className="container no-padding">
              <div className="suitablePlanContent">
                <div className="suitablePlanCard">
                  <div className="suitablePlanCardHead userPlanCardHead">
                    <h3>Unlock Single Car</h3>
                  </div>
                  <p>Starting from</p>
                  <h4>
                    <span>
                      <span>Rs 749/- </span>
                      <span style={{ textDecoration: "line-through" }}>₹999</span>
                    </span>
                    /Car
                  </h4>
                  <Link to={`/payment/1`} onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                    Get Started <img src={arrowBlue} alt="arrow" />
                  </Link>
                </div>

                <div className="suitablePlanCard">
                  <label className="offer">Save ₹1000</label>
                  <div className="suitablePlanCardHead userPlanCardHead">
                    <h3>Unlock 3 cars pack</h3>
                  </div>
                  <p>Starting from</p>
                  <h4>
                    <span>₹833</span>/Car
                  </h4>
                  <Link to={`/payment/2`} onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                    Get Started <img src={arrowBlue} alt="arrow" />
                  </Link>
                </div>
                <div className="suitablePlanCard">
                  <label className="offer">Save ₹2000</label>
                  <div className="suitablePlanCardHead userPlanCardHead">
                    <h3>Unlock 5 Cars Pack</h3>
                    <label>
                      <img src={starOrange} alt="starOrange" />
                      Popular
                    </label>
                  </div>
                  <p>Starting from</p>
                  <h4>
                    <span>₹599</span>/Car
                  </h4>
                  <Link to={`/payment/5`} onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                    Get Started <img src={arrowBlue} alt="arrow" />
                  </Link>
                </div>
              </div>
              <div className="mobile">
                <h3>
                  Explore Pricing Plans <img src={arrowBlue} alt="arrowBlue" />
                </h3>
              </div>
            </div>
          </div>

          <div className={ownerPlanTabActive === 0 ? "ownerCompare detailTableActive" : "ownCarTable"}>
            <div className="table" id="Compare_Pricing_plans">
              <h3>
                <img src={Engine} alt="engine" />
                Engine Performance Check
              </h3>
              <div className="tableData tableDataHead">
                <div className="tableCard tableCardTwo">
                  <h3 className="tableCardheading3two" style={{ lineHeight: "28px" }}>
                    WiseAssist
                  </h3>

                  <p>
                    <span>Rs 749/- </span>
                    <span style={{ textDecoration: "line-through" }}>Rs 999/-</span>
                  </p>
                  {/* <a onClick={toggleModal} className="contactUsBtn contactUsBtnModifyTwo" style={{ cursor: "default" }}>
                    Pay now <img src={RightGrediantArrow} alt="arrow" />
                  </a> */}
                  <button
                    style={{
                      backgroundColor: "rgb(235,109,71)",
                      color: "white",
                      borderRadius: "10px",
                      border: "none",
                      padding: "10px 20px",
                      fontWeight: "600",
                      width: "300px",
                      height: "52px",
                      letterSpacing: -"-0.1",
                      lineHeight: "21px",
                      fontSize: "14px",
                      marginTop: "20px",
                      marginLeft: "5px",
                      cursor: "pointer",
                      marginBottom: "30px",
                    }}
                    onClick={toggleModal}
                  >
                    {/* Just{" "} */}
                    <span
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        fontWeight: "700",
                        fontSize: "28px",
                        color: "#fff",
                      }}
                    >
                      Rs 749/-
                    </span>
                    <span
                      style={{ fontWeight: "400", fontSize: "25px", color: "#fff", textDecoration: "line-through" }}
                    >
                      Rs 999/-{" "}
                    </span>
                  </button>
                </div>

                <div className="tableCard tableCardTwo" style={{ marginBottom: "42px" }}>
                  <h3 className="tableCardheading3two " style={{ lineHeight: "28px" }}>
                    WiseAssist Plus
                  </h3>
                  <p> New plan</p>
                  {/* <Link to="/" className="tableButton">
                    Coming Soon
                  </Link> */}
                </div>
                <div className="tableCard tableCardResp" style={{ marginBottom: "42px" }}>
                  <h3 className="tableCardheading3three" style={{ lineHeight: "28px" }}>
                    WiseAssist Premium
                  </h3>
                  <p>New plan</p>
                  {/* <Link className="tableButton" to="#">
                    Coming Soon
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="tableContent no-Margin">
              <h4>Unlimited Access to Pre-Approved Warranty Cars</h4>
              <div className="tableData">
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <div className="tableContent no-Margin">
              <h4>Post your requirement to 300+ partnered dealers</h4>
              <div className="tableData">
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>

            <div className="tableContent no-Margin">
              <h4>FREE Inspect the cars from Partnered Dealers</h4>
              <div className="tableData">
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <div className="tableContent no-Margin">
              <h4>Share the Pre-approved warranty cars from Dealers</h4>
              <div className="tableData">
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <div className="tableContent no-Margin">
              <h4>5 FREE Inspections of your choice anywhere in Bangalore</h4>
              <div className="tableData">
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <h3 className="transmissionParts">
              <img src={Transmission} alt="engine" />
              Documentation and History Check
            </h3>
            <div className="tableContent no-Margin">
              <h4>Vehicle Service History Check</h4>
              <div className="tableData">
                <div className="tableCard"></div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <div className="tableContent no-Margin">
              <h4>Vehicle Blacklist Check</h4>
              <div className="tableData">
                <div className="tableCard"></div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <div className="tableContent no-Margin">
              <h4>Vehicle Insurance Validity Check</h4>
              <div className="tableData">
                <div className="tableCard"></div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <div className="tableContent no-Margin">
              <h4>Vehicle Accident Claim Check</h4>
              <div className="tableData">
                <div className="tableCard"></div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <div className="tableContent no-Margin">
              <h4>Vehicle Police Challan(s) Check</h4>
              <div className="tableData">
                <div className="tableCard"></div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
                <div className="tableCard">
                  {" "}
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <h3 className="transmissionParts">
              <img src={Transmission1} alt="engine" />
              RTO and Financial Services
            </h3>
            <div className="tableContent no-Margin">
              <h4>Transfer of Vehicle Ownership with RTO</h4>
              <div className="tableData">
                <div className="tableCard"></div>
                <div className="tableCard"></div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <div className="tableContent no-Margin">
              <h4>3rd Party Insurance for 1 year</h4>
              <div className="tableData">
                <div className="tableCard"></div>
                <div className="tableCard"></div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
            <div className="tableContent no-Margin">
              <h4>Loan Processing assistance</h4>
              <div className="tableData">
                <div className="tableCard"></div>
                <div className="tableCard"></div>
                <div className="tableCard">
                  <img src={Tick} alt="Tick" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile compareMobile">
        <div className="container no-padding">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 24,
            }}
            // className={ownerPlanTabActive === 0 ? "comparePlans ownCarPlans activatePlans" : "comparePlans ownCarPlans"}
          >
            <div className="comparePlansCard">
              <div>
                <h3>WiseAssist</h3>
                <p>
                  Save money, time and effort in buying the best car. Get your dream car from 2800+ pre-approved cars
                  from 300+ used car dealers at the comfort of your home.
                </p>
              </div>

              <button
                style={{
                  backgroundColor: "rgb(235,109,71)",
                  color: "white",
                  borderRadius: "10px",
                  border: "none",
                  padding: "10px 20px",
                  fontWeight: "600",
                  width: "300px",
                  height: "52px",
                  letterSpacing: -"-0.1",
                  lineHeight: "21px",
                  fontSize: "14px",
                  marginTop: "20px",
                  marginLeft: "5px",
                  cursor: "pointer",
                  marginBottom: "15px",
                }}
                onClick={toggleModal}
              >
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontWeight: "700",
                    fontSize: "28px",
                    color: "#fff",
                  }}
                >
                  Free
                </span>
                <span style={{ textDecoration: "line-through", fontWeight: "400", fontSize: "25px", color: "#fff" }}>
                  Rs 999/-{" "}
                </span>
              </button>
            </div>

            <div className="comparePlansCard" style={{ marginBottom: "26px", marginTop: 16 }}>
              <div>
                <h3>WiseAssist Plus</h3>
                <p>
                  Get your dream car from 2800+ pre-approved cars from 300+ used car dealers at the comfort of your
                  home. Get RTO documentation hassle FREE with this plan
                </p>
              </div>
            </div>

            <div className="comparePlansCard" style={{ marginBottom: "26px" }}>
              <div>
                <h3>WiseAssist Premium</h3>
                <p>
                  Save money, time and effort in buying the best car. Get RTO documentation hassle FREE with this plan
                  and get 3rd party Insurance FREE and loan offer FREE with Car.
                </p>
              </div>
            </div>
          </div>

          {ownerPlanTabActive === 0 && (
            <label className="choosePlans padding-sides-16" style={{ position: "unset" }}>
              Choose your plan
            </label>
          )}
          <div className={ownerPlanTabActive === 0 ? "planDropdown dropdownActive " : "planDropdown"}>
            <div className="selectPlanDropdown padding-sides-16 " style={{ position: "unset" }}>
              <div style={{ position: "relative" }}>
                <img src={Dropdown} alt="dropdown" />
                <select name="plans" id="plans" onChange={(e) => setDropdown(e.target.value)}>
                  <option value="WiseAssist">WiseAssist</option>
                  <option value="WiseAssist Plus">WiseAssist Plus</option>
                  <option value="WiseAssist Premimum">WiseAssist Premium</option>
                </select>
              </div>
            </div>

            {dropdown === "WiseAssist" ? (
              <div className="planAccordion">
                <div className="container padding-bottom-24">
                  <div className="accordionTabs">
                    <div
                      className={minimize === 0 && toggle ? "accordionTab accordionOpen" : "accordionTab"}
                      onClick={() => {
                        setToggle(!toggle);
                        setToggleTwo(false);
                        setToggleThree(false);
                      }}
                    >
                      <input type="checkbox" id="engineParts" />
                      <label
                        // htmlFor="engineParts"
                        class="tab-label"
                        onClick={() => setMinimize(0)}
                      >
                        {!toggle ? <img src={Plus} alt="plus" /> : <img src={Minus} alt="minus" />}
                        Engine Performance Check
                      </label>
                      {minimize === 0 ? (
                        <>
                          <div className="tab-content">
                            <p>Unlimited Access to Pre-Approved Warranty Cars</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Post your requirement to 300+ partnered dealers</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>FREE Inspect the cars from Partnered Dealers</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Share the Pre-approved warranty cars from Dealers</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>5 FREE Inspections of your choice anywhere in Bangalore</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    // className={minimize === 0 ? "accordionTabs tabsSticky" : "accordionTabs"}
                    className="accordionTabs"
                  >
                    <div
                      className={minimize === 1 && toggleTwo ? "accordionTab accordionOpen" : "accordionTab"}
                      onClick={() => {
                        setToggleTwo(!toggleTwo);
                        setToggle(false);
                        setToggleThree(false);
                      }}
                    >
                      <input type="checkbox" id="manualTransmission" />
                      <label
                        // htmlFor="manualTransmission"
                        class="tab-label"
                        onClick={() => setMinimize(1)}
                      >
                        {!toggleTwo ? <img src={Plus} alt="plus" /> : <img src={Minus} alt="minus" />}
                        Documentation and History Check
                      </label>
                      {minimize === 1 ? (
                        <>
                          <div className="tab-content">
                            <p>Vehicle Service History Check</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Blacklist Check</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Insurance Validity Check</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Accident Claim Check</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Police Challan(s) Check</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    // className={minimize === 0 ? "accordionTabs tabsSticky" : "accordionTabs"}
                    className="accordionTabs"
                  >
                    <div
                      className={minimize === 2 && toggleThree ? "accordionTab accordionOpen" : "accordionTab"}
                      onClick={() => {
                        setToggleThree(!toggleThree);
                        setToggleTwo(false);
                        setToggle(false);
                      }}
                    >
                      <input type="checkbox" id="manualTransmission" />
                      <label
                        // htmlFor="manualTransmission"
                        class="tab-label"
                        onClick={() => setMinimize(2)}
                      >
                        {!toggleThree ? <img src={Plus} alt="plus" /> : <img src={Minus} alt="minus" />}
                        RTO and Financial Service
                      </label>
                      {minimize === 2 ? (
                        <>
                          <div className="tab-content">
                            <p>Transfer of Vehicle Ownership with RTO</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>3rd Party Insurance for 1 year</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Loan Processing assistance</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {dropdown === "WiseAssist Plus" ? (
              <div className="planAccordion">
                <div className="container padding-bottom-24">
                  <div className="accordionTabs">
                    <div
                      className={minimize === 0 && toggle ? "accordionTab accordionOpen" : "accordionTab"}
                      onClick={() => {
                        setToggle(!toggle);
                        setToggleTwo(false);
                      }}
                    >
                      <input type="checkbox" id="engineParts" />
                      <label
                        // htmlFor="engineParts"
                        class="tab-label"
                        onClick={() => setMinimize(0)}
                      >
                        {!toggle ? <img src={Plus} alt="plus" /> : <img src={Minus} alt="minus" />}
                        Engine Performance Check
                      </label>
                      {minimize === 0 ? (
                        <>
                          <div className="tab-content">
                            <p>Unlimited Access to Pre-Approved Warranty Cars</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Post your requirement to 300+ partnered dealers</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>FREE Inspect the cars from Partnered Dealers</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Share the Pre-approved warranty cars from Dealers</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>5 FREE Inspections of your choice anywhere in Bangalore</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    // className={minimize === 0 ? "accordionTabs tabsSticky" : "accordionTabs"}
                    className="accordionTabs"
                  >
                    <div
                      className={minimize === 1 && toggleTwo ? "accordionTab accordionOpen" : "accordionTab"}
                      onClick={() => {
                        setToggleTwo(!toggleTwo);
                        setToggle(false);
                      }}
                    >
                      <input type="checkbox" id="manualTransmission" />
                      <label
                        // htmlFor="manualTransmission"
                        class="tab-label"
                        onClick={() => setMinimize(1)}
                      >
                        {!toggleTwo ? <img src={Plus} alt="plus" /> : <img src={Minus} alt="minus" />}
                        Documentation and History Check
                      </label>
                      {minimize === 1 ? (
                        <>
                          <div className="tab-content">
                            <p>Vehicle Service History Check</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Blacklist Check</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Insurance Validity Check</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Accident Claim Check</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Police Challan(s) Check</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    // className={minimize === 0 ? "accordionTabs tabsSticky" : "accordionTabs"}
                    className="accordionTabs"
                  >
                    <div
                      className={minimize === 2 && toggleThree ? "accordionTab accordionOpen" : "accordionTab"}
                      onClick={() => {
                        setToggleThree(!toggleThree);
                        setToggleTwo(false);
                        setToggle(false);
                      }}
                    >
                      <input type="checkbox" id="manualTransmission" />
                      <label
                        // htmlFor="manualTransmission"
                        class="tab-label"
                        onClick={() => setMinimize(2)}
                      >
                        {!toggleThree ? <img src={Plus} alt="plus" /> : <img src={Minus} alt="minus" />}
                        RTO and Financial Service
                      </label>
                      {minimize === 2 ? (
                        <>
                          <div className="tab-content">
                            <p>Transfer of Vehicle Ownership with RTO</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>3rd Party Insurance for 1 year</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Loan Processing assistance</p>
                            <p>
                              <img src={Wrong} alt="Wrong" />
                              Not Included
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {dropdown === "WiseAssist Premimum" ? (
              <div className="planAccordion">
                <div className="container padding-bottom-24">
                  <div className="accordionTabs">
                    <div
                      className={minimize === 0 && toggle ? "accordionTab accordionOpen" : "accordionTab"}
                      onClick={() => {
                        setToggle(!toggle);
                        setToggleTwo(false);
                      }}
                    >
                      <input type="checkbox" id="engineParts" />
                      <label
                        // htmlFor="engineParts"
                        class="tab-label"
                        onClick={() => setMinimize(0)}
                      >
                        {!toggle ? <img src={Plus} alt="plus" /> : <img src={Minus} alt="minus" />}
                        Engine Performance Check
                      </label>
                      {minimize === 0 ? (
                        <>
                          <div className="tab-content">
                            <p>Unlimited Access to Pre-Approved Warranty Cars</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Post your requirement to 300+ partnered dealers</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>FREE Inspect the cars from Partnered Dealers</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Share the Pre-approved warranty cars from Dealers</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>5 FREE Inspections of your choice anywhere in Bangalore</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    // className={minimize === 0 ? "accordionTabs tabsSticky" : "accordionTabs"}
                    className="accordionTabs"
                  >
                    <div
                      className={minimize === 1 && toggleTwo ? "accordionTab accordionOpen" : "accordionTab"}
                      onClick={() => {
                        setToggleTwo(!toggleTwo);
                        setToggle(false);
                      }}
                    >
                      <input type="checkbox" id="manualTransmission" />
                      <label
                        // htmlFor="manualTransmission"
                        class="tab-label"
                        onClick={() => setMinimize(1)}
                      >
                        {!toggleTwo ? <img src={Plus} alt="plus" /> : <img src={Minus} alt="minus" />}
                        Documentation and History Check
                      </label>
                      {minimize === 1 ? (
                        <>
                          <div className="tab-content">
                            <p>Vehicle Service History Check</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Blacklist Check</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Insurance Validity Check</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Accident Claim Check</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Vehicle Police Challan(s) Check</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    // className={minimize === 0 ? "accordionTabs tabsSticky" : "accordionTabs"}
                    className="accordionTabs"
                  >
                    <div
                      className={minimize === 2 && toggleThree ? "accordionTab accordionOpen" : "accordionTab"}
                      onClick={() => {
                        setToggleThree(!toggleThree);
                        setToggleTwo(false);
                        setToggle(false);
                      }}
                    >
                      <input type="checkbox" id="manualTransmission" />
                      <label
                        // htmlFor="manualTransmission"
                        class="tab-label"
                        onClick={() => setMinimize(2)}
                      >
                        {!toggleThree ? <img src={Plus} alt="plus" /> : <img src={Minus} alt="minus" />}
                        RTO and Financial Service
                      </label>
                      {minimize === 2 ? (
                        <>
                          <div className="tab-content">
                            <p>Transfer of Vehicle Ownership with RTO</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>3rd Party Insurance for 1 year</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                          <div className="tab-content">
                            <p>Loan Processing assistance</p>
                            <p>
                              <img src={Tick} alt="Tick" />
                              Included
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* <div
            className={ownerPlanTabActive === 1 ? "planDropdown dropdownActive padding-24" : "planDropdown padding-24"}
          >
            <div className="suitablePlanContent ">
              <div className="suitablePlanCard">
                <div className="suitablePlanCardHead userPlanCardHead">
                  <h3>Unlock Single Car</h3>
                </div>
                <p>Starting from</p>
                <h4>
                  <span>₹999</span>/Car
                </h4>
                <Link to={`/payment/1`} onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                  Get Started <img src={arrowBlue} alt="arrow" />
                </Link>
              </div>
              <div className="suitablePlanCard">
                <label className="offer">Save ₹1000</label>
                <div className="suitablePlanCardHead userPlanCardHead">
                  <h3>Unlock 3 cars pack</h3>
                </div>
                <p>Starting from</p>
                <h4>
                  <span>₹833</span>/Car
                </h4>
                <Link to={`/payment/2`} onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                  Get Started <img src={arrowBlue} alt="arrow" />
                </Link>
              </div>
              <div className="suitablePlanCard">
                <label className="offer">Save ₹2000</label>
                <div className="suitablePlanCardHead userPlanCardHead">
                  <h3>Unlock 5 Cars Pack</h3>
                  <label>
                    <img src={starOrange} alt="starOrange" />
                    Popular
                  </label>
                </div>
                <p>Starting from</p>
                <h4>
                  <span>₹599</span>/Car
                </h4>
                <Link to={`/payment/5`} onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                  Get Started <img src={arrowBlue} alt="arrow" />
                </Link>
              </div>
            </div>
          </div> */}
        </div>
        {/* {ownerPlanTabActive === 0 && (
          <div className="saveOffer">
            <div className="container">
              <div className="container saveOfferContainer">
                <div className="offerPercentage">
                  <p>Save upto 50% p.a</p>
                </div>
                <div className="buyBtn">
                  <Link to="/checkout-plans/0">
                    Buy Now <img className="buyBtnArrow" src={RightGrediantArrow} alt="arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
      <InspectionPlanModalWiseAssist isOpen={isModalOpen} toggler={toggleModal} pageData={pageData} />
    </section>
  );
}

export default PlanTables;
