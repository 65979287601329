import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./report-details.css";
import History from "./reportDetails";
import ReportHeader from "../../components/header/ReportHeader";

const ReportDetails = () => {
  return (
    <div>
      <ReportHeader />
      <History />
      <Footer />
    </div>
  );
};

export default ReportDetails;
