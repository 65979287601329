import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import OTPInput, { ResendOTP } from "otp-input-react";
import Kyc from "../../assets/images/home/kyc.png";
import Close from "../../assets/images/home/close.svg";
import RightArrow from "../../assets/images/home/rightArrow.svg";
import "react-phone-input-2/lib/style.css";
import "./kycPopup.css";
import { AcceptOnlyNumber, EncryptData, checkIsValidFormat } from "../../utils/commonFunctions";
import { GetInspectionReportOtpApi, VerifyInspectionReportOtpApi } from "../../Api/Apis";

const FetchCarReport = ({ carNumber, closeKycPopup }) => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [phoneInvalidError, setPhoneInvalidError] = useState(false);
  const [otpErr, setOtpErr] = useState(false);
  const [otp, setOTP] = useState("");
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState("");
  const [disabledButton, setDisableButton] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const ip = "";

  const handleNumber = (e) => {
    setShowMessage(false);
    setPhoneErr(false);
    setPhoneInvalidError(false);
    const value = e.target.value;
    const isValid = AcceptOnlyNumber(value);
    if (isValid) {
      setPhone(value);
    }
  };

  const sendOTP = async () => {
    if (!phone) {
      setPhoneErr(true);
    } else if (phone.length < 10) {
      setPhoneInvalidError(true);
    } else {
      setDisableButton(true);
      try {
        const resp = await GetInspectionReportOtpApi(phone, carNumber, ip);
        if (resp.status === 200) {
          if (resp.data.responseType === "300") {
            setErrorMessage(resp.data.response.message);
          } else {
            if (resp.data.response.Details.can_go_inside === "Y") {
              setVisible(true);
              setData(resp.data.response.Details);
            }
          }
          setDisableButton(false);
          setShowMessage(true);
          setErrorMessage(resp.data.response.Details.error_msg_1);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setDisableButton(false);
      }
    }
  };

  const verifyOtp = async () => {
    try {
      const resp = await VerifyInspectionReportOtpApi(phone, otp);
      if (resp.status === 200) {
        if (resp.data.responseType === "300") {
          setOtpErr(true);
        } else {
          sessionStorage.setItem("phone", phone);
          sessionStorage.setItem("lead_Id", data.lead_id);
          sessionStorage.setItem("name", data.lead_name);

          navigate(
            `/asda12/${data.customer_id || "nodata"}/${data.insp_req_vehicle_id || "nodata"}/${
              data.lead_id || "nodata"
            }/${data.lead_vehicle_id || "nodata"}/${data.vehicle_id || "nodata"}`,
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = () => {
    setVisible(false);
    setOTP("");
  };

  const handleInputChange = (e) => {
    if (visible === false) {
      setPhone(e.target.value);
      setPhoneErr(false);
    } else {
      setOTP(e);
      setOtpErr(false);
    }
  };
  const variableBackground = () => {
    document.body.style.overflow = "auto";
  };

  return (
    <div className="kycContainer kycContainerPhase3">
      <div className="kycContent kycResponsive kycFormFixedBottom">
        {/* <div className="kycFormTitle kycFormTitleMobile">
          <h3>Fetch car report</h3>
          <img src={Close} alt="close" onClick={closeKycPopup} />
        </div> */}
        <div className="kycImage">
          <h2>
            {" "}
            <span>
              Explore <b> 2 million, used </b>{" "}
            </span>{" "}
            🚘{" "}
            <span>
              <b>Car reports</b> which guarantee you peace of mind
            </span>{" "}
          </h2>
          <img src={Kyc} alt="car kyc" />
        </div>
        {!visible && (
          <div className={visible ? "kycForm " : "kycForm active"}>
            <div className="kycFormTitle kycFormTitleDesk">
              <h3>Fetch car report</h3>
              <img
                src={Close}
                alt="close"
                onClick={() => {
                  closeKycPopup(carNumber);
                  variableBackground();
                }}
              />
            </div>
            <div className="kycFormContent">
              <div className="kycInputGroup">
                <span className="inputLabel inputLabelPhone">Phone number</span>
                <div className="rr-input-container">
                  <span className="countryCode fetch-countryCode">+91</span>
                  <div className="line"></div>
                  <input
                    placeholder="ex. 9123456780"
                    className="rr-input padding-0 fetch-rr-input"
                    type="text"
                    value={phone}
                    onChange={handleNumber}
                  />
                </div>
                {phoneErr && <p className="error active mobile-error">Please enter phone number</p>}
                {phoneInvalidError && <p className="error active mobile-error">please enter valid phone number</p>}
                {showMessage && <p className="error active mobile-error">{errorMessage}</p>}
              </div>
              <button
                className="cta-button"
                disabled={disabledButton}
                onClick={sendOTP}
                style={{ cursor: disabledButton ? "not-allowed" : "pointer" }}
              >
                Get Otp <img src={RightArrow} alt="Arrow" />
              </button>
            </div>
          </div>
        )}
        {visible && (
          <div className={visible ? "kycForm otp active" : "kycForm otp "}>
            <div className="kycFormTitle kycFormTitleDesk">
              <h3>OTP Verification</h3>
              <img src={Close} onClick={closeKycPopup} alt="close" />
            </div>
            <div className="kycFormContent">
              <div className="kycInputGroup">
                <p className="inputLabel">
                  We've sent a 4-digital code to your phone <strong>{phone}</strong>{" "}
                  <span className="cursor-pointer" onClick={handleEdit}>
                    Edit
                  </span>
                </p>
                <OTPInput
                  className="otpInput kycFormOtp"
                  value={otp}
                  onChange={handleInputChange}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                />
                <div className="">
                  <div className="otpErrorContainer">
                    {otpErr && (
                      <p className="error otpError active"> The OTP entered is invalid/incorrect. Please try again.</p>
                    )}
                  </div>

                  <p className="resend">
                    You didn't recieved OTP?{" "}
                    <ResendOTP
                      className="resendBtn "
                      onResendClick={() => {
                        sendOTP();
                      }}
                    />{" "}
                  </p>
                </div>
              </div>
              <div className="mbt20">
                <button className="cta-button" onClick={() => verifyOtp()} st>
                  Get report <img src={RightArrow} alt="Arrow" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FetchCarReport;
