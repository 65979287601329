import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Tick from "../../assets/images/home/tickWhite.svg";
import Shield from "../../assets/images/home/shield.svg";
import whiteShield from "../../assets/images/owner-home/shield.svg";
import BuyBackImage from "../../assets/images/home/buyback.png";
import buybackImgPhone from "../../assets/images/owner-home/buybackImgPhone.png";
import whiteGrey from "../../assets/images/owner-home/tickGrey.svg";
import buybackImg from "../../assets/images/owner-home/buyPackImg.png";

function BuyBack() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 10000,
    slidesToShow: 0,
    slidesToScroll: 0,
    autoplaySpeed: 0,
    cssEase: "linear",
    autoplay: true,
    infiniteScroll: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 0,
          slidesToScroll: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="buyback buybackOwner">
      <div className="buybackContents">
        <div className="container">
        <div className="buybackWrapper">
          <div className="buybackContent">
            <div className="securedDesk">
              <img src={whiteShield} alt="shield" />
              If we cannot repair your car we will buy it at Invoice price.
            </div>
            <h2 className="headingDesk">
              <span>5Lakhs</span> Engine & Transmission Warranty or{" "}
              <span>100% Buyback Guarantee</span>
            </h2>
            <ul className="buybackList">
              <li>
                <img src={whiteGrey} alt="tick" />
                <span>100% claims settled in less than 24 hours*</span>
              </li>
              <li>
                <img src={whiteGrey} alt="tick" />
                <span>100% Peace of Mind Guaranteed</span>
              </li>
              <li>
                <img src={whiteGrey} alt="tick" />
                <span>100% Customer Satisfaction Guaranteed</span>
              </li>
            </ul>
            <div className="mobile buybackMob">
              <img src={buybackImgPhone} alt="buyback" />
              <ul className="buybackLists">
              <li>
                <img src={whiteGrey} alt="tick" />
                <span>100% claims settled in less than 24 hours*</span>
              </li>
              <li>
                <img src={whiteGrey} alt="tick" />
                <span>100% Peace of Mind Guaranteed</span>
              </li>
              <li>
                <img src={whiteGrey} alt="tick" />
                <span>100% Customer Satisfaction Guaranteed</span>
              </li>
            </ul>
            </div>
            {/* <div className="buybackButtons buybackButtonsDesk">
              <Link to="/claim-process" className="cta-button">
              </Link>
              <Link to="/terms-and-conditions" className="cta-button">
                <span className="btnDesk">Terms & Conditions</span>
              </Link>
            </div> */}
          </div>
          <div className="buybackImage">
            {/* <div className="securedMobile">
              <img src={Shield} alt="" />
              If we can't repair it we buy it
            </div>
            <h2 className="headingMobile">
              <span>100% Buyback </span> Guarantee Protection for 365 Days
            </h2> */}
            <div className="desktop">
              <div className="buyPackImg">
                <img src={buybackImg} alt="buyback" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="buyBackSlider">
          <Slider {...settings}>
            <div>
              <li>
                <img src={Tick} alt="tick" />
                <span>100% Buyback at 100% purchase Price</span>
              </li>
            </div>
            <div>
              <li>
                <img src={Tick} alt="tick" />
                <span>No questions asked money back guarantee*</span>
              </li>
            </div>
            <div>
              <li>
                <img src={Tick} alt="tick" />
                <span>Superfast claim processing</span>
              </li>
            </div>
          </Slider>
          <div className="buybackButtons">
            <Link to="/claim-process" className="cta-button">
              <span>How it works</span>
            </Link>
            <Link to="/terms-and-conditions" className="cta-button">
              <span className="btnDesk">Terms & Conditions</span>
              <span className="btnMobile">T&C</span>
            </Link>
          </div>
        </div> */}
      </div>
      </div>
    </section>
  );
}

export default BuyBack;
