import React from "react";
import Wrong from "../../assets/images/warranty-benefits/wrongBg.svg";
import Right from "../../assets/images/warranty-benefits/rightBg.svg";

function Maintain() {
  return (
    <section className="maintain">
      <div className="container">
        <div className="commonHeading">
          <h2>
            Help us to maintain your car the <span>way you want it to be.</span>
          </h2>
        </div>
        <div className="maintainContent">
          <div className="maintainContentCard">
            <img src={Wrong} alt="Wrong" />
            <h3>Without Extended Warranty</h3>
            <ul>
              <li>The inevitable breakdown happens.</li>
              <li>You pay to have your car towed to closest mechanic.</li>
              <li>
                Mechanic tells you what is wrong, leaving you to trust he is
                telling you the truth.
              </li>
              <li>
                You end up trying to negotiate with the mechanic directly.
              </li>
              <li>
                You potentially pay THOUSANDS out of pocket for the repairs.
              </li>
              <li>You leave not knowing if you paid too much,</li>
            </ul>
          </div>
          <div className="maintainContentCard">
            <img src={Right} alt="Right" />
            <h3>With Extended Warranty</h3>
            <ul>
              <li>Select a plan that works with your life.</li>
              <li>No worry of breakdowns.</li>
              <li>Enjoy the benefits of the Endurance Protection Plan.</li>
              <li>Our super fast support team will assist you in 30 min.</li>
              <li>Roadside Assistance anywhere in India</li>
              <li>24/7 customer care.</li>
              <li>Quality and trust ensured inbuilt.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Maintain;
