import React, { useEffect, useState } from "react";
import styles from "../../containers/car-details/car-details.module.css";
import image1 from "../../assets/CarImages/gridimages/image1.jpg";
import image2 from "../../assets/CarImages/gridimages/image2.jpeg";
import image3 from "../../assets/CarImages/gridimages/image3.jpg";
import image4 from "../../assets/CarImages/gridimages/image4.jpg";
import ImageGalleryPopup from "../popups/imageGalleryPopup";
import defaultImg from "../../assets/CarImages/default.jpg";
import useWindowDimensions from "../util-hooks/useWindowDimentions";

function CarImageGrid({ images }) {
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const { width, height } = useWindowDimensions();

  const [viewText, setViewText] = useState();

  // add the available images in sequence to the grid
  // if front image is not ther add image which is not front,back, left, right, with the next image available,
  // same with other placeholders. (if one is image is already used then no need to use it again)

  useEffect(() => {
    if (images) {
      if (width >= 780) {
        if (Object.keys(images).length <= 3) {
          setViewText("View");
        } else {
          setViewText(`+${Object.keys(images).length - 3} photos`);
        }
      } else {
        if (Object.keys(images).length <= 2) {
          setViewText("View");
        } else {
          setViewText(`+${Object.keys(images).length - 2} photos`);
        }
      }
    }
  }, [images, width]);

  function toggleImageGallery() {
    setIsImageGalleryOpen((prevState) => !prevState);
  }

  console.log(images);

  return (
    <>
      <div className={styles.imageGrid}>
        <div onClick={toggleImageGallery}>
          {images && images["Front Side"] ? (
            <img src={images["Front Side"]} alt="car view" />
          ) : (
            <img src={defaultImg} alt="car view" />
          )}
          {/* <img src={image1} alt="car view" /> */}
        </div>
        <div onClick={toggleImageGallery}>
          {images && images["Back Side"] ? (
            <img src={images["Back Side"]} alt="car view" />
          ) : (
            <img src={defaultImg} alt="car view" />
          )}
          {/* <img src={image2} alt="car view" /> */}
        </div>
        <div onClick={toggleImageGallery}>
          {images && images["Right Side"] ? (
            <img src={images["Right Side"]} alt="car view" />
          ) : (
            <img src={defaultImg} alt="car view" />
          )}
          {/* <img src={image3} alt="car view" /> */}
        </div>
        <div style={{ position: "relative", cursor: "pointer" }} onClick={toggleImageGallery}>
          <div className={styles.moreImageOverlay}>
            {/* <span>+{} Photos</span> */}
            <span>{viewText}</span>
          </div>
          {images && images["Left Side"] ? (
            <img src={images["Left Side"]} alt="car view" />
          ) : (
            <img src={defaultImg} alt="car view" />
          )}
          {/* <img src={image4} alt="car view" /> */}
        </div>
      </div>
      {isImageGalleryOpen && <ImageGalleryPopup toggler={toggleImageGallery} imageList={images} />}
    </>
  );
}

export default CarImageGrid;
