import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Banner from "./banner";
import Deals from "../../components/deals";
import "./payment.css";
import Addbanner from "./addbanner";
import ReportHeader from "../../components/header/ReportHeader";

const Payment = () => {
  return (
    <>
      <ReportHeader />
      <Banner />
      {/* <Addbanner />
        <Deals /> */}
      <Footer />
    </>
  );
};

export default Payment;
