import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { MyContext } from "../../context";
import CarBanner from "./CarBanner";
import Header from "../../components/header";
import Banner from "./banner";
import Footer from "../../components/footer";
import Service from "./service";
import Calculate from "./calculate";
import ContactUs from "../../components/contact-us/contact-us";
import PlanSection from "./plan";
import SaveTime from "./save-time";
import Warranty from "./warranty";
import BuyBack from "./buyback";
import Customers from "../../components/customers/customers";
import Saving from "./saving";
import WarrantyTable from "./warranty-table";
import Download from "../../components/download/download";
import Price from "../../components/price/price";
import SuitablePlans from "../../components/suitablePlans";

import "./owner-home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EngineProtection from "../../components/engineProtection";
import PartnerLocation from "../../components/partnerLocation";

import Guarantee from "../../containers/warranty-benefits/guarantee";
import CheckUp from "../../containers/warranty-benefits/check-up";

const OwnerHome = () => {
  const { count, setCount } = useContext(MyContext);
  const { index, setIndex } = useContext(MyContext);
  const { heightAct, setHeightAct } = useContext(MyContext);
  const [loader, setLoader] = useState(true);

  window.addEventListener("scroll", () => {
    let bannerHeight = document.getElementsByClassName("ownerBanner")[0].clientHeight;
    let saveTimeHeight = document.getElementsByClassName("guarenty")[0].clientHeight;
    let planHeight = document.getElementsByClassName("suitablePlans")[0].clientHeight;
    let savingHeight = document.getElementsByClassName("buybackOwner")[0].clientHeight;
    let warrantyTableHeight = document.getElementsByClassName("checkUp")[0].clientHeight;
    let priceHeight = document.getElementsByClassName("partnerLocation")[0].clientHeight;
    
    let progress = document.getElementById("progress1");

    let sectionsHeight = bannerHeight + saveTimeHeight + planHeight + savingHeight + warrantyTableHeight + priceHeight;
    if (window.pageYOffset >= sectionsHeight) {
      setHeightAct(true);
      progress.classList.add("addProgress");
    }
  });

  const checkTimer = () => {
    let hours = 24; // if you want to clear after 1hrs simply change hours=1)
    let now = new Date().getTime();
    let setupTime = localStorage.getItem("setupTime");
    if (setupTime == null) {
      localStorage.setItem("setupTime", now);
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem("setupTime", now);
      }
    }
  };

  useEffect(() => {
    sessionStorage.clear();
    setLoader(false);
    checkTimer();
  }, []);

  return (
    !loader && (
      <div>
        {/* <PurchasePlan /> */}
        <Header />
        <Banner />
        {/* <CarBanner /> */}
        <Guarantee />
        <SuitablePlans />
        <BuyBack />
        <CheckUp />
        <PartnerLocation />
        <Warranty />
        <Price />
        <EngineProtection />
        <WarrantyTable />
        <Customers />
        <Download />
        <ContactUs />
        <Footer />
        {/* <PlanSection /> */}
        {/* <SaveTime /> */}
        {/* <Saving /> */}
        {/* <Service /> */}
        {/* <Calculate /> */}
      </div>
    )
  );
};

export default OwnerHome;
