import React from "react";
import userTechnologybullet from "../../assets/images/home/userTechnologybullet.svg";
import usertechnologyImage from "../../assets/images/home/usertechnologyImage.png";
import { useState } from "react";
import InspectionPlanModalWiseAssist from "../../components/popups/InspectionPlanModalWiseAssist";

const Technology = ({ pageData }) => {
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };

  return (
    <>
      <section className="technology">
        <div className="container">
          <div className="technologyContainer">
            <div className="technologySection">
              <h2 className="technologyHeadingDesk">
                <span>Advanced AI technology </span>
                to analyse your car engine performance
              </h2>
              <ul className="technologyList">
                <li>
                  <img src={userTechnologybullet} alt="bullet" />
                  <div>Comprehensive Warranty which guarantee peace of mind</div>
                </li>
                <li>
                  <img src={userTechnologybullet} alt="bullet" />
                  <div>Free Service and Maintenance at OEM authorised service center</div>
                </li>
                <li>
                  <img src={userTechnologybullet} alt="bullet" />
                  <div>PAN India Roadside Assistance providing complete protection</div>
                </li>
              </ul>

              <button
                style={{
                  backgroundColor: "rgb(235,109,71)",
                  color: "white",
                  borderRadius: "10px",
                  border: "none",
                  padding: "10px 20px",
                  fontWeight: "600",
                  width: "300px",
                  height: "52px",
                  letterSpacing: -"-0.1",
                  lineHeight: "21px",
                  fontSize: "14px",
                  marginTop: "20px",
                  marginLeft: "5px",
                  cursor: "pointer",
                  marginBottom: "30px",
                }}
                onClick={toggleModal}
              >
                {/* Just{" "} */}
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontWeight: "700",
                    fontSize: "28px",
                    color: "#fff",
                  }}
                >
                  {" "}
                  <span style={{}}>Rs 749/- </span>
                </span>
                <span style={{ textDecoration: "line-through", fontWeight: "400", fontSize: "25px", color: "#fff" }}>
                  Rs 999/-
                </span>
              </button>
            </div>
            <div className="usertechnologyImage">
              <img src={usertechnologyImage} alt="usertechnologyImage" />
            </div>
          </div>
          <InspectionPlanModalWiseAssist isOpen={isModalOpen} toggler={toggleModal} pageData={pageData} />
        </div>
      </section>
    </>
  );
};

export default Technology;
