import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PaymentFailure() {
  const query = useQuery();

  useEffect(() => {
    const transactionId = query.get("txnid");
    const status = query.get("status");
    const amount = query.get("amount");
    const key = query.get("key");
    const productinfo = query.get("productinfo");
    const firstname = query.get("firstname");
    const email = query.get("email");

    // Handle the response and update your UI or state
    console.log("Transaction ID:", transactionId);
    console.log("Status:", status);
    console.log("Amount:", amount);
    console.log("Key:", key);
    console.log("Product Info:", productinfo);
    console.log("First Name:", firstname);
    console.log("Email:", email);
  }, [query]);

  return (
    <div>
      <h1>Payment Success</h1>
      <p>Thank you for your payment!</p>
    </div>
  );
}

export default PaymentFailure;
