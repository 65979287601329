import React from "react";
import Slider from "react-slick";

import TickGreen from "../../assets/images/home/tickGreen.svg";
import RightArrow from "../../assets/images/home/rightArrow.svg";
import Ford from "../../assets/images/home/ford.svg";
import Nissan from "../../assets/images/home/nissanBanner.svg";
import Hyundai from "../../assets/images/home/hyundaiBanner.svg";
import Toyota from "../../assets/images/home/toyota.svg";
import Honda from "../../assets/images/home/honda.svg";
import Suzuki from "../../assets/images/home/suzuki.svg";
import Skoda from "../../assets/images/home/skoda.svg";
import Kia from "../../assets/images/home/kia.svg";
import Volvo from "../../assets/images/home/volvo.svg";
import Benz from "../../assets/images/home/benz.svg";
import Tata from "../../assets/images/home/tata.svg";
import Audi from "../../assets/images/home/audi.svg";
import Mg from "../../assets/images/home/mg.svg";
import Force from "../../assets/images/home/force.svg";
import One from "../../assets/images/home/1.png";
import Two from "../../assets/images/home/2.png";
import Three from "../../assets/images/home/3.png";
import Four from "../../assets/images/home/4.png";
import fatherDaughter from "../../assets/images/owner-home/fatherDaughter.png";
import FreeMaintenance from "../../assets/images/owner-home/freeMaintenance.png";

function Banner() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 25000,
    slidesToShow: 5,
    slidesToScroll: 6,
    autoplaySpeed: 0,
    cssEase: "linear",
    autoplay: true,
    variableWidth: true,
    infiniteScroll: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var props = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    vertical: true,
    slidesToShow: 3.99,
    slidesToScroll: 1,
    adaptiveHeight: false,
    speed: 5000,
    autoplaySpeed: 0,
    verticalSwiping: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 993,
        settings: {
          vertical: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 850,
        settings: {
          vertical: false,
          slidesToShow: 2.2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          vertical: false,
          slidesToShow: 1.4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          vertical: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 374,
        settings: {
          vertical: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="bannerSection ownerBanner">
      <div className="ownerBannerHead">
      <div className="container">
        <div className="bannerHeading newContent">
          <div>
            <label>
              {/* <img src={TickGreen} alt="Tick" /> */}
              Extended warranty-Peace of mind guarantee
            </label>
            <h1>
              <span>
                Don’t pay for car maintenance.
                <br />
              </span>
              It’s free, Forever
            </h1>
            <p>Ask for Wisedrive Warranty and protect your car.</p>
            <a href="#warranty" className="cta-button">
              Explore Wisedrive <img src={RightArrow} alt="Arrow" />
            </a>
          </div>
          {/* <div className="mobile">
            <img src={fatherDaughter} alt="father daughter img" />
          </div> */}
          <div className="bannerSlider">
            {/* <Slider {...props}>
              <div className="cardsContainer">
                <img src={One} alt="" />
              </div>
              <div className="cardsContainer">
                <img src={Two} alt="" />
              </div>
              <div className="cardsContainer">
                <img src={Three} alt="" />
              </div>
              <div className="cardsContainer">
                <img src={Four} alt="" />
              </div>
            </Slider> */}
            <img src={FreeMaintenance} alt="FreeMaintenance" />
          </div>
          <div className="tabSize">
            <img src={FreeMaintenance} alt="FreeMaintenance" />
          </div>
        </div>

        <div className="bannerWarranty">
          <p>Extended warranty provided for all brands</p>
          <Slider {...settings}>
            <img src={Ford} alt="ford" />
            <img src={Hyundai} alt="hyundai" />
            <img src={Toyota} alt="toyota" />
            <img src={Nissan} alt="nissan" />
            <img src={Suzuki} alt="suzuki" />
            <img src={Honda} alt="honda" />
            <img src={Skoda} alt="Skoda" />
            <img src={Kia} alt="Kia" />
            <img src={Volvo} alt="Volvo" />
            <img src={Benz} alt="Benz" />
            <img src={Tata} alt="Tata" />
            <img src={Audi} alt="audi" />
            <img src={Force} alt="Force" />
            <img src={Mg} alt="Mg" />
          </Slider>
        </div>
      </div>
      </div>
    </section>
  );
}

export default Banner;
