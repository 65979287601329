import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import OTPInput, { ResendOTP } from "otp-input-react";
import Kyc from "../../assets/images/home/kyc.png";
import Close from "../../assets/images/home/close.svg";
import RightArrow from "../../assets/images/home/rightArrow.svg";
import "react-phone-input-2/lib/style.css";
import "./kycPopup.css";
import { checkIsValidFormat } from "../../utils/commonFunctions";
import { ValidationContainer } from "../../utils/constants";
import { requestOTP, verifyOTP } from "../../services/getInspectionReport";

const KycPopup = ({carNumber, closeKycPopup }) => {
  const vehicleNumber= sessionStorage.getItem("vehicleNo");
  const phoneNo= localStorage.getItem("phoneNo");
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [otpErr, setOtpErr] = useState(false);
  const [OTP, setOTP] = useState("");
  const [visible, setVisible] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const [vehicleNo,setvehicleNo]=useState(null)
 

  const handleInputChange = (e) => {
    if (visible === false) {
      setPhone(e.target.value);
      setPhoneErr(false);
    } else {
      setOTP(e);
      setOtpErr(false);
    }
  };

  const sendOTP = async () => {
    if (
      phone === "" ||
      !checkIsValidFormat(phone, ValidationContainer.mobileNumberFormat)
    ) {
      setVisible(false);
      setPhoneErr(true);
    } else {
      const response = await requestOTP({
        vehicleNo: vehicleNo,
        mobileNo: phone,
      });

      
      if (response?.data?.responseType === "200") {
        setVisible(true);
        setLeadId(response?.data?.response?.Details?.lead_id);
        sessionStorage.setItem("lead_Id", response?.data?.response?.Details?.lead_id);
        localStorage.setItem("phoneNo", phone);
      }
    }
  };

  const verifyOtp = async () => {
    const response = await verifyOTP({
      vehicleNo:vehicleNo,
      mobile_no: phone,
      leadId:leadId,
      otp: OTP,
    });
    if (response?.data?.responseType === "200" &&
      response?.data?.response?.Details?.is_vehicle_present === "Y"
    ) {
      sessionStorage.setItem("LeadVerified", response?.data?.response?.LeadVerified);
      sessionStorage.setItem ("carData", response?.data?.response?.Details?.is_vehicle_present)
      navigate("/report");
    }
    else if ( response?.data?.responseType === "200" &&
    response?.data?.response?.Details?.is_vehicle_present === "N"){
      navigate(`/report-not-available/${vehicleNo}`);
      sessionStorage.setItem ("carData", response?.data?.response?.Details?.is_vehicle_present)
    }
    else{
      setOtpErr(true);
    }
  };

  const handleEdit = () => {
    setVisible(false);
    setOTP("");
  };

  useEffect(() => {
    setLeadId(sessionStorage.getItem("lead_id"));
    setvehicleNo(carNumber?carNumber :vehicleNumber);
    setPhone(phoneNo??"")
  }, [])
  
  return (
    <div className="kycContainer kycContainerPhase3">
      <div className="kycContent kycResponsive kycFormFixedBottom">
        <div className="kycFormTitle kycFormTitleMobile">
          <h3>Fetch car report</h3>
          <img src={Close} alt="close" onClick={closeKycPopup} />
        </div>
        <div className="kycImage">
          <h2>
            {" "}
            <span>
              Explore <b> 2 million, used </b>{" "}
            </span>{" "}
            🚘{" "}
            <span>
              <b>Car reports</b> which guarantee you peace of mind
            </span>{" "}
          </h2>
          <img src={Kyc} alt="car kyc" />
        </div>
        {!visible && (
          <div className={visible ? "kycForm " : "kycForm active"}>
            <div className="kycFormTitle kycFormTitleDesk">
              <h3>Fetch car report</h3>
              <img src={Close} alt="close" onClick={()=>closeKycPopup(carNumber)} />
            </div>
            <div className="kycFormContent">
              <div className="kycInputGroup">
                <span className="inputLabel inputLabelPhone">Phone number</span>
                <div className="rr-input-container">
                  <span className="countryCode fetch-countryCode">+91</span>
                  <div className="line"></div>
                  <input
                    placeholder="ex. 9123456780"
                    className="rr-input padding-0 fetch-rr-input"
                    type="text"
                    value={phone}
                    onChange={handleInputChange}
                  />
                </div>
                {phoneErr && (
                  <p className="error active mobile-error">Invalid phone number</p>
                )}
              </div>
              <a onClick={sendOTP}>
                <button className="cta-button">
                  Get Otp <img src={RightArrow} alt="Arrow" />
                </button>
              </a>
            </div>
          </div>
        )}
        {visible && (
          <div className={visible ? "kycForm otp active" : "kycForm otp "}>
            <div className="kycFormTitle kycFormTitleDesk">
              <h3>OTP Verification</h3>
              <img src={Close} onClick={closeKycPopup} alt="close" />
            </div>
            <div className="kycFormContent">
              <div className="kycInputGroup">
                <p className="inputLabel">
                  We've sent a 4-digital code to your phone{" "}
                  <strong>{phone}</strong>{" "}
                  <span className="cursor-pointer" onClick={handleEdit}>
                    Edit
                  </span>
                </p>
                <OTPInput
                  className="otpInput kycFormOtp"
                  value={OTP}
                  onChange={handleInputChange}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                />
              <div className="">
              <div className="otpErrorContainer">
              {
                otpErr && <p className="error otpError active"> The OTP entered is invalid/incorrect. Please try again.</p>
              }
              </div>
                
                <p className="resend">
                  You didn't recieved OTP?{" "}
                  <ResendOTP
                    className="resendBtn "
                    onResendClick={() => {
                      sendOTP();
                    }}
                  />{" "}
                </p>
              </div>
              </div>
              <div className="mbt20">
              <button className="cta-button" onClick={() => verifyOtp()}>
                Get report <img src={RightArrow} alt="Arrow" />
              </button>
              </div>
             
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default KycPopup;