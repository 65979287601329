import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Lock from "../../assets/images/reports/lock.svg";
import ArrowBlue from "../../assets/images/home/rightArrowGrediant.svg";
import Engine1 from "../../assets/images/reports/engineImages1.png";
import Engine2 from "../../assets/images/reports/Engine2.png";
import play from "../../assets/images/reports/play_circle.png";
import ImagePopup from "../../components/popups/imagePopup";
import VideoPopup from "../../components/popups/videoPopup";
import { getCarImageDetails } from "../../services/getInspectionReport";
import { makeLabel } from "../../utils/commonFunctions";
import { ReportDataApi } from "../../Api/Apis";
import { useParams } from "react-router-dom";

const CarImages = ({ image, video }) => {
  const data = useParams();
  const [imagePopup, setImagePopup] = useState(false);
  const [videoPop, setVideoPop] = useState(false);
  const vehicleID = sessionStorage.getItem("vehicleId");
  const [carData, setCarData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const playIconColor = "#ff0000"; // Replace with the desired color

  // const convertData = (resData) => {
  //   const temp = [];
  //   reportData.map((report) => {
  //     for (let item in resData) {
  //       if (resData?.[item]?.[0]?.module_id === report?.module_id) {
  //         let payload = null;
  //         let images = [];
  //         if (item === "EngineVideos" || item === "TransmissionVideos") {
  //           resData[item].map((key) => {
  //             images.push({ videoUrl: key?.video && key?.video, image: item === "EngineVideos" ? Engine1 : Engine2 });
  //           });
  //         } else {
  //           resData[item].map((key) => {
  //             images.push({ image: key?.image !== "" && key?.image });
  //           });
  //         }

  //         if (item === "EngineVideos" || item === "TransmissionVideos") {
  //           payload = {
  //             carTitle: report.module_name,
  //             image: item === "EngineVideos" ? Engine1 : Engine2,
  //             type: "video",
  //             smallImages: images,
  //             price: report.price,
  //             unlocked: report.is_unlocked,
  //             module_id: report.module_id,
  //           };
  //           temp.push(payload);
  //         } else {
  //           payload = {
  //             carTitle: report.module_name,
  //             image: resData[item][0]?.image,
  //             type: "image",
  //             price: report.price,
  //             unlocked: report.is_unlocked,
  //             module_id: report.module_id,
  //             smallImages: images,
  //           };
  //           temp.push(payload);
  //         }
  //       }
  //     }
  //   });

  //   setCarData(temp);
  // };

  // const getAllData = async () => {
  //   const response = await getCarImageDetails({
  //     vehicleId: vehicleID,
  //     moduleId: "",
  //     date: "",
  //   });
  //   convertData(response.data?.response);
  // };

  // useEffect(() => {
  //   getAllData();
  // }, [reportData]);

  const handlePopup = (cardDetails, ind) => {
    setSelectedData(cardDetails.smallImages);
    if (cardDetails.type === "image" && cardDetails.unlocked === "Y") {
      if (cardDetails?.smallImages[0].image !== undefined) {
        setImagePopup(true);
        setVideoPop(false);
      } else {
        setImagePopup(false);
        setVideoPop(false);
      }
    } else if (cardDetails.type === "video" && cardDetails.unlocked === "Y") {
      setImagePopup(false);
      setVideoPop(true);
    }
  };

  const fixBackground = () => {
    document.body.style.overflow = imagePopup ? "auto" : "hidden";
  };

  return (
    <>
      <section className="carImages phaseTwo" style={{ paddingTop: "8px" }}>
        <div className="container">
          <div className="newCommonHeading">
            <h2>Car Images & Videos</h2>
          </div>
          <div className="carImagesContainer">
            <div style={{ margin: "0" }} class="carImagesCard">
              <h3>{data.moduleId === "1" ? "Engine Image" : data.moduleId === "2" ? "Transmission Image" : ""}</h3>
              <div
                className={
                  "undefined" === "undefined" ? "main-Image play-video-icon-container" : " play-video-icon-container"
                }
                onClick={() => {
                  setImagePopup(true);
                  fixBackground();
                }}
              >
                {image && image[0] && <img src={image[0].inspection_url} alt="" />}
              </div>
              <div className="carImagesSmall">
                {image && image[1] && (
                  <img
                    src={image[1].inspection_url}
                    alt=""
                    onClick={() => {
                      setImagePopup(true);
                      fixBackground();
                    }}
                  />
                )}
                {image && image.length > 2 && (
                  <div>
                    {image && image[1] && <img src={image[1].inspection_url} alt="" />}
                    <p
                      onClick={() => {
                        setImagePopup(true);
                        fixBackground();
                      }}
                    >
                      {image && image.length - 2}+ <br /> {"Images More"}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div style={{ marginTop: "" }} class="carImagesCard">
              <h3>{data.moduleId === "1" ? "Engine Video" : data.moduleId === "2" ? "Transmission Video" : ""}</h3>

              <div
                className={
                  "undefined" === "undefined" ? "main-Image play-video-icon-container" : " play-video-icon-container"
                }
                onClick={() => {
                  setVideoPop(true);
                  fixBackground();
                }}
              >
                <div>
                  {video && video[0] && (
                    <video width="100%" height="80%" src={video[0].inspection_url}>
                      <source type="video/mp4" />
                    </video>
                  )}
                </div>
                <img className="play-video-icon" src={play} alt="" />
              </div>

              {/* {true && ( */}
              <div className="carImagesSmall">
                <div
                  onClick={() => {
                    setVideoPop(true);
                    fixBackground();
                  }}
                >
                  {video && video[1] && (
                    <video
                      width="100%"
                      // src={video[1].inspection_url}
                      height="100%"
                      style={{ objectFit: "cover", borderRadius: "8px" }}
                    >
                      <source type="video/mp4" src={video[1].inspection_url} />
                    </video>
                  )}
                </div>
                {video && video.length > 2 && (
                  <div>
                    {video && video[1] && (
                      <video
                        width="100%"
                        src={video[1].inspection_url}
                        height="100%"
                        style={{ objectFit: "cover", borderRadius: "8px" }}
                      >
                        <source type="video/mp4" />
                      </video>
                    )}
                    <p
                      onClick={() => {
                        setVideoPop(true);
                        fixBackground();
                      }}
                    >
                      {video && video.length}+ <br /> {"Videos More"}
                    </p>
                  </div>
                )}
              </div>
              {/* )} */}
            </div>
            {/* <div style={{ margin: "0" }} class="carImagesCard">
              <h3>{makeLabel("Exterior Vehicle Image")}</h3>
              <div
                className={
                  "undefined" === "undefined" ? "main-Image play-video-icon-container" : " play-video-icon-container"
                }
              >
                <img src="https://image.made-in-china.com/318f0j00GQvUkHhEVLzd/video.webp" alt="" />
                {("Engine Videos" === "Engine Videos" || "Transmission Videos" === "Transmission Videos") && (
                  <img className="play-video-icon" src={play} alt="" />
                )}
                {true && (
                  <Link to="/payment" className="unlockReport">
                    <img src={Lock} alt="" />
                    <p>Unlock for ₹{"20,000"}</p>
                    <img src={ArrowBlue} alt="" />
                  </Link>
                )}
              </div>
              {true && (
                <div className="carImagesSmall">
                  <div>
                    <img src="https://image.made-in-china.com/318f0j00GQvUkHhEVLzd/video.webp" alt="engine" />
                  </div>
                </div>
              )}
            </div> */}
            {/* <div style={{ margin: "0" }} class="carImagesCard">
              <h3>{makeLabel("Engine Videos")}</h3>
              <div
                className={
                  "undefined" === "undefined" ? "main-Image play-video-icon-container" : " play-video-icon-container"
                }
              >
                <img
                  src="https://images.hindustantimes.com/auto/img/2021/03/30/1600x900/Continental_GT_Speed_-_1_1617087166811_1617087591230.jpg"
                  alt=""
                />
                {("Engine Videos" === "Engine Videos" || "Transmission Videos" === "Transmission Videos") && (
                  <img className="play-video-icon" src={play} alt="" />
                )}
                {true && (
                  <Link to="/payment" className="unlockReport">
                    <img src={Lock} alt="" />
                    <p>Unlock for ₹{"20,000"}</p>
                    <img src={ArrowBlue} alt="" />
                  </Link>
                )}
              </div>
              {true && (
                <div className="carImagesSmall">
                  <div>
                    <img
                      src="https://images.hindustantimes.com/auto/img/2021/03/30/1600x900/Continental_GT_Speed_-_1_1617087166811_1617087591230.jpg"
                      alt="engine"
                    />
                  </div>
                </div>
              )}
            </div> */}
            {/* <div style={{ margin: "0" }} class="carImagesCard">
              <h3>{makeLabel("Transmission Videos")}</h3>
              <div
                className={
                  "undefined" === "undefined" ? "main-Image play-video-icon-container" : " play-video-icon-container"
                }
              >
                <img src="https://www.india.com/wp-content/uploads/2013/12/Fortuner-tysoncornercars.jpg" alt="" />
                {("Engine Videos" === "Engine Videos" || "Transmission Videos" === "Transmission Videos") && (
                  <img className="play-video-icon" src={play} alt="" />
                )}
                {true && (
                  <Link to="/payment" className="unlockReport">
                    <img src={Lock} alt="" />
                    <p>Unlock for ₹{"20,000"}</p>
                    <img src={ArrowBlue} alt="" />
                  </Link>
                )}
              </div>
              {true && (
                <div className="carImagesSmall">
                  <div>
                    <img
                      src="https://www.india.com/wp-content/uploads/2013/12/Fortuner-tysoncornercars.jpg"
                      alt="engine"
                    />
                  </div>
                </div>
              )}
            </div> */}

            <div style={{ margin: "0" }} class="carImagesCard"></div>
          </div>
        </div>
      </section>
      {imagePopup ? <ImagePopup setImagePopup={setImagePopup} selectedData={image} /> : ""}
      {videoPop ? <VideoPopup setVideoPop={setVideoPop} selectedData={video} /> : ""}
    </>
  );
};

export default CarImages;
