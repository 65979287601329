import React from "react";
import { Link } from "react-router-dom";
import ServiceLogos from "../../assets/images/home/serviceLogos.png";
import ServiceMap from "../../assets/images/home/serviceMap.png";
import Arrow from "../../assets/images/home/orangeArrow.svg";

function Service() {
  return (
    <section className="service">
      <div className="container">
        <div className="commonHeading">
          <h2>
            We partner with <span>OEM authorised service centres</span> to provide you the <span>best service</span>
          </h2>
        </div>
        <div className="serviceContent">
          <div className="serviceContentCard">
            <img src={ServiceLogos} alt="Logos" className="serviceContentImage" />
            <h3>Maintenance & Service partners</h3>
            <p>
              Get <b>Free Maintenance & service</b> at authorised service centers.
            </p>
            <Link to="/service-network">
              Explore Service Partners <img src={Arrow} alt="Arrow" />
            </Link>
          </div>

          <div className="serviceContentCard">
            <img src={ServiceMap} alt="Logos" className="serviceContentImage" />
            <h3>Roadside assistance partners</h3>
            <p>
              <b>Free Dedicated roadside assistance</b> support team to take care of emergency needs 24/7.
            </p>
            <Link to="/service-network">
              Explore Roadside Assistance <img src={Arrow} alt="Arrow" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;
