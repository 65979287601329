import React from "react";
import Blog1 from "../../assets/images/blog/warrantyCarblog1.png";
import Blog2 from "../../assets/images/blog/blogs2.png";
import Blog3 from "../../assets/images/blog/blogs3.png";
import Blog4 from "../../assets/images/blog/blogs4.png";
import Blog5 from "../../assets/images/blog/blogs5.png";
import Blog6 from "../../assets/images/blog/blogs6.png";
import Arrow from "../../assets/images/blog/arrow.svg";
import { Link } from "react-router-dom";

function Discover() {
  return (
    <section className="blogSection">
      <div className="container">
        <div className="commonHeading">
          <h2>
            <span>Discover more</span> blogs
          </h2>
        </div>
        <div className="discoverCards">
          <Link to="extended-warranty-on-a-car">
            <div className="blogCard">
              <div className="blogCardImg">
                <img src={Blog1} alt="Blog" style={{ borderRadius: "6px" }} />
              </div>
              {/* <label>MAY 28, 2021</label> */}
              <h3>What is an extended warranty on a car?</h3>
              <p>
                An extended warranty on a car is an optional agreement that provides additional coverage beyond the
                standard factory warranty. These warranties are...
              </p>
              <Link to="extended-warranty-on-a-car">
                Read Now
                <img src={Arrow} alt="Car" />
              </Link>
            </div>
          </Link>
          <Link to="">
            <div className="blogCard">
              <div className="blogCardImg">
                <img src={Blog2} alt="Blog" />
              </div>
              {/* <label>MAY 28, 2021</label> */}
              <h3>Roadside Assistance: What is It?</h3>
              <p>
                Did you know that both car warranty and car insurance are equally important? When you purchase a car,
                you get both – a ...
              </p>
              <Link to="">
                Read Now
                <img src={Arrow} alt="Car" />
              </Link>
            </div>
          </Link>
          <Link to="">
            <div className="blogCard">
              <div className="blogCardImg">
                <img src={Blog3} alt="Blog" />
              </div>
              {/* <label>MAY 28, 2021</label> */}
              <h3>How to Check Used Bike Price?</h3>
              <p>
                Did you know that both car warranty and car insurance are equally important? When you purchase a car,
                you get both – a ...
              </p>
              <Link to="">
                Read Now
                <img src={Arrow} alt="Car" />
              </Link>
            </div>
          </Link>
        </div>
        <div className="discoverCards last">
          <Link to="">
            <div className="blogCard">
              <div className="blogCardImg">
                <img src={Blog4} alt="Blog" />
              </div>
              {/* <label>MAY 28, 2021</label> */}
              <h3>How to Check Used Bike Price?</h3>
              <p>
                Did you know that both car warranty and car insurance are equally important? When you purchase a car,
                you get both – a ...
              </p>
              <Link to="">
                Read Now
                <img src={Arrow} alt="Car" />
              </Link>
            </div>
          </Link>
          <Link to="">
            <div className="blogCard">
              <div className="blogCardImg">
                <img src={Blog5} alt="Blog" />
              </div>
              {/* <label>MAY 28, 2021</label> */}
              <h3>What are the Documents Required for Driving Two Wheeler?</h3>
              <p>
                Did you know that both car warranty and car insurance are equally important? When you purchase a car,
                you get both – a ...
              </p>
              <Link to="">
                Read Now
                <img src={Arrow} alt="Car" />
              </Link>
            </div>
          </Link>
          <Link to="">
            <div className="blogCard">
              <div className="blogCardImg">
                <img src={Blog6} alt="Blog" />
              </div>
              {/* <label>MAY 28, 2021</label> */}
              <h3>Roadside Assistance: What is It?</h3>
              <p>
                Did you know that both car warranty and car insurance are equally important? When you purchase a car,
                you get both – a ...
              </p>
              <Link to="">
                Read Now
                <img src={Arrow} alt="Car" />
              </Link>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Discover;
