import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./warranty-benefits.css";
import Banner from "./banner";
import Guarantee from "./guarantee";
import Maintain from "./maintain";
import ContactUs from "../../components/contact-us/contact-us";
import CheckUp from "./check-up";

const WarantyBenefits = () => {
  return (
    <div>
      <Header />
      <Banner />
      <Guarantee />
      <CheckUp />
      <Maintain />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default WarantyBenefits;
