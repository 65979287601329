import React, { useState } from "react";
import Arrow from "../../assets/images/pricing-plans/downArrow.svg";
import "./faq.css";

function Faq() {
  const [active, setActive] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [toggleTwo, setToggleTwo] = useState(false);
  const [toggleThree, setToggleThree] = useState(false);
  const [toggleFour, setToggleFour] = useState(false);
  return (
    <section className="faq">
      <div className="container">
        <div className="commonHeading">
          <h2>
            Frequently Asked <span>Questions</span>
          </h2>
        </div>
        <div className="faqContainer">
          <div
            className={
              active === 0 && toggle ? "faqAccordion active" : "faqAccordion"
            }
            onClick={() => {
              setToggle(!toggle);
              setToggleTwo(false);
              setToggleThree(false);
              setToggleFour(false);
              setActive(0);
            }}
          >
            <div className="faqAccordionQuestion">
              <p>What is Extended Warranty?</p>
              <img src={Arrow} alt="Arrow" />
            </div>
            <div className="faqAccordionAnswer">
              <p>
                Odds are when you bought your car, it came with a warranty
                guarantee, either from the manufacturer or from the dealer, that
                your car would stay in good working condition for a certain
                number of miles or a certain amount of time. Unfortunately, most
                big-ticket repairs and breakdowns happen after the
                manufacturer's warranty has run out. That's where an extended
                auto warranty comes in. An extended auto warranty is just what
                it sounds like: an extension above and beyond the coverage
                offered by your manufacturer or dealer. By putting a good
                warranty plan in place, you can protect yourself from the burden
                of high repair bills.
              </p>
            </div>
          </div>

          <div
            className={
              active === 1 && toggleTwo ? "faqAccordion active" : "faqAccordion"
            }
            onClick={() => {
              setToggleTwo(!toggleTwo);
              setToggle(false);
              setToggleThree(false);
              setToggleFour(false);
              setActive(1);
            }}
          >
            <div className="faqAccordionQuestion">
              <p>Why Do I Need Extended Warranty?</p>
              <img src={Arrow} alt="Arrow" />
            </div>
            <div className="faqAccordionAnswer">
              <p>
                Here's the sad but honest truth: even if you have the
                best-manufactured, most reliable car on the market, someday it's
                going to break down. Modern cars are fantastically complicated.
                And even a relatively minor defect, or a mechanical breakdown
                can cost a fortune to repair. By buying a solid,
                insurance-company-backed warranty, you protect the investment
                you made in your vehicle. When something goes wrong, you simply
                pay the low deductible and let us handle the rest. In many
                cases, an extended auto warranty can pay for itself in one
                repair. And just having a quality, transferable warranty in
                place can raise the resale value of your car.
              </p>
            </div>
          </div>

          <div
            className={
              active === 2 && toggleThree
                ? "faqAccordion active"
                : "faqAccordion"
            }
            onClick={() => {
              setToggleThree(!toggleThree);
              setToggle(false);
              setToggleTwo(false);
              setToggleFour(false);
              setActive(2);
            }}
          >
            <div className="faqAccordionQuestion">
              <p>Which Vehicles does Wisedrive Warranty Cover?</p>
              <img src={Arrow} alt="Arrow" />
            </div>
            <div className="faqAccordionAnswer">
              <p>
                We cover most vehicles up to 20 model years and up to 200,000
                miles. It's easy to find the right extended auto warranty plan
                for your make, model and budget. Our trained staff is
                knowledgeable and friendly. They'll make sure you find just what
                you need.
              </p>
            </div>
          </div>

          <div
            className={
              active === 3 && toggleFour
                ? "faqAccordion active"
                : "faqAccordion"
            }
            onClick={() => {
              setToggleFour(!toggleFour);
              setToggle(false);
              setToggleTwo(false);
              setToggleThree(false);
              setActive(3);
            }}
          >
            <div className="faqAccordionQuestion">
              <p>Can I Cancel My Warranty</p>
              <img src={Arrow} alt="Arrow" />
            </div>
            <div className="faqAccordionAnswer">
              <p>
                At Wisedrive we believe both in our products, and in the wisdom
                of purchasing an extended auto warranty. That's why car extended
                auto warranty plan we sell comes with a full 30-day
                unconditional money-back guarantee. Our return policy is simple.
                We consider your first 30 days with a new extended auto warranty
                a "review period." Take your time, read through your policy and
                discuss it with your spouse and family. We're confident that you
                like so many other Indian's will be happy and satisfied with
                your extended auto warranty. But if you decide for any reason
                that this isn't the extended auto warranty for you, simply call
                our customer service line. As long as you haven't used your
                policy during those 30 days, we'll be happy to refund your money
                and cancel your extended warranty.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
