import React, { useEffect } from "react";
import Footer from "../../components/footer";
import ReportBanner from "./banner";
import DreamCar from "./dream-car";
import HealthReports from "./health-reports";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./report.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ReactSpeedometer from "react-d3-speedometer";
import ReportHeader from "../../components/header/ReportHeader";
import { VehicleDetailsApi } from "../../Api/Apis";

const Report = () => {
  const data = useParams();
  const [vehicleDetails, setVehicleDetails] = useState({});

  const getVehicllDetails = async () => {
    try {
      const resp = await VehicleDetailsApi(
        data.vehicleId === "nodata" ? "" : data.vehicleId,
        data.leadvehicleId === "nodata" ? "" : data.leadvehicleId,
        data.insprequvehiId === "nodata" ? "" : data.insprequvehiId,
      );
      if (resp.status === 200) {
        setVehicleDetails(resp.data.response.VehicleDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "auto";
    getVehicllDetails();
  }, []);

  return (
    // !loader && (
    <>
      <ReportHeader />
      <ReportBanner vehicleDetails={vehicleDetails} />
      {vehicleDetails && vehicleDetails.rating === null ? (
        ""
      ) : (
        <div className="container">
          <h2 style={{ marginBottom: "-12px", color: "#262c6c", marginTop: "16px" }}>Summary</h2>
          <br />
          <div className="summaryDetails">
            <div className="summaryHeading" style={{ marginTop: "14px" }}>
              <div className="engineImage">
                <img
                  src="https://ab-prod-container.s3.us-east-2.amazonaws.com/CustomerApp/engine.png"
                  alt="engine"
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "8px" }}
                />
              </div>
              <div className="summaryText">
                <h3>Engine Approval rating</h3>
                <p>Approval summary is based wisedrive technical evaluation </p>
              </div>
            </div>
            <div className="summaryImage" style={{ marginBottom: "-38px" }}>
              <ReactSpeedometer
                value={vehicleDetails.rating}
                maxSegmentLabels={5}
                segments={10}
                minValue={0}
                maxValue={10}
                height={160}
                valueTextFontSize="30px"
                width={220}
                ringWidth={35}
                paddingVertical={22}
                needleHeightRatio={0.8}
                textColor="#262c6e"
                valueTextFontWeight="900"
              />
            </div>
          </div>
        </div>
      )}

      <HealthReports black_list={vehicleDetails.blacklistStatus || "nodata"} />
      <DreamCar />
      {/* <Deals /> */}
      <Footer />
      {/* <div className="totalAmount mobile" style={{ background: "#FF7F50" }}>
          <div>
            <h4>hello</h4>
          </div>
          <div>
            <button>
              Proceed <img src={Arrow} alt="" />
            </button>
          </div>
        </div> */}
    </>
  );
  // );
};

export default Report;
