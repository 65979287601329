import React, { useState, useEffect, useContext } from "react";
import Activate from "../../assets/images/home/activate.png";
import DownloadApp from "../../assets/images/home/downloadApp.png";
import EnterCode from "../../assets/images/home/enterCode.png";
import { MyContext } from "../../context";
import BookService from "../../assets/images/partnerLocation/freeDropCar.svg";
import InspectionPlanModalWiseAssist from "../../components/popups/InspectionPlanModalWiseAssist";

function WarrantyFacebookLandingPage({ pageData }) {
  const { count, setCount } = useContext(MyContext);
  const { index, setIndex } = useContext(MyContext);
  const { heightAct, setHeightAct } = useContext(MyContext);
  const [timer, setTimer] = useState(0);
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };

  useEffect(() => {
    if (heightAct === true) {
      let interval;
      if (index === 0 || index === 1 || index === 2) {
        setCount(0);
      }
      interval = setInterval(() => {
        setCount((co) => co + 1);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [timer, heightAct]);

  useEffect(() => {
    if (heightAct === true) {
      setCount(0);
      if (count === 1 || count === 2) {
        setIndex(index + 1);
      }
      if (index === 3) {
        setIndex(0);
      }
    }
  }, [count, heightAct]);

  function handleClick(i) {
    setIndex(i);
    setTimer(timer + 1);
    var progress = document.getElementsByClassName("progress");
    if (progress[0].classList === "warrantyProgressBar progress") {
      progress[0].classList.remove("warrantyProgressBar");
      progress[0].classList.add("warrantyProgressBar");
    }
  }
  return (
    <section className="warranty" id="warrantysection">
      <div className="container">
        <div className="commonHeading">
          <h2>
            Buy with trust, <span> Drive with confidence</span>
          </h2>
        </div>
        <h4 className="bookingService">
          <img src={BookService} alt="bookService" />
          Remove the stress when buying a used car
        </h4>
        <div className="warrantyDetails">
          <div className="warrantyContent">
            <div
              className={index === 0 ? "warrantyAccordion active" : "warrantyAccordion"}
              onClick={() => {
                handleClick(0);
              }}
            >
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="url(#paint0_linear_749_7069)" />
                <g clipPath="url(#clip0_749_7069)">
                  <path
                    d="M16 18.6667C15.8633 18.6667 15.7326 18.6107 15.638 18.512L12.138 14.8453C11.8346 14.5273 12.062 14 12.5 14H14.3333V13.1667C14.3333 10.318 16.6513 8 19.5 8C19.776 8 20 8.224 20 8.5C20 8.776 19.776 9 19.5 9C18.4886 9 17.6666 9.822 17.6666 10.8333V14H19.5C19.938 14 20.1653 14.5273 19.862 14.8453L16.362 18.512C16.2673 18.6107 16.1366 18.6667 16 18.6667Z"
                    fill="#353D9C"
                  />
                  <path
                    d="M22.1111 20H10.7778C9.7364 20 8.88892 20.6979 8.88892 21.5556C8.88892 22.4132 9.7364 23.1111 10.7778 23.1111H22.1111C23.1525 23.1111 24 22.4132 24 21.5556C24 20.6979 23.1525 20 22.1111 20Z"
                    fill="#353D9C"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_749_7069"
                    x1="4.5"
                    y1="-1.09945e-06"
                    x2="28.4939"
                    y2="24.0089"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#D3E0FF" />
                    <stop offset="1" stopColor="#D3E0FF" stopOpacity="0.21" />
                  </linearGradient>
                  <clipPath id="clip0_749_7069">
                    <rect width="16" height="16" fill="white" transform="translate(8 8)" />
                  </clipPath>
                </defs>
              </svg>

              <div className="warrantyAccordionQuestion">
                <p>Subscribe to WiseAssist</p>
              </div>
              <div className="warrantyAccordionAnswer">
                <p>Unlimited access to the best cars available and pre-approved for Wisedrive warranty</p>
                <img src={Activate} alt="text message" />
              </div>
              <div className="" id="progress10">
                <div className={index === 0 ? "warrantyProgressBar progress" : "progress"}></div>
              </div>
            </div>

            <div
              className={index === 1 ? "warrantyAccordion active" : "warrantyAccordion"}
              onClick={() => {
                handleClick(1);
              }}
            >
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="url(#paint0_linear_1453_6644)" />
                <path
                  d="M20.3333 10.4792V10.125C20.3333 8.95625 19.3771 8 18.2083 8H11.125C9.95625 8 9 8.95625 9 10.125V22.875C9 24.0437 9.95625 25 11.125 25H18.2083C19.3771 25 20.3333 24.0437 20.3333 22.875V20.1267C20.0783 20.2967 19.7667 20.3958 19.4479 20.3958C19.2638 20.3958 19.0867 20.3675 18.9167 20.3038V22.875C18.9167 23.2646 18.5979 23.5833 18.2083 23.5833H11.125C10.7354 23.5833 10.4167 23.2646 10.4167 22.875V10.125C10.4167 9.73542 10.7354 9.41667 11.125 9.41667H11.4792C11.8688 9.41667 12.1875 9.73542 12.1875 10.125C12.1875 10.5146 12.5063 10.8333 12.8958 10.8333H16.4375C16.8271 10.8333 17.1458 10.5146 17.1458 10.125C17.1458 9.73542 17.4646 9.41667 17.8542 9.41667H18.2083C18.5979 9.41667 18.9167 9.73542 18.9167 10.125V10.4792H20.3333Z"
                  fill="#353D9C"
                />
                <path
                  d="M24.0519 11.5417H18.0311C17.3475 11.5417 16.7915 12.0978 16.7915 12.7813V15.9688C16.7915 16.6524 17.3475 17.2084 18.0311 17.2084H18.9165V18.8022C18.9165 19.021 19.0511 19.218 19.2551 19.2973C19.3174 19.3214 19.3833 19.3334 19.4478 19.3334C19.5937 19.3334 19.7368 19.2732 19.8395 19.1613L21.6294 17.2084H24.0519C24.7355 17.2084 25.2915 16.6524 25.2915 15.9688V12.7813C25.2915 12.0978 24.7355 11.5417 24.0519 11.5417ZM19.2707 14.9063C18.9774 14.9063 18.7394 14.6683 18.7394 14.3751C18.7394 14.0818 18.9774 13.8438 19.2707 13.8438C19.5639 13.8438 19.8019 14.0818 19.8019 14.3751C19.8019 14.6683 19.5639 14.9063 19.2707 14.9063ZM21.0415 14.9063C20.7483 14.9063 20.5103 14.6683 20.5103 14.3751C20.5103 14.0818 20.7483 13.8438 21.0415 13.8438C21.3348 13.8438 21.5728 14.0818 21.5728 14.3751C21.5728 14.6683 21.3348 14.9063 21.0415 14.9063ZM22.8123 14.9063C22.5191 14.9063 22.2811 14.6683 22.2811 14.3751C22.2811 14.0818 22.5191 13.8438 22.8123 13.8438C23.1056 13.8438 23.3436 14.0818 23.3436 14.3751C23.3436 14.6683 23.1056 14.9063 22.8123 14.9063Z"
                  fill="#353D9C"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1453_6644"
                    x1="4.5"
                    y1="-1.09945e-06"
                    x2="28.4939"
                    y2="24.0089"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#D3E0FF" />
                    <stop offset="1" stopColor="#D3E0FF" stopOpacity="0.21" />
                  </linearGradient>
                </defs>
              </svg>

              <div className="warrantyAccordionQuestion">
                <p>Buy the best car</p>
              </div>
              <div className="warrantyAccordionAnswer">
                <p>Shortlist and buy your dream car, worry-free and stress-free</p>
                <img src={DownloadApp} alt="text message" />
              </div>
              <div className="warrantyProgress">
                <div className={index === 1 ? "warrantyProgressBar progress" : "progress"}></div>
              </div>
            </div>

            <div
              className={index === 2 ? "warrantyAccordion active" : "warrantyAccordion"}
              onClick={() => {
                handleClick(2);
              }}
            >
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="url(#paint0_linear_749_7101)" />
                <path
                  d="M21.25 9H10.75C9.78487 9 9 9.78487 9 10.75V18.625C9 19.5901 9.78487 20.375 10.75 20.375H11.625V22.5625C11.625 22.7323 11.723 22.8871 11.877 22.9589C11.9356 22.986 11.9995 23 12.0625 23C12.1631 23 12.2629 22.965 12.3425 22.8985L15.3709 20.375H21.25C22.2151 20.375 23 19.5901 23 18.625V10.75C23 9.78487 22.2151 9 21.25 9ZM16.4375 16H12.9375C12.696 16 12.5 15.804 12.5 15.5625C12.5 15.321 12.696 15.125 12.9375 15.125H16.4375C16.679 15.125 16.875 15.321 16.875 15.5625C16.875 15.804 16.679 16 16.4375 16ZM19.0625 13.375H12.9375C12.696 13.375 12.5 13.179 12.5 12.9375C12.5 12.696 12.696 12.5 12.9375 12.5H19.0625C19.304 12.5 19.5 12.696 19.5 12.9375C19.5 13.179 19.304 13.375 19.0625 13.375Z"
                  fill="#353D9C"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_749_7101"
                    x1="4.5"
                    y1="-1.09945e-06"
                    x2="28.4939"
                    y2="24.0089"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#D3E0FF" />
                    <stop offset="1" stopColor="#D3E0FF" stopOpacity="0.21" />
                  </linearGradient>
                </defs>
              </svg>

              <div className="warrantyAccordionQuestion">
                <p>Activate Wisedrive warranty</p>
              </div>
              <div className="warrantyAccordionAnswer">
                <p>Enter the Activation code in app then sit back and relax.</p>
                <img src={EnterCode} alt="text message" />
              </div>
              <div className="warrantyProgress">
                <div className={index === 2 ? "warrantyProgressBar progress" : "progress"}></div>
              </div>
            </div>

            {/* <div
              className={index === 3 ? "warrantyAccordion active" : "warrantyAccordion"}
              onClick={() => {
                handleClick(3);
              }}
            >
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="url(#paint0_linear_749_7114)" />
                <g clipPath="url(#clip0_749_7114)">
                  <path d="M10.0208 22.4167H11.4792C12.0421 22.4167 12.5 21.9588 12.5 21.3959V14.6876C12.5 14.1247 12.0421 13.6667 11.4792 13.6667H10.0208C9.45792 13.6667 9 14.1247 9 14.6876V21.3959C9 21.9588 9.45792 22.4167 10.0208 22.4167Z" fill="#353D9C" />
                  <path d="M20.014 22.5625C21.1515 22.5625 22.1198 21.7458 22.3123 20.6258L22.9656 16.8342C23.2106 15.405 22.114 14.1042 20.6673 14.1042H17.914C17.914 14.1042 18.3515 13.2292 18.3515 11.7708C18.3515 10.0208 17.039 9.4375 16.4557 9.4375C15.8723 9.4375 15.5807 9.72917 15.5807 11.1875C15.5807 12.5735 14.2384 13.6888 13.3751 14.2634V21.4898C14.309 21.922 16.1786 22.5625 19.0807 22.5625H20.014Z" fill="#353D9C" />
                </g>
                <defs>
                  <linearGradient id="paint0_linear_749_7114" x1="4.5" y1="-1.09945e-06" x2="28.4939" y2="24.0089" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D3E0FF" />
                    <stop offset="1" stopColor="#D3E0FF" stopOpacity="0.21" />
                  </linearGradient>
                  <clipPath id="clip0_749_7114">
                    <rect width="14" height="14" fill="white" transform="translate(23 23) rotate(-180)" />
                  </clipPath>
                </defs>
              </svg>

              <div className="warrantyAccordionQuestion">
                <p>Fetch Complete Car Report</p>
              </div>
              <div className="warrantyAccordionAnswer">
                <p>Explore the detailed report of engine, transmission, OBD, Traffic fines, Blacklist & Service histroy</p>
                <img src={Relax} alt="text message" />
              </div>
              <div className="warrantyProgress">
                <div className={index === 3 ? "warrantyProgressBar progress" : "progress"}></div>
              </div>
            </div> */}
          </div>

          <div className={index === 0 ? "warrantyDetailsImage active" : "warrantyDetailsImage"}>
            <img src={Activate} alt="text message" />
          </div>
          <div className={index === 1 ? "warrantyDetailsImage active" : "warrantyDetailsImage"}>
            <img src={DownloadApp} alt="text message" />
          </div>
          <div className={index === 2 ? "warrantyDetailsImage active" : "warrantyDetailsImage"}>
            <img src={EnterCode} alt="text message" />
          </div>
          {/* <div className={index === 3 ? "warrantyDetailsImage active" : "warrantyDetailsImage"}>
            <img src={Relax} alt="text message" />
          </div> */}
        </div>
        <button
          style={{
            backgroundColor: "rgb(235,109,71)",
            color: "white",
            borderRadius: "10px",
            border: "none",
            padding: "10px 20px",
            fontWeight: "600",
            width: "300px",
            height: "52px",
            letterSpacing: -"-0.1",
            lineHeight: "21px",
            fontSize: "14px",
            marginTop: "20px",
            marginLeft: "5px",
            cursor: "pointer",
            marginBottom: "30px",
          }}
          onClick={toggleModal}
        >
          {/* Just{" "} */}
          <span
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              fontWeight: "700",
              fontSize: "28px",
              color: "#fff",
            }}
          >
            Rs 749/-{" "}
          </span>
          <span
            style={{
              textDecoration: "line-through",
              fontWeight: "400",
              fontSize: "25px",
              color: "#fff",
            }}
          >
            Rs 999/-{" "}
          </span>
        </button>
      </div>
      <InspectionPlanModalWiseAssist isOpen={isModalOpen} toggler={toggleModal} pageData={pageData} />
    </section>
  );
}

export default WarrantyFacebookLandingPage;
