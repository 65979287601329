import React, { useState } from "react";
import "./engineProtection.css";
import engineView from "../../assets/images/engineProtection/engineView.png";
import expandMore from "../../assets/images/engineProtection/expandMore.svg";
import engineView2 from "../../assets/images/engineProtection/engineView2.png";
import engineView3 from "../../assets/images/engineProtection/engineView3.png";
import engineView4 from "../../assets/images/engineProtection/engineView4.png";
import engineView5 from "../../assets/images/engineProtection/engineView5.png";
import engineView6 from "../../assets/images/engineProtection/engineView6.png";
import engineView7 from "../../assets/images/engineProtection/engineView7.png";
import Health from "../../assets/health.png";

function EngineProtectionTwo() {
  const [openContent, setOpenContent] = useState(0);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);

  const handleTabOpen = (v) => {
    setOpenContent(v);
  };
  return (
    <section className="engineProtection" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
      <div className="container">
        <div className="engineDetails">
          <div className="engineView">
            <div className="commonHeading">
              <h2 style={{ marginLeft: "2%" }}>
                <span>Starts from Rs.8999</span> for OEM Authorized service and maintenance
              </h2>
            </div>
            <div className="desktop">
              {one ? <img src={engineView} alt="engineView" /> : ""}
              {two ? <img src={engineView6} alt="engineView" /> : ""}
              {three ? <img src={Health} alt="engineView" /> : ""}
              {openContent === 3 ? <img src={engineView3} alt="engineView" /> : ""}
              {openContent === 4 ? <img src={engineView4} alt="engineView" /> : ""}
              {openContent === 5 ? <img src={engineView5} alt="engineView" /> : ""}
              {openContent === 6 ? <img src={engineView6} alt="engineView" /> : ""}
              {openContent === 7 ? <img src={engineView7} alt="engineView" /> : ""}
            </div>
          </div>

          <div className="engineAccordion">
            <div
              className="accordions"
              onClick={() => {
                setOne((prevState) => !prevState);
                setTwo(false);
                setThree(false);
              }}
            >
              <h3>
                <span>General Service</span>
                <img className={one ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {one ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Engine Oil</li>
                    <li>Oil Filter</li>
                    <li>Air Filter</li>
                    <li>Coolant</li>
                    <li>Brake Oil</li>
                    <li>Wiper Fluid</li>
                    <li>Brakes Servicing</li>
                    <li>Tyres check up</li>
                    <li>Car Wash</li>
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className="accordions"
              onClick={() => {
                setTwo((prevState) => !prevState);
                setOne(false);
                setThree(false);
              }}
            >
              <h3>
                <span>Ac Service </span>
                <img className={two ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {two ? (
                <div>
                  <p></p>
                  <ul>
                    <li>AC Gas Top up (upto 400 gm)n</li>
                    <li>AC Filter Cleaning</li>
                    <li>AC System checkup</li>
                    <li>AC Vent Cleaning</li>
                    <li>AC Duct Cleaning</li>
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView2} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className="accordions"
              onClick={() => {
                setThree((prevState) => !prevState);
                setOne(false);
                setTwo(false);
              }}
            >
              <h3>
                <span>Health Checkup</span>
                <img className={three ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {three ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Coolant (upto 500 ml)</li>
                    <li>Brake Oil (upto 100ml)</li>
                    <li>Wiper Fluid</li>
                    <li>Air Filter Cleaning</li>
                    <li>AC Filter Cleaning</li>
                    <li>Radiator Fan Check</li>
                    <li>Engine Oil Check</li>
                    <li>Tyre Pressure Check</li>
                    <li>Electrical Check</li>
                  </ul>
                  {/* <div className="mobile accordionsCompleteImg">
                    <img src={engineView2} alt="expandMore" />
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default EngineProtectionTwo;
