import React, { useState, useEffect } from "react";
import PurchasePlan from "../../components/popups/purchasePlan";
import rightArrow from "../../assets/images/home/rightArrowGrediant.svg";
import arrowBlue from "../../assets/images/home/arrowBlue.svg";
import starOrange from "../../assets/images/home/starOrange.svg";
import { Link } from "react-router-dom";
import logo1 from "../../assets/images/priceplan-logos/logo-2.png";
import logo2 from "../../assets/images/priceplan-logos/logo-1.png";
import logo3 from "../../assets/images/priceplan-logos/logo-3.png";
import useWindowDimensions from "../util-hooks/useWindowDimentions";
import styles from "./suitablePlan2.module.css";

function SuitablePlans2({ PlansContent, warrantyPlan }) {
  const [openPurchasePlanModal, setOpenPurchasePlanModal] = useState(false);
  const [active, setActive] = useState(0);
  const [chStyles, setChStyles] = useState({});
  const [plan, setPlan] = useState();
  const [tabId, setTabId] = useState();
  const [planName, setPlanName] = useState("");

  const { height, width } = useWindowDimensions();

  const toggleModal = () => {
    setOpenPurchasePlanModal((prevState) => !prevState);
    document.body.style.overflow = openPurchasePlanModal ? "auto" : "hidden";
  };

  function GetCommonHeadingStyle() {
    // if (width <= 830) {
    //   return {
    //     flexDirection: "column",
    //     gap: 25,
    //     alignItems: "flex-start",
    //   };
    // }

    if (width <= 1148) {
      return { justifyContent: "space-between", alignItems: "start", gap: 20 };
    }

    if (width <= 1600) {
      return { justifyContent: "space-between", alignItems: "start", gap: 20 };
    }
  }

  useEffect(() => {
    const style = GetCommonHeadingStyle();
    setChStyles(style);
  }, [width]);

  return (
    <section
      // id="warranty-plan"
      className={styles.suitablePlans}
      style={{ backgroundImage: "linear-gradient(105.15deg, #333c99 25.5%, #555fd7 64.88%)" }}
    >
      <div className="container">
        <div className={`commonHeading ${styles.heading1}`} style={chStyles}>
          <h2 style={{ color: "#fff", minWidth: "60%" }} className={styles.commonHeader}>
            Select the best <span style={{ color: "#eb6d47" }}>vehicle protection plan </span>for your car and guarantee{" "}
            <span style={{ color: "#eb6d47" }}>Peace of Mind</span>
          </h2>
          <div className={styles.logoswrappers}>
            <div className={styles.logobox}>
              <div className={styles.logobox3}>
                <img src={logo3} alt="made with love" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.suitablePlanContent}>
          {warrantyPlan &&
            warrantyPlan.map((each, index) => {
              return (
                <div className={styles.suitablePlanCard} style={{ border: "none" }} key={index}>
                  <div className={`${styles.suitablePlanCardHead} ${styles.userPlanCardHead}`}>
                    <h3>{each.package_type_name}</h3>
                  </div>
                  <p>Starting from</p>
                  <h4>
                    <span>₹{each.starts_from}</span>
                  </h4>
                  <a
                    onClick={() => {
                      toggleModal();
                      setPlan(each.package_type_name_id);
                      setPlanName(each.package_type_name);
                      setTabId(index);
                    }}
                    className={styles.getStarted}
                  >
                    Get Started <img src={arrowBlue} alt="arrow" />
                  </a>
                </div>
              );
            })}
        </div>

        {/* {PlansContent === "buyer" ? (
          <div className="suitablePlanContent">
            <div className="suitablePlanCard" style={{ border: "none" }}>
              <div className="suitablePlanCardHead userPlanCardHead">
                <h3>Unlock Single Car</h3>
              </div>
              <p>Starting from</p>
              <h4>
                <span>₹999</span>/Car
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>

            <div className="suitablePlanCard" style={{ border: "none" }}>
              <label className="offer">Save ₹1000</label>
              <div className="suitablePlanCardHead userPlanCardHead">
                <h3>Unlock 3 cars pack</h3>
              </div>
              <p>Starting from</p>
              <h4>
                <span>₹833</span>/Car
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>
            <div className="suitablePlanCard" style={{ border: "none" }}>
              <label className="offer">Save ₹2000</label>
              <div className="suitablePlanCardHead userPlanCardHead">
                <h3>Unlock 5 Cars Pack</h3>
                <label>
                  <img src={starOrange} alt="starOrange" />
                  Popular
                </label>
              </div>
              <p>Starting from</p>
              <h4>
                <span>₹599</span>/Car
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>
          </div>
        ) : (
          <div className="suitablePlanContent suitablePlanContentOwner">
            <div onMouseEnter={() => setActive(0)} className="suitablePlanCard">
              style={{ border: "none" }}
              <div className="suitablePlanCardHead">
                <h3>Comprehensive Warranty + Buyback Guarantee Plan</h3>
              </div>
              <p>Starting from</p>
              <h4>
                <span>₹375</span>/month
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>

            <div onMouseEnter={() => setActive(1)} className="suitablePlanCard">
              style={{ border: "none" }}
              <label className="offer">Save ₹1000</label>
              <div className="suitablePlanCardHead">
                <h3>Service and Maintenance Plan</h3>
              </div>
              <p>Starting from</p>
              <h4>
                <span>749</span>/month
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>
            <div onMouseEnter={() => setActive(2)} className="suitablePlanCard" style={{ border: "none" }}>
              <label className="offer">Save ₹2000</label>
              <div className="suitablePlanCardHead">
                <h3>Super Combo Plan</h3>
                <label>
                  <img src={starOrange} alt="starOrange" />
                  Popular
                </label>
              </div>
              <p>Starting from</p>
              <h4>
                <span>799</span>/month
              </h4>
              <a onClick={() => setOpenPurchasePlanModal(true)} className="getStarted">
                Get Started <img src={arrowBlue} alt="arrow" />
              </a>
            </div>
          </div>
        )} */}
        {/* <div className="mobile">
          <h3>
            Explore Pricing Plans <img src={arrowBlue} alt="arrowBlue" />
          </h3>
        </div> */}
      </div>

      {openPurchasePlanModal ? (
        <PurchasePlan
          PlansContent={PlansContent}
          setOpenPurchasePlanModal={setOpenPurchasePlanModal}
          active={active}
          plan={plan}
          toggleModal={toggleModal}
          planName={planName}
          tabId={tabId}
          // header={""}
        />
      ) : (
        ""
      )}
    </section>
  );
}
export default SuitablePlans2;
