import React, { createContext } from "react";
import { useState, useEffect } from "react";
import { GetAllCarsList, GetLinkListedCars } from "../Api/PortalApis";
import { maxPrice, minPrice, minKms, maxKms, minYear, maxYear, minOwners, maxOwners } from "./constants";

const initialState = {
  totalCars: 0,
  setTotalCars: () => {},
  userIp: "",
  setUserIp: () => {},
  allCarsList: [],
  setAllCarsList: () => {},
  currentPageCarList: [],
  searchPageNo: 1,
  setSearchPageNo: () => {},
  itemsPerPage: 6,

  // filters
  selectedYearList: [],
  setSelectedYearList: () => {},
  selectedTransmissionList: [],
  setSelectedTransmissionList: () => {},
  selectedFuelList: [],
  setSelectedFualList: () => {},
  selectedColors: [],
  setSelectedColors: () => {},
  selectedCategories: [],
  setSelectedCategories: () => {},
  selectedCarMakes: [],
  setSelectedCarMakes: () => {},
  selectedCarModels: [],
  setSelectedCarModels: () => {},
  priceValues: [],
  setPriceValues: () => {},
  kmsValues: [],
  setKmsValues: () => {},
  noOwners: [],
  setNoOwners: () => {},

  allFilteredData: [],

  setNonCategoryListEmpty: () => {},
  setNonMakeFilterEmpty: () => {},

  linkId: "",
  setLinkId: () => {},

  setIsFromCarByModal: () => {},

  setAllCarsList: () => {},
  setAllFilteredData: () => {},

  isAllFilterEmpty: () => {},
  setHasSearchPageLoaded: () => {},
};

export const PortalContext = createContext(initialState);

const PortalContextProvider = (props) => {
  // states
  const [totalCars, setTotalCars] = useState(null);
  const [userIp, setUserIp] = useState("");
  const [allCarsList, setAllCarsList] = useState([]);
  const [currentPageCarList, setCurrentPageCarList] = useState([]);
  const [searchPageNo, setSearchPageNo] = useState(1);
  const [linkId, setLinkId] = useState([]);

  const [allCarsLoading, setAllCarsLoading] = useState([]);

  const [selectedYearList, setSelectedYearList] = useState([]);
  const [selectedTransmissionList, setSelectedTransmissionList] = useState([]);
  const [selectedFuelList, setSelectedFualList] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCarMakes, setSelectedCarMakes] = useState([]);
  const [selectedCarModels, setSelectedCarModels] = useState([]);

  const [isFromCarByModal, setIsFromCarByModal] = useState(false);

  const [allFilteredData, setAllFilteredData] = useState([]);

  const [yearFilteredData, setYearFilteredData] = useState([]);
  const [transmissionFilteredData, setTransmissionFilteredData] = useState([]);
  const [fualFilteredData, setFualFilteredData] = useState([]);
  const [colorFilteredData, setColorFilteredData] = useState([]);
  const [categoryFilteredData, setCategoryFilteredData] = useState([]);
  const [carMakerFilteredData, setCarMakerFilteredData] = useState([]);
  const [carModelFilteredData, setCarModelFilteredData] = useState([]);
  const [priceFilteredData, setPriceFilteredData] = useState([]);
  const [kmsFilteredData, setKmsFilteredData] = useState([]);
  const [noOwnersFilteredData, setNoOwnersFilteredData] = useState([]);

  const [yearValues, setYearValues] = useState([0, 100]);
  const [priceValues, setPriceValues] = useState([0, 100]);
  const [kmsValues, setKmsValues] = useState([0, 100]);
  const [noOwners, setNoOwners] = useState([0, 100]);

  const [hasSearchPageLoaded, setHasSearchPageLoaded] = useState(false);

  const priceDiff = maxPrice - minPrice;
  const kmsDiff = maxKms - minKms;
  const yearDiff = maxYear - minYear;
  const noOwnersDiff = maxOwners - minOwners;

  // console.log("linkid", linkId);

  function setNonCategoryListEmpty() {
    setSelectedYearList([]);
    setSelectedTransmissionList([]);
    setSelectedFualList([]);
    setSelectedColors([]);
    setSelectedCarMakes([]);
    setSelectedCarModels([]);

    setYearValues([0, 100]);
    setPriceValues([0, 100]);
    setKmsValues([0, 100]);
    setNoOwners([0, 100]);
  }

  function setNonMakeFilterEmpty() {
    setSelectedYearList([]);
    setSelectedTransmissionList([]);
    setSelectedFualList([]);
    setSelectedColors([]);
    setSelectedCarModels([]);
    setSelectedCategories([]);
    setYearValues([0, 100]);
    setPriceValues([0, 100]);
    setKmsValues([0, 100]);
    setNoOwners([0, 100]);
  }

  function getSliderValue(diff, per, minVal) {
    return Math.round(minVal + (per * diff) / 100);
  }

  const itemsPerPage = 6;

  function isAllFilterEmpty() {
    return (
      selectedYearList.length === 0 &&
      selectedTransmissionList.length === 0 &&
      selectedFuelList.length === 0 &&
      selectedColors.length === 0 &&
      selectedCategories.length === 0 &&
      selectedCarMakes.length === 0
    );
  }

  function isAllSliderFullRange() {
    return (
      (priceValues[0] === 0 && priceValues[1]) === 100 &&
      kmsValues[0] === 0 &&
      kmsValues[1] === 100 &&
      yearValues[0] === 0 &&
      yearValues[1] === 100 &&
      noOwners[0] === 0 &&
      noOwners[1] === 100
    );
  }

  function setFiltersFromSession() {
    const sessionFilters = JSON.parse(sessionStorage.getItem("filters"));
    if (!sessionFilters) return;
    // key labels to extract from session filters
    const keys = [
      "Category",
      "Car Make",
      "Car Model",
      "Year",
      "Transmission Type",
      "Fuel Type",
      "Price",
      "Kms",
      "No of owners",
      "Color",
    ];

    // take each key. check key is present, if present assingn the value to the corresponding filter
    if (sessionFilters["Category"]) {
      setSelectedCategories(sessionFilters["Category"]);
    }
    if (sessionFilters["Car Make"]) {
      setSelectedCarMakes(sessionFilters["Car Make"]);
    }
    if (sessionFilters["Car Model"]) {
      setSelectedCarModels(sessionFilters["Car Model"]);
    }
    if (sessionFilters["Year"]) {
      setYearValues(sessionFilters["Year"]);
    }
    if (sessionFilters["Transmission Type"]) {
      setSelectedTransmissionList(sessionFilters["Transmission Type"]);
    }
    if (sessionFilters["Fuel Type"]) {
      setSelectedFualList(sessionFilters["Fuel Type"]);
    }
    if (sessionFilters["Price"]) {
      setPriceValues(sessionFilters["Price"]);
    }
    if (sessionFilters["Kms"]) {
      setKmsValues(sessionFilters["Kms"]);
    }
    if (sessionFilters["No of owners"]) {
      setNoOwners(sessionFilters["No of owners"]);
    }
    if (sessionFilters["Color"]) {
      setSelectedColors(sessionFilters["Color"]);
    }

    // export this function run on the backbutton click of /car details to direct to /search-cars
  }

  async function getAllCars() {
    try {
      // if (!linkId) {

      const resp = await GetAllCarsList(0);
      if (resp.status === 200) {
        setAllCarsList(resp.data);
        setAllFilteredData(resp.data);
      }
    } catch (error) {
      console.error("fetching all cars failed!", error);
    }
  }

  async function getLinkListedCars(linkId) {
    try {
      const resp = GetLinkListedCars(linkId);
      if (resp.status === 200) {
        console.log("link cars", resp);
      }
    } catch (error) {
      console.error("error getting link based car list", error);
    }
  }

  function filterByValue(allData, yearList, param_name) {
    const updatedData = allData.filter((data) => yearList.includes(data[param_name]));
    return updatedData;
  }

  function filterByRange(allData, rangeData, param_name, diff, minVal) {
    const lowerVal = getSliderValue(diff, rangeData[0], minVal);
    const upperVal = getSliderValue(diff, rangeData[1], minVal);

    const updatedData = allData.filter((data) => data[param_name] >= lowerVal && data[param_name] <= upperVal);
    return updatedData;
  }

  const getCombinedFilterData = (...arrays) => {
    const commonObjects = [];
    const countMap = {};

    // console.log(...arrays);

    // Count occurrences of each vehicle ID in the arrays
    for (const array of arrays) {
      for (const obj of array) {
        const vid = obj.vehicleId;
        countMap[vid] = (countMap[vid] || 0) + 1;
      }
    }
    // console.log(countMap);

    // Find objects with vehicle IDs present in all arrays
    for (const array of arrays) {
      for (const obj of array) {
        const vid = obj.vehicleId;
        if (countMap[vid] === arrays.length && !commonObjects.some((o) => o.vehicleId === vid)) {
          commonObjects.push(obj);
        }
      }
    }


    return commonObjects;
  };

  // Solution try
  useEffect(() => {
    const isFiltersNull = isAllFilterEmpty();
    const isAllRangeFull = isAllSliderFullRange();
    if (isFiltersNull && isAllRangeFull) {
      getAllCars();
      return;
    }

    const fualData = filterByValue(allCarsList, selectedFuelList, "fuel");
    setFualFilteredData(fualData);

    const transmissionData = filterByValue(allCarsList, selectedTransmissionList, "transmission");
    setTransmissionFilteredData(transmissionData);

    const colorData = filterByValue(allCarsList, selectedColors, "color");
    setColorFilteredData(colorData);

    const categoryData = filterByValue(allCarsList, selectedCategories, "body");
    setCategoryFilteredData(categoryData);

    const makeData = filterByValue(allCarsList, selectedCarMakes, "make");
    setCarMakerFilteredData(makeData);

    const modelData = filterByValue(allCarsList, selectedCarModels, "model");
    setCarModelFilteredData(modelData);

    // when  range slider values is full then call all cars (has to be added along with checkign allfilterempty)
    const priceData = filterByRange(allCarsList, priceValues, "price", priceDiff, minPrice);
    setPriceFilteredData(priceData);

    const kmsData = filterByRange(allCarsList, kmsValues, "odometer", kmsDiff, minKms);
    setKmsFilteredData(kmsData);

    const yearData = filterByRange(allCarsList, yearValues, "manuyear", yearDiff, minYear);
    setYearFilteredData(yearData);

    const ownerData = filterByRange(allCarsList, noOwners, "owner", noOwnersDiff, minOwners);
    setNoOwnersFilteredData(ownerData);
  }, [
    selectedFuelList,
    selectedTransmissionList,
    selectedColors,
    selectedCategories,
    selectedCarMakes,
    selectedCarModels,
    priceValues,
    kmsValues,
    yearValues,
    noOwners,
  ]);

  // individual function calls
  // useEffect(() => {
  //   const newData = filterByValue(allCarsList, selectedFuelList, "fuel");
  //   const isFiltersNull = isAllFilterEmpty();
  //   if (isFiltersNull) {
  //     getAllCars();
  //     // setSearchPageNo(1);
  //   } else {
  //     // FILTERED DATA TEMP
  //     setFualFilteredData(newData);
  //     // setSearchPageNo(1);
  //   }
  // }, [selectedFuelList]);

  // useEffect(() => {
  //   const newData = filterByValue(allCarsList, selectedTransmissionList, "transmission");
  //   const isFiltersNull = isAllFilterEmpty();
  //   if (isFiltersNull) {
  //     // if (hasSearchPageLoaded) {
  //     getAllCars();
  //     // }
  //     // setSearchPageNo(1);
  //   } else {
  //     setTransmissionFilteredData(newData);
  //     // setSearchPageNo(1);
  //   }
  // }, [selectedTransmissionList]);

  // useEffect(() => {
  //   const newData = filterByValue(allCarsList, selectedColors, "color");
  //   const isFiltersNull = isAllFilterEmpty();
  //   if (isFiltersNull) {
  //     // fetch all cars again
  //     getAllCars();
  //     // setSearchPageNo(1);
  //   } else {
  //     setColorFilteredData(newData);
  //     // setSearchPageNo(1);
  //   }
  // }, [selectedColors]);

  // useEffect(() => {
  //   const newData = filterByValue(allCarsList, selectedCategories, "body");
  //   const isFiltersNull = isAllFilterEmpty();
  //   if (isFiltersNull) {
  //     // fetch all cars again
  //     getAllCars();
  //     // setSearchPageNo(1);
  //   } else {
  //     // FILTERED DATA TEMP
  //     setCategoryFilteredData(newData);
  //     // setSearchPageNo(1);
  //   }
  // }, [selectedCategories]);

  // useEffect(() => {
  //   const newData = filterByValue(allCarsList, selectedCarMakes, "make");
  //   const isFiltersNull = isAllFilterEmpty();
  //   if (isFiltersNull) {
  //     // fetch all cars again
  //     getAllCars();
  //     // setSearchPageNo(1);
  //   } else {
  //     setCarMakerFilteredData(newData);
  //     // setSearchPageNo(1);
  //   }
  // }, [selectedCarMakes]);

  // useEffect(() => {
  //   const newData = filterByValue(allCarsList, selectedCarModels, "model");
  //   const isFiltersNull = isAllFilterEmpty();
  //   if (isFiltersNull) {
  //     // fetch all cars again
  //     getAllCars();
  //     // setSearchPageNo(1);
  //   } else {
  //     setCarModelFilteredData(newData);
  //     // setSearchPageNo(1);
  //   }
  // }, [selectedCarModels]);

  // useEffect(() => {
  //   const newData = filterByRange(allCarsList, priceValues, "price", priceDiff, minPrice);

  //   const fullRange = priceValues[0] === 0 && kmsValues[1] === 100;
  //   const isFiltersNull = isAllFilterEmpty();
  //   if (isFiltersNull && fullRange) {
  //     // fetch all cars again
  //     getAllCars();
  //     // setSearchPageNo(1);
  //   } else {
  //     setPriceFilteredData(newData);
  //     // setSearchPageNo(1);
  //   }
  // }, [priceValues]);

  // useEffect(() => {
  //   const newData = filterByRange(allCarsList, kmsValues, "odometer", kmsDiff, minKms);
  //   const isFiltersNull = isAllFilterEmpty();
  //   const fullRange = kmsValues[0] === 0 && kmsValues[1] === 100;
  //   if (isFiltersNull && fullRange) {
  //     // fetch all cars again
  //     getAllCars();
  //     // setSearchPageNo(1);
  //   } else {
  //     setKmsFilteredData(newData);
  //     // setSearchPageNo(1);
  //   }
  // }, [kmsValues]);

  // useEffect(() => {
  //   const newData = filterByRange(allCarsList, yearValues, "manuyear", yearDiff, minYear);
  //   const isFiltersNull = isAllFilterEmpty();
  //   const fullRange = yearValues[0] === 0 && yearValues[1] === 100;
  //   if (isFiltersNull && fullRange) {
  //     // fetch all cars again
  //     getAllCars();
  //     // setSearchPageNo(1);
  //   } else {
  //     setYearFilteredData(newData);
  //     // setSearchPageNo(1);
  //   }
  // }, [yearValues]);

  // useEffect(() => {
  //   const newData = filterByRange(allCarsList, noOwners, "owner", noOwnersDiff, minOwners);
  //   const isFiltersNull = isAllFilterEmpty();
  //   const fullRange = noOwners[0] === 0 && noOwners[1] === 100;
  //   if (isFiltersNull && fullRange) {
  //     getAllCars();
  //     // setSearchPageNo(1);
  //   } else {
  //     // FILTERED DATA TEMP
  //     setNoOwnersFilteredData(newData);
  //     // setSearchPageNo(1);
  //   }
  // }, [noOwners]);

  useEffect(() => {
    if (isFromCarByModal) {
      if (categoryFilteredData.length > 0) {
        setAllFilteredData(categoryFilteredData);
        setIsFromCarByModal(false);
      }
      if (carMakerFilteredData.length > 0) {
        setAllFilteredData(carMakerFilteredData);
        setIsFromCarByModal(false);
      }
    } else {
      // list is empty still it shold be sent to the the combining function.
      const arraysToCombine = [];
      if (yearValues[0] !== 0 || yearValues[1] !== 100) {
        arraysToCombine.push(yearFilteredData);
      }
      if (selectedTransmissionList.length > 0) {
        arraysToCombine.push(transmissionFilteredData);
      }
      if (selectedFuelList.length > 0) {
        arraysToCombine.push(fualFilteredData);
      }
      if (selectedColors.length > 0) {
        arraysToCombine.push(colorFilteredData);
      }
      if (selectedCategories.length > 0) {
        arraysToCombine.push(categoryFilteredData);
      }
      if (selectedCarMakes.length > 0) {
        arraysToCombine.push(carMakerFilteredData);
      }
      if (selectedCarModels.length > 0) {
        arraysToCombine.push(carModelFilteredData);
      }
      if (kmsValues[0] !== 0 || kmsValues[1] !== 100) {
        arraysToCombine.push(kmsFilteredData);
      }
      if (priceValues[0] !== 0 || priceValues[1] !== 100) {
        arraysToCombine.push(priceFilteredData);
      }
      if (noOwners[0] !== 0 || noOwners[1] !== 100) {
        arraysToCombine.push(noOwnersFilteredData);
      }

      const finalRenderData = getCombinedFilterData(...arraysToCombine);
      setAllFilteredData(finalRenderData);
    }
  }, [
    yearFilteredData,
    transmissionFilteredData,
    fualFilteredData,
    colorFilteredData,
    categoryFilteredData,
    carMakerFilteredData,
    carModelFilteredData,
    priceFilteredData,
    kmsFilteredData,
    noOwnersFilteredData,
    priceValues,
    kmsValues,
    noOwners,
    isFromCarByModal,
  ]);

  const store = {
    totalCars: totalCars,
    setTotalCars: setTotalCars,
    userIp: userIp,
    setUserIp: setUserIp,
    allCarsList: allCarsList,
    setAllCarsList: setAllCarsList,
    searchPageNo: searchPageNo,
    setSearchPageNo: setSearchPageNo,
    itemsPerPage,
    currentPageCarList,
    allFilteredData,

    selectedYearList,
    setSelectedYearList,
    selectedTransmissionList,
    setSelectedTransmissionList,
    selectedFuelList,
    setSelectedFualList,
    selectedColors,
    setSelectedColors,
    selectedCategories,
    setSelectedCategories,
    selectedCarMakes,
    setSelectedCarMakes,
    selectedCarModels,
    setSelectedCarModels,
    priceValues,
    setPriceValues,
    kmsValues,
    setKmsValues,
    noOwners,
    setNoOwners,
    yearValues,
    setYearValues,

    setNonCategoryListEmpty,
    setNonMakeFilterEmpty,

    linkId,
    setLinkId,

    setIsFromCarByModal,

    setAllCarsList,
    setAllFilteredData,

    isAllFilterEmpty,

    setFiltersFromSession,

    setHasSearchPageLoaded,
  };

  return <PortalContext.Provider value={store}>{props.children}</PortalContext.Provider>;
};

export default PortalContextProvider;
