import React, { useState } from "react";
import "./faqSection.css";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, Typography } from "@mui/material";

const faqData1 = [
  {
    question: "Can I Buy inspection from website?",
    answer: "Yes, you can purchase any inspection plan from website",
  },
  {
    question: "What car types are covered in the inspection plans?",
    answer:
      "We provide inspection to all types of car types like Hatchback, Sedan and SUV.",
  },
  {
    question: "How can I request for inspection?",
    answer:
      "Please send message “Book Inspection” to 7353 34 35 36 on WhatsApp and follow the instructions on WhatsApp to book the inspection. You can also call us at 7353 34 35 36 to book inspection.",
  },
  {
    question: "When can I expect inspection report ?",
    answer:
      "The inspection report will usually be available within 8 business hours. Normally delay happens to prepare the estimate for repairs based on which price recommendations are made.",
  },
  {
    question: "Do you also inspect luxury cars ?",
    answer: "No. We do not provide inspection services to Luxury cars.",
  },
  {
    question: "Which cars do you provide inspection services?",
    answer:
      "We provide inspection services to all models of Maruti, Hyundai, Honda, Toyota, Tata, Mahindra, Nissan, Renault, Volkswagen, Skoda and Jeep brands.",
  },
  {
    question: "Which car do you NOT provide inspection services?",
    answer:
      "We DO NOT provide inspection services to all model of Volvo, Mercedes Benz, BMW, Audi, Bentley, Bugatti etc.",
  },
  {
    question:
      "Can I activate warranty for any car from the inspection package ?",
    answer:
      "Yes, you will be eligible to activate warranty for any one car which is inspected in the package ONLY.",
  },
  {
    question:
      "Can I  activate warranty for car which is not approved by Wisedrive ?",
    answer:
      "No, unfortunately you cannot activate warranty for cars that are not approved by Wisedrive.",
  },
  {
    question:
      "What happens if none of the cars from the package are approved by Wisedrive? Can I choose some other car from outside to activate warranty ?",
    answer:
      "No. You can only activate one car from the approved cars within the package. If no cars are approved, we strongly recommend not to buy those cars as we will not provide warranty and risk of buying those cars is considerably high.",
  },
  {
    question:
      "Can I buy 1 car inspection and later upgrade to 3 cars inspection package.",
    answer:
      "No. Unfortunately we DO NOT have option to upgrade after the purchase is made",
  },
  {
    question:
      "What if I do not want to proceed with your inspection after purchasing the package?",
    answer:
      "You can cancel you subscription anytime before 48 hrs of making the payment but you should not have got any car inspected during this period. Even if One car is inspected the entire amount is non-refundable",
  },
  {
    question:
      "What if I want to understand more about the car which you inspected? Who will help me understand the inspection report?",
    answer:
      "We have expert advise option available for customers. So please select the appropriate package for right support you need.",
  },
  {
    question: "Will Wisedrive negotiate with the seller ?",
    answer:
      "No. We DO NOT negotiate with the seller. However, we will provide you with all necessary details and repair estimates for your car and provide price recommendations based on the repair estimate. Please select there appropriate package for this support.",
  },
  {
    question: "How can I activate warranty ?",
    answer:
      "It’s simple. Just sent message “activate warranty” to 7353 34 35 36 on WhatsApp and follow the instructions on WhatsApp to activate warranty. You can also send us an email to activatewarranty@wisedrive.in with the car number and we will do the rest for you.",
  },
];

const faqData = [
  {
    question: "What services does Wisedrive offer for used car inspections?",
    answer:
      "Wisedrive offers comprehensive used car inspections covering critical components such as the engine, transmission, brakes, and electrical systems.",
  },
  {
    question: "How can i book appointment for car inspections?",
    answer:
      "You can book an inspection through our website or mobile app by selecting your preferred package and scheduling an appointment.",
  },
  {
    question: "What is included in a Wisedrive used car inspection? ",
    answer:
      "Our inspections cover over 200 checkpoints, including mechanical, electrical, and safety systems.",
  },
  {
    question: "How does the inspection process work?",
    answer:
      "A certified technician will inspect your car at your location or a partner garage, providing a detailed report afterward.",
  },
  {
    question: "How long does a typical car inspection take?",
    answer:
      "An inspection usually takes about 1-2 hours, depending on the vehicle’s condition and the package chosen.",
  },
  {
    question: "Can I request an inspection for any type of vehicle? ",
    answer:
      "We inspect most types of vehicles, including sedans, SUVs, and trucks.",
  },
  {
    question: "What is the cost of a used car inspection?",
    answer:
      "The cost varies based on the package selected. Detailed pricing is available on our website.",
  },
  {
    question: "What types of warranties does Wisedrive offer?",
    answer:
      "We offer various warranty plans that cover different aspects of vehicle maintenance and repairs.",
  },
  {
    question: "How do I purchase a warranty with my inspection?",
    answer:
      "You can add a warranty to your inspection package during the booking process on our website or app.",
  },
  {
    question: "What does the Wisedrive warranty cover?",
    answer:
      "Coverage varies by plan but generally includes major components such as the engine, transmission, and electrical systems.",
  },
  {
    question: "Are there any exclusions in the warranty coverage?",
    answer:
      "Yes, some exclusions apply. Please refer to the specific warranty terms and conditions for details.",
  },
  {
    question: "How do I claim warranty repairs with Wisedrive?",
    answer:
      "Contact our support team to file a claim, and we will guide you through the process and direct you to an authorised service centre.",
  },
  {
    question: "Can I transfer my warranty to a new owner if I sell my car? ",
    answer:
      "Yes, warranties can be transferred to new owners, ensuring continued coverage.",
  },
  {
    question: "What is the validity period of your warranties?",
    answer:
      "Warranty periods vary by package and can range from 6 months to several years.",
  },
];
function Faqsection() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (isExpanded) => {
    setExpanded(isExpanded);
  };
  return (
    <section className="faqSection">
      <div className="container">
        <div className="faqContainer">
          <div className="commonHeading">
            <h2 style={{ marginBottom: "10px" }}>Frequently Asked Questions</h2>
            <p className="p-padding-top">
              Everything you need to know about product ad billing. Can't find
              the answer you're looking for? Contact Us
            </p>
          </div>
          <div className="faq-container-wrap">
            <div className="faq-container-wrap-left">
              {faqData?.map((item, index) => {
                return (
                  <div key={index} style={{ margin: "10px" }}>
                    {index < faqData.length / 2 && (
                      <Accordion
                        expanded={expanded === item?.question}
                        onChange={() => handleChange(item?.question)}
                        sx={{
                          boxShadow:
                            "0px 10px 20px 0px rgba(164, 172, 179, 0.4) !important",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            expanded === item?.question ? (
                              <RemoveIcon />
                            ) : (
                              <AddIcon />
                            )
                          }
                        >
                          <p
                            className="p-padding-top"
                            style={{
                              fontFamily: "gilroy",
                              color: "black",
                            }}
                          >
                            {item?.question}
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{item?.answer}</p>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="faq-container-wrap-left">
              {faqData?.map((item, index) => {
                return (
                  <div key={index} style={{ margin: "10px" }}>
                    {index >= faqData.length / 2 && (
                      <Accordion
                        expanded={expanded === item?.question}
                        onChange={() => handleChange(item?.question)}
                        sx={{
                          boxShadow:
                            "0px 10px 20px 0px rgba(164, 172, 179, 0.4) !important",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            expanded === item?.question ? (
                              <RemoveIcon />
                            ) : (
                              <AddIcon />
                            )
                          }
                        >
                          <p
                            className="p-padding-top"
                            style={{
                              fontFamily: "gilroy",
                              color: "black",
                            }}
                          >
                            {item?.question}
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{item?.answer}</p>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div className="faq-cards-container">
            {faqData?.map((item) => {
              return (
                <div className="faq-card">
                  <h6>{item?.question}</h6>
                  <p>{item?.answer}</p>
                </div>
              );
            })}
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Faqsection;
