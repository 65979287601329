import React from "react";
import Slider from "react-slick";
import NoMaintenance from "../../assets/images/warranty-benefits/accesslogo.jpeg";
import Peace from "../../assets/images/warranty-benefits/peaceOfMind.svg";
import Increase from "../../assets/images/warranty-benefits/choosecar.jpeg";
import Smile from "../../assets/images/warranty-benefits/inspectionlogo.jpeg";
import Flexibility from "../../assets/images/warranty-benefits/flexibility.svg";
import OneO from "../../assets/icons/oneO.png";
import TwoO from "../../assets/icons/twoO.png";
import ThreeO from "../../assets/icons/threeO.png";
import FourO from "../../assets/icons/fourO.png";
import FiveO from "../../assets/icons/fiveO.png";
import SixO from "../../assets/icons/sixO.png";

function WisedriveProgramNew() {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    autoplaySpeed: 0,
    variableWidth: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 500,
        settings: {
          sliderToShow: 1,
        },
      },
    ],
    // dots: false,
    // arrows: true,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 3.36,
    // slidesToScroll: 1,
    // autoplaySpeed: 3000,
    // centerMode: false,
    // autoplay: true,
    // infiniteScroll: true,
    // adaptiveHeight: true,
    // responsive: [
    //   {
    //     breakpoint: 1350,
    //     settings: {
    //       slidesToShow: 3,
    //     },
    //   },
    //   {
    //     breakpoint: 1200,
    //     settings: {
    //       slidesToShow: 2.5,
    //     },
    //   },
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2.2,
    //       // infiniteScroll: true,
    //       // centerMode: false,
    //     },
    //   },
    //   {
    //     breakpoint: 900,
    //     settings: {
    //       slidesToShow: 2.1,
    //       slidesToScroll: 1,
    //       // infiniteScroll: true,
    //       // centerMode: false,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1.5,
    //       slidesToScroll: 1,
    //       // infiniteScroll: true,
    //       // centerMode: false,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1.1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 350,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };
  return (
    <section className="guarenty" id="warranty">
      <div className="container">
        <div className="commonHeading">
          {/* <h2>
            <span>WiseAssist Program </span> guarantees, the best car
          </h2> */}

          <h2>
            It’s <span>time to change</span> the old ways of buying used cars
          </h2>
        </div>
        <div className="desktop">
          <div className="guarentyContent" style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="guarentyCard">
              <img src={OneO} alt="logo" width="54px" height="78px" />
              <h4>
                {/* <b>Unlimited</b> Access */}
                Get Matching requirements on whatsApp
              </h4>
              {/* <p>
                Get unlimited access to <b>3000+ pre-approved warranty cars</b>
              </p> */}
            </div>

            <div className="guarentyCard">
              <img src={TwoO} alt="logo" width="54px" height="78px" />
              <h4>
                {/* <b>Unlimited</b> Inspections */}
                Get RTO verification details on whatsApp
              </h4>
              {/* <p>
                We post your requirement across <b>300+ trusted used car dealers</b> and inspect cars for free
              </p> */}
            </div>

            <div className="guarentyCard">
              <img src={ThreeO} alt="logo" width="54px" height="78px" />
              <h4>
                {/* Choose <b>your car</b> */}
                Get police/criminal check records on whatsApp
              </h4>
              {/* <p>
                Choose upto 5 cars of your choice <b>anywhere in Bangalore</b> for free inspection. &nbsp;
              </p> */}
            </div>

            <div className="guarentyCard">
              <img src={FourO} alt="logo" width="54px" height="78px" />
              <h4>
                {/* Choose <b>your car</b> */}
                Planned dealer visits by saving time
              </h4>
              {/* <p>
                Choose upto 5 cars of your choice <b>anywhere in Bangalore</b> for free inspection. &nbsp;
              </p> */}
            </div>
            <div className="guarentyCard">
              <img src={FiveO} alt="logo" width="54px" height="78px" />
              <h4>
                {/* Choose <b>your car</b> */}
                Get help in negotiating price for your car
              </h4>
              {/* <p>
                Choose upto 5 cars of your choice <b>anywhere in Bangalore</b> for free inspection. &nbsp;
              </p> */}
            </div>
            <div className="guarentyCard">
              <img src={SixO} alt="logo" width="54px" height="78px" />
              <h4>
                {/* Choose <b>your car</b> */}
                Warranty Activation
              </h4>
              {/* <p>
                Choose upto 5 cars of your choice <b>anywhere in Bangalore</b> for free inspection. &nbsp;
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="mobile">
        <div className="guarentyContent">
          <Slider {...settings}>
            <div className="guarentyCardParent">
              <div className="guarentyCard">
                <img src={Smile} alt="logo" />
                <h4>Extended Warranty</h4>
                <p>Expensive engine and transmission are covered for free</p>
              </div>
            </div>
            <div className="guarentyCardParent">
              <div className="guarentyCard">
                <img src={NoMaintenance} alt="logo" />
                <h4>No Maintenance.</h4>
                <p>Our warranty program comes with free maintenance and service module.</p>
              </div>
            </div>
            <div className="guarentyCardParent">
              <div className="guarentyCard">
                <img src={Increase} alt="logo" />
                <h4>Roadside Assistance</h4>
                <p>Pan India roadside assistance free for all Wisedrive customer &nbsp;</p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default WisedriveProgramNew;
