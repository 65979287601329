import React from "react";
import "./onlyWhatsApp.css";
import { useState } from "react";

import AiDrivenLogo from "../../assets/gradeIcons/aiDrivenEngine.png";
import OBDScanner from "../../assets/gradeIcons/OBDScanner.png";
import UnbiasedCustomer from "../../assets/gradeIcons/unbiasedCustomer.png";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { Divider } from "@mui/material";

function OnlyWhatsApp() {
  const gradeCardsData = [
    {
      src: UnbiasedCustomer,
      header: "Unbiased & Customer focused Inspection",
      content:
        "Our inspections are completely transparent and unbiased, providing an objective assessment focused solely on ensuring the highest vehicle quality and reliability.",
    },
    {
      src: AiDrivenLogo,
      header: "AI Driven - Engine Sound Analysis",
      content:
        "Wisedrive uses advanced AI for analysing the Engine Sound analysis with comparing mi lions of data points for similar cars across the country, ",
    },
    {
      src: OBDScanner,
      header: " OBD-2 Scanner Analysis",
      content:
        "Engine Control Module (ECM), Body Control Module (BCM), Transmission Control Module (TCM) analysis of your car with latest OBD-2 scanners.",
    },
    // {
    //   src: BatteryIcon,
    //   header: "Best in the Industry Inspection",
    //   content:
    //     "Wisedrive delivers the best in the industry inspection services. Our team of certified professionals conducts meticulous checks on all aspects of a vehicle, from mechanical and electrical systems to the exterior and interior condition.",
    // },
    // {
    //   src: TurboWarrantyIcon,
    //   header: "Renew Insurance",
    //   content: "Comprehensive coverage of turbo parts are included ",
    // },
    // {
    //   src: AcCompressorIcon,
    //   header: "Insurance Claim support",
    //   content: "Comprehensive AC components warranty ",
    // },
    // {
    //   src: InsuranceClaimSuportIcon,
    //   header: "Subscription details",
    //   content: "Call us for any insurance claim support ",
    // },
    // {
    //   src: InsuranceClaimSuportIcon,
    //   header: "Subscription details",
    //   content: "Call us for any insurance claim support ",
    // },
  ];
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };
  const handleGetWarranty = () => {
    alert();
  };
  return (
    <section className="onlyApp-background">
      <div className="container">
        <div className="onlyWhatsApp">
          <div className="commonHeading">
            <h2 className="whatsapp-mobile-header">
              Unbiased, <br />
              Tech-Driven, <br />
              Customer-Focused, State-of-the-Art Inspections
            </h2>
            <h2 className="whatsapp-header">
              Unbiased, Tech-Driven, Customer-Focused, State-of-the-Art
              Inspections
            </h2>
            <p>
              At WiseDrive, you experience unparaleled quality with impartial
              inspections, advanced technology, customer-first focus, and
              cutting-edge methods
            </p>
          </div>
        </div>
        {/* <div className="onlyWhatsApp-cards">
          {gradeCardsData?.map((item) => {
            return (
              <div className="card">
                <GppGoodIcon
                  sx={{ color: "#0c286a", width: "40px", height: "40px" }}
                />
                <div style={{ marginTop: "20px" }}>
                  <h6>{item?.header}</h6>
                  <p>{item?.content}</p>
                </div>
              </div>
            );
          })}
        </div> */}
        <div className="onlyWhatsApp-cards">
          <div className="card">
            <img
              src={gradeCardsData?.[0]?.src}
              alt=""
              style={{ color: "#25632d", width: "50px", height: "50px" }}
            />

            <div style={{ marginTop: "20px" }}>
              <h6 style={{ color: "#25632d" }}>
                {gradeCardsData?.[0]?.header}
              </h6>
              <Divider
                style={{
                  borderColor: "#25632d",
                  borderWidth: "2px",
                  marginBottom: "1.5rem",
                  marginTop: "1rem",
                }}
              />
              <p style={{ color: "#25632d" }}>{gradeCardsData?.[0]?.content}</p>
            </div>
          </div>
          <div className="card" style={{ backgroundColor: "#0c286a" }}>
            <img
              src={gradeCardsData?.[1]?.src}
              style={{ color: "white", width: "50px", height: "50px" }}
            />

            <div style={{ marginTop: "20px" }}>
              <h6 style={{ color: "white" }}>{gradeCardsData?.[1]?.header}</h6>
              <Divider
                style={{
                  borderColor: "white",
                  borderWidth: "2px",
                  marginBottom: "1.5rem",
                  marginTop: "1rem",
                }}
              />
              <p style={{ color: "white" }}>{gradeCardsData?.[1]?.content}</p>
            </div>
          </div>
          <div className="card">
            <img
              src={gradeCardsData?.[2]?.src}
              style={{ color: "#0c286a", width: "50px", height: "50px" }}
            />
            <div style={{ marginTop: "20px" }}>
              <h6>{gradeCardsData?.[2]?.header}</h6>
              <Divider
                style={{
                  borderColor: "#0c286a",
                  borderWidth: "2px",
                  marginTop: "1rem",
                  marginBottom: "1.5rem",
                }}
              />
              <p>{gradeCardsData?.[2]?.content}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OnlyWhatsApp;
