import React, { useState, useEffect, useContext } from "react";
import "./header.css";
import companyLogo from "../../assets/images/home/logo.svg";
import Bars from "../../assets/images/home/bars.svg";
import Close from "../../assets/images/home/close.svg";
import Expand from "../../assets/images/header/expandMore.svg";
import { Link, useNavigate } from "react-router-dom";
// import wisedDrivelogo from "../../assets/images/header/wisedDrivelogo.svg";
import wisedDrivelogo from "../../assets/images/header/log0.png";
import KycPopup from "../popups/kycPopup";
import { MyContext } from "../../context";
import RightGrediantArrow from "../../assets/images/home/rightArrowGrediant.svg";
import InspectionPlanModalWiseAssist from "../popups/InspectionPlanModalWiseAssist";

const Header = ({ pageData }) => {
  const pathname = window.location.pathname;
  const path = window.location.href.split("/")[3];
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  const [sticky, setSticky] = useState("");
  const [headerDropDownText, setHeaderDropDownText] = useState("");
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [openKycPopup, setOpenKycPopup] = useState(false);
  const { setOwnerPlanTabActive } = useContext(MyContext);
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? "auto" : "hidden";
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset <= 500) {
        setSticky("sticky");
      } else {
        setSticky("sticky");
      }

      let moving = window.pageYOffset;

      if (window.pageYOffset > 100) {
        setVisible(position > moving);
      }
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const cls = visible ? "" : "hidden";

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.pageYOffset <= 10) {
  //       document.getElementsByTagName("header")[0]?.style.backgroundColor = "#000000";
  //     } else {
  //       document.getElementsByTagName("header")[0]?.style.backgroundColor = "#fff";
  //     }
  //   });
  // }, []);

  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(!isOpen);

    // document.addEventListener("click", handleDocumentClick);
  };

  const closeNav = () => {
    setIsOpen(false);
    document.getElementById("myNav").style.height = "0%";
    // document.removeEventListener("click", handleDocumentClick);
  };

  const closeKycPopup = () => {
    setOpenKycPopup(false);
  };

  useEffect(() => {
    ShowHeaderDropdown();
  }, [path]);

  const ShowHeaderDropdown = () => {
    if (path === "buyer") {
      setHeaderDropDownText("Buyer");
    } else if (path === "owner") {
      setHeaderDropDownText("Owner");
    } else {
      setHeaderDropDownText("Owner");
    }
  };

  const ExplorePlansSwitchTabs = () => {
    if (path === "buyer") {
      setOwnerPlanTabActive(1);
    } else if (path === "owner") {
      setOwnerPlanTabActive(0);
    } else {
      setOwnerPlanTabActive(0);
    }

    navigate("/owner-pricing-plans");
    setActive("");
  };

  return (
    <>
      <header className={`${active} ${cls} ${sticky}`}>
        <div className="container">
          <div className="logo">
            {/* <Link to="/"> */}
            <img src={wisedDrivelogo} alt="company logo" className="company-logo" />
            {/* </Link> */}
            <div className="menuLinkAndOwner">
              <div className="mobile">
                {/* <div className="headerDropdown">
                  <span>
                    Owner
                    <img src={Expand} alt="Expand" />
                  </span>
                  <div className="dropdownContent">
                    <p>Owner</p>
                    <p>User</p>
                  </div>
                </div> */}
                <div id="myNav" className={`overlay ${isOpen ? "open" : ""}`}>
                  {/* <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a> */}
                  <div class="overlay-content">
                    <Link onClick={() => setIsOpen(!isOpen)} to="/owner">
                      Owner
                    </Link>
                    <Link onClick={() => setIsOpen(!isOpen)} to="/buyer">
                      Buyer
                    </Link>
                  </div>
                </div>
                {/* <span onClick={openNav}>
                   {headerDropDownText} <img src={Expand} alt="Expand" />
                </span> */}
                {/* <li onClick={ExplorePlansSwitchTabs}>Explore Plans</li> */}
              </div>
              {pathname === "/wiseassist" || pathname === "/wiseassist_fb" ? (
                <a
                  onClick={toggleModal}
                  className="contactUsBtn"
                  style={{ cursor: "pointer", wordSpacing: "-2px", padding: "5px" }}
                >
                  <span style={{ fontWeight: "400", textDecoration: "line-through" }}>Rs 999/-</span>
                  <span style={{ fontSize: "20px", color: "#2836d7", fontWeight: 500 }}>Free </span>
                  {/* <img src={RightGrediantArrow} alt="Arrow" style={{ width: "27px", height: "27px" }} /> */}
                </a>
              ) : pathname === "/payment-success-wiseassist" || pathname === "/payment-success-wiseassist_fb" ? (
                ""
              ) : pathname === "/payment-wiseassist" ? (
                ""
              ) : (
                <img
                  className="barsPhase3"
                  src={Bars}
                  alt="Bars"
                  onClick={() => {
                    setActive("active");
                  }}
                />
              )}
            </div>
          </div>
          <div className="navLinks">
            <div className="logo">
              <Link to="/">
                <img src={companyLogo} alt="company logo" />
              </Link>
              <img
                className="closeIconPhase3"
                src={Close}
                alt="Close"
                onClick={() => {
                  setActive("");
                }}
              />
            </div>
            <ul>
              {/* <li>
                <Link to="/warranty-benefits">Warranty Benefits</Link>
              </li>
              <li>
                <Link to="/service-network">Service Network</Link>
              </li>
              <li>
                <Link to="/owner-pricing-plans">Plans</Link>
              </li>
              <li>
                <Link to="/claim">Claims</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact us</Link>
              </li> */}
              <li className="headerDropdownList">
                {/* <div className="headerDropdown">
                  <span className="ownerExpand">
                    {headerDropDownText}
                    <img src={Expand} alt="Expand" />
                  </span>
                  <div className="dropdownContent">
                    <p>
                      <Link to="/owner">  Owner</Link>
                    </p>
                    <p>
                      <Link to="/buyer">  Buyer</Link>
                    </p>
                  </div>
                </div> */}
              </li>

              {/* <Link onClick={ExplorePlansSwitchTabs} className="mobile mobileHeader tabListMobile" to="/owner">
                <li>Owner</li>
              </Link> */}

              <Link
                onClick={ExplorePlansSwitchTabs}
                className="mobile mobileHeader tabListMobile"
                // to="/buyer"
                to="/"
              >
                <li>Buyer</li>
              </Link>
              {
                pathname === "/wiseassist" || pathname === "/wiseassist_fb" ? (
                  <a
                    onClick={toggleModal}
                    className="contactUsBtn"
                    style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                  >
                    <span style={{ textDecoration: "line-through", fontWeight: "400" }}>Rs 999/- </span>
                    <span
                      style={{
                        fontSize: "15px",
                        color: "#2836d7",
                      }}
                    >
                      Rs 749/-
                    </span>
                    {/* <img src={RightGrediantArrow} alt="Arrow" /> */}
                  </a>
                ) : pathname === "/payment-success-wiseassist" || pathname === "/payment-success-wiseassist_fb" ? (
                  ""
                ) : pathname === "/payment-wiseassist" ? (
                  ""
                ) : null
                // <li onClick={ExplorePlansSwitchTabs}>Explore Plans</li>
              }
              {/* <li className="carNumFetch">
                <a className="exploreBtn">
                  <input type="text" placeholder="Enter car number" />
                  <button onClick={() => setOpenKycPopup(true)}>Fetch report</button>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </header>
      {/* {openKycPopup ? <KycPopup closeKycPopup={closeKycPopup} /> : ""} */}
      <InspectionPlanModalWiseAssist isOpen={isModalOpen} toggler={toggleModal} pageData={pageData} />
    </>
  );
};

export default Header;
