import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { contactUsApi, getCityListApi } from "../../Api/Apis";
import DownArrow from "../../assets/images/checkot-plans/DownArrow.svg";
            
function Banner() {
  const [maxLength, setMaxLength] = useState(10);
  const [cityList, setCityList] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phoneNum: "",
    cityId: "",
    message: "",
  });
  
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  // console.log(process.env);

  const getCityList = async () => {
    try {
      const response = await getCityListApi();
      console.log(response, "response");
      if (response.status === 200) {
        setCityList(response.data.response.CityList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCityList();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userData.name) {
      return toast.error("Please enter your name", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else if (!userData.phoneNum) {
      return toast.error("Please enter your phoneno", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      try {
        const response = await contactUsApi(userData);
        if (response.status === 200) {
          console.log(response);
          setUserData({
            name: "",
            email: "",
            phoneNum: "",
            cityId: "",
            message: "",
          });
          toast.success("Submitted successfully", {
            position: toast.POSITION.TOP_LEFT,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <section className="contactUsBanner">
      <ToastContainer />
      <div className="container">
        <div className="bannerHeading">
          <h1>
            Reach out <span>to us for all your Queries</span>
          </h1>
          <p className="padding-top-40">
            For any inquiries, you can reach us by phone at <br />
            <a href="tel:+91-73533-43536"> +91 73533 43536</a> or by email at <br />
            <a href="mailto:customercare@wisedrive.in" target="_blank">
              customercare@wisedrive.in
            </a>
            &nbsp;Alternatively, you can fill out a form, and we will get back to you as soon as possible.
          </p>
        </div>
        <form className="contactUsForm" onSubmit={handleSubmit}>
          <div className="formGroup">
            <div className="formField">
              <label>
                Name
                <span style={{ color: "#eb6d47", fontSize: "16px" }}>*</span>
              </label>
              <input
                onChange={handleInputChange}
                name="name"
                value={userData.name}
                type="text"
                placeholder="Enter your name"
              />
            </div>
            <div className="formField">
              <label>Email</label>
              <input
                onChange={handleInputChange}
                name="email"
                value={userData.email}
                type="text"
                placeholder="Enter your email"
              />
            </div>
          </div>
          <div className="formGroup">
            <div className="formField">
              <label>
                Phone
                <span style={{ color: "#eb6d47", fontSize: "16px" }}>*</span>
              </label>
              <input
                onChange={handleInputChange}
                name="phoneNum"
                value={userData.phoneNum}
                type="text"
                placeholder="Ex. 73533 43536"
                maxLength={maxLength}
              />
            </div>

            <div className="formField contactus-location">
              <label>Location</label>
              <select
                onChange={handleInputChange}
                name="cityId"
                value={userData.cityId}
                className="contactUsForm-select "
              >
                <option hidden>Your City</option>
                {cityList.map((item) => (
                  <option key={item.city_id} value={item.city_id}>
                    {item.city}
                  </option>
                ))}
              </select>
              <img src={DownArrow} alt="" />
            </div>
          </div>
          <div className="formField">
            <label>Message</label>
            <textarea
              onChange={handleInputChange}
              name="message"
              value={userData.message}
              rows="4"
              placeholder="What’s your message"
            ></textarea>
          </div>
          <div className="formGroup">
            <input type="submit" value="Submit" />
          </div>
        </form>
      </div>
    </section>
  );
}

export default Banner;

