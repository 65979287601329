import React from "react";

import Header from "../../components/header";
import Footer from "../../components/footer";

import ContactUs from "../../components/contact-us/contact-us";
import "../blog/blog.css";
import Discover from "./discover";
import Description from "./description";

const DifferentCarWarranty = () => {
  return (
    <div>
      <Header />
      <Description />
      <Discover />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default DifferentCarWarranty;
