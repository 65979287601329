import React, { useEffect, useState } from "react";
import styles from "../../containers/car-details/car-details.module.css";
import StarIcon from "@mui/icons-material/Star";
import logo1 from "../../assets/CarImages/logo/logo1.png";
import map from "../../assets/other/map.webp";
import ViewSellerPopup from "../popups/viewSellerPopup";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CallIcon from "@mui/icons-material/Call";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { GetDealerInfo, VerifyViewSellerOtp } from "../../Api/PortalApis";

function ContactForm() {
  const [sellerPopupOpen, setSellerPopupOpen] = useState(false);
  const [dealerData, setDealerData] = useState(null);
  const location = useLocation();
  const params = useParams();

  // console.log(dealerData);

  async function getDealerData() {
    const hasVerified = localStorage.getItem("hasOtpVerified") === "Y";
    const phoneNum = localStorage.getItem("phoneNumber");
    console.log(hasVerified);

    if (hasVerified) {
      try {
        const resp = await GetDealerInfo(params.id, phoneNum);
        if (resp.status === 200) {
          // console.log(resp.data);
          setDealerData(resp.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    if (location.state && location.state.fromViewSeller && location.state.dealerData) {
      setDealerData(location.state.dealerData);
    } else {
      getDealerData();
    }
  }, [params.id]);

  function closeSellerPopup() {
    setSellerPopupOpen(false);
  }

  function triggerPhoneCall() {
    if (dealerData.phoneNumber) {
      window.location.href = "tel:" + dealerData.phoneNumber;
    } else {
      return;
    }
  }

  // useEffect(() => {
  //   if (hasPopupSubmitted) {
  //     setSellerPopupOpen(false);
  //   }
  // }, [hasPopupSubmitted]);

  return (
    <>
      <div className={styles.contactForm}>
        <h4 className={styles.textFade} style={{ alignSelf: "flex-start", width: 312, color: "#0d0a19" }}>
          Verified Seller
        </h4>
        <div className={styles.sellerContact}>
          {dealerData && dealerData.dealerLogo ? (
            <div className={styles.logo}>
              <img src={dealerData?.dealerLogo || ""} alt="logo" />
            </div>
          ) : null}
          <div style={{ display: "flex", flexDirection: "column", gap: 2, paddingBottom: 6 }}>
            {dealerData && dealerData.delearName ? (
              <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                <h2 className={styles.h3} style={{ marginBottom: 2 }}>
                  {dealerData.delearName}
                </h2>
                <VerifiedUserIcon style={{ color: "#7963f0", width: 16, height: 16 }} />
              </div>
            ) : null}
            {dealerData && dealerData.phoneNumber ? (
              <span
                className={styles.labelSquare}
                style={{ backgroundColor: "#fff", color: "#0d0a19", marginTop: 6, fontSize: 16 }}
                onClick={triggerPhoneCall}
              >
                <CallIcon style={{ width: 14, height: 14 }} />
                <span style={{ marginLeft: 6 }}>{dealerData.phoneNumber}</span>
              </span>
            ) : (
              <button
                className={styles.btnSmall}
                onClick={() => {
                  setSellerPopupOpen(true);
                }}
              >
                View phone number
              </button>
            )}
          </div>
        </div>
        {/* <div className={styles.sellerLocation}>
          <img src={map} alt="location map" />
        </div> */}
        {dealerData && (dealerData.rating || dealerData.dealerListedCarCount) && (
          <div
            className={styles.sellerInfo}
            style={{ marginTop: 10, borderRadius: 8, backgroundColor: "#fff", color: "#0d0a19" }}
          >
            {dealerData && dealerData.rating && (
              <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                <StarIcon style={{ width: 20, height: 20, color: "#EF8354" }} />
                <span className={styles.textBigBold} style={{ fontSize: 18 }}>
                  {dealerData.rating}
                </span>
              </div>
            )}
            {dealerData && dealerData.dealerListedCarCount && (
              <p
                className={styles.textFadeBig}
                style={{ textAlign: "center", fontSize: 15, lineHeight: 1.3, color: "#0d0a19" }}
              >
                {`This seller has ${dealerData.dealerListedCarCount} more preapproved cars`}
              </p>
            )}
          </div>
        )}
      </div>
      {sellerPopupOpen ? (
        <ViewSellerPopup closeKycPopup={closeSellerPopup} setDealerData={setDealerData} vehicleId={params.id} />
      ) : (
        ""
      )}
    </>
  );
}

export default ContactForm;
