import React from "react";

function CustomerPolicy() {
  return (
    <section className="commonConditions">
      <div className="container">
        <div className="smallContainer">
          <h1>Warranty Policy and Disclaimer</h1>
          <div>
            {/* <h2>Warranty</h2> */}
            <p>
              Our Extended Warranty Program offers an extensive range of coverage options tailored to your specific
              needs. It provides protection for critical components and systems, including engine & transmission
              warranty and maintenance plans which will be valid for either 6 months (180 days) or 12 months (365 days),
              or until the vehicle reaches 10,000 kilometers (whichever occurs earlier). Based on the package subscribed
              by the customer. The calculation of the coverage period begins from the date of package activation,
              ensuring that the benefits are accessible within the specified timeframe outlined in the subscribed
              package. Rest assured that our program is designed to minimize your out-of-pocket expenses and provide
              prompt assistance in case of any covered repairs. Package type and package details are mentioned below. In
              the unfortunate event that your car requires repairs, our claims process is designed to be hassle-free and
              efficient. Our customer support team will be available to assist you throughout the entire claim process,
              ensuring that your concerns are addressed promptly and professionally. For any query / Complaint, Kindly
              call us at 7353343536 or put a mail to claims@wisedrive.in. or raise a request through our customer
              application.
            </p>
          </div>
          <div>
            <h2>Package Types and Description</h2>
            <p>
              <ul style={{ paddingLeft: 20 }}>
                <li style={{ listStyleType: "disc" }}>Maintenance Package.</li>
                <li style={{ listStyleType: "disc" }}>RSA (Road Side Assistance).</li>
                <li style={{ listStyleType: "disc" }}>Comprehensive Engine and Transmission Warranty</li>
                <li style={{ listStyleType: "disc" }}>Basic Engine and Transmission Warranty</li>
              </ul>
            </p>
          </div>

          <div>
            <h2>The maintenance package includes:-</h2>
            <p>
              Coverage Period: The Maintenance package period begins on the date of package activation and is valid for
              either 6 months (180 days) or 12 months (365 days), or until the vehicle reaches 10,000
              kilometers,(whichever occurs earlier). Based on the package subscribed by the customer
            </p>
          </div>
          <div className="" style={{ paddingLeft: 20 }}>
            <h2 style={{ marginLeft: -20 }}>General Service</h2>
            <p>
              <ul>
                <li style={{ listStyleType: "disc" }}>Engine Oil Replacement</li>
                <li style={{ listStyleType: "disc" }}>Oil Filter Replacement</li>
                <li style={{ listStyleType: "disc" }}>Air Filter Cleaning</li>
                <li style={{ listStyleType: "disc" }}>Brakes Servicing</li>
                <li style={{ listStyleType: "disc" }}>All PMS Related labor works will be performed for the car</li>
              </ul>
            </p>
          </div>

          <div className="" style={{ paddingLeft: 20 }}>
            <h2 style={{ marginLeft: -20 }}>AC Service</h2>
            <p>
              <ul>
                <li style={{ listStyleType: "disc" }}>AC Gas Topup</li>
                <li style={{ listStyleType: "disc" }}>AC Filter Cleaning</li>
                <li style={{ listStyleType: "disc" }}>AC Duct Cleaning</li>
                <li style={{ listStyleType: "disc" }}>AC Vents Cleaning</li>
                <li style={{ listStyleType: "disc" }}>Complete AC System Checkup</li>
              </ul>
            </p>
          </div>

          <div className="" style={{ paddingLeft: 20 }}>
            <h2 style={{ marginLeft: -20 }}>Complete vehicle health checkup</h2>
            <p>
              <ul>
                <li style={{ listStyleType: "disc" }}>Engine oil Checkup</li>
                <li style={{ listStyleType: "disc" }}>Air Filter Checkup and Cleaning</li>
                <li style={{ listStyleType: "disc" }}>AC Filter Checkup and Cleaning</li>
                <li style={{ listStyleType: "disc" }}>Radiator Fan and Functioning Check</li>
                <li style={{ listStyleType: "disc" }}>Entire Electrical System Check</li>
                <li style={{ listStyleType: "disc" }}>The entire Mechanical Systems Check</li>
                <li style={{ listStyleType: "disc" }}>Light/ indicator/Horn Check</li>
                <li style={{ listStyleType: "disc" }}>Tyre Pressure Check</li>
              </ul>
            </p>
          </div>

          <div>
            <h2>RSA (Road Side Assistance ) package includes:-</h2>
            <p>
              Coverage Period: The RSA package period begins on the date of package activation and is valid for either 6
              months (180 days) or 12 months (365 days), or until the vehicle reaches 10,000 kilometers (whichever
              occurs earlier). Based on the package subscribed by the customer.
            </p>
            <br />
            <p>
              <ul style={{ paddingLeft: 20 }}>
                <li style={{ listStyleType: "disc" }}>
                  Puncture repair ( within 30 KM from City Limits at the District level in Karnataka )
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Battery jump start (within 30 KM from City Limits at the District level in Karnataka)
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Roadside minor repair (within 30 KM from City Limits at the District level in Karnataka)
                </li>
              </ul>
            </p>
          </div>

          <div>
            <h2>Comprehensive Engine and Transmission Warranty:-</h2>
            <p>
              Coverage Period: The Engine and Transmission warranty period begins on the date of package activation and
              is valid for either 6 months (180 days) or 12 months (365 days), or until the vehicle reaches 10,000
              kilometers (whichever occurs earlier). Based on the package subscribed by the customer.
            </p>
            <br />
            <p>
              <h2>Covered Components:</h2>
              <h3>Engine Warranty Parts include</h3>
              <p>
                Oil Pump, Crankshaft & Pulley, Big end and main bearings, Connecting rods, Gudgeon pins, piston, and
                rings, inlet and exhaust valves (excluding burnt and pitted valves), Spring and guides, cylinder block,
                cylinder head, camshaft, rocker arms, shaft, timing gears, tensioner bearings, water pump (failure due
                to external damage or corrosion is not covered), inlet and exhaust manifold, Head Gasket, Cylinder Block
                Gasket, Cylinder linear, Oil Sump pan, Oil Sump Gasket, Spark plug, Timing Chain, Timing Pulley Timing
                Belt, Timing Belt tensioner, Engine Packing Kit, Camshaft pulley, Oil Cooler, Starter Motor, Diesel
                filter, Oil Seal, Crank Gears, Valves spring, Flywheel, Rocker Bullet, Oil Drain Bolt, Glow Plug.
              </p>
              <br />
              <h3>Manual Transmission Warranty Parts include</h3>
              <p>Gears, Shaft, Synchromesh hubs, Rings, Selectors, Bearings, Transfer Gears, and Oil Seal.</p>
              <br />
              <h3>Automatic Transmission Warranty Parts include</h3>
              <p>
                Shaft, Gears, Brake/Clutch bands, oil pump, Bearings, Bushes, Valves, Drive plates, Transfer Gears, and
                Transmission Gears.
              </p>
              <br />
              <h3>Basic Engine and Transmission Warranty</h3>
              <p>
                Coverage Period: The engine and Transmission warranty period begins on the date of package activation
                and is valid for either 6 months (180 days) or 12 months (365 days), or until the vehicle reaches 10,000
                kilometers(whichever occurs earlier).Based on the package subscribed by the customer.
              </p>
              <br />
            </p>
          </div>

          <div>
            <h2>Basic Engine and Transmission Warranty</h2>
            <p>
              Coverage Period: The engine and Transmission warranty period begins on the date of package activation and
              is valid for either 6 months (180 days) or 12 months (365 days), or until the vehicle reaches 10,000
              kilometers(whichever occurs earlier).Based on the package subscribed by the customer
            </p>
            <br />
            <p>
              <h2>Covered Components:</h2>
              <h3>Engine Warranty Parts include</h3>
              <p>
                Oil Pump, Crankshaft & Pulley, Big end and main bearings, Connecting rods, Gudgeon pins, piston and
                rings, inlet and exhaust valves (excluding burnt and pitted valves) Spring and guides, cylinder block,
                cylinder head, camshaft, rocker arms, shaft, timing gears, tensioner bearings, water pump (failure due
                to external damage or corrosion is not covered), inlet, and exhaust manifold.
              </p>
              <br />
              <h3>Manual Transmission Warranty Parts include</h3>
              <p>Gears, Shaft, Synchromesh hubs, Rings, Selectors, Bearings, and Transfer Gears.</p>
              <br />
              <h3>Automatic Transmission WarrantyParts include</h3>
              <p>
                Shaft, Gears, Brake/Clutch bands, oil pump, Bearings, Bushes, Valves, Drive plates, Transfer Gears, and
                Transmission Gears.
              </p>
              <br />
            </p>
          </div>

          <div>
            {/* <h2>Note:</h2> */}
            <p>
              We are confident that our Extended Warranty Program will provide you with the peace of mind you deserve,
              knowing that your car is protected by a trusted and reliable provider. Should you have any questions or
              require further assistance, please do not hesitate to contact our dedicated customer support team at +91
              7353343536 or customercare@wisedrive.in. We are here to assist you and ensure that your experience with
              our Extended Warranty Program exceeds your expectations.
            </p>
            <br />
            <p>
              Once again, thank you for choosing Wisedrive Technologies Pvt Ltd. We are excited to have you as our
              valued customer and look forward to providing you with exceptional service and support throughout your
              ownership journey.
            </p>
          </div>
        </div>
      </div>

      <div className="container" style={{ paddingTop: 80 }}>
        <div className="smallContainer">
          <h1>Warranty Exclusions and Terms & Conditions</h1>
          <div>
            {/* <h2>Warranty</h2> */}
            <p>
              Service Inclusive and/or Repair Inclusive are performed as mentioned in the plan details. The customer
              voluntarily opted to purchase Service Inclusive/Repair Inclusive packages, a value-added product offered
              by WISEDRIVE TECHNOLOGIES PVT LTD exclusively for personal use only. To avail of additional benefits by
              payment of additional costs are subject to customer discretion. Hence, WISEDRIVE TECHNOLOGIES PVT LTD
              intends to sell Service Maintenance Plans to the customer through authorized used car dealers and online
              via www.wisedrive.in in India, subject to the Terms and Conditions mentioned below. The customer hereby
              confirms that he has fully understood and accepted the same. Service and Repair Inclusive is only
              applicable to the original factory-built product specifications.
            </p>
          </div>
          <div>
            <h2>Duration of Package Coverage (For All Packages):</h2>
            <p>
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  The package commences at the vehicle's defined warranty start date as per the package activation date
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Costs for services and repairs that were carried out before the purchase of the packages are not
                  covered or cannot be refunded back.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  The package terminates when the time limit threshold is reached, which is valid for either 6 months
                  (180 days) or 12 months (365 days), or until the vehicle reaches 10,000 kilometers,(whichever occurs
                  earlier). Based on the package subscribed by the customer.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  If the car is approved under the cooling period the condition of the cooling period KMS & Days
                  mentioned has to be completed before a warranty claim can be made.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2>Exclusions</h2>
            <p>(The following services are not included in the "Repair Inclusive)</p>
            <p>
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  Fuel and contamination in the fuel system and consequential damage.
                </li>
                <li style={{ listStyleType: "disc" }}>Damage to paint finish, body, covers, and panels.</li>
                <li style={{ listStyleType: "disc" }}>
                  All kind of glass defects and/or accidental damages (e.g. mirrors, windows, headlight housing, optical
                  distortion, spots, blurriness, etc)
                </li>
                <li style={{ listStyleType: "disc" }}>Tyres, wheel balance weights, and wheel damage.</li>
                <li style={{ listStyleType: "disc" }}>
                  Repairs for which the vehicle user is responsible (engine damage due to deficiency of oil, overdue oil
                  change and participation in racing events).
                </li>
                <li style={{ listStyleType: "disc" }}>Hydrostatic lock, flood and/or accidental damages.</li>
                <li style={{ listStyleType: "disc" }}>Towing costs in case of break down.</li>
                <li style={{ listStyleType: "disc" }}>Bulb, etc. (except LED and Xenon Lights).</li>
                <li style={{ listStyleType: "disc" }}>
                  Odors and dust accumulation-related defects like wiper washing system nozzles, etc.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Repair or results caused by the usage of original/non-original accessories.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Accidental and general damages caused by external forces, collision, flood, corrosion, fire, theft,
                  rat bite or any consequential damages occurred due to external factors and not related to any
                  manufacturing defect are not covered under Wisedrive prepaid maintenance plan.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All adjustments like Wheel Alignment, Balancing, Head light alignment etc are not covered under
                  Wisedrive prepaid maintenance plan.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Body and Paint surface related concerns are not covered under Wisedrive prepaid maintenance plan.
                  Schedule Maintenance related parts like lubricants, filters, brake fluids, steering fluids, drive
                  Belts, spark plug, Coolant, wiper etc as applicable.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All consumables like Brake Cleaners, Grease, Ac gas, additives, Fuse and Relay.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Complete Exhaust system like Silencer pipe, Muffler, Catalytic Converter, Diesel Particulate Filter.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All batteries like Starter batteries, Auxiliary batteries, and Remote key batteries.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All light assemblies including Headlights, Tail lights, fog lamps.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All wear and tear parts like wheels, Brake pads and Discs, Clutch plate, Suspension related rubber
                  parts like, bushes, Shock absorbers, Boot shock absorbers, Air suspension struts, Air Bellows, Sunroof
                  rail Sunroof Guide, pulleys, Plastic and Rubber parts.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All rubber mountings like Engine mountings, Transmission mountings, exhaust pipe mounting.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Charges towards Diagnosing, Checking/testing, Software updates and Adaptation.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All accessories and external fitted parts like entertainment system, add on kits.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Multimedia remote, center locking remote, Dashboard, center console, Arm rest, clock, Roof lining,
                  sunroof curtain, Floor carpets, Sound and heat insulations.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All consequential damages caused due to factors like water seepage/ingress to electric and
                  non-electric components.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All failures reported due to delay or lapse in carrying out scheduled maintenance/repairs at
                  authorized dealership.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All consequential damages or failure due to fitment/usage of non-genuine spare parts, lubricants or
                  accessories.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All consequential failures reported due to usage of adulterated or wrong fuel.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All Pre-existing defects observed/captured during evaluation or reported before the issue of the
                  policy until it is rectified from an Authorized dealership.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All claims reported on a vehicle which was declared cash loss/total loss by insurance or any competent
                  authority and then repair.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All failures and consequential failures reported due to continuous usage of vehicle in spite of
                  getting fault codes or failure to identify the problem in the vehicle.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Wisedrive Warranty Package does not include any reimbursements for any external repairs/replacements
                  done by you or for any other accidental damages.
                </li>
                <li style={{ listStyleType: "disc" }}></li>
              </ul>
            </p>
          </div>
          <div>
            <h2>This warranty shall not apply to:</h2>
            <p>
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  Normal maintenance services including without limitation, cleaning & polishing, injector cleaning,
                  engine decarburizing, engine tuning, oil/fluid changes, filters replenishment, fastener retightening,
                  adjustments of doors, brake and clutch, wheel balancing, wheel alignment, Tire rotation, headlamp
                  alignment.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Replacement of parts as a result of normal wear and tear, such as spark plugs, belts, brake pads and
                  brake shoes, brake discs, clutch disc/pressure plate, filters, wiper blades, bulbs, fuses, brushes,
                  hoses, Tire/tube, battery, audio system, audio video navigation (AVN), catalytic converter,
                  silencer/exhaust pipe.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Tie Rod ends, Steering Ball Joints, Rear Shockers, Front Struts, Tail Door lifters, and Front & Rear
                  Wheel Bearings/wheel hubs are not covered beyond 60,000 Km of operation.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Rubber parts like Wheel Cylinder kit, all doors weather-strips/beadings & A/C "O" rings, Clutch cable,
                  Accelerator cable, Speedometer cable & Tail Door Opener cable.
                </li>
                <li style={{ listStyleType: "disc" }}>Leakage of A/C refrigerant.</li>
                <li style={{ listStyleType: "disc" }}>
                  Normal aging, deterioration, or rusting of plated parts, paint coat, rubber parts, upholstery, soft
                  trim, etc.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Body, paint, glass, interior/exterior trim, exhaust systems, and normal wear and tear of any other
                  component.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Wisedrive policy has a default cooling period of 10 days; any claim registered within this period will
                  not be covered or approved.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  If the Vehicle model is equipped with an LPG Kit/ CNG Kit (Either Manufacturer fitted or Locally
                  fitted) is not applicable for Wisedrive Extended Warranty.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2>Damage or failure resulting from:</h2>
            <p>
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  Warranties will become void and will not be reinstated if the car is ‘not’ serviced in a timely manner
                  by the customer who is: 1st General Service*- Done within 3 months or up to 3,000 Kms from the date of
                  Warranty package activation (whichever occurs first).
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Misuse, abuse (such as racing, overloading, riding over the clutch), accident or collision, theft,
                  flooding, or fire.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Use of improper, adulterated, or insufficient fuel, fluids, or lubricants.
                </li>
                <li style={{ listStyleType: "disc" }}>Use of parts other than OEM Genuine Parts.</li>
                <li style={{ listStyleType: "disc" }}>
                  Any device and/or accessories not supplied or not approved by Wisedrive.
                </li>
                <li style={{ listStyleType: "disc" }}>Modifications, alterations, tampering, or improper repair.</li>
                <li style={{ listStyleType: "disc" }}>
                  Parts used in applications for which they were not designed or not approved by Wisedrive.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Any repair carried out other than by Wisedrive Authorized Dealer / Service Centre.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Slight irregularities not recognized as affecting the quality or function of the vehicle or parts,
                  such as slight noise or vibrations, or items considered characteristic of the vehicle.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Airborne fallout, Industrial fallout, acid rain, flood, hail, wind storms, or other Acts of God.
                </li>
                <li style={{ listStyleType: "disc" }}>Paint scratches, dents, or similar paint or body damage.</li>
                <li style={{ listStyleType: "disc" }}>
                  Revolution or Mutiny, any intentional or mollified act of the owner or a driver, abuse of the vehicle,
                  negligence.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  The action of road elements (sand, gravel, dust, or road debris) results in stone chipping of
                  paint/glass or damage to any other part.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  The vehicle which has been used for any sort of competition, sport, rally, transport for hire of
                  persons or goods, Taxi service or short or long-term self-drive hire or driving school, or any
                  commercial use.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Any vehicle purchased as a taxi under the special excise concessions or registered as a Tourist taxi
                  with the Transport Authorities.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  The vehicle in which the odometer has been tampered with, changed, or disconnected.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Incidental or consequential damages, including without limitation, loss of time, inconvenience, loss
                  of use of vehicle, or commercial loss.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  No liability will be accepted for any losses covered under an accidental damage or road risk policy or
                  for any road hazard/fire or accident damage, no liability of any kind exists in respect of a third
                  party.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  No liability will be accepted for damage caused by neglect, intrusion of foreign or harmful or
                  injurious matter, lack of servicing, overheating, freezing, or abuse to the continued use of the
                  vehicle after a fault has become evident or for consequential loss on the failure of parts not is
                  covered by this Extended Warranty.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2>OTHER TERMS:</h2>
            <p>
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  Service Inclusive and/or Repair Inclusive packages are available for all used cars* across all model
                  ranges (Except Luxury Vehicles) on sale in India.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  The customer needs to order Service and/or Repair Inclusive through the Wisedrive Mobile app in my
                  plans section or through customer support at +91 7353343536.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All servicing and maintenance jobs must be carried out at a Wisedrive Authorised Service Workshop or
                  by Wisedrive Approved Technicians.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Service Inclusive and/or Repair Inclusive is transferable to subsequent owners after re-inspection
                  approval but not transferable to another car at a fee.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  The customer can request for cancellation of the Service Inclusive and/or Repair Inclusive Package
                  within two weeks from the date of activation of the package provided no services have been availed on
                  the car under any scope. No cancellation or refund will be made after the above-stated period.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  In the event the vehicle covered under Service Inclusive and/or Repair Inclusive is involved in an
                  accident rendering it beyond economical repairs/total loss, Wisedrive Service maintenance Plan
                  Services Inclusive and/or Wisedrive Repair Inclusive cover will be annulled and no reimbursement will
                  be made for the remaining period of the validity. No cancellation request can be processed either.
                </li>
                <li style={{ listStyleType: "disc" }}>There are no refunds for past service jobs.</li>
                <li style={{ listStyleType: "disc" }}>
                  WISEDRIVE TECHNOLOGIES PVT LTD reserves the right to discontinue, alter, adjust, add, or remove any
                  packages, the scope of services, exclusions, inclusions, etc. without any prior notice. For the latest
                  Terms and Conditions, policy and prices, etc. please visit the website www.wisedrive.in
                </li>
                <li style={{ listStyleType: "disc" }}>
                  The construction, interpretation, and meaning of the provisions of these Terms and Conditions shall be
                  determined in accordance with Indian law. The Bangalore Court shall have exclusive jurisdiction.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  The warranty Holder must confirm with WISEDRIVE TECHNOLOGIES PVT LTD before proceeding with any type
                  of vehicle repair during the Warranty period. In case any repair is conducted without prior approval
                  and confirmation, the Warranty will be invalidated.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  No dealer or its agent or employee is authorized to extend or enlarge this warranty and no dealer or
                  its agent or employee is authorized to make any oral warranty on Wisedrive behalf. Warranty service
                  shall be provided only by Wisedrive authorized dealers and Dealer Service Branch. Wisedrive decision
                  is final and binding on the Owner of the vehicle in all warranty matters. Wisedrive reserves the right
                  to the final decision on all warranty matters.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2>Customer Obligation: Fitness Certificate Requirement for Warranty Claim</h2>
            <p>
              <ul>
                <ul>
                  <li style={{ listStyleType: "disc" }}>
                    In order to be eligible for a warranty claim, it is a prerequisite for the customer to possess a
                    valid fitness certificate for the vehicle. This requirement applies specifically to vehicles that
                    are more than 15 years old or as stipulated by the prevailing government regulations. Failure to
                    provide a valid fitness certificate when filing a warranty claim will result in the rejection of the
                    claim.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    It is essential to understand that the presentation of a fitness certificate is a mandatory
                    condition for the approval of warranty claims. This requirement is in place to ensure compliance
                    with legal and regulatory standards.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    We strongly urge customers to ensure they possess a valid fitness certificate for their vehicle
                    before attempting to raise a warranty claim. Failure to meet this requirement may result in the
                    denial of the claim, as per the terms and conditions set forth by Wisedrive.
                  </li>
                </ul>
              </ul>
            </p>
          </div>
          <div>
            <h2>Customer Obligation: New RC Card Update for Wisedrive Warranty</h2>
            <p>
              <ul>
                <ul>
                  <li style={{ listStyleType: "disc" }}>
                    The customer is solely responsible for promptly updating the new Registration Certificate (RC) card
                    of the vehicle with Wisedrive within a period of 45 days from the date of activating the Wisedrive
                    Warranty. Failure to fulfill this obligation within the specified 45-day timeframe will
                    automatically render the Wisedrive Warranty void and without effect.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    In the event of voiding the warranty, the customer may request a reinspection of the vehicle by the
                    Wisedrive team to reinstate the warranty package. However, the availability of inspection slots is
                    subject to the discretion and scheduling of the Wisedrive team. During the period between warranty
                    voiding and completion of the reinspection, the warranty shall remain non-claimable and void. The
                    continuation of the car warranty is subject to the discretion of the Wisedrive team, and their
                    findings during the inspection process.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    It is important to note that Wisedrive reserves the right to reject the reapproval of the warranty
                    based on the results of the inspection.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    We emphasize the significance of complying with this obligation to ensure the uninterrupted validity
                    and coverage of your Wisedrive Warranty.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    In order to make a warranty claim, it is imperative that the car owner's name and the details
                    provided in the vehicle's Registration Certificate (RC book) align with the information stated in
                    the warranty policy. Any discrepancy or inconsistency between the owner's details and the warranty
                    documentation may result in the rejection of the warranty claim.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    It is the responsibility of the car owner to ensure that the information contained in the RC book,
                    including their name, address, and other relevant particulars, accurately corresponds to the details
                    provided in the warranty policy. Any changes or updates to the information should be promptly
                    communicated to the Wisedrive authorities and duly reflected in both the RC book and warranty
                    documentation.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Wisedrive reserves the right to reject warranty claims if there is a failure to comply with these
                    requirements. It is recommended that car owners meticulously review and verify the accuracy of their
                    information in both the RC book and warranty policy to avoid any potential complications or warranty
                    claim rejections.
                  </li>
                </ul>
              </ul>
            </p>
          </div>
          <div>
            <h2>Owner’s Responsibilities:</h2>
            <p>
              <ul>
                <ul>
                  <li style={{ listStyleType: "disc" }}>
                    Proper use, maintenance, and care of the vehicle in accordance with the instructions contained in
                    the Owner’s Manual and Service Booklet. If the vehicle is subject to severe usage conditions, such
                    as operation in extremely dusty, rough, more repeated short-distance driving or heavy city traffic
                    during hot weather, maintenance of the vehicle should be done more frequently as mentioned in the
                    Owner’s Manual and Service Booklet.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    In order to maintain the validity of this Extended Warranty, the vehicle must be serviced by
                    Wisedrive Authorized Service Centres/Dealers in accordance with the Owner’s Manual and Service
                    Booklet.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Retention of maintenance service records. It may be necessary for the customer to show that the
                    required maintenance has been performed, as specified in the Owner’s Manual and Service Booklet.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Delivery of the vehicle along with the Owner's Manual and Service Booklet and Extended Warranty
                    Certificate during regular service business hours to any authorized Wisedrive Dealer to obtain
                    warranty service.
                  </li>
                </ul>
              </ul>
            </p>
          </div>
          <div>
            <h2>Driver/Manual Errors Not Covered Under Warranty Claim</h2>
            <p>
              <ul>
                <p style={{ listStyleType: "disc" }}>Failure to maintain proper oil levels:</p>
                <ul>
                  <li style={{ listStyleType: "disc" }}>
                    Neglecting to regularly check and maintain engine oil at recommended levels.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Insufficient lubrication leads to increased friction and wear on engine components.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Potential consequences: Engine failure, damage to internal parts.
                  </li>
                </ul>
                <br />
                <p style={{ listStyleType: "disc" }}>Ignoring oil change intervals:</p>
                <ul>
                  <li style={{ listStyleType: "disc" }}>
                    Neglecting timely oil changes causes oil degradation and reduced lubrication.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Contaminated oil leads to increased engine wear and potential failure.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Potential consequences: Engine failure, and decreased engine performance.
                  </li>
                </ul>
                <br />
                <p style={{ listStyleType: "disc" }}>Overheating the engine:</p>
                <ul>
                  <li style={{ listStyleType: "disc" }}>
                    Allowing the engine to overheat due to cooling system malfunctions or low coolant levels.
                  </li>
                  <li style={{ listStyleType: "disc" }}>Excessive stress on the engine leads to severe damage.</li>
                  <li style={{ listStyleType: "disc" }}>
                    Potential consequences: Warped cylinder heads, blown head gaskets, seized engine.
                  </li>
                </ul>
                <br />
                <p style={{ listStyleType: "disc" }}>Using incorrect fuel or oil:</p>
                <ul>
                  <li style={{ listStyleType: "disc" }}>
                    Using the wrong type of fuel (e.g., gasoline instead of diesel) or improper engine oil.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Interference with engine combustion process and poor performance.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Potential consequences: Engine damage, and decreased fuel efficiency.
                  </li>
                </ul>
                <br />
                <p style={{ listStyleType: "disc" }}>Ignoring warning signs:</p>
                <ul>
                  <li style={{ listStyleType: "disc" }}>
                    Disregarding engine warning signs like unusual noises, excessive smoke, loss of power, or dashboard
                    warning lights.
                  </li>
                  <li style={{ listStyleType: "disc" }}>Failure to address early indications of engine problems.</li>
                  <li style={{ listStyleType: "disc" }}>
                    Potential consequences: Further damage, engine failure, compromised safety.
                  </li>
                </ul>
                <br />
                <p style={{ listStyleType: "disc" }}>Over-revving or redlining the engine:</p>
                <ul>
                  <li style={{ listStyleType: "disc" }}>
                    Revving the engine excessively or operating it consistently at high RPMs.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Placing excessive stress on engine components and accelerating wear.
                  </li>
                  <li style={{ listStyleType: "disc" }}>
                    Potential consequences: Increased engine wear, and reduced engine lifespan.
                  </li>
                  <li style={{ listStyleType: "disc" }}>Improper maintenance and neglect:</li>
                  <li style={{ listStyleType: "disc" }}>
                    Failing to perform routine maintenance tasks (e.g., timing belt replacement, spark plug replacement,
                    air filter replacement).
                  </li>
                  <li style={{ listStyleType: "disc" }}>Deterioration of engine components due to neglect.</li>
                  <li style={{ listStyleType: "disc" }}>
                    Potential consequences: Engine issues, decreased performance, increased risk of failure.
                  </li>
                </ul>
              </ul>
            </p>
            <br />
            <p>
              It's important to note that this list is not exhaustive, and specific vehicle models may have additional
              guidelines and requirements outlined in their respective owner's manuals. Always refer to the
              manufacturer's recommendations and follow proper maintenance procedures to ensure the longevity and
              performance of your vehicle.
            </p>
          </div>

          <div>
            <h2>Important Note:</h2>
            <p>
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  This Extended Warranty is transferable to subsequent owners for the remaining Warranty Period after
                  re-inspection approval.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Wisedrive has the right to cancel/decline the warranty transfer request based on reinspection.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  This Extended Warranty scheme has no surrender value, no refund of warranty premium is available and
                  it cannot be transferred to another vehicle.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Extended Warranty is not applicable on Taxi/Fleet and Test Drive vehicles.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All Maintenance services and warranty claim repairs will be exclusively conducted at the district
                  level within Karnataka state at Wisedrive Authorised/approved Service Center/Workshop.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Valid vehicle insurance must be presented by the warranty holder at the time of submitting/requesting
                  a warranty claim. Failure to provide proof of insurance will lead to the rejection of the warranty
                  claim.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Warranty Claim requests for warranty coverage outside of Karnataka state will not be considered
                  feasible or viable.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All Maintenance services for service requests outside of Karnataka state will not be considered
                  feasible or viable.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  Extended Warranty Prices are subject to change without any prior notice.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All above Extended Warranty options are subject to change/obsolete without any prior notice.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  All disputes arising out of this Extended Warranty will be subject to the jurisdiction of Courts in
                  Bangalore only.
                </li>
              </ul>
            </p>
          </div>

          <div>
            <h2>Note:</h2>
            <p>
              Wisedrive reserves the right to make improvements, or change the features or the design of all Wisedrive
              coverage plans at any time with no obligation to make similar changes on the vehicle already assured. For
              the latest Terms and Conditions, policy and prices, etc. please visit www.wisedrive.in or reach out to
              Wisedrive customer support on 7353343536 over the phone.
            </p>
            <br />
            <p>
              We hope the information shown above is found by you correct. For detailed terms & conditions (Policy
              Wordings) governing this policy, kindly refer to our website www.wisedrive.in In case of any query, you
              may contact our customer care service number at +91 7353343536 .
            </p>
            <br />
            <p>We look forward to having a long-term & fruitful relationship.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CustomerPolicy;
