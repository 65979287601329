import React, { useEffect, useState, useRef } from "react";
import Back from "../../assets/images/home/back.svg";
import Arrow from "../../assets/images/home/rightArrow.svg";
import Whitestar from "../../assets/images/checkot-plans/whitestar.svg";
import orangeStar from "../../assets/images/checkot-plans/orangeStar.svg";
import GreenStar from "../../assets/images/checkot-plans/greenStar.svg";
import verifiedUser from "../../assets/images/checkot-plans/verifiedUser.svg";
import BuleArrow from "../../assets/images/checkot-plans/BlueRightArrow.svg";
import Carparts from "../../assets/images/checkot-plans/explorePayment.png";
import { Link, useParams } from "react-router-dom";
import { getPackageDetails, getVehicleDetails } from "../../services/getInspectionReport";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getPackageDetailsApi, subPackageListApi } from "../../Api/Apis";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Banner = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [count, setCount] = useState(1);
  const [unlockCount, setUnlockCount] = useState(1);
  const [carData, setCarData] = useState([]);
  const [packagedata, setPackageData] = useState([]);
  const vehicleId = sessionStorage.getItem("vehicleId");
  const [grandTotal, setGrandTotal] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const lead_Id = sessionStorage.getItem("lead_Id");
  const phone = sessionStorage.getItem("phone");
  const [packageList, setPackageList] = useState([]);
  const [packageSubList, setPackageSubList] = useState([]);
  const [unlockSingleModule, setunlockSingleModule] = useState([]);
  const [unlockBundleModule, setUnlockbundleModule] = useState([]);
  const [packageId, setPackageId] = useState(0);
  const [subPackageList, setSubPackageList] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [selected, setSelected] = useState(0);
  const [subSelected, setSubSelected] = useState(0);

  const ref = useRef();

  const getPackageList = async () => {
    try {
      const resp = await getPackageDetailsApi();
      if (resp.status === 200) {
        setPackageList(resp?.data?.response?.PackDetails);
        setPackageId(resp?.data?.response?.PackDetails[0]?.id);
        // console.log(resp, "checking");
        setPackageSubList(resp?.data?.response?.PackDetails[0]?.SubPackDetails);
        subPackage(resp?.data?.response?.PackDetails[0]?.SubPackDetails[0]?.id);
        setCouponDiscount(0);
        calculateGrandTotal(
          resp?.data?.response?.PackDetails[0]?.amount,
          resp?.data?.response?.PackDetails[0]?.dicount,
        );
        setQuantity(resp?.data?.response?.PackDetails[0]?.quantity);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPackageList();
  }, []);

  const subPackage = async (packageId) => {
    try {
      const resp = await subPackageListApi(packageId);
      if (resp.status === 200) {
        // console.log(resp, "subPackageList");
        setSubPackageList(resp?.data?.response?.PackageDescription);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   subPackage();
  // }, [packageId, count]);

  const calculateGrandTotal = (total, discount) => {
    const discountPrice = (total * discount) / 100;
    const totalPrice = total - discountPrice;
    setGrandTotal(totalPrice);
    console.log(totalPrice);
  };

  const handleCoupon = () => {
    if (coupon === "WSD100" && grandTotal >= 150) {
      setCouponDiscount(100);
    }
  };

  const checkout = async () => {
    const data = {
      leadEmail: "",
      leadPhone: phone ?? "",
      leadName: "",
      orderAmount: grandTotal,
      leadId: lead_Id ?? "",
    };

    try {
      const response = await axios.post(`${baseUrl}cashfree/generateOrder`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.responseType === "200") {
        sessionStorage.setItem("packageId", packageId);
        sessionStorage.setItem("refeId", response.data.response.CashfreeorderData.cf_order_id);
        sessionStorage.setItem("quantity", quantity);
        navigate(`/checkout/${response.data.response.CashfreeorderData.payment_session_id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(packageSubList);

  const getDate = (data) => {
    // console.log(data);
  };

  return (
    <>
      <section className="paymentBanner">
        <div className="container">
          <div className="paymentContent">
            {/* <Link to="/">
              <img src={Back} alt="back" />
            </Link> */}
            <div></div>
            <div>
              <h1>Simple & Transparent pricing</h1>
              <p>Simple plans to access warranty cars in and buy the best car.</p>
            </div>
            <>&nbsp;</>
          </div>

          <div className="paymentTabs">
            <div className="paymentTabsTitles">
              {packageList &&
                packageList.map((each, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        selected === index ? "paymentTabsTitle paymentTabsTitleMobGreen current" : "paymentTabsTitle "
                      }
                    >
                      <h2
                        onClick={() => {
                          setCouponDiscount(0);
                          calculateGrandTotal(each?.amount, each?.dicount);
                          setPackageId(each?.id);
                          setQuantity(each?.quantity);
                          setPackageSubList(each?.SubPackDetails);
                          subPackage(each?.id);
                          setSelected(index);
                          getDate(each);
                          setSubSelected(0);
                        }}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g mask="url(#mask0_4771_34425)">
                            <path
                              className="circle"
                              d="M12 17C13.3833 17 14.5627 16.5123 15.538 15.537C16.5127 14.5623 17 13.3833 17 12C17 10.6167 16.5127 9.43733 15.538 8.462C14.5627 7.48733 13.3833 7 12 7C10.6167 7 9.43767 7.48733 8.463 8.462C7.48767 9.43733 7 10.6167 7 12C7 13.3833 7.48767 14.5623 8.463 15.537C9.43767 16.5123 10.6167 17 12 17Z"
                              fill="url(#paint1_linear_4771_34425)"
                            />
                            <path
                              d="M12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                              fill="url(#paint2_linear_4771_34425)"
                            />
                          </g>
                          <defs>
                            <linearGradient
                              id="paint0_linear_4771_34425"
                              x1="0"
                              y1="0"
                              x2="28.6597"
                              y2="8.76687"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#2836D7" />
                              <stop offset="1" stop-color="#8E93D7" />
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_4771_34425"
                              x1="7"
                              y1="7"
                              x2="18.9416"
                              y2="10.6529"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#2836D7" />
                              <stop offset="1" stop-color="#8E93D7" />
                            </linearGradient>
                            <linearGradient
                              id="paint2_linear_4771_34425"
                              x1="2"
                              y1="2"
                              x2="25.8831"
                              y2="9.30572"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#2836D7" />
                              <stop offset="1" stop-color="#8E93D7" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <span>{each?.display_name}</span>
                      </h2>
                      {selected === index && (
                        <div className="paymentTabsContent mobile paymentTabsContentPhase3">
                          <div className="unlockTab">
                            {packageSubList &&
                              packageSubList.map((each, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={`${subSelected === index ? "unlockTitile current" : "unlockTitile"}`}
                                    onClick={() => {
                                      setCouponDiscount(0);
                                      calculateGrandTotal(each?.amount, each?.dicount);
                                      setPackageId(each?.id);
                                      setQuantity(each?.quantity);
                                      subPackage(each?.id);
                                      setSubSelected(index);
                                    }}
                                  >
                                    <p>{each?.quantity} Cars</p>
                                    <h3>
                                      ₹ {each?.amount} <br />
                                      <span>{each?.dicount}% Discount</span>
                                    </h3>
                                  </div>
                                );
                              })}
                          </div>

                          <div className="tabData">
                            <ul className="tabList">
                              {subPackageList &&
                                subPackageList.map((each) => <li key={each.description_id}>{each.description}</li>)}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>

            <div className="paymentTabsContainer desktop">
              <div className="paymentTabsContent">
                <div className="unlockTab">
                  {packageSubList &&
                    packageSubList.map((each, index) => {
                      return (
                        <div
                          key={index}
                          className={`${subSelected === index ? "unlockTitile current" : "unlockTitile"}`}
                          onClick={() => {
                            setCouponDiscount(0);
                            calculateGrandTotal(each?.amount, each?.dicount);
                            setPackageId(each?.id);
                            setQuantity(each?.quantity);
                            subPackage(each?.id);
                            setSubSelected(index);
                          }}
                        >
                          <p>{each?.quantity} Cars</p>
                          <h3>
                            ₹{each?.amount} <span>{each?.dicount}% Discount</span>
                          </h3>
                        </div>
                      );
                    })}
                </div>
                <div className="tabData" style={{ height: "200px", overflow: "auto" }}>
                  <ul className="tabList" style={{ height: "200px", overflow: "auto" }}>
                    {subPackageList &&
                      subPackageList.map((each) => <li key={each.description_id}>{each.description}</li>)}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="paymentAmount">
            <div className="paymentCoupon"></div>
            <div className="paymentTotal">
              <div>
                <p>Total (INR)</p>
                <span>₹{grandTotal}</span>
              </div>
              {/* <div>
                <p>Coupon Discount</p>
                <span>₹{couponDiscount}</span>
              </div> */}
              <div>
                <p>Subtotal</p>
                <span>₹{grandTotal - couponDiscount}</span>
              </div>
              <div className="seperator desktop">
                <>&nbsp;</>
              </div>
              <div className="desktop">
                <h4>Total (INR)</h4>
                <h3>{grandTotal - couponDiscount}</h3>
              </div>
              <div className="desktop">
                <button onClick={() => checkout()}>
                  Proceed <img src={Arrow} alt="" />
                </button>
              </div>
            </div>

            <div className="totalAmount mobile">
              <div>
                <h4>Total (INR)</h4>
                <h3>
                  <span style={{ color: "#202020", fontWeight: 600 }}>₹{grandTotal}</span>
                </h3>
              </div>
              <div>
                <button onClick={() => checkout()}>
                  Proceed <img src={Arrow} alt="" />
                </button>
              </div>
            </div>
          </div> 
        </div>
      </section>
    </>
  );
};

export default Banner;
