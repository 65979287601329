import React, { useRef, useState } from "react";
import SearchPagination from "./SearchPagination";
import SearchLabels from "./SearchLabels";
import styles from "./searchResulstNew.module.css";
import ResultCardSquare from "./ResultCardSquare";
import ResultCard2 from "./ResultCard2";
import styles2 from "./card2.module.css";
import styles1 from "./card1.module.css";
import FilterListIcon from "@mui/icons-material/FilterList";

import { PortalContext } from "../../context/carPortalContext";

import {
  // GetAllCarsList,
  GetCarPortalLandingSummary,
  GetDynamicData,
  GetLinkListedCars,
  SendShortListedVehicle,
} from "../../Api/PortalApis";
import { useEffect } from "react";
import useWindowDimensions from "../util-hooks/useWindowDimentions";
import { useParams } from "react-router-dom";
import NoResultsFound from "./child-components/NoResultsFound";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import DoneIcon from "@mui/icons-material/Done";

import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import wimg from "../../assets/CarImages/wimg.png";
import bbgimg from "../../assets/CarImages/bbgimg.png";
import ssimg from "../../assets/CarImages/ssimg.png";

function sum(obj) {
  var sum = 0;
  for (var el in obj) {
    if (obj.hasOwnProperty(el)) {
      sum += parseFloat(obj[el]);
    }
  }
  return sum;
}

const SearchResultsNew = React.forwardRef((props, ref) => {
  // console.log(ref);
  const {
    // currentPageCarList,
    // setLinkId,
    allFilteredData,
    totalCars,
    setTotalCars,
    setFiltersFromSession,
    // setAllCarsList,
    // setAllFilteredData,
  } = useContext(PortalContext);

  const [squareDesignActive, setSquareDesignActive] = useState(true);
  const { width, height } = useWindowDimensions();
  const [lid, setLid] = useState(false);
  const [linkListLoading, setLinkListLoading] = useState(false);
  const [squareTempRows, setSquareTempRows] = useState("");
  const location = useLocation();

  const [buttonData, setButtonData] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const containerRef = useRef(null);

  // console.log(scrollPosition);

  useEffect(() => {
    const handleScroll = () => {
      // console.log(containerRef.current.scrollTop);
      if (containerRef.current) {
        setScrollPosition(containerRef.current.scrollTop);
      }
    };

    containerRef.current.addEventListener("scroll", handleScroll);

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerRef.current]);

  useEffect(() => {
    const storedScrollPosition = localStorage.getItem("scrollPosition");
    if (storedScrollPosition) {
      setScrollPosition(parseInt(storedScrollPosition, 10));
      if (containerRef.current) {
        containerRef.current.scrollTo(0, parseInt(storedScrollPosition, 10));
      }
    }
  }, [containerRef.current]);

  function setScroll() {
    localStorage.setItem("scrollPosition", scrollPosition.toString());
  }

  // console.log("buttonData: ", buttonData);

  async function getCarsSummary() {
    try {
      const resp = await GetCarPortalLandingSummary();
      if (resp.status === 200) {
        const totalCars = sum(resp.data.carBody) + resp.data.additionalCount;
        setTotalCars(totalCars);

        // setCarBrandSummary(resp.data)
      }
    } catch (error) {
      console.error("error while fetching cars summary", error);
    }
  }

  useEffect(() => {
    setFiltersFromSession();
    getCarsSummary();
  }, []);

  async function getDynamicData() {
    try {
      const resp = await GetDynamicData();
      if (resp.status === 200) {
        // console.log(resp.data);
        const btnData = {
          toplabel: resp.data.warrantybutton,
          bottomLabel: resp.data.packageName,
          toplabelMob: resp.data.warrantybuttonmobile,
          bottomLabeMob: resp.data.packagenamemobile,
          bottomLabelMob: resp.data.packagenamemobile,
        };
        setButtonData(btnData);
      }
    } catch (error) {
      console.error("error getting dynamic data", error);
    }
  }

  function toggleDesign() {
    setSquareDesignActive((isActive) => !isActive);
  }

  useEffect(() => {
    // if (width <= 1440) {
    //   setSquareTempRows("repeat(2, 375px)");
    // }
    // if (width <= 1234) {
    //   setSquareTempRows("repeat(2, 315px)");
    // }

    // if (width <= 1054) {
    //   setSquareTempRows("repeat(3, 375px)");
    // }
    // if (width <= 940) {
    //   setSquareTempRows("repeat(2, 375px)");
    // }
    // if (width <= 762) {
    //   setSquareTempRows("repeat(3, 375px)");
    // }

    // if (width <= 510) {
    //   setSquareTempRows(`repeat(${allFilteredData.length}, 375px)`);
    // }

    if (width <= 762) {
      setSquareDesignActive(false);
    } else {
      setSquareDesignActive(true);
    }
  }, [width, allFilteredData]);

  useEffect(() => {
    getDynamicData();
  }, [location.pathname]);

  return (
    <div
      className={styles.searchBody}
      ref={containerRef}
      style={{ maxHeight: width <= 510 ? "none" : "calc(100vh - 6rem)" }}
    >
      <div className={styles.designToggleBtn} style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
        {/* <button onClick={toggleDesign}>Toggle Design</button> */}
        <div onClick={props.toggleFilter} className={styles.openFilterIcon}>
          {/* <FilterListIcon /> */}
          <h3
            style={{
              padding: "8px 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              backgroundColor: "#eee",
              backgroundColor: "#e9f0",
              borderRadius: "10px",
              color: "#0d0a19",
              fontSize: 14,
              fontWeight: 500,
              paddingRight: 16,
            }}
          >
            <TuneIcon style={{ width: "20px", height: "20px" }} />
            <span style={{ fontWeight: 600 }}>Filter</span>
            {/* {isFilterOpen && <CloseIcon className={styles.closeFilterIcon} onClick={toggleFilter} />} */}
          </h3>
        </div>
        <div className={styles.saparator}></div>
        <div className={styles.shortlistLabel}>
          <FavoriteBorderOutlinedIcon style={{ color: "#ff6b00" }} />
          <span>Shortlist</span>
        </div>
        <div className={styles.saparator}></div>
        <div className={styles.locationLabel}>
          {/* icon */}
          <LocationOnOutlinedIcon />
          <span>Bangalore</span>
        </div>

        {/* // old text shown
          // <div>
          //   <p>
          //     <span style={{ fontWeight: "bold" }}>{totalCars}</span> warranty cars with buyback guarantee available in{" "}
          //     <span style={{ fontWeight: "bold" }}>Bangalore</span>
          //   </p>
          // </div> */}

        {/* <label className={styles.switch}>
          <input className={styles.toggleInput} type="checkbox" onClick={toggleDesign} />
          <span className={`${styles.slider} ${styles.round}`}></span>
        </label> */}
      </div>
      {/* <SearchLabels toggleFilter={toggleFilter} /> */}
      {/* </div> */}
      {/* {!squareDesignActive && <br />} */}
      {/* {allFilteredData.length === 0 && <NoResultsFound />} */}
      {squareDesignActive && (
        <div
          // className={width <= 400 ? styles1.SearchResultsGridMob : styles1.SearchResultsGrid}
          className={styles1.SearchResultsGrid}
          style={{
            gridTemplateColumns: allFilteredData.length < 3 && "repeat(auto-fit, minmax(280px, 340px))",
            // gridTemplateRows: squareTempRows,
          }}
        >
          {allFilteredData.length === 0
            ? null
            : allFilteredData.map((carInfo, index) => (
                <>
                  <ResultCardSquare key={index} carInfo={carInfo} btnData={buttonData} setScroll={setScroll} />
                  {(index + 1) % 6 === 0 && (
                    <>
                      {/* Render static content boxes */}
                      {/* <div className={styles2.infoCards}> */}
                      <div className={styles.infoInnerCard}>
                        <div className={styles.bgImgContainer}>
                          <img src={wimg} alt="warrany" />
                        </div>
                        <div className={styles.logoContainer}>
                          <div className={styles.infoInnerLogo}>
                            <span>FREE</span>
                          </div>
                        </div>
                        <h2>Comprehensive Warranty</h2>
                      </div>
                      <div className={styles.infoInnerCard}>
                        <div className={styles.bgImgContainer}>
                          <img src={bbgimg} alt="warrany" />
                        </div>
                        <div className={styles.logoContainer}>
                          <div className={styles.infoInnerLogo}>
                            <span>FREE</span>
                          </div>
                        </div>
                        <h2>Buyback Guarantee</h2>
                      </div>
                      <div className={styles.infoInnerCard}>
                        <div className={styles.bgImgContainer}>
                          <img src={ssimg} alt="warrany" />
                        </div>
                        <div className={styles.logoContainer}>
                          <div className={styles.infoInnerLogo}>
                            <span>50% OFF</span>
                          </div>
                        </div>
                        <h2>OEM service & Maintenance</h2>
                      </div>
                      {/* </div> */}
                    </>
                  )}
                </>
              ))}
        </div>
      )}
      {!squareDesignActive && (
        <div className={styles2.SearchResultsRect2}>
          {allFilteredData.length === 0
            ? null
            : allFilteredData.map((carInfo, i) => {
                if ((i + 1) % 6 === 0) {
                  return (
                    <>
                      <ResultCard2
                        key={i}
                        carInfo={carInfo}
                        btnData={buttonData}
                        setScroll={setScroll}
                        // shortListVehicle={shortListVehicle}
                        // shortListedVehIds={shortListedVehIds}
                      />
                      <div className={styles2.infoCards}>
                        <div className={styles2.infoInnerCard}>
                          <div className={styles2.logoContainer}>
                            <div className={styles2.infoInnerLogo}>
                              <span>FREE</span>
                            </div>
                          </div>
                          <h2>Comprehensive Warranty</h2>
                        </div>
                        <div className={styles2.infoInnerCard}>
                          <div className={styles2.logoContainer}>
                            <div className={styles2.infoInnerLogo}>
                              <span>FREE</span>
                            </div>
                          </div>
                          <h2>Buyback Guarantee</h2>
                        </div>
                        <div className={styles2.infoInnerCard}>
                          <div className={styles2.logoContainer}>
                            <div className={styles2.infoInnerLogo}>
                              <span>50% OFF</span>
                            </div>
                          </div>
                          <h2>OEM service & Maintenance</h2>
                        </div>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <ResultCard2
                      key={i}
                      carInfo={carInfo}
                      btnData={buttonData}
                      setScroll={setScroll}
                      // shortListVehicle={shortListVehicle}
                      // shortListedVehIds={shortListedVehIds}
                    />
                  );
                }
              })}
        </div>
      )}
      <div className={styles.searchPagination}>{/* <SearchPagination /> */}</div>
    </div>
  );
});

export default SearchResultsNew;
