import React from "react";
import Banner from "../../assets/images/blog/blogBanner.png";
import BannerImage from "../../assets/images/blog/typewarranty.png";
import Banner1 from "../../assets/images/blog/blogBanner1.png";
import ArrowLeft from "../../assets/images/blog/arrowLeft.svg";
import twitter from "../../assets/images/blog/twitter.svg";
import link from "../../assets/images/blog/link.svg";
import fb from "../../assets/images/blog/fb.svg";

import { Link } from "react-router-dom";

function Description() {
  return (
    <section className="blogSection">
      <div className="container">
        <div className="blogContainer">
          <div className="blogTop">
            <Link to="/blog">
              <img src={ArrowLeft} alt="Car" />
              back
            </Link>
            <div className="head">
              {/* <h3>FEB 9, 2023</h3> */}
              {/* <label></label> */}
              {/* <span>Volta</span> */}
            </div>
            <h1>What Are the Different Types of Car Warranties?</h1>
            <div className="blogImg" style={{ minHeight: "40px" }}>
              <img src={BannerImage} alt="Banner" style={{ borderRadius: "12px" }} />
            </div>
            <p>There are an numbers of different warranties available to car owners of both new cars and used cars, but they usually fall under one of three main categories.</p>
          </div>

          <div className="blogText">
            <h2>Factory Warranties</h2>
            <p>Each and every new vehicles rolls off the production line with what’s known as a company warranty (and also commonly referred to as a manufacturer warranty). This is effectively the manufacturer’s promise to cover the cost of repairs due to defective vehicle components for a number of years/miles traveled after purchase.</p>
            <p style={{ minHeight: "20px" }}>A manufacturer warranty can include few or all of the following:</p>
            <p>
              <ul>
                <li style={{ marginLeft: "32px", listStyleType: "disc" }}>
                  <b>Powertrain warranty:</b> A powertrain warranty covers the components that make the car move, such as the engine, transmission, and drive axles. It is typically offered separately from the basic warranty and lasts longer.
                </li>
                <li
                  style={{
                    marginLeft: "32px",
                    listStyleType: "disc",
                    marginTop: "12px",
                  }}
                >
                  <b>Bumper-to-bumper warranty:</b> Also known as a Comprehensive Warranty, a basic warranty typically covers the cost of repairs or replacements for most parts of the vehicle, including the engine, transmission, and electrical system. This type of warranty is usually included in the purchase price of a new car and lasts for a specific number of years or a certain number of miles driven.
                </li>
                <li
                  style={{
                    marginLeft: "32px",
                    listStyleType: "disc",
                    marginTop: "12px",
                  }}
                >
                  <b>Extended Warranty:</b> An extended warranty is a service contract that extends the coverage of the manufacturer's warranty for an additional period of time or miles driven. It is often sold by the dealer or a third-party provider like Wisedrive and can include additional benefits, such as roadside assistance and maintenance coverage.
                </li>
                <li
                  style={{
                    marginLeft: "32px",
                    listStyleType: "disc",
                    marginTop: "12px",
                  }}
                >
                  <b>Drivetrain warranty:</b> Covers your transmission, driveshaft, axle shafts, and wheels.
                </li>
                <li
                  style={{
                    marginLeft: "32px",
                    listStyleType: "disc",
                    marginTop: "12px",
                  }}
                >
                  <b>Rust or Corrosion warranty:</b> This type of warranty covers the cost of repairs for rust or corrosion on the vehicle's body. It is usually offered for a specific number of years.
                </li>
                <li
                  style={{
                    marginLeft: "32px",
                    listStyleType: "disc",
                    marginTop: "12px",
                  }}
                >
                  <b>Emissions warranty:</b> An emissions warranty covers the cost of repairs related to the vehicle's emissions system, including the catalytic converter and oxygen sensor. This type of warranty is required by law and lasts for a specific number of years or miles driven.
                </li>
              </ul>
            </p>
            <p style={{ marginLeft: "32px" }}>To qualify for repairs under your company warranty, you must follow your car manufacturer’s recommended maintenance schedule to the letter. Manufacturer’s also often stipulate that all repair work must be carried out at an authorized dealership. Most factory warranties transfer from owner to owner when a car is sold, though the transferred warranty may be shortened by the car manufacturer.</p>
            <p>Like all good things, factory warranties eventually come to an end. And when they do, you’ll need to find coverage elsewhere if you want to stay protected.</p>
          </div>
          <div className="blogText">
            <h2>Manufacturer Extended Warranties</h2>
            <p>
              If you want to continue the coverage of your factory warranty past its expiry date, you may want to &nbsp;
              <a href="https://www.wisedrive.in/benefits" target="_blank">
                purchase an extended warranty.
              </a>
              &nbsp; These will be offered via your manufacturers or dealership, and levels of coverage available will differ between manufacturers.
            </p>
            <p>Usually, you at least have the option to take out an extended powertrain warranty or an extended bumper-to-bumper, or equivalent, warranty. While the cost of a factory warranty is included in the purchase price of your vehicle, an extended car warranty is usually paid for via a monthly or annual fee. Unlike a factory warranty, extended vehicle warranties often include a deductible when repairs are made.</p>
            <p>The biggest catch with used car extended warranties is that they must be purchased while your factory warranty is still active. Just one day late, and you’ll no longer qualify.</p>
          </div>
          <div className="blogText">
            <h2>Third-Party Extended Warranties</h2>
            <p>If you aren’t eligible for a traditional extended warranty, don’t worry. Third-party extended warranties (also known as auto protection plans or vehicle service contracts) are often a better option anyway. This is because third-party warranty providers are incentivized to offer more coverage at a better price. They’re also known to throw in a selection of great perks to sweeten the deal for aftermarket warranties.</p>
            <p>
              <a href="https://www.wisedrive.in/" target="_blank">
                WISEDRIVE,
              </a>
              &nbsp; Omega Auto Care, Protect My Car, and Olive are some of the top providers, with each offering a range of unique protection packages for car owners to choose from. It’s more than likely plans from third-party providers will be referred to as drivetrain or powertrain&nbsp;{" "}
              <a href="https://www.wisedrive.in/benefits" target="_blank">
                warranties.
              </a>
              While only manufacturers can offer true bumper-to-bumper coverage, some third-party providers come close with their comprehensive exclusionary plans, which cover almost everything with only a small list of exclusions.
            </p>
            <p>Vehicle service contracts can be renewed or extended for as long as your vehicle is eligible in the eyes of the provider. You can often negotiate to create an individual protection package, plus you aren’t tied to dealerships, as repairs can be carried out at any certified auto shop.</p>
            <p>
              Different states also have their own requirements for extended coverage. For example, Bangalore generally requires mechanical breakdown insurance (MBI), which is another type of third-party coverage similar to an auto protection&nbsp;
              <a href="https://www.wisedrive.in/pricing" target="_blank">
                plan.
              </a>
            </p>
          </div>
          <div className="blogText">
            <h2>How Can a Wisedrive Extended Warranty Help My Car?</h2>
            <p>
              Wisedrive is India’s most trusted vehicle Warranty provider. Our choice of award-winning plans and coverage options has something for all kinds of used vehicles, with packages to suit car owners, rideshare drivers,&nbsp;
              <a href="https://www.wisedrive.in/service-coverage" target="_blank">
                and even high-mileage cars.
              </a>
              &nbsp; We also offer unrivaled flexibility—just give our friendly customer service team a call, and we’ll tailor a bespoke protection program to suit your needs and budget.
            </p>
            <p>
              All &nbsp;
              <a href="https://www.wisedrive.in/pricing" target="_blank">
                Wisedrive plans
              </a>
              &nbsp; include 24/roadside assistance, towing, trip-interruption costs, replacement travel reimbursement, and rental car coverage as standard. Better still, every new Wisedrive member gets a year’s free access to our Benefits, opening up additional perks such as tire replacement, total loss protection, and more.&nbsp;
              <a href="https://www.wisedrive.in/pricing" target="_blank">
                Wisedrive plans
              </a>
              &nbsp; are also fully transferable, increasing the value of your vehicle should you decide to sell it.
            </p>
          </div>
          <div className="blogText">
            <h2>Get the Best Car Protection with Wisedrive</h2>
            <p>
              Want to know more about how Wisedrive coverage plans can help you reduce the cost of car ownership? Call our award-winning customer service team at +91-73533-43536 for a free, no-obligation quote, or&nbsp;
              <a href="https://www.wisedrive.in/pricing" target="_blank">
                shop online today.
              </a>
              &nbsp; We offer a buy-back guarantee and flexible monthly payments.
            </p>
          </div>
        </div>
        <div className="share">
          <h1>Share via</h1>
          <ul>
            <li>
              {/* <Link to=""> */}
              <a href="https://twitter.com/wisedrivehq" target="_blank">
                <img src={twitter} alt="Twitter" />
              </a>
              {/* </Link> */}
            </li>
            <li>
              {/* <Link to=""> */}
              <a href="https://in.linkedin.com/company/wisedrivewarranty" target="_blank">
                <img src={link} alt="Link" />
              </a>
              {/* </Link> */}
            </li>
            <li>
              {/* <Link to=""> */}
              <a href="https://www.facebook.com/Wisedrivewarranty" target="_blank">
                <img src={fb} alt="Facebook" />
              </a>
              {/* </Link> */}
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Description;
