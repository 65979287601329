import React, { useState, useEffect, useContext } from "react";
import "./header.css";
import companyLogo from "../../assets/images/home/logo.svg";
import Close from "../../assets/images/home/close.svg";
import { Link, useNavigate } from "react-router-dom";
import wisedDrivelogo from "../../assets/images/header/log0.png";
import { MyContext } from "../../context";

const ReportHeader = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  const [sticky, setSticky] = useState("");
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const { setOwnerPlanTabActive } = useContext(MyContext);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset <= 500) {
        setSticky("sticky");
      } else {
        setSticky("sticky");
      }

      let moving = window.pageYOffset;

      if (window.pageYOffset > 100) {
        setVisible(position > moving);
      }
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const cls = visible ? "" : "hidden";

  return (
    <>
      <header className={`${active} ${cls} ${sticky}`}>
        <div className="container">
          <div className="logo">
            <img src={wisedDrivelogo} alt="company logo" className="company-logo" />
          </div>
          <div className="navLinks">
            <div className="logo">
              <Link to="/">
                <img src={companyLogo} alt="company logo" />
              </Link>
              <img
                className="closeIconPhase3"
                src={Close}
                alt="Close"
                onClick={() => {
                  setActive("");
                }}
              />
            </div>
            <ul></ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default ReportHeader;
