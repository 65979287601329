import React, { useContext } from 'react';
import { MyContext } from '../../context';

function PricingPlanHead() {
  const { ownerPlanTabActive, setOwnerPlanTabActive } = useContext(MyContext)
  return (
    <section className="pricePlanBanner ownerPriceBanner">
      <div className="container">
        <h1>
          <span>Save Money</span> with every km you drive
        </h1>
       {/* <div className="unlockTab">
          <div
            className={ownerPlanTabActive === 0 ? "unlockTitile current" : "unlockTitile"}
            onClick={() => setOwnerPlanTabActive(0)}
          >
            <p>I Own a car</p>
          </div>
          <div className={ownerPlanTabActive === 1 ? "unlockTitile current" : "unlockTitile"} onClick={() => setOwnerPlanTabActive(1)}>
            <p>I’m Buying new car</p>
          </div>
        </div>*/}
      </div>
    </section>
  )
}

export default PricingPlanHead