import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { updateObjectOnSession } from "./helpers";

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider({ value, setValue, label }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);

    // get label as a prop here and update session filters object
    updateObjectOnSession("filters", label, newValue);
  };

  return (
    <Box sx={{ width: "80%", margin: "0 auto" }}>
      <Slider
        getAriaLabel={() => "Temperature range"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        sx={{
          color: "#7963F0",
          height: 4,
          "& .MuiSlider-thumb": {
            width: 10,
            height: 10,
            border: "3px solid #7963F0",
            "&:focus, &:hover, &.Mui-active": {
              boxShadow: "0 0 0 8px rgba(121, 99, 240, 0.1)",
              // Reset on touch devices, it doesn't add specificity
              // "@media (hover: none)": {
              //   boxShadow: iOSBoxShadow,
              // },
            },
          },
          "& .MuiSlider-valueLabel": {
            display: "none",
            // lineHeight: 1.2,
            // fontSize: 12,
            // background: "unset",
            // padding: 0,
            // width: 32,
            // height: 32,
            // borderRadius: "50% 50% 50% 0",
            // backgroundColor: "#52af77",
            // transformOrigin: "bottom left",
            // transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
            // "&:before": { display: "none" },
            // "&.MuiSlider-valueLabelOpen": {
            //   transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
            // },
            // "& > *": {
            //   transform: "rotate(45deg)",
            // },
          },
        }}
      />
    </Box>
  );
}
