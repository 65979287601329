import styles from "./card2.module.css";

import React, { useState, useEffect } from "react";
// import styles from "../../containers/searchcars/SearchPage.module.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ViewSellerPopup from "../popups/viewSellerPopup";
import { Link } from "react-router-dom";
import defaultImg from "../../assets/CarImages/default.jpg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { formatPrice } from "../../utils/commonFunctions";
import useWindowDimensions from "../util-hooks/useWindowDimentions";
import DoneIcon from "@mui/icons-material/Done";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { SendShortListedVehicle, SendUnShortListedVehicle } from "../../Api/PortalApis";

// CAR IMAGE IMPORTS

function ResultCard2({ carInfo, btnData, setScroll }) {
  //
  const [isKycPopupOpen, setIsKycPopupOpen] = useState(false);
  // const [vehicleId, setVehicleId] = useState("");
  const [imageToShow, setImageToShow] = useState("");

  const [isShortListed, setIsShortlisted] = useState(false);

  // console.log("carinfo", carInfo);

  async function sendShortlisted() {
    try {
      // check phone number if not open veiw seller popup
      const hasVerified = localStorage.getItem("phoneNumber");

      if (!hasVerified) {
        setIsKycPopupOpen(true);
        return;
      }

      // if data is there sent api
      const data = {
        phoneNumber: localStorage.getItem("phoneNumber"),
        listOfVehicleIds: [carInfo.vehicleId],
      };
      //
      if (isShortListed) {
        const resp = await SendUnShortListedVehicle(data);
        if (resp.status === 200) {
          setIsShortlisted(false);
        }
      } else {
        const resp = await SendShortListedVehicle(data);
        if (resp.status === 200) {
          setIsShortlisted(true);
        }
      }
    } catch (error) {
      console.error("error shortlisting vehicle", error);
    }
  }

  function closeKycPopup() {
    setIsKycPopupOpen(false);
  }

  useEffect(() => {
    if (carInfo && carInfo.imageList) {
      if (carInfo?.imageList["Front Side"]) {
        setImageToShow(carInfo?.imageList["Front Side"]);
      } else if (carInfo?.imageList["Right Side"]) {
        setImageToShow(carInfo?.imageList["Right Side"]);
      } else if (carInfo?.imageList["Left Side"]) {
        setImageToShow(carInfo?.imageList["Left Side"]);
      } else if (carInfo?.imageList["Back Side"]) {
        setImageToShow(carInfo?.imageList["Back Side"]);
      } else {
        const firstItem = Object.keys(carInfo.imageList)[0];
        if (!firstItem) {
          setImageToShow("");
        } else {
          setImageToShow(carInfo.imageList[firstItem]);
        }
      }
    }
  }, [carInfo]);

  // console.log(btnData);

  return (
    <>
      <div style={{ position: "relative" }}>
        {/* <div style={{ position: "absolute", padding: 10, zIndex: 50, top: 0, right: 0 }} onClick={sendShortlisted}>
          {isShortListed ? (
            <FavoriteIcon style={{ color: "#ff6b00", fontSize: 22 }} />
          ) : (
            <FavoriteBorderOutlinedIcon style={{ color: "#ff6b00", fontSize: 22 }} />
          )}
        </div> */}
        <Link to={`/car-details/${carInfo.vehicleId}`} onClick={() => setScroll()}>
          <div className={styles.carCard}>
            <div className={styles.carImagWrapper}>
              <Link to={`/car-details/${carInfo.vehicleId}`}>
                <div className={styles.carImage}>
                  {carInfo.imageList && imageToShow ? (
                    <img src={imageToShow} alt="car" />
                  ) : (
                    <img src={defaultImg} alt="car" />
                  )}
                </div>
              </Link>
              {/* <div className={styles.topLabelRect}>
              <span>{btnData?.toplabelMob}</span>
              </div> */}
              <div
                style={{
                  padding: "8px 12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                  backgroundColor: "#eee",
                  backgroundColor: "#e9effe",
                  borderRadius: "10px",
                  color: "#0d0a19",
                  fontSize: 11,
                  padding: "4px 8px",
                  paddingRight: 10,
                }}
              >
                <DoneIcon style={{ color: "green", fontSize: 17 }} />
                <span style={{ fontWeight: 600 }}>{btnData?.toplabelMob}</span>
              </div>
            </div>
            <div className={styles.carInfo}>
              <h3 className={styles.h3} style={{ marginTop: -10, fontWeight: 600 }}>{`${carInfo.model}`}</h3>
              <ul className={styles.carLabels}>
                {carInfo.make && (
                  <li style={{ fontWeight: 500 }}>
                    {carInfo.make}{" "}
                    <span>
                      <FiberManualRecordIcon style={{ fontSize: 8 }} />
                    </span>
                  </li>
                )}
                {carInfo.manuyear && (
                  <li>
                    {carInfo.manuyear}{" "}
                    {/* <span>
                  <FiberManualRecordIcon style={{ fontSize: 8 }} />
                </span> */}
                  </li>
                )}
              </ul>
              <ul className={styles.carLabels} style={{ marginBottom: 8 }}>
                {carInfo.odometer && (
                  <li>
                    {carInfo.odometer} Kms{" "}
                    <span>
                      <FiberManualRecordIcon style={{ fontSize: 8 }} />.
                    </span>
                  </li>
                )}
                {carInfo.transmission && (
                  <li>
                    {carInfo.transmission}{" "}
                    {/* <span>
                  <FiberManualRecordIcon style={{ fontSize: 8 }} />
                </span> */}
                  </li>
                )}
              </ul>
              {/* <ul className={styles.carLabels} style={{ marginBottom: 5 }}>
            {carInfo.owner && (
              <li>
                {carInfo.owner} {carInfo.owner == 1 ? "Owner" : "Owners"}{" "}
                <span>
                  <FiberManualRecordIcon style={{ fontSize: 8 }} />
                </span>
              </li>
            )}
            </ul> */}

              {/* {carInfo.odometer && carInfo.color && (
              <FiberManualRecordIcon style={{ color: "#ddd", width: 8, height: 8 }} />
            )} */}
              <div className={styles.carPriceRect}>
                <span className={styles.priceRect}>
                  <CurrencyRupeeIcon style={{ width: 12, height: 12 }} />
                  <span>{formatPrice(carInfo.price)}</span>
                </span>
                {/* <span
                className={styles.labelRect}
                onClick={() => {
                setIsKycPopupOpen(true);
                setVehicleId(carInfo.vehicleId);
                }}
                >
                View Seller
                </span> */}
              </div>
              <div className={styles.carCardFlags}>
                {/* <div className={styles.topLabelRect}>
                <span>1 year FREE* warranty</span>
                </div> */}
                {/* <div className={styles.bottomLabelRect}>
                <span>{btnData?.bottomLabelMob}</span>
                </div> */}
                <div
                  style={{
                    padding: "8px 12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 5,
                    backgroundColor: "#eee",
                    backgroundColor: "#e9effe",
                    borderRadius: "10px",
                    color: "#0d0a19",
                    fontSize: 11,
                    padding: "4px 8px",
                    paddingRight: 10,
                  }}
                >
                  <DoneIcon style={{ color: "green", fontSize: 17 }} />
                  <span style={{ fontWeight: 600 }}>{btnData?.bottomLabelMob}</span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      {isKycPopupOpen ? (
        <ViewSellerPopup closeKycPopup={closeKycPopup} vehicleId={carInfo.vehicleId ?? ""} isFromShortlist={true} />
      ) : (
        ""
      )}
    </>
  );
}

export default ResultCard2;
