import React from "react";
import "./footer.css";
import Logo from "../../assets/images/header/wisedDrivelogo.svg";
import { Link } from "react-router-dom";
import Toyota from "../../assets/images/home/toyota.svg";
import Honda from "../../assets/images/home/honda.svg";
import Suzuki from "../../assets/images/home/suzuki.svg";
import Hyundai from "../../assets/images/home/hyundai.svg";
import Nissan from "../../assets/images/home/nissan.svg";
import Ford from "../../assets/images/home/ford.svg";
import ReadyAssist from "../../assets/images/home/readyAssist.svg";
import Rescue from "../../assets/images/home/rescue.svg";
import Bro4U from "../../assets/images/home/bro4u.svg";
import Europe from "../../assets/images/home/europAssistance.svg";
import GoBumber from "../../assets/images/home/goBumper.svg";
import Line from "../../assets/images/home/line.svg";
import CopyrightsLine from "../../assets/images/home/copyRightsLine.svg";
import Slider from "react-slick";
import Skoda from "../../assets/images/home/skoda.svg";
import Kia from "../../assets/images/home/kia.svg";
import Volvo from "../../assets/images/home/volvo.svg";
import Benz from "../../assets/images/home/benz.svg";
import Tata from "../../assets/images/home/tata.svg";
import Audi from "../../assets/images/home/audi.svg";
import Mg from "../../assets/images/home/mg.svg";
import Force from "../../assets/images/home/force.svg";
import companyLogo from "../../assets/images/home/logo.svg";

const FooterFacebookLandingPage = () => {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 6,
    autoplaySpeed: 0,
    cssEase: "linear",
    autoplay: true,
    variableWidth: true,
    infiniteScroll: true,
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 4,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          initialSlide: 2,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          autoplay: true,
        },
      },
    ],
  };

  var props = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 5,
    autoplaySpeed: 0,
    autoplay: false,
    speed: 5000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: true,
          speed: 5000,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 4,
          infiniteScroll: true,
          cssEase: "linear",
          autoplaySpeed: 0,
        },
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     autoplay: true,
      //     speed: 10000,
      //     slidesToShow: 2,
      //     slidesToScroll: 3,
      //   },
      // },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     autoplay: true,
      //     speed: 10000,
      //     slidesToShow: 2,
      //     slidesToScroll: 3,
      //   },
      // },
    ],
  };
  return (
    <footer className="footer">
      <div className="container">
        <div className="footerPartner">
          <p>Our Maintenance partner</p>
          <div className="footerPartnerLogos">
            <Slider {...settings}>
              <img src={Ford} alt="ford" />
              <img src={Line} alt="Line" />
              <img src={Hyundai} alt="hyundai" />
              <img src={Line} alt="Line" />
              <img src={Toyota} alt="toyota" />
              <img src={Line} alt="Line" />
              <img src={Nissan} alt="nissan" />
              <img src={Line} alt="Line" />
              <img src={Suzuki} alt="suzuki" />
              <img src={Line} alt="Line" />
              <img src={Honda} alt="honda" />
              <img src={Line} alt="Line" />
              <img src={Skoda} alt="Skoda" />
              <img src={Line} alt="Line" />
              <img src={Kia} alt="Kia" />
              <img src={Line} alt="Line" />
              <img src={Volvo} alt="Volvo" />
              <img src={Line} alt="Line" />
              <img src={Benz} alt="Benz" />
              <img src={Line} alt="Line" />
              <img src={Tata} alt="Tata" />
              <img src={Line} alt="Line" />
              <img src={Audi} alt="audi" />
              <img src={Line} alt="Line" />
              <img src={Force} alt="Force" />
              <img src={Line} alt="Line" />
              <img src={Mg} alt="Mg" />
            </Slider>
          </div>
        </div>
        <div className="footerPartner footerAssistance">
          <p>Our roadside assistance</p>
          <div className="footerPartnerLogos">
            <Slider {...settings}>
              {/* <img src={ReadyAssist} alt="Hyundai" /> */}
              <img src={Line} alt="Line" />
              <img src={Rescue} alt="Line" />
              <img src={Line} alt="Line" />
              <img src={Bro4U} alt="Ford" />
              <img src={Line} alt="Line" />
              {/* <img src={Europe} alt="Line" /> */}
              <img src={Line} alt="Line" />
              <img src={GoBumber} alt="Toyota" />
            </Slider>
          </div>
        </div>
        <div className="footerCopyRight landingPageFacebook">
          {/* <Link to="/"> */}
          <img src={companyLogo} alt="company logo" className="company-logo" />
          {/* </Link> */}
          <p style={{ fontSize: "17px", lineHeight: "27px", fontWeight: "500", color: "gray" }}>
            Copyright © wisedrive 2022
          </p>
          <p style={{ fontSize: "17px", lineHeight: "27px", fontWeight: "500", color: "gray", textAlign: "justify" }}>
            By visiting this page, you agree to{" "}
            <span style={{ color: "#e02d5a", fontWeight: "900" }}>
              <Link to="/terms-and-conditions" style={{ color: "#e02d5a" }}>
                Terms of service,
              </Link>{" "}
              {/*  */}
              {/* <Link to="/customerWarrantyPolicy" style={{ color: "#e02d5a" }}>
                Customer Warranty Policy,{" "}
              </Link> */}
              <Link to="/warrantyPolicy" style={{ color: "#e02d5a" }}>
                Warranty policy,{" "}
              </Link>
              <Link to="/buybackPolicy" style={{ color: "#e02d5a" }}>
                Buyback policy
              </Link>{" "}
              <Link to="/privacy-policy" style={{ color: "#e02d5a" }}>
                & Privacy policy.
              </Link>
            </span>
          </p>

          <p style={{ fontSize: "17px", lineHeight: "27px", fontWeight: "500", color: "gray", textAlign: "justify" }}>
            This site is not a part of the Facebook website or Facebook Inc. Additionally, This site is NOT endorsed by
            Facebook in any way.
          </p>
          <p style={{ fontSize: "17px", fontWeight: "500", lineHeight: "27px", color: "gray", textAlign: "justify" }}>
            FACEBOOK is a trademark of FACEBOOK, Inc.
          </p>

          <p style={{ fontSize: "17px", lineHeight: "27px", fontWeight: "500", color: "gray", textAlign: "justify" }}>
            Disclaimer: Wisedrive inspection is done by mechanics from Wisedrive and does not guarantee any price
            related to car. Our inspection approved vehicles are termed as "Warranty Eligible" vehicles, it does not
            guarantee a FREE Warranty for the car. Wisedrive has partnered with 300+ used car dealers in Bangalore who
            are willing to provide Extended warranty for used cars which are approved by Wisdrive. Before buying the car
            make sure your car is approved by Wisedrive and has FREE warranty offered by Used car dealer / seller from
            whom you wish to buy the car. Wisedrive does not guarantee FREE Warranty for the cars inspected by
            Wisedrive. Wisedrive offers Buyback guarantee for each activated Warranty, which states that if Wisedrive
            cannot repair your car within 60 days from date of approval to repair your car with Non warranty parts then
            Wisedrive will buyback the car at the price you bought the car from the seller / used car dealer. Customers
            have to activate warranty and buyback guarantee for each car using Wisedrive Mobile App. Every Warranty
            issued by WIsedrive or activated by Customer or activated by Used car dealer / seller has a default 10 day
            cooling period, during which Wisedrive is not liable for any Warranty claims or buyback guarantee.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterFacebookLandingPage;
