import React, { useEffect, useState } from "react";
import Header from "../../components/header/headerWA";
import Footer from "../../components/footer/";
import PaymentCompleted from "./paymentCompleted";
import FooterFacebookLandingPage from "../../components/footerFacebookLandingPage";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { GetPageList, SendTrackingCount } from "../../Api/Apis";

ReactGA.initialize("AW-11111300491");

const PaymentSuccessWiseAssist = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isPaymentSuccess, setPaymentSuccess] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [pageData, setPageData] = useState([]);

  async function getIpAddress() {
    const res = await axios.get("https://api.ipify.org/?format=json");

    if (res.status === 200) {
      const userIp = res.data.ip;
      localStorage.setItem("userIp", userIp);
      setIpAddress(userIp);
    } else {
      console.error("Error getting ip address of user");
    }
  }

  async function getPageList() {
    try {
      const resp = await GetPageList();
      if (resp.status === 200) {
        const pageInfo = resp.data.response.PageList;
        setPageData(pageInfo);
      }
    } catch (error) {
      console.log(error, "error getting page list");
    }
  }

  useEffect(() => {
    getPageList();
    if (!localStorage.getItem("userIp")) {
      getIpAddress();
    }
  }, []);

  async function sendTrackingCount(data) {
    try {
      const resp = await SendTrackingCount(data);
      if (resp.status === 200) {
        //
        console.log("tracking count sent successfully!");
      }
    } catch (error) {
      console.error(error, "error sending tracking count");
    }
  }

  useEffect(() => {
    // console.log(location.state);

    const ipAddr = localStorage.getItem("userIp");

    // console.log(ipAddr, "ip address");

    if (pageData.length > 0 && ipAddr) {
      //
      let srcId = "";
      const pageId = pageData.filter((page) => page.name === "Success_page")[0].id;
      if (location.pathname === "/payment-success-wiseassist") {
        srcId = 26;
      }
      if (location.pathname === "/payment-success-wiseassist_fb") {
        srcId = 28;
      }

      const data = {
        ip: ipAddr,
        sourceId: srcId,
        pageTypeId: pageId,
      };

      sendTrackingCount(data);
    }
  }, [pageData, ipAddress]);
  

  useEffect(() => {
    const paymentSuccessFlag = sessionStorage.getItem("paymentSuccessFlag");
    if (!paymentSuccessFlag) {
      // If the flag is not present, set it to true and display the payment success content
      sessionStorage.setItem("paymentSuccessFlag", "true");
      setPaymentSuccess(true);
    } else {
      // If the flag is already set to true, redirect to another page (e.g., home page)
      if (location.pathname === "/payment-success-wiseassist") {
        navigate(`/wiseassist`, { state: { fromSuccess: true } });
        // window.location.href = "/wiseassist"; //
      }

      if (location.pathname === "/payment-success-wiseassist_fb") {
        navigate(`/wiseassist_fb`, { state: { fromSuccess: true } });
        // window.location.href = "/wiseassist_fb";
      }
      sessionStorage.removeItem("paymentSuccessFlag");
    }
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <script>{`gtag("event", "conversion", { send_to: "AW-11111300491/1XO3CPmS9LYYEIv7o7Ip" })`}</script>
        {/* <script>
          {`
            function trackConversion() {
              gtag("event", "conversion", { send_to: "AW-11111300491/1XO3CPmS9LYYEIv7o7Ip" });
              }
            trackConversion();
          `}
        </script> */}
      </Helmet>
      <Header />
      <PaymentCompleted />
      <FooterFacebookLandingPage />
    </>
  );
};

export default PaymentSuccessWiseAssist;
