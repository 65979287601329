import React, { useEffect, useState } from "react";
import styles from "../../containers/searchcars/SearchPage.module.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Link } from "react-router-dom";
import ViewSellerPopup from "../popups/viewSellerPopup";
import styles1 from "./card1.module.css";
// CAR IMAGE IMPORTS
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import defaultImg from "../../assets/CarImages/default.jpg";
import { formatPrice } from "../../utils/commonFunctions";
import { SendShortListedVehicle, SendUnShortListedVehicle } from "../../Api/PortalApis";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";

function ResultCardSquare({ carInfo, fromDetails, btnData, setScroll }) {
  const [isKycPopupOpen, setIsKycPopupOpen] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const [imageToShow, setImageToShow] = useState("");

  const [isShortListed, setIsShortlisted] = useState(false);

  async function sendShortlisted() {
    try {
      // check phone number if not open veiw seller popup
      const hasVerified = localStorage.getItem("phoneNumber");

      if (!hasVerified) {
        setIsKycPopupOpen(true);
        return;
      }

      // if data is there sent api
      const data = {
        phoneNumber: localStorage.getItem("phoneNumber"),
        listOfVehicleIds: [carInfo.vehicleId],
      };
      //
      if (isShortListed) {
        const resp = await SendUnShortListedVehicle(data);
        if (resp.status === 200) {
          setIsShortlisted(false);
        }
      } else {
        const resp = await SendShortListedVehicle(data);
        if (resp.status === 200) {
          setIsShortlisted(true);
        }
      }
    } catch (error) {
      console.error("error shortlisting vehicle", error);
    }
  }

  // console.log(carInfo);

  function closeKycPopup() {
    setIsKycPopupOpen(false);
  }

  useEffect(() => {
    if (carInfo && carInfo.imageList) {
      if (carInfo?.imageList["Front Side"]) {
        setImageToShow(carInfo?.imageList["Front Side"]);
      } else if (carInfo?.imageList["Right Side"]) {
        setImageToShow(carInfo?.imageList["Right Side"]);
      } else if (carInfo?.imageList["Left Side"]) {
        setImageToShow(carInfo?.imageList["Left Side"]);
      } else if (carInfo?.imageList["Back Side"]) {
        setImageToShow(carInfo?.imageList["Back Side"]);
      } else {
        const firstItem = Object.keys(carInfo.imageList)[0];
        if (!firstItem) {
          setImageToShow("");
        } else {
          setImageToShow(carInfo.imageList[firstItem]);
        }
      }
    }
  }, [carInfo]);

  return (
    <>
      <div
        className={styles1.carCardSquare}
        style={{ backgroundColor: fromDetails ? "#fff" : "", position: "relative" }}
      >
        {/* <div style={{ position: "absolute", padding: 10, zIndex: 50, top: 0, right: 0 }} onClick={sendShortlisted}>
          {isShortListed ? (
            <FavoriteIcon style={{ color: "#ff6b00", fontSize: 24 }} />
          ) : (
            <FavoriteBorderOutlinedIcon style={{ color: "#ff6b00", fontSize: 24 }} />
          )}
        </div> */}
        <Link to={`/car-details/${carInfo.vehicleId}`} onClick={() => setScroll()}>
          <div className={styles1.carImageSquare}>
            <div className={styles1.topLabel}>
              <span>{btnData?.toplabel}</span>
            </div>
            {carInfo.imageList && imageToShow ? (
              <img src={imageToShow} alt="car" />
            ) : (
              <img src={defaultImg} alt="car" />
            )}
          </div>
        </Link>
        <div className={styles1.bottomLabel}>
          <span>{btnData?.bottomLabel}</span>
        </div>
        <div className={styles1.carInfoSquare}>
          <Link to={`/car-details/${carInfo.vehicleId}`}>
            <h3 className={styles1.h3}>{`${carInfo.make} ${carInfo.model}`}</h3>
            <div className={styles1.carLabelsSquare}>
              <span>{carInfo.odometer} Kms</span>
              {/* <li>{carInfo.color}</li> */}
              <div>
                {carInfo.color && <span>{carInfo.color}</span>}
                {carInfo.color && carInfo.manuyear && (
                  <FiberManualRecordIcon style={{ color: "#ddd", width: 8, height: 8 }} />
                )}
                {carInfo.manuyear && <span>{carInfo.manuyear}</span>}
                {carInfo.manuyear && carInfo.owner && (
                  <FiberManualRecordIcon style={{ color: "#ddd", width: 8, height: 8 }} />
                )}
                {carInfo.owner && (
                  <span>{carInfo.owner == 1 ? `${carInfo.owner} Owner` : `${carInfo.owner} Owners`}</span>
                )}
              </div>
            </div>
          </Link>
          <div className={styles1.carPriceSquare}>
            {/* <span className={styles1.priceSquare}>{carInfo.price}</span> */}
            <span className={styles1.priceSquare}>
              <CurrencyRupeeIcon style={{ width: 12, height: 12 }} />
              <span>{formatPrice(carInfo.price)}</span>
            </span>
            <span
              className={styles1.labelSquare}
              onClick={() => {
                setIsKycPopupOpen(true);
                setVehicleId(carInfo.vehicleId);
              }}
            >
              View Seller
            </span>
          </div>
        </div>
      </div>
      {isKycPopupOpen ? <ViewSellerPopup closeKycPopup={closeKycPopup} vehicleId={vehicleId} /> : ""}
    </>
  );
}

export default ResultCardSquare;
