import React, { useState } from "react";
import styles from "./newDeals.module.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import ViewSellerPopup from "../../../popups/viewSellerPopup";
import ViewSellerPopup from "../../components/popups/viewSellerPopup";
import { Link } from "react-router-dom";

// CAR IMAGE IMPORTS

function NewDeals({ carInfo }) {
  const [isKycPopupOpen, setIsKycPopupOpen] = useState(false);

  function closeKycPopup() {
    setIsKycPopupOpen(false);
  }


  
  return (
    <>
      <div className={styles.carCard}>
        <Link to={"/car-details"}>
          <div className={styles.carImage}>
            {/* <div className={styles.topLabelRect1}>
              <span>1 year FREE* warranty</span>
            </div> */}
            <img src={carInfo.image} alt="car" />
            {/* <div className={styles.bottomLabelRect1}>
              <span>365 days buyback guarantee</span>
            </div> */}
          </div>
        </Link>
        <div className={styles.carInfo}>
          <h3 className={styles.h3}>{carInfo.name}</h3>
          <ul className={styles.carLabels}>
            <li>{carInfo.odometer}</li>
            <li>{carInfo.color}</li>
            <li>{carInfo.cylinder}</li>
          </ul>
          <div className={styles.carPriceRect}>
            {/* <span className={styles.priceSquare}>{carInfo.price}</span> */}
            <span className={styles.priceRect}>
              <CurrencyRupeeIcon style={{ width: 12, height: 12 }} />
              <span>{carInfo.price}</span>
            </span>
            <span
              className={styles.labelRect}
              onClick={() => {
                setIsKycPopupOpen(true);
              }}
            >
              View Seller
            </span>
          </div>
          {/* <div className={styles.carCardFlags}>
            <div className={styles.topLabelRect}>
              <span>1 year FREE* warranty</span>
            </div>
            <div className={styles.bottomLabelRect}>
              <span>365 days buyback guarantee</span>
            </div>
          </div> */}
        </div>
      </div>
      {isKycPopupOpen ? <ViewSellerPopup closeKycPopup={closeKycPopup} /> : ""}
    </>
  );
}

export default NewDeals;
