import React from "react";
import Download from "../../assets/images/claims/download.png";
import Initiate from "../../assets/images/claims/initiate.png";
import Relax from "../../assets/images/claims/relax.png";
import Bg from "../../assets/images/claims/requestBg.svg";
import DownloadMobile from "../../assets/images/claims/downloadMobile.png";
import InitiateMobile from "../../assets/images/claims/initiateMobile.png";
import RelaxMobile from "../../assets/images/claims/relaxMobile.png";
import One from "../../assets/images/claims/01.svg";
import Two from "../../assets/images/claims/02.svg";
import Three from "../../assets/images/claims/03.svg";
import { useState } from "react";

function Requests() {
  const [count, setCount] = useState(0);

  // Scroll top image
  window.addEventListener("scroll", () => {
    let banner = document.getElementsByClassName("claimBanner")[0].offsetHeight;
    let assist = document.getElementById("assist").offsetHeight;
    let height = banner + assist + 100;
    if (window.pageYOffset >= height + 60) {
      downloadImg();
    }
  });

  // Scroll second Image
  window.addEventListener("scroll", () => {
    let banner = document.getElementsByClassName("claimBanner")[0].offsetHeight;
    let assist = document.getElementById("assist").offsetHeight;
    let download = document.getElementById("download").offsetHeight;
    let height = banner + assist + download + 100;

    if (window.pageYOffset >= height - 300) {
      initiateImg();
    }
  });

  // Scroll third image
  window.addEventListener("scroll", () => {
    let banner = document.getElementsByClassName("claimBanner")[0].offsetHeight;
    let assist = document.getElementById("assist").offsetHeight;
    let download = document.getElementById("download").offsetHeight;
    let initiate = document.getElementById("initiate").offsetHeight;
    let height = banner + assist + download + initiate + 100;

    if (window.pageYOffset >= height - 400) {
      relaxImg();
    }
  });

  const downloadImg = () => {
    setCount(0);
  };

  const initiateImg = () => {
    setCount(1);
  };

  const relaxImg = () => {
    setCount(2);
  };

  return (
    <section className="request" id="request">
      <div className="container">
        <div className="commonHeading" id="assist">
          <h2>
            Your <span>Claim requests</span> made simple and faster
          </h2>
        </div>
        <div className="requestContainer">
          <div>
            <div className="requestContent" id="download">
              <label>
                <img src={One} alt="one" />
              </label>
              <h3>Download our app</h3>
              <img className="mobileImage" src={DownloadMobile} alt="download" />
              <p>Download our app and enter the activation code. Select the car you want to raise claim.</p>
            </div>
            <div className="requestContent" id="initiate">
              <label>
                <img src={Two} alt="one" />
              </label>
              <h3>Initiate Claims</h3>
              <img className="mobileImage" src={InitiateMobile} alt="initiade" />
              <p>Enter the details of the claim and few photo of the car are required to initiate the claim process. Its easy and fast.</p>
            </div>
            <div className="requestContent" id="relax">
              <label>
                <img src={Three} alt="one" />
              </label>
              <h3>Sit back & relax</h3>
              <img className="mobileImage" src={RelaxMobile} alt="relax" />
              <p>Once your claim in initiated, our claims team will assist you till your car is received back. Regular updates can be found in the app.</p>
            </div>
          </div>
          <div className="requestBgImage">
            <div className="requestImage">
              <img className={count === 0 ? "current" : "inCurrent"} src={Download} alt="download" id="downloadImage" />
              <img className={count === 1 ? "current" : "inCurrent"} src={Initiate} alt="initiate" id="initiateImage" />
              <img className={count === 2 ? "current" : "inCurrent"} src={Relax} alt="relax" id="relaxImage" />
              <img className="requestBg" src={Bg} alt="Background" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Requests;
