import React from "react";
import Faq from "../../components/faq/faq";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Banner from "../contact-us/banner";
import "./contact-us.css";

function index() {
  return (
    <div>
      <Header />
      <Banner />
      <Faq />
      <Footer />
    </div>
  );
}

export default index;
