import React, { useState } from "react";
import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";
import { cashfreeProd } from "cashfree-pg-sdk-javascript";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PaymentDoneModal from "../../components/popups/paymentDoneModal";
import { completePaymentApi, completeWarrantyPackagePaymentApi } from "../../Api/Apis";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import PaymentFailModalWarrantyPackage from "../../components/popups/PaymentFailModalWarrantyPackage";

const CheckoutWarrantyPackage = () => {
  const navigate = useNavigate();
  const data = useParams();
  const orderToken = data.seId;
  const CoupanCodeTypeId = sessionStorage.getItem("CoupanCodeTypeId");
  const CoupanTypeId = sessionStorage.getItem("CoupanTypeId");
  const productId = sessionStorage.getItem("ProductId");
  const leadId = sessionStorage.getItem("leadId");
  const CoupanId = sessionStorage.getItem("CoupanId");
  const CoupanAmount = sessionStorage.getItem("CoupanAmount");
  const mainPackId = sessionStorage.getItem("mainPackId");
  const customerId = sessionStorage.getItem("customerId");
  const CoupanCode = sessionStorage.getItem("CoupanCode");
  const subpackageId = sessionStorage.getItem("categoryId");
  const phone = sessionStorage.getItem("phone");
  const leadVehicleId = sessionStorage.getItem("leadVehicleId");
  const refeId = sessionStorage.getItem("refeId");
  const vehicleId = sessionStorage.getItem("vehicleId");

  console.log(vehicleId);

  const [paymnetDoneModal, setPaymentDoneModal] = useState(false);
  const [paymentFailModal, setPaymentFailModal] = useState(false);

  function handlePayNowClick() {
    let cashfree = new cashfreeProd.Cashfree(orderToken);
    cashfree.drop(document.getElementById("payment-form"), {
      orderToken: orderToken,
      onSuccess: function (response) {
        console.log(response, "pass");
        completePayment(response.transaction.transactionAmount, response.order.orderId, response.order.status);
      },
      onFailure: function (error) {
        setPaymentFailModal(true);
        console.log(error, "fail");
        completePayment(
          error.transaction.transactionAmount,
          error.order.orderId,
          error.order.status === "ACTIVE" ? "FAILED" : "",
        );
      },
      components: ["order-details", "card", "netbanking", "upi", "app"],
      style: { theme: "Bubble Gum" },
    });
  }

  const closeKycPopup = () => {
    setPaymentDoneModal(false);
  };

  useEffect(() => {
    handlePayNowClick();
  }, []);

  const completePayment = async (amount, orderId, status) => {
    const datas = {
      productId: productId ?? "",
      addonId: "",
      mainPackId: mainPackId ?? "",
      subpackId: subpackageId ?? "",
      amount: amount,
      payStatus: status,
      payType: "online",
      mode: "",
      refNo: refeId,
      orderId: orderId,
      paymentId: "",
      discount: 0,
      gatewayId: "",
      leadId: leadId,
      customerId: customerId,
      leadVehicleId: leadVehicleId === "null" ? "" : leadVehicleId,
      customerId: customerId ?? "",
      leadVehicleId: leadVehicleId ?? "",
      withpack: "Y",
      ismultipleaddon: "N",
      deliveryNote: "",
      salesReceipt: "",
      couponId: !CoupanId ? "" : CoupanId,
      couponTypeId: !CoupanTypeId ? "" : CoupanTypeId,
      couponAmount: !CoupanAmount ? "" : CoupanAmount,
      vehicleId: vehicleId === "null" ? "" : vehicleId,
      couponCode: !CoupanCode ? "" : CoupanCode,
      couponCodeTypeId: !CoupanCodeTypeId ? "" : CoupanCodeTypeId,
      addedFrom: "",
      isPartialPayment: "N",
      packFullAmount: 0,
      packPartialAmount: 0,
    };
    try {
      const resp = await completeWarrantyPackagePaymentApi(datas);
      if (resp.status === 200) {
        console.log(resp.data.response.dppId);
        sessionStorage.setItem("vehicleNumber", resp.data.response.vehicleNum);
        sessionStorage.setItem("vehicleMake", resp.data.response.vehicleMake);
        sessionStorage.setItem("vehicleModel", resp.data.response.vehicleModel);
        sessionStorage.setItem("message", resp.data.response.successmsg);

        navigate(`/payment-success-warranty-package/${resp.data.response.dppId}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ background: "#90D3EA" }}>
      <form id="payment-form" style={{ maxWidth: "800px", margin: "auto" }}>
        {/* Add form fields for payment information */}
      </form>
      {paymnetDoneModal && <PaymentDoneModal onClose={closeKycPopup} />}
      {paymentFailModal && <PaymentFailModalWarrantyPackage onClose={closeKycPopup} />}
    </div>
  );
};

export default CheckoutWarrantyPackage;
