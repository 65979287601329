import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ComparePlans from "../comparePlans/comparePlans";

const KnowMore = () => {
  return (
    <div>
      <Header />
      <ComparePlans />
      <Footer />
    </div>
  );
};

export default KnowMore;
