import React from "react";
import "./aboutwisedrive.css";
import { Divider } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

function AboutWiseDrive() {
  return (
    <section>
      <div className="container">
        <p>About us</p>
        <div className="about-wisedrive-header">
          <h1 style={{ width: "60%", fontSize: "55px" }}>
            Our mission is <br />
            to guarantee
            <br /> peace of mind <br />
            for used car buyers
          </h1>
          <p style={{ width: "50%", fontFamily: "gilroy" }}>
            Wisedrive is India’s first Generative AI company providing
            innovative used car inspection service and advanced showroom grade
            Extended warranty services for used cars in India. We have partnered
            with OEM authorised services to provide best in class services for
            our warranty customers post purchase guaranteeing Peace of Mind. We
            provide Independent & Unbiased 3rd party inspection with Best AI in
            Automotive Industry and guaranteed Transparent and Accurate
            used car inspection.
          </p>
        </div>
        <div className="about-wisedrive-flex">
          <div className="about-wisedrive-inFlex">
            <div className="about-wisedrive-inFlex-content">
              <h2>200,000+</h2>
              <p>Used Car inspection</p>
              <p style={{ fontSize: "14px", fontFamily: "gilroy" }}>
                Customer who bought our inspections
              </p>
            </div>
            <div className="about-wisedrive-inFlex-content">
              <h2>75,000+</h2>
              <p>Used Car Warranty</p>
              <p style={{ fontSize: "14px", fontFamily: "gilroy" }}>
                Active Warranty Customers in India
              </p>
            </div>
          </div>
          <div className="about-wisedrive-inFlex">
            <div className="about-wisedrive-inFlex-content">
              <h2>80,00,000+</h2>
              <p>Trust in Wisedrive</p>
              <p style={{ fontSize: "14px", fontFamily: "gilroy" }}>
                Km travelled by our happy customers
              </p>
            </div>
            <div className="about-wisedrive-inFlex-content">
              <h2>20 Cities</h2>
              <p>7000+ Pincodes</p>
              <p style={{ fontSize: "14px", fontFamily: "gilroy" }}>
                We cater inspection in 20 Cities in India
              </p>
            </div>
          </div>
        </div>
        <Divider sx={{ marginTop: "3rem", marginBottom: "3rem" }} />
        <p>Our Mission</p>
        <h2 style={{ fontFamily: "gilroySemiBold", marginBottom: "0px" }}>
          How we guarantee peace of mind?
        </h2>
        <p style={{ fontFamily: "gilroy" }}>
          We start by inspecting your car before you purchase
        </p>
        <div className="about-wisedrive-inFlex">
          <div style={{ width: "100%" }}>
            <p style={{ fontFamily: "gilroy" }}>
              WiseDrive is committed to protecting used car buyers from the risk
              of purchasing unreliable vehicles. Our process starts with a
              thorough inspection of each car, ensuring it meets our stringent
              quality standards. Only the vehicles that pass our inspection
              receive the WiseDrive seal of approval. As part of our commitment
              to customer satisfaction, every approved car comes with a free
              60-day warranty. This initial coverage ensures buyers have peace
              of mind and confidence in their purchase.
            </p>
            <h6 style={{ marginTop: "10px" }}>Here’s how it works:</h6>
            <p style={{ fontFamily: "gilroy" }}>
              <DoneIcon /> Comprehensive Inspection: Every car is rigorously
              inspected to identify and address potential issues.
            </p>
            <p style={{ paddingTop: "0px", fontFamily: "gilroy" }}>
              <DoneIcon /> Free 60-Day Warranty: Approved vehicles come with
              this complimentary coverage, giving buyers immediate protection
              against unexpected repairs.
            </p>
            <p style={{ paddingTop: "0px", fontFamily: "gilroy" }}>
              <DoneIcon /> Extended Warranty Options: After the initial 60 days,
              customers have the option to extend their warranty and continue
              enjoying protection.
            </p>
          </div>
          <div style={{ width: "100%" }}>
            <p style={{ fontFamily: "gilroy" }}>
              Beyond offering protection during purchase, WiseDrive’s service
              and maintenance module ensures long-term vehicle health. Through
              our platform, customers can easily track their vehicle’s
              maintenance history, receive reminders for upcoming services, and
              even book appointments with trusted service providers.
            </p>
            <h6 style={{ marginTop: "10px" }}>
              Our maintenance features include:
            </h6>
            <p style={{ fontFamily: "gilroy" }}>
              <DoneIcon /> Service Reminders: Never miss essential maintenance
              tasks like oil changes, tire rotations, or brake checks.
            </p>
            <p style={{ paddingTop: "0px", fontFamily: "gilroy" }}>
              <DoneIcon /> Easy Booking: Schedule maintenance appointments
              directly through the platform with vetted service centers.
            </p>
            <p style={{ paddingTop: "0px", fontFamily: "gilroy" }}>
              <DoneIcon /> Maintenance History Tracking: Keep all records in one
              place for better organization and resale value.
            </p>
            <p style={{ fontFamily: "gilroy" }}>
              By combining thorough inspections, warranty protection, and
              ongoing maintenance support, WiseDrive not only helps buyers avoid
              bad cars but also ensures their vehicles remain in top condition,
              enhancing safety and reliability over time.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutWiseDrive;
