import { Height } from "@mui/icons-material";
import React from "react";

function NoResultsFound() {
  return (
    <section
      style={{ width: "100%", display: "flex", minHeight: "6rem", justifyContent: "center", alignItems: "center" }}
    >
      <p style={{ fontSize: 20, fontWeight: "bold" }}>No results found for the selected filters!</p>
    </section>
  );
}

export default NoResultsFound;
