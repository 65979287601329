import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import PaymentCompleted from "./paymentCompleted";

const PaymentSuccessWarrantyPackage = () => {
  return (
    <>
      <Header />
      <PaymentCompleted />
      <Footer />
    </>
  );
};

export default PaymentSuccessWarrantyPackage;
