import React from 'react';
import Header from "../../components/header";
import Footer from "../../components/footer";
import PaymentCompleted from "./paymentCompleted";

const PaymentSuccess = () => {
    return(
        <>
            <Header/>
            <PaymentCompleted/>
            <Footer/>
        </>
    )
}

export default PaymentSuccess