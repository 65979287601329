import React from "react";
import GooglePlay from "../../assets/images/home/googlePlay.png";
import AppStore from "../../assets/images/home/appStore.png";
import QrCode from "../../assets/images/home/newQr.png";
import "./download.css";

function Download({}) {
  return (
    <section className="download">
      <div className="container">
        <div className="downloadDetails">
          {/* <img src={Mobile} alt="Mobile App" /> */}
          <div className="downloadContent">
            {/* <h2>
              Download one click <b>extended warranty</b>
            </h2> */}
            <h2>
              One click download <b>peace of mind</b>
            </h2>
            <ul>
              {/* <li>Comprehensive warranty with buyback warranty</li> */}
              <li>Book Periodic Maintenance services</li>
              <li>Request Road side assistance</li>
            </ul>
            <div className="downloadApp">
              <div className="downloadAppStore">
                <a
                  href="https://play.google.com/store/apps/details?id=com.wisedrive.customerapp&hl=en_IN&gl=US"
                  target="_blank"
                >
                  <img src={GooglePlay} alt="Google Play Store" />
                </a>
                <a
                  href="https://apps.apple.com/in/app/wisedrive-warranty/id1599327744"
                  target="_blank"
                >
                  <img src={AppStore} alt="App Store" />
                </a>
              </div>
            </div>
          </div>
          <div className="downloadQr">
            <img src={QrCode} alt="Scan QR" />
            <p>Scan to download</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Download;
