import React from "react";
import Star from "../../assets/images/pricing-plans/priceStar.svg";
import styles from "./mostPopular.module.css";

const MostPopular = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className={styles.most_popular}>
          <img src={Star} alt="Star" />
          <div>Popular</div>
        </div>
      </div>
    </>
  );
};

export default MostPopular;
