import React, { useEffect, useState } from "react";
import backarrow from "../../assets/images/checkot-plans/backarrow.svg";

import edit from "../../assets/images/checkot-plans/edit.svg";
import BuleArrow from "../../assets/images/checkot-plans/BlueRightArrow.svg";
import verifiedUser from "../../assets/images/checkot-plans/verifiedUser.svg";
import Warning from "../../assets/images/checkot-plans/warning.svg";
import Arrow from "../../assets/images/home/rightArrow.svg";
import Carparts from "../../assets/images/checkot-plans/Carparts.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import PurchasePlan from "../popups/purchasePlan";
import WhiteStar from "../../assets/images/checkot-plans/whitestar.svg";
import OrangeStar from "../../assets/images/checkot-plans/orangeStar.svg";
import { WarrantyPlanApi, WarrantyPlanPackageApi, packageBasedonPlanApi, verifyCoupanApi } from "../../Api/Apis";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Plans = ({ tabsOpened }) => {
  const navigate = useNavigate();
  const { id, plan, tabId } = useParams();
  const planNumber = parseInt(plan);
  const selectedTab = parseInt(tabId);
  const [count, setCount] = useState(planNumber === 1 ? 0 : planNumber === 4 ? 2 : "");
  const [unlockCount, setUnlockCount] = useState(0);
  const [subcount2, setSubCount2] = useState(0);
  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(1999);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [moreCheckError, setMoreCheckError] = useState(false);
  const [moreCheckError1, setMoreCheckError1] = useState(false);
  const [openCarModal, setOpenCarModal] = useState(false);
  const [warrantyPlanList, setWarrantyPlanList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [coupanCode, setCoupanCode] = useState("");
  const [validCoupan, setValidCoupan] = useState(false);
  const [packId, setPackId] = useState("");
  const [mainPackId, setMainPackId] = useState("");
  const [coupanErroMessage, setCoupanErrorMessage] = useState(false);
  const [coupanInvalidmessage, setCoupanInvalidMessage] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [selected, setSelected] = useState(selectedTab);
  const [subSelected, setSubSelected] = useState(0);
  const [mobileSubSelect, setMobileSubSelect] = useState(0);
  const [subSubPackageList, setSubSubpackageList] = useState({});
  const [show, setShow] = useState(false);

  const leadId = sessionStorage.getItem("leadId");
  const customerId = sessionStorage.getItem("customerId");
  const phone = sessionStorage.getItem("phone");
  const categoryId = sessionStorage.getItem("categoryId");

  const getWarrantyPlanApi = async () => {
    try {
      const resp = await WarrantyPlanApi();
      if (resp.status === 200) {
        console.log(resp, "abcd");
        setPlanList(resp.data.response.WarrantyPackList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // getWarrantyPlanApi();
  }, []);

  const packageBasedOnPlan = async (lead, customer, plan, categoryId) => {
    try {
      const resp = await packageBasedonPlanApi(lead, customer, plan, categoryId);
      if (resp.status === 200) {
        setPackageList(resp.data.response.PackBasedOnPlan);
        setTotal(resp.data.response.PackBasedOnPlan[0].final_price);
        setPackId(resp.data.response.PackBasedOnPlan[0]?.package_id);
        setMainPackId(resp.data.response.PackBasedOnPlan[0]?.main_package_id);
        setSubSubpackageList(resp.data.response.PackBasedOnPlan[0]);
        console.log(resp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    packageBasedOnPlan(leadId, customerId, planNumber, categoryId);
  }, []);

  const checkout = async () => {
    const data = {
      leadEmail: "",
      leadPhone: phone,
      leadName: "",
      orderAmount: total - couponDiscount,
      leadId: leadId,
    };

    try {
      const response = await axios.post(`${baseUrl}cashfree/generateOrder`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.responseType === "200") {
        sessionStorage.setItem("ProductId", packId);
        sessionStorage.setItem("mainPackId", mainPackId);
        sessionStorage.setItem("refeId", response.data.response.CashfreeorderData.cf_order_id);
        console.log(response, "abcdgggg");
        navigate(`/checkout_warranty_package/${response.data.response.CashfreeorderData.payment_session_id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyCoupan = async () => {
    if (!coupanCode) {
      setCoupanErrorMessage(true);
    } else {
      try {
        const resp = await verifyCoupanApi(leadId, customerId, packId, categoryId, mainPackId, total, coupanCode);
        if (resp.status === 200) {
          if (resp.data.responseType === "200") {
            setCouponDiscount(resp.data.response.discount_amount);
            sessionStorage.setItem("CoupanId", resp.data.response.coupon_id);
            sessionStorage.setItem("CoupanTypeId", resp.data.response.coupon_type);
            sessionStorage.setItem("CoupanAmount", resp.data.response.discount_amount);
            sessionStorage.setItem("CoupanCode", resp.data.response.coupon_code);
            sessionStorage.setItem("CoupanCodeTypeId", resp.data.response.coupon_code_type_id);

            console.log(resp);
            setValidCoupan(true);
          } else if (resp.data.responseType === "300") {
            console.log(resp);
            setCoupanInvalidMessage(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeCoupan = () => {
    setValidCoupan(false);
    setCoupanCode("");
    setCouponDiscount(0);
  };

  return (
    <>
      <section className="checkoutBanner">
        <div className="container">
          {/* Top  start */}
          <div className="checkout-title-content">
            <div className="checkout-link-container">
              <a onClick={() => navigate(-1)}>
                <img className="checkout-back-icon" src={backarrow} alt="back" />
              </a>

              <h1>Checkout</h1>
            </div>

            {/* <div className="checkout-input-container">
              <div>
                <h3 className="checkout-carmake">
                  Honda skoda
                  <span className="checkout-carnumber mobile2">KA05MX4269</span>{" "}
                </h3>
                <span className="checkout-carnumber desktop">KA05MX4269</span>
                <img className="cursor-pointer" src={edit} alt="" onClick={() => setOpenCarModal(true)} />
              </div>
            </div> */}
          </div>
          {/* Top end */}

          <div className="checkout-Tabswrapper2">
            {/* -----Tab one start ----*/}
            {planList &&
              planList.map((each, index) => {
                const isSelected = selected === index;

                return (
                  <>
                    <div
                      className={`plansTabs ${isSelected ? "selected" : ""}`}
                      style={{ marginLeft: "2%", marginRight: "0px" }}
                      key={index}
                      onClick={() => {
                        packageBasedOnPlan(leadId, customerId, each.package_type_name_id, categoryId);
                        setSelected(index);
                        setSubSelected(0);
                      }}
                    >
                      <div className={`planTabsTitle ${isSelected ? "planTabsTitle-current" : ""}`}>
                        <div className="checkout-green-comboplan containerdiv">
                          <h2>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g mask="url(#mask0_4771_34425)">
                                <path
                                  className="circle"
                                  d="M12 17C13.3833 17 14.5627 16.5123 15.538 15.537C16.5127 14.5623 17 13.3833 17 12C17 10.6167 16.5127 9.43733 15.538 8.462C14.5627 7.48733 13.3833 7 12 7C10.6167 7 9.43767 7.48733 8.463 8.462C7.48767 9.43733 7 10.6167 7 12C7 13.3833 7.48767 14.5623 8.463 15.537C9.43767 16.5123 10.6167 17 12 17Z"
                                  fill="url(#paint1_linear_4771_34425)"
                                />
                                <path
                                  d="M12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                  fill="url(#paint2_linear_4771_34425)"
                                />
                              </g>
                              <defs>
                                <linearGradient
                                  id="paint0_linear_4771_34425"
                                  x1="0"
                                  y1="0"
                                  x2="28.6597"
                                  y2="8.76687"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#2836D7" />
                                  <stop offset="1" stop-color="#8E93D7" />
                                </linearGradient>
                                <linearGradient
                                  id="paint1_linear_4771_34425"
                                  x1="7"
                                  y1="7"
                                  x2="18.9416"
                                  y2="10.6529"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#2836D7" />
                                  <stop offset="1" stop-color="#8E93D7" />
                                </linearGradient>
                                <linearGradient
                                  id="paint2_linear_4771_34425"
                                  x1="2"
                                  y1="2"
                                  x2="25.8831"
                                  y2="9.30572"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#2836D7" />
                                  <stop offset="1" stop-color="#8E93D7" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </h2>
                          <p>{each.package_type_name}</p>
                        </div>
                      </div>
                    </div>

                    {isSelected && (
                      <div className="mobile mobile-plan-wrpper2">
                        <div className="paymentTabsContent">
                          <div className="planTabsContent">
                            <div className="unlockTab">
                              {packageList &&
                                packageList.map((subEach, subIndex) => {
                                  const isSelected = mobileSubSelect === subIndex;

                                  return (
                                    <div
                                      key={subIndex}
                                      className={`unlockTableSwitch ${isSelected ? "current" : ""}`}
                                      onClick={() => {
                                        setMobileSubSelect(subIndex);
                                        setTotal(subEach.final_price);
                                        setPackId(subEach.package_id);
                                        setMainPackId(subEach.main_package_id);
                                        setSubSubpackageList(subEach);
                                      }}
                                    >
                                      <p>
                                        Plan {subIndex + 1} <br />(
                                        <span style={{ textDecoration: "line-through" }}>{subEach.original_price}</span>
                                        )
                                      </p>
                                      <h3>₹{subEach.final_price}</h3>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="planData">
                              <div>
                                <ul className="tabList">
                                  {subSubPackageList.display_name}
                                  {subSubPackageList.ProductList &&
                                    subSubPackageList.ProductList.map((subEach, Subindex) => {
                                      return (
                                        <li key={Subindex}>
                                          {subEach.validity}
                                          {subEach.product_name}
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}

            {/*---- Tab one end---- */}

            {/* ----Tab three start---- */}
            {/* <div
              className={
                count === 2 ? "plansTabs planTabsSuperCombo no-padding-bottom" : "plansTabs green planTabsSuperCombo"
              }
              style={{ marginLeft: "4%" }}
            >
              <div
                onClick={() => {
                  setCount(2);
                  packageBasedOnPlan(leadId, customerId, planList[1]?.package_type_name_id, categoryId);
                  setUnlockCount(0);
                }}
                className={count === 2 ? "planTabsTitle planTabsTitle-current" : "planTabsTitle green"}
              >
                <div className=" containerdiv checkout-green-comboplan green">
                  <h2>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g mask="url(#mask0_4771_34425)">
                        <path
                          className="circle"
                          d="M12 17C13.3833 17 14.5627 16.5123 15.538 15.537C16.5127 14.5623 17 13.3833 17 12C17 10.6167 16.5127 9.43733 15.538 8.462C14.5627 7.48733 13.3833 7 12 7C10.6167 7 9.43767 7.48733 8.463 8.462C7.48767 9.43733 7 10.6167 7 12C7 13.3833 7.48767 14.5623 8.463 15.537C9.43767 16.5123 10.6167 17 12 17Z"
                          fill="url(#paint1_linear_4771_34425)"
                        />
                        <path
                          d="M12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                          fill="url(#paint2_linear_4771_34425)"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_4771_34425"
                          x1="0"
                          y1="0"
                          x2="28.6597"
                          y2="8.76687"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2836D7" />
                          <stop offset="1" stop-color="#8E93D7" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_4771_34425"
                          x1="7"
                          y1="7"
                          x2="18.9416"
                          y2="10.6529"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2836D7" />
                          <stop offset="1" stop-color="#8E93D7" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_4771_34425"
                          x1="2"
                          y1="2"
                          x2="25.8831"
                          y2="9.30572"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2836D7" />
                          <stop offset="1" stop-color="#8E93D7" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </h2>
                  <p>{planList[1]?.package_type_name}</p>
                  <label>
                    {" "}
                    <img className="greenStar" src={WhiteStar} alt="green star" />
                    <img className="orangeStar" src={OrangeStar} alt="green star" />
                    Popular!
                  </label>
                </div>

                {count !== 2 && (
                  <div className="mobile smallTextPrice">
                    <span className="getFreeService color-green-text mobile"></span>
                  </div>
                )}
              </div>
              {count === 2 && (
                <>
                  <div className="mobile no-padding-bottom">
                    <div className="paymentTabsContent">
                      <div className="planTabsContent">
                        <div className="unlockTab">
                          <div
                            className={`${unlockCount === 0 ? "unlockTableSwitch current " : "unlockTableSwitch"}`}
                            onClick={() => {
                              setUnlockCount(0);
                              setTotal(packageList[0]?.final_price);
                              setPackId(packageList[0]?.package_id);
                              setMainPackId(packageList[0]?.main_package_id);
                            }}
                          >
                            <p>
                              Plan 1 <br />(
                              <span style={{ textDecoration: "line-through" }}>{packageList[0]?.original_price}</span>)
                            </p>
                            <h3>₹{packageList[0]?.final_price}</h3>
                          </div>
                        </div>
                        <div className="tabData">
                          {unlockCount === 0 && (
                            <ul className="tabList">
                              {packageList[0]?.display_name}
                              {packageList[0]?.ProductList.map((each, index) => {
                                return (
                                  <li>
                                    {each.validity}
                                    {each.product_name}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div> */}
          </div>
          {/*---- Tab three end---- */}

          {/* <div className="mobile checkouts-carparts-wrapper">
            <div className="carparts-title-wrapper">
              <div>
                <img src={verifiedUser} alt="" />
                <h2>Complete car protection covered</h2>
              </div>
              <div>
                <p>20+ parts covered in single plan</p>
              </div>
            </div>
            <div className="car-parts-img-wapper">
              <img className="carpartsImg" src={Carparts} alt="" />
            </div>
            <div className="carpartsfooterLink">
              <a href="">
                Get Super Combo Plan <img src={BuleArrow} alt="" />
              </a>
            </div>
          </div> */}

          {/* --------- laptopScreen start--------- */}
          <div className="checkoutAmount">
            <div className="checkout-bill-Container desktop">
              <div className="paymentTabsContent">
                <div className="planTabsContent">
                  <div className="unlockTab">
                    {packageList &&
                      packageList.map((each, index) => {
                        const isSelected = subSelected === index;
                        return (
                          <div
                            className={`unlockTableSwitch ${isSelected ? "current" : ""}`}
                            key={index}
                            onClick={() => {
                              setSubSelected(index);
                              setTotal(each.final_price);
                              setPackId(each.package_id);
                              setMainPackId(each.main_package_id);
                              setSubSubpackageList(each);
                            }}
                          >
                            <p>
                              Plan {index + 1} (
                              <span style={{ textDecoration: "line-through" }}>{each.original_price}</span>){" "}
                            </p>
                            <h3>₹{each.final_price}</h3>
                          </div>
                        );
                      })}
                  </div>
                  <div className="planData">
                    <div>
                      <ul className="tabList">
                        {subSubPackageList.display_name}
                        {subSubPackageList.ProductList &&
                          subSubPackageList.ProductList.map((subEach, Subindex) => {
                            return (
                              <li key={Subindex}>
                                {subEach.validity}
                                {subEach.product_name}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="plansTotal">
              <div>
                <p>Total (INR)</p>
                <span>₹{total}</span>
              </div>
              <div>
                <p>Coupon Discount</p>
                <span>₹{couponDiscount}</span>
              </div>
              <div>
                <p>Subtotal</p>
                <span>₹{total - couponDiscount}</span>
              </div>
              <div className="seperator desktop">
                <>&nbsp;</>
              </div>
              <div className="desktop">
                <h4>Total (INR)</h4>
                <h3>{total - couponDiscount}</h3>
              </div>
              <div className="seperator desktop">
                <>&nbsp;</>
              </div>
              <div className="couponWrapper">
                <input
                  value={coupanCode}
                  onChange={(e) => {
                    setCoupanCode(e.target.value);
                    setCoupanErrorMessage(false);
                    setCoupanInvalidMessage(false);
                  }}
                  type="text"
                />
                {validCoupan ? (
                  <a className="active" style={{ color: "red" }} onClick={removeCoupan}>
                    Remove
                  </a>
                ) : (
                  <span onClick={verifyCoupan} style={{ cursor: "pointer" }}>
                    Apply
                  </span>
                )}
              </div>
              {coupanErroMessage && <p style={{ color: "red" }}>Please enter Coupon</p>}
              {coupanInvalidmessage && <p style={{ color: "red" }}>Invalid Coupon</p>}

              <div className="desktop">
                <button
                  onClick={() => {
                    checkout();
                  }}
                >
                  Proceed <img src={Arrow} alt="" />
                </button>
              </div>
            </div>
            <div className="totalAmount mobile">
              <div>
                <h4>Total (INR)</h4>
                <h3>
                  <span></span>
                  <p>₹{total - couponDiscount}</p>
                </h3>
              </div>

              <div>
                <button
                  onClick={() => {
                    checkout();
                  }}
                >
                  Proceed <img src={Arrow} alt="" />
                </button>
              </div>
            </div>
          </div>
          {/* ------------laptopScreen end----------- */}
        </div>
        {openCarModal && <PurchasePlan setOpenPurchasePlanModal={setOpenCarModal} carNumber />}
      </section>
    </>
  );
};

export default Plans;
