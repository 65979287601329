import React, { useState } from "react";
import ContactUs from "../../components/contact-us/contact-us";
import Customers from "../../components/customers/customers";
import Faq from "../../components/faq/faq";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Banner from "./banner";
import Compare from "./compare";

import "./pricing-plans.css";
import PricingTables from "../../components/pricingTables";

const OwnerPricingPlans = () => {
  const [active, setActive] = useState(0);
  return (
    <div>
      <Header />
      <PricingTables />
      <Faq />
      <Customers />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default OwnerPricingPlans;
