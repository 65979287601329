import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import "./comparePlans.css";

function createData(description, icon, icon1) {
  return { description, icon, icon1 };
}
const rows = [
  createData(
    "FREE Warranty upto 2 years with Inspection 2 years with Inspection",
    <DoneIcon style={{ color: "white" }} />,
    <CloseIcon style={{ color: "red" }} />
  ),
  createData(
    "FREE Inspection post purchase of car while in Warranty 2 years with Inspection",
    <DoneIcon style={{ color: "white" }} />,
    <CloseIcon style={{ color: "red" }} />
  ),
  createData(
    "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    <DoneIcon style={{ color: "white" }} />,
    <CloseIcon style={{ color: "red" }} />
  ),
  createData(
    "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    <DoneIcon style={{ color: "white" }} />,
    <CloseIcon style={{ color: "red" }} />
  ),
  createData(
    "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    <DoneIcon style={{ color: "white" }} />,
    <CloseIcon style={{ color: "red" }} />
  ),
];
let data = [
  {
    type: "Warranty",
    desc: "Warranty",
    inspection: null,
    warranty: null,
    freedom: null,
  },
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: true,
    warranty: false,
    freedom: true,
  },
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  {
    type: "Inspection",
    desc: "Inspection",
    inspection: null,
    warranty: null,
    freedom: null,
  },
  ,
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  ,
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  ,
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  ,
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  ,
  {
    type: "Freedom",
    desc: "Freedom",
    inspection: null,
    warranty: null,
    freedom: null,
  },
  ,
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  ,
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  ,
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  ,
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  ,
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
  ,
  {
    type: "",
    desc: "FREE Maintenance for 2 years while in Warranty 2 years with Inspection",
    inspection: false,
    warranty: true,
    freedom: false,
  },
];
const columns = [
  //   { field: "id", headerName: "id", width: 150 },
  { field: "desc", headerName: " ", width: 500 },

  {
    field: "inspection",
    headerName: "Inspection 1999",
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    renderCell: (params) => {
      return params.value === true ? (
        <DoneIcon style={{ color: "green" }} />
      ) : (
        <CloseIcon style={{ color: "red" }} />
      );
    },
  },
  {
    field: "warranty",
    headerName: "Warranty 2999",
    width: 200,
    display: "flex",
    alignItems: "center",
    renderCell: (params) => {
      return params.value === true ? (
        <DoneIcon style={{ color: "green" }} />
      ) : (
        <CloseIcon style={{ color: "red" }} />
      );
    },
  },
  {
    field: "freedom",
    headerName: "Freedom 3999",
    width: 200,
    display: "flex",
    alignItems: "center",
    renderCell: (params) => {
      return params.value === true ? (
        <DoneIcon style={{ color: "green" }} />
      ) : (
        <CloseIcon style={{ color: "red" }} />
      );
    },
  },
];
const ComparePlans = () => {
  const getRowClassName = (params) => {
    const { row } = params;
    // Conditionally apply CSS class based on the presence of type value
    if (row.type) {
      return "highlighted-row"; // Apply 'highlighted-row' class
    } else {
      return "regular-row"; // Default class
    }
  };

  return (
    <section>
      <div className="container">
        <DataGrid
          sx={{ width: "90%" }}
          getRowId={(row) => row?.type + 1}
          rows={data}
          columns={columns}
          getRowClassName={getRowClassName}
          disableRowSelectionOnClick={true}
          editable={false}
        />
      </div>
    </section>
  );
};

export default ComparePlans;
