import React, { useState } from "react";
import Header from "../../components/header";
import Plans from "../../components/checkout-plans/Plans";
import "./CheckoutPlans.css";
import Footer from "../../components/footer";
import Tables from "../../components/checkout-plans/Tables";

function CheckoutPlans() {
  const [tabsOpened, setTabsOpened] = useState(undefined);
  const handleBtnChange = (tabSelected) => {
    console.log(tabSelected);
    setTabsOpened(tabSelected);
  };
  return (
    <div className="padding-bottom-150px">
      <Header />
      <div className="checkout-container">
        <Plans tabsOpened={tabsOpened} />
      </div>
      {/* <div className="data-tables">
        <Tables handleBtnChange={handleBtnChange} />
      </div> */}
      <Footer />
    </div>
  );
}

export default CheckoutPlans;
