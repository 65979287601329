import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import Car from "../../assets/images/reports/reportCar.png";
// import Shield from "../../assets/images/reports/WD-reportWarranty.svg";
import Shield from "../../assets/carShield.png";

import Honk from "../../assets/images/reports/honk.svg";
import Note from "../../assets/images/reports/note.svg";
import Arrow from "../../assets/images/home/orangeArrow.svg";
import Right from "../../assets/images/home/rightArrowGrediant.svg";
import VersionHistory from "../../components/versionHistory";
import { dateFormatChanger } from "../../utils/commonFunctions";
import { useState } from "react";
import { useEffect } from "react";
import { getVehicleDetails } from "../../services/getInspectionReport";
import Cars from "../../assets/car.png";

const ReportBanner = ({ vehicleDetails }) => {
  const [carData, setCarData] = useState([]);
  const vehicleId = sessionStorage.getItem("vehicleId");
  let countsLeft = sessionStorage.getItem("countsLeft");
  countsLeft = JSON.parse(countsLeft);

  const getCarData = async () => {
    const response = await getVehicleDetails({ vehicleId });
    if (response?.data?.responseType === "200") {
      setCarData(response?.data?.response.Details);
    }
  };

  useEffect(() => {
    getCarData();
  }, [vehicleId]);
  var Props = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 15000,
    slidesToScroll: 3,
    autoplaySpeed: 0,
    cssEase: "linear",
    autoplay: true,
    variableWidth: true,
    infiniteScroll: true,
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "NA";
    }

    const date = new Date(dateString);
    if (isNaN(date)) {
      return "NA";
    }

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    let daySuffix;

    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    const formattedDate = `${day}${daySuffix} ${month} ${year}`;

    return formattedDate;
  };

  function modifyActivatedDate(date) {
    if (date) {
      let yearModified = "";
      const [day, month, year] = date.split(" ");
      if (year.length == 2) {
        yearModified = "20" + year;
      } else {
        yearModified = year;
      }

      return [day, month, yearModified].join(" ");
    }
  }

  return (
    <>
      <section className="reportBanner">
        <div className="container">
          <div
            className="reportContentContainer"
            style={{
              border:
                vehicleDetails?.inspection_status_id === "1"
                  ? "1px solid #208704"
                  : vehicleDetails?.inspection_status_id === "2"
                  ? " 1px solid #f0b9be"
                  : vehicleDetails?.inspection_status_id === "3"
                  ? "1px solid #f2bc8a"
                  : vehicleDetails?.inspection_status_id === "4"
                  ? "1px solid #f0b9be"
                  : "",
            }}
          >
            <div className="reportContainer">
              <div className="reportContent">
                <h2>
                  {vehicleDetails?.vehicle_make} {vehicleDetails?.vehicle_model}{" "}
                </h2>
                <p>{vehicleDetails?.vehicle_no}</p>
                <div className="reportImage mobile">
                  <img src={vehicleDetails?.vehicle_front_image} alt="" />
                </div>
                <div className="reportList">
                  <div className="vehicleDetailsBox">
                    <h3>Fuel type</h3>
                    <p>{vehicleDetails?.fuel_type || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBox">
                    <h3>Transmission</h3>
                    <p>{vehicleDetails?.transmission_type || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBox">
                    <h3>KM Driven</h3>
                    <p>{`${vehicleDetails?.odometer} Kms` || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBox">
                    <h3>Mfg Year</h3>
                    <p>{vehicleDetails.manufacturing_year || "NA"} </p>
                  </div>
                  <div className="vehicleDetailsBox">
                    <h3>Inspection on</h3>
                    <p>{formatDate(vehicleDetails?.inspection_on) || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBox">
                    <h3 style={{ color: "red" }}>Expires on</h3>
                    <p>{formatDate(vehicleDetails?.validity) || "NA"}</p>
                  </div>
                </div>
                <div className="reportList">
                  <div className="vehicleDetailsBox">
                    <h3>Color</h3>
                    <p>{vehicleDetails?.vehicleColour || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBox">
                    <h3>Seating capacity</h3>
                    <p>{vehicleDetails?.vehicleSeatCapacity || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBox">
                    <h3>Cylinders</h3>
                    <p>{vehicleDetails?.vehicleCylindersNo || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBox">
                    <h3>Category</h3>
                    <p>{vehicleDetails?.vehicleCategory || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBox">
                    <h3>Body type</h3>
                    <p>{vehicleDetails?.bodyType || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBox">
                    <h3>Fitness upto</h3>
                    <p>{formatDate(vehicleDetails?.fitness_upto) || "NA"}</p>
                  </div>
                </div>
                <div className="reportList" style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
                  <div className="vehicleDetailsBoxSecond vehicleDetailsBox">
                    <h3>Owner sr.no</h3>
                    <p>{vehicleDetails?.ownerCount || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBoxSecond vehicleDetailsBox">
                    <h3>Engine num</h3>
                    <p>{vehicleDetails?.engine_number || "NA"}</p>
                  </div>
                  <div className="vehicleDetailsBoxSecond vehicleDetailsBox">
                    <h3>Norm type</h3>
                    <p>{vehicleDetails?.normsType || "NA"}</p>
                  </div>
                  <div>
                    <h3>Chassis num</h3>
                    <p>{vehicleDetails?.chassis_num || "NA"}</p>
                  </div>
                </div>
              </div>
              <div className="reportImage desktop">
                <img src={vehicleDetails?.vehicle_front_image} style={{ height: "150px", width: "200px" }} alt="" />
              </div>
            </div>
          </div>
          {vehicleDetails?.inspection_status_id === "1" && (
            <div className="reportMarquee" style={{ border: "0.5px solid #208704" }}>
              <Slider {...Props}>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#208704" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#208704" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#208704" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#208704" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#208704" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#208704" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#208704" }}>{vehicleDetails?.message}</span>
                </p>
              </Slider>
            </div>
          )}
          {vehicleDetails?.inspection_status_id === "2" && (
            <div className="reportMarquee" style={{ background: "#f0b9be", border: "1px solid #a30816" }}>
              <Slider {...Props}>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
              </Slider>
            </div>
          )}
          {vehicleDetails?.inspection_status_id === "3" && (
            <div className="reportMarquee" style={{ background: "#f2bc8a", border: "1px solid #f2bc8a" }}>
              <Slider {...Props}>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#d96207" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#d96207" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#d96207" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#d96207" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#d96207" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#d96207" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Shield} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#d96207" }}>{vehicleDetails?.message}</span>
                </p>
              </Slider>
            </div>
          )}
          {vehicleDetails?.inspection_status_id === "4" && (
            <div className="reportMarquee" style={{ background: "#f0b9be", border: "1px solid #f0b9be" }}>
              <Slider {...Props}>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
                <p>
                  <img src={Cars} alt="warranty" height="25px" width="25px" style={{ color: "#b50b1c" }} />{" "}
                  <span style={{ color: "#a30816" }}>{vehicleDetails?.message}</span>
                </p>
              </Slider>
            </div>
          )}

          {/* <div className="reportAccess">
            <div>
              <img src={Honk} alt="access" />
              <p>{"countsLeft.result"}</p>
            </div>
            {countsLeft?.pending_count !== 0 ? (
              <a href="/payment">
                <span>Subscribe Now</span>
                <img src={Arrow} alt="" />
              </a>
            ) : (
              <a href="/">
                <span>Fetch reports</span>
                <img src={Arrow} alt="" />
              </a>
            )}
          </div> */}
          {/* <VersionHistory setSelectedDate={setSelectedDate} setVersionData={setVersionData} versionData={versionData} />  */}
        </div>
      </section>
    </>
  );
};

export default ReportBanner;
