import React from "react";
import styles from "../../containers/car-details/car-details.module.css";
import StarIcon from "@mui/icons-material/Star";
import userimage from "../../assets/icons/usericon.png";

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-IN", options);
}

function ReviewCard({ review }) {
  return (
    <div className={styles.reviewCard}>
      <div className={styles.reviewHeader}>
        <h4 className={styles.h4}>{review.heading_name}</h4>
        <div style={{ display: "flex", alignItems: "center", gap: 4, marginRight: 10 }}>
          <StarIcon style={{ width: 18, height: 18, color: "#EF8354" }} />
          <span className={styles.h4}>{review.rating}</span>
        </div>
      </div>
      <div className={styles.reviewBody}>
        <p className={styles.p}>{review.description}</p>
        <div className={styles.userInfo}>
          {review.image ? (
            <div className={styles.userImage}>
              <img src={review.image} alt="customer" />
            </div>
          ) : (
            <div className={styles.userImage}>
              <img src={userimage} alt="customer" />
            </div>
          )}
          <div>
            <p className={styles.textNormalBold}>{review.reviewer_name}</p>
            <p className={styles.textFadeSmall}>{review?.date_of_review && formatDate(review?.date_of_review)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCard;
