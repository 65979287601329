import React from "react";
import BuyingCar from "../../assets/images/home/car.svg";
import RightArrow from "../../assets/images/home/rightArrow.svg";
import Jeevan from "../../assets/images/home/jeevan.svg";
import Arjuna from "../../assets/images/home/arjuna.svg";
import Lakshmi from "../../assets/images/home/lakshmi.svg";
import Akhil from "../../assets/images/home/akhil.svg";
import "./claims.css";
import Slider from "react-slick/lib/slider";
import Profile from "../../assets/images/claims/devon.svg";

function Banner() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    vertical: true,
    slidesToShow: 2.35,
    slidesToScroll: 1,
    adaptiveHeight: false,
    speed: 2000,
    autoplaySpeed: 3000,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          vertical: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          vertical: false,
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 650,
        settings: {
          vertical: false,
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          vertical: false,
          slidesToShow: 1.1,
        },
      },
      {
        breakpoint: 374,
        settings: {
          vertical: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="claimBanner">
      <div className="container">
        <div className="bannerHeading">
          <label>
            <img src={BuyingCar} alt="Car" /> Make money through your network
          </label>
          <h1>
            We take each <span>claim seriously!</span>
          </h1>
          <p>Our simple and hassle free claim process will ensure you get the right support & your car gets specialised attention!</p>
          <a href="#request" className="cta-button">
            How to Claim <img src={RightArrow} alt="Arrow" />
          </a>
        </div>
        <div className="bannerSlider claims-profile-wrapper">
          <Slider {...settings}>
            <div className="cardsContainer">
              <div className="bannerCard">
                <p>Very good service. And on time wise drive thank u soo much for the one year warranty and service. Person Syed imran</p>
                <p>
                  Claim Amount : <span>₹40,000</span>
                </p>
                <p>
                  Time Taken: <span>20 minutes</span>
                </p>
                <div className="customersCardProfile">
                  <img src={Jeevan} alt="Profile" />
                  <div className="customersCardDetail">
                    <h5>jeevan</h5>
                    <label>Maruti swift</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardsContainer">
              <div className="bannerCard">
                <p>Wisedrive provided excellent and professional service for my skoda rapid at the door step.. very much happy... Would highly recommend this</p>
                <p>
                  Claim Amount : <span>₹40,000</span>
                </p>
                <p>
                  Time Taken: <span>20 minutes</span>
                </p>
                <div className="customersCardProfile">
                  <img src={Arjuna} alt="Profile" />
                  <div className="customersCardDetail">
                    <h5>Arjuna kumar</h5>
                    <label>Skoda Rapid</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardsContainer">
              <div className="bannerCard">
                <p>It was wonderfull experince. They have done showroom service for my Ecosport vehicle. Customer support has been done amazing job by providing eacha and every update. Thanks to wisedrive</p>
                <p>
                  Claim Amount : <span>₹40,000</span>
                </p>
                <p>
                  Time Taken: <span>20 minutes</span>
                </p>
                <div className="customersCardProfile">
                  <img src={Lakshmi} alt="Profile" />
                  <div className="customersCardDetail">
                    <h5>Laxmi</h5>
                    <label>Ford Ecosport</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="cardsContainer">
              <div className="bannerCard">
                <p>I had my car service today through wisedrive and the service provided was really good and the executive assisted the vehicle at best, trust me it was quick with full quality, All the parts replaced were genune.</p>
                <p>
                  Claim Amount : <span>₹40,000</span>
                </p>
                <p>
                  Time Taken: <span>20 minutes</span>
                </p>
                <div className="customersCardProfile">
                  <img src={Akhil} alt="Profile" />
                  <div className="customersCardDetail">
                    <h5>akhil sacky</h5>
                    <label>Honda city</label>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Banner;
