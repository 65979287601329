import React, { useState, useRef } from "react";
import Back from "../../assets/images/home/back.svg";
import Arrow from "../../assets/images/home/rightArrow.svg";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Banner = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [grandTotal, setGrandTotal] = useState(749);
  const lead_Id = sessionStorage.getItem("lead_Id");
  const phone = sessionStorage.getItem("phone");
  const [packageId, setPackageId] = useState(3);
  const [quantity, setQuantity] = useState(5);

  const ref = useRef();

  const checkout = async () => {
    const data = {
      leadEmail: "",
      leadPhone: phone,
      leadName: "",
      orderAmount: grandTotal,
      leadId: lead_Id,
    };

    try {
      const response = await axios.post(`${baseUrl}cashfree/generateOrder`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.responseType === "200") {
        sessionStorage.setItem("packageId", packageId);
        sessionStorage.setItem("refeId", response.data.response.CashfreeorderData.cf_order_id);
        sessionStorage.setItem("quantity", quantity);
        navigate(`/checkout-wiseAssist/${response.data.response.CashfreeorderData.payment_session_id}`);
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <section className="paymentBanner">
        <div className="container">
          <div className="paymentContent">
            <Link to="/report">
              <img src={Back} alt="back" />
            </Link>
            <div>
              <h1>Simple & Transparent pricing</h1>
              <p>Simple plans to access warranty cars in and buy the best car.</p>
            </div>
            <>&nbsp;</>
          </div>

          <div className="paymentTabs">
            <div className="paymentTabsTitles">
              {/* First Tab Start */}
              <div className={count === 1 ? "paymentTabsTitle current" : "paymentTabsTitle"}>
                <h2>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g mask="url(#mask0_4771_34425)">
                      <path
                        className="circle"
                        d="M12 17C13.3833 17 14.5627 16.5123 15.538 15.537C16.5127 14.5623 17 13.3833 17 12C17 10.6167 16.5127 9.43733 15.538 8.462C14.5627 7.48733 13.3833 7 12 7C10.6167 7 9.43767 7.48733 8.463 8.462C7.48767 9.43733 7 10.6167 7 12C7 13.3833 7.48767 14.5623 8.463 15.537C9.43767 16.5123 10.6167 17 12 17Z"
                        fill="url(#paint1_linear_4771_34425)"
                      />
                      <path
                        d="M12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                        fill="url(#paint2_linear_4771_34425)"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_4771_34425"
                        x1="0"
                        y1="0"
                        x2="28.6597"
                        y2="8.76687"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2836D7" />
                        <stop offset="1" stop-color="#8E93D7" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_4771_34425"
                        x1="7"
                        y1="7"
                        x2="18.9416"
                        y2="10.6529"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2836D7" />
                        <stop offset="1" stop-color="#8E93D7" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_4771_34425"
                        x1="2"
                        y1="2"
                        x2="25.8831"
                        y2="9.30572"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2836D7" />
                        <stop offset="1" stop-color="#8E93D7" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span>Wiseassist</span>
                </h2>
                {count === 1 && (
                  <div className="paymentTabsContent mobile ">
                    <div className="tabHeading">
                      <h3 className="tabHeadingTitlePhase3">Wiseassist</h3>
                    </div>
                    <div className="tabData">
                      <ul className="tabList">
                        <li>Unlimited Access of Pre-approved Warranty cars in Wisedrive.in portal.</li>
                        <li>Post vehicle requirement to partner dealers.</li>
                        <li>Unlimited FREE inspections at partner dealers.</li>
                        <li>5 FREE inspections at your preferred place anywhere within Bangalore city limits.</li>
                        <li>
                          Rs 999 is refundable or deductible with 1year Comprehensive Warranty package or 1
                          Comprehensive Warranty with Service Package.
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              {/* First Tab end */}
            </div>

            <div className="paymentTabsContainer desktop">
              {/* SubPackage display on laptop Screen start */}
              {count === 1 && (
                <div className="paymentTabsContent">
                  <div className="tabHeading">
                    <h3 className="tabHeadingTitlePhase3">Wiseassist</h3>
                  </div>
                  <div className="tabData" style={{ height: "200px", overflow: "auto" }}>
                    <ul className="tabList">
                      <li>Unlimited Access of Pre-approved Warranty cars in Wisedrive.in portal.</li>
                      <li>Post vehicle requirement to partner dealers.</li>
                      <li>Unlimited FREE inspections at partner dealers.</li>
                      <li>5 FREE inspections at your preferred place anywhere within Bangalore city limits.</li>
                      <li>
                        Rs 999 is refundable or deductible with 1year Comprehensive Warranty package or 1 Comprehensive
                        Warranty with Service Package.
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            {/* SubPackage display on laptop screen */}
          </div>

          <div className="paymentAmount">
            <div className="paymentCoupon"></div>
            <div className="paymentTotal">
              <div className="seperator desktop">
                <>&nbsp;</>
              </div>
              <div className="desktop">
                <h4>Total (INR)</h4>
                <h3>₹ {grandTotal}</h3>
              </div>
              <div className="desktop">
                <button onClick={() => checkout()}>
                  Pay Now
                  <img src={Arrow} alt="" />
                </button>
              </div>
            </div>

            <div className="totalAmount mobile">
              <div>
                <h4>Total (INR)</h4>
                <h3>
                  <span style={{ fontWeight: "500", color: "#262C6E", fontSize: "27px" }}>₹ {grandTotal}</span>
                </h3>
              </div>
              <div>
                <button onClick={() => checkout()}>
                  Pay Now
                  <img src={Arrow} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
