import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactUs from "../../components/contact-us/contact-us";
import Download from "../../components/download/download";
import Faq from "../../components/faq/faq";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Assistance from "./assistance";
import Banner from "./banner";
import ServiceCenter from "./service-center";
import "./service-network.css";
import Form from "./form";
import Guarantee from "../warranty-benefits/guarantee";
import Customers from "../../components/customers/customers";
// import FormCompare from "./formCompare";
import HowItWorks from "./how-it-works";
import AdditionalFooter from "./additional-footer";
import CheckUp from "../warranty-benefits/check-up";
import PartnerLocation from "../../components/partnerLocation";
import PmpFooter from "../../components/Pmp_footer";
import CheckUpPnp from "../warranty-benefits/check-up-pnp";
import { SendTrackingCount } from "../../Api/Apis";
import styles from "./serviceNetwork.module.css";

function Pmp() {
  return (
    <div>
      <Helmet>
        <title>Wisedrive - Google Services add form</title>
        <meta name="description" content="Wisedrive - Google Services add form " />
        <link rel="canonical" href="" />
      </Helmet>
      <Header />
      <Banner />
      <div className={`${styles.form}`}>
        <Form />
      </div>
      <CheckUpPnp />
      {/* <FormCompare /> */}
      {/* <ServiceCenter /> */}
      <PartnerLocation />
      {/* <PmpPartnerLocation /> */}
      <HowItWorks />
      <Guarantee />
      {/* <Assistance /> */}
      <Faq />
      <Customers />
      <Download />
      {/* <ContactUs /> */}
      <AdditionalFooter />
      <PmpFooter />
    </div>
  );
}

export default Pmp;
