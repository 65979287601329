import React from "react";
import Avata from "../../assets/icons/avatar.png";

const PeaceOfmind = () => {
  return (
    <>
      <section className="inspection">
        <div className="container">
          <div className="inspectionContainer">
            <div className="inspectionSection inspectionSectionPhase3">
              {/* <h2> We do inspection which we take <span> responsibility </span></h2> */}
              <h2>
                Wisedrive Warranty Customer
                <br />
                <span>Buying for Used Car Dealers</span>
              </h2>
            </div>
            <div className="inspectionSection">
              <div className="inspectionProfileLandingPage">
                <img
                  src="https://ab-prod-container.s3.us-east-2.amazonaws.com/Customer/01838D4C-1A66-4D11-B703-87DED4AA61E3.png"
                  className="inspectionImg"
                  alt="userInspection1"
                />
                <div className="inspectionProfileCard">
                  <img src={Avata} alt="userInspectiontool" width="20px" height="22px" />
                  <p className="inspectionText">Maheen </p>
                </div>
              </div>
              <div className="inspectionProfileLandingPage">
                <img
                  src="https://ab-prod-container.s3.us-east-2.amazonaws.com/Customer/ACEB5F66-E5FA-4EE9-B120-9359E1F27C41.png"
                  className="inspectionImg"
                  alt="userInspection1"
                />
                <div className="inspectionProfileCard">
                  <img src={Avata} alt="userInspectiontool" width="20px" height="22px" />
                  <p className="inspectionText">Sunil Verma</p>
                </div>
              </div>

              <div className="inspectionProfileLandingPage">
                <img
                  src="https://ab-prod-container.s3.us-east-2.amazonaws.com/Customer/D787DA7B-C3A1-485D-9B51-6D2D19B82C07.png"
                  className="inspectionImg inspectionImgRightTop"
                  alt="userInspection2"
                />
                <div className="inspectionProfileCard">
                  <img src={Avata} alt="userInspectiontool" width="20px" height="22px" />
                  <p className="inspectionText">Sonu singh</p>
                </div>
              </div>
            </div>
            <div className="inspectionSection inspectionSectionPhase3Big">
              {/* <div className="inspectionProfileLandingPage">
                <img
                  src="https://ab-prod-container.s3.us-east-2.amazonaws.com/Customer/ACEB5F66-E5FA-4EE9-B120-9359E1F27C41.png"
                  className="inspectionImg inspectionImgLeftBottom"
                  alt="userInspection3"
                />
                <div className="inspectionProfileCard">
                  <img src={Avata} alt="userInspectiontool" width="20px" height="22px" />
                  <p className="inspectionText">Sonu singh</p>
                </div>
              </div>
              <div className="inspectionProfileLandingPage">
                <img
                  src="https://ab-prod-container.s3.us-east-2.amazonaws.com/Customer/7AE24520-80B6-48DB-9A17-B7181664BF49.png"
                  className="inspectionImg"
                  alt="userInspection2"
                />
                <div className="inspectionProfileCard">
                  <img src={Avata} alt="userInspectiontool" width="20px" height="22px" />
                  <p className="inspectionText">Sonu singh</p>
                </div>
              </div>
              <div className="inspectionProfileLandingPage">
                <img
                  src="https://ab-prod-container.s3.us-east-2.amazonaws.com/Customer/BA5CFF51-D8D7-472B-AFFB-C7529B9C81A6.png"
                  className="inspectionImg"
                  alt="userInspection4"
                />
                <div className="inspectionProfileCard">
                  <img src={Avata} alt="userInspectiontool" width="20px" height="22px" />
                  <p className="inspectionText">Sonu singh</p>
                </div>
              </div> */}
              {/* <div className="inspectionProfileLandingPage">
                <img
                  src="https://ab-prod-container.s3.us-east-2.amazonaws.com/Customer/D787DA7B-C3A1-485D-9B51-6D2D19B82C07.png"
                  className="inspectionImg inspectionImgRightBottom"
                  alt="userInspection1"
                />
                <div className="inspectionProfileCard">
                  <img src={Avata} alt="userInspectiontool" width="20px" height="22px" />
                  <p className="inspectionText">Sonu singh</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PeaceOfmind;
