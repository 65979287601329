import React from "react";
import Banner from "../../assets/images/blog/blogBanner.png";
import BannerImage from "../../assets/images/blog/carExtendwa.png";
import Banner1 from "../../assets/images/blog/blogBanner1.png";
import ArrowLeft from "../../assets/images/blog/arrowLeft.svg";
import twitter from "../../assets/images/blog/twitter.svg";
import link from "../../assets/images/blog/link.svg";
import fb from "../../assets/images/blog/fb.svg";

import { Link } from "react-router-dom";

function Description() {
  return (
    <section className="blogSection">
      <div className="container">
        <div className="blogContainer">
          <div className="blogTop">
            <Link to="/blog">
              <img src={ArrowLeft} alt="Car" />
              back
            </Link>
            <div className="head">
              {/* <h3>FEB 9, 2023</h3> */}
              {/* <label></label> */}
              {/* <span>Volta</span> */}
            </div>
            <h1>What to Look For in a Car Extended Warranty Contract?</h1>
            <div className="blogImg" style={{ minHeight: "40px" }}>
              <img src={BannerImage} alt="Banner" style={{ borderRadius: "12px" }} />
            </div>
            <p>Buying a car is a major investment and one that should be protected as much as possible. One way to do this is by purchasing an extended warranty for your vehicle. An extended warranty can provide peace of mind and financial protection in case your car needs repairs or maintenance in the future. However, not all extended warranties are made equal. It’s important to understand what to look for in a car extended warranty contract to ensure that you’re getting the best coverage for your needs.</p>
          </div>

          <div className="blogText">
            <h2>Here are some key factors to consider when evaluating car extended warranty contracts:</h2>
          </div>

          <div className="blogText">
            <h2>Coverage Level</h2>
            <p>The first thing to consider when looking at an extended warranty contract is the level of coverage it provides. Some contracts may only cover major mechanical components, while others may offer more comprehensive coverage that includes items like electronics and interior components. Be sure to read the contract carefully to understand exactly what is covered and what is not.</p>
          </div>

          <div className="blogText">
            <h2>Duration of Coverage</h2>
            <p>The duration of coverage is also an important factor to consider. Some extended warranties may only last for a few years or a certain number of miles, while others may offer coverage for the life of the vehicle. Consider how long you plan to keep your car and choose a warranty that provides coverage for that period of time.</p>
          </div>
          <div className="blogText">
            <h2>Deductible Amount</h2>
            <p>Like insurance policies, extended warranties often require the payment of a deductible when making a claim. Be sure to understand the deductible amount and whether it is per claim or per repair. A higher deductible may lower the cost of the warranty, but it could also mean a larger out-of-pocket expense if you need to make a claim.</p>
          </div>
          <div className="blogText">
            <h2>Repairs and Maintenance</h2>
            <p>Another important factor to consider is the process for repairs and maintenance. Some extended warranties may require you to take your vehicle to a specific repair shop or dealer for repairs, while others may allow you to choose your own mechanic. Be sure to understand the process for making claims and what all parts the warranty covers.</p>
          </div>
          <div className="blogText">
            <h2>Transferability</h2>
            <p>If you plan to sell your car before the extended warranty expires, it’s important to consider whether the warranty is transferable to the new owner. Some warranties may allow for transfer, while others may not. A transferable warranty can be a valuable selling point when it’s time to sell your car.</p>
          </div>

          <div className="blogText">
            <h2>Exclusions and Limitations</h2>
            <p>As with any contract, it’s important to read the fine print and understand any exclusions or limitations of coverage. Some extended warranties may not cover certain types of repairs, such as those caused by normal wear and tear, while others may have limits on the amount that can be paid out for a single claim. Be sure to understand these limitations before signing a contract.</p>
          </div>
          <div className="blogText">
            <h2>Customer Service and Reputation</h2>
            <p>
              Finally, it’s important to consider the reputation and customer service of the&nbsp;
              <a href="https://www.wisedrive.in/warranty-benefits" target="_blank" rel="noreferrer">
                extended warranty
              </a>
              &nbsp; provider. Look for a provider with a solid reputation for paying claims and providing good customer service. Read reviews and ask for recommendations from friends and family members who have purchased extended warranties for their vehicles.
            </p>
            <p>
              In conclusion, purchasing an &nbsp;
              <a href="https://www.wisedrive.in/warranty-benefits" target="_blank" rel="noreferrer">
                extended warranty
              </a>
              &nbsp; for your car can be a wise investment, but it’s important to carefully consider the contract and provider before making a decision. By understanding the coverage level, duration, deductible amount, repairs and maintenance process, transferability, exclusions and limitations, and customer service reputation, you can choose the best extended warranty for your needs and protect your investment for years to come.
            </p>
          </div>
          <div className="blogText">
            <h2>How Can a Wisedrive Extended Warranty Help My Car?</h2>
            <p>
              Wisedrive is India’s most trusted vehicle Warranty provider. Our choice of award-winning plans and coverage options has something for all kinds of used vehicles, with packages to suit car owners, rideshare drivers, and&nbsp;
              <a href="https://www.wisedrive.in/service-network" target="_blank" rel="noreferrer">
                even high-mileage cars.
              </a>
              &nbsp; We also offer unrivaled flexibility—just give our friendly customer service team a call, and we’ll tailor a bespoke protection program to suit your needs and budget.
            </p>
            <p>
              All&nbsp;
              <a href="/owner-pricing-plans" target="_blank" rel="noreferrer">
                Wisedrive plans
              </a>
              &nbsp; include 24/roadside assistance, towing, trip-interruption costs, replacement travel reimbursement, and rental car coverage as standard. Better still, every new Wisedrive member gets a year’s free access to our Benefits, opening up additional perks such as tire replacement, total loss protection, and more. &nbsp;
              <a href="/owner-pricing-plans" target="_blank" rel="noreferrer">
                Wisedrive plans
              </a>
              &nbsp; are also fully transferable, increasing the value of your vehicle should you decide to sell it.
            </p>
          </div>
          <div className="blogText">
            <h2>Get the Best Car Protection with Wisedrive</h2>
            <p>
              Want to know more about how Wisedrive&nbsp;
              <a href="/owner-pricing-plans" target="_blank" rel="noreferrer">
                coverage plans
              </a>
              &nbsp; can help you reduce the cost of car ownership? Call our award-winning customer service team at
              <a href="tel:+91-73533-43536"> +91-73533-43536</a> for a free, no-obligation quote, or &nbsp;
              <a
                href="/owner-pricing-plans"
                target="_blank
              "
                rel="noreferrer"
              >
                shop online today.
              </a>
              &nbsp; We offer a buy-back guarantee and flexible monthly payments.
            </p>
          </div>
        </div>
        <div className="share">
          <h1>Share via</h1>
          <ul>
            <li>
              {/* <Link to=""> */}
              <a href="https://twitter.com/wisedrivehq" target="_blank">
                <img src={twitter} alt="Twitter" />
              </a>
              {/* </Link> */}
            </li>
            <li>
              {/* <Link to=""> */}
              <a href="https://in.linkedin.com/company/wisedrivewarranty" target="_blank">
                <img src={link} alt="Link" />
              </a>
              {/* </Link> */}
            </li>
            <li>
              {/* <Link to=""> */}
              <a href="https://www.facebook.com/Wisedrivewarranty" target="_blank">
                <img src={fb} alt="Facebook" />
              </a>
              {/* </Link> */}
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Description;
