import React from "react";
import HeroImage from '../../assets/images/warranty-benefits/warrantyHeroImage.png'

function Banner() {
  return (
    <section className="warrantyBanner">
      <div className="bannerContainer">
        <div className="container">
          <label>Get upto Rs 500,000</label>
          <h1>
            Coverage for your <span>engine & Transmission</span> repairs
          </h1>
          <p>We have covered almost 90% of the parts in your engine</p>
        </div>
        <img src={HeroImage} alt="Banner" />
      </div>
    </section>
  );
}

export default Banner;
