import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Close } from "@mui/icons-material";
import "./modal.css";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   width: "40%",
//   // height: "90%",
//   transform: "translate(-50%, -50%)",
//   bgcolor: "ghostWhite",
//   border: "2px solid #fff !important",
//   boxShadow: 24,
//   borderRadius: 4,
//   p: 2,
// };

const BuyNowModal = ({ open, handleClose, loaderOpen }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      // handleUpgradePlan={handleUpgradePlan}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-box-css">
        {loaderOpen ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CheckCircleIcon
                style={{ color: "#25632D", width: "50px", height: "50px" }}
              />
            </div>
            <div>
              <p
                className="modal-text-mobile"
                style={{ fontFamily: "gilroyMedium" }}
              >
                Thank you for your interest to purchase inspection package from
                Wisedrive. We have received your request. Our support team will
                reach out to you and assist you further. For any queries, please
                call us at <strong>7353 34 35 36 </strong>from Mon-Fri between
                9am-7pm.
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#003CC5", fontFamily: "gilroyMedium" }}
                onClick={handleClose}
              >
                Thank you
              </Button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};
export default BuyNowModal;
