import React from "react";
import "./payment-success.css";
import previous from "../../assets/images/payment-success/previousArrow.svg";
import checkTick from "../../assets/images/payment-success/checkCircle.svg";
import creditScore from "../../assets/images/payment/creditScore.svg";
import purchaseScan from "../../assets/images/payment/purchaseScan.svg";
import rightArrow from "../../assets/images/payment/rightArrow.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

function PaymentCompleted() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const transactionStatus = queryParams.get("TransactionStatus");
  const transactionID = queryParams.get("TransactionID");
  const bankReferenceNumber = queryParams.get("BankReferenceNumber");
  // const transactionMessage = queryParams.get("TransactionMessage");
  // console.log(
  //   "data",
  //   data,
  //   transactionStatus,
  //   transactionID,
  //   bankReferenceNumber,
  //   transactionMessage
  // );
  var storedJsonString = sessionStorage.getItem("data");
  var storedArray = JSON.parse(storedJsonString);

  const name = sessionStorage.getItem("name");
  const refId = sessionStorage.getItem("refeId");
  const Activated = sessionStorage.getItem("activated");
  const Expires = sessionStorage.getItem("expiary");

  // console.log(Activated);

  function modifyActivatedDate(date) {
    if (date) {
      let yearModified = "";
      const [day, month, year] = date.split(" ");
      if (year.length == 2) {
        yearModified = "20" + year;
      } else {
        yearModified = year;
      }

      return [day, month, yearModified].join(" ");
    }
  }

  const navigate = useNavigate();

  const handleLinkClick = (event) => {
    event.preventDefault();
    sessionStorage.clear();
    navigate(`/`);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "Invalid date";
    }

    const date = new Date(dateString);
    if (isNaN(date)) {
      return "Invalid date";
    }

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    let daySuffix;

    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    const formattedDate = `${day}${daySuffix} ${month} ${year}`;

    return formattedDate;
  };

  return (
    <section className="paymentSuccess">
      <div className="container">
        <div className="purchaseSuccessArrowMobile mobile">
          <img
            onClick={handleLinkClick}
            className="previousArrow"
            src={previous}
            alt="previous"
          />
          <div>
            <h2>Your purchase was Successful</h2>
            <p>
              Thank you for purchasing WiseAssist plan. We are so exited to
              welcome you to the Wisedrive family.
            </p>
          </div>
        </div>
        <div className="purchaseSuccess">
          <img
            className="previousArrow"
            src={previous}
            alt="previous"
            onClick={handleLinkClick}
          />
          <div className="purchaseIncludes">
            <div className="desktop">
              <h2>Your purchase was Successful</h2>
              <p>
                Thank you for purchasing WiseAssist plan. We are so exited to
                welcome you to the Wisedrive family
              </p>
            </div>
            <h4>What’s Included</h4>
            <ul>
              {storedArray &&
                storedArray.map((each) => {
                  return (
                    <li key={each.description_id}>
                      <img src={checkTick} alt="tick" />
                      {each.description}
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="purchaseDone">
            <div className="purchaseWelcome">
              <div className="creditScoreImage">
                <img src={creditScore} alt="image" />
              </div>
              <h3>Welcome to Wisedrive Family</h3>
            </div>
            <div className="purchaseDoneContent">
              {/* <div className="purchaseText">
                <div className="purchaseInfo">
                  <p>Purchased by</p>
                  <h4>{name}</h4>
                </div>
                <div className="purchaseInfo">
                  <p>Transaction ID</p>
                  <h4>{refId}</h4>
                </div>
                <div className="purchaseInfo">
                  <p>Activation Date</p>
                  <h4>{modifyActivatedDate(Activated)}</h4>
                </div>
                <div className="purchaseInfo">
                  <p>Expire Date</p>
                  <h4>{formatDate(Expires)}</h4>
                </div>
              </div> */}
              <div className="purchaseText">
                <div className="purchaseInfo">
                  <p>Transaction Status</p>
                  <h4>{transactionStatus}</h4>
                </div>
                <div className="purchaseInfo">
                  <p>Transaction ID</p>
                  <h4>{transactionID}</h4>
                </div>
                <div className="purchaseInfo">
                  <p>Refference ID</p>
                  <h4>{bankReferenceNumber}</h4>
                </div>
              </div>
              <div
                className="purchaseScan"
                style={{ display: "flex", justifyContent: "center" }}
                onClick={handleLinkClick}
              >
                {/* <img className="desktop" src={purchaseScan} alt="image" /> */}
                <div className="purchaseDownloadApp">
                  {/* <h4>Scan to Download</h4> */}
                  <Link to="">
                    <span>Back to home</span>{" "}
                    <img src={rightArrow} alt="arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PaymentCompleted;
