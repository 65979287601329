import React from 'react'
import ContactUs from '../../components/contact-us/contact-us'
import Download from '../../components/download/download'
import Faq from '../../components/faq/faq'
import Footer from '../../components/footer'
import Header from '../../components/header'
import PartnerLocation from '../../components/partnerLocation'
import Assistance from './assistance'
import Banner from './banner'
import ServiceCenter from './service-center'
import './service-network.css';

function ServiceNetwork() {
  return (
    <div>
        <Header />
        <Banner />
        <PartnerLocation serviceNetworkPage="true"/>
        <Faq />
        <Download />
        <ContactUs />
        <Footer />
    </div>
  )
}

export default ServiceNetwork
