import React from "react";
import PricingPlanHead from "./pricingPlanHead";
import PlanTables from "./planTables";

function PricingTablesFacebookLandingPage({ secondComponentRef, pageData }) {
  return (
    <>
      <PricingPlanHead />
      <div ref={secondComponentRef}>
        <PlanTables pageData={pageData} />
      </div>
    </>
  );
}

export default PricingTablesFacebookLandingPage;
