import React, { useState } from "react";
import Toyoto from "../../assets/images/service-network/toyota.svg";
import Honda from "../../assets/images/service-network/honda.svg";
import Hyundai from "../../assets/images/service-network/hyundai.svg";
import Ford from "../../assets/images/service-network/ford.svg";
import Suzuki from "../../assets/images/service-network/suzuki.svg";
import Tata from "../../assets/images/service-network/tata.svg";
import Kia from "../../assets/images/service-network/kia.svg";
import Skoda from "../../assets/images/service-network/skoda.svg";
import Renault from "../../assets/images/service-network/renault.svg";
import Mahindra from "../../assets/images/service-network/mahindra.svg";
import Cheverlot from "../../assets/images/service-network/cheverlot.svg";
import Volkswagen from "../../assets/images/service-network/volkswagen.svg";
import Arrow from "../../assets/images/service-network/downArrow.svg";
import { Link } from "react-router-dom";

function ServiceCenter() {
  const [toggle, setToggle] = useState(false);
  const [option, setOption] = useState("Bangalore");

  const changeOption = (e) => {
    setOption(e.target.value);
  };

  return (
    <section className="serviceCenter">
      <div className="container">
        <div className="commonHeading">
          <h2>
            We partner only with <span>manufacture authorised service</span> centers
          </h2>
          <form className="serviceForm">
            <select className="commonSelect" onChange={changeOption}>
              <option value="Bangalore">Bangalore</option>
              <option value="Tumkuru">Tumkuru</option>
              <option value="Mysore">Mysore</option>
              <option value="Mangalore">Mangalore</option>
              <option value="Chitradurga">Chitradurga</option>
              <option value="Kolar">Kolar</option>
              <option value="Chikkaballapur">Chikkaballapur</option>
              <option value="Ramanagara">Ramanagara</option>
              <option value="Davanagere">Davanagere</option>
              <option value="Dharwad">Dharwad</option>
            </select>
          </form>
        </div>

        {/* Bangalore Service Centers */}
        <div className={option === "Bangalore" ? "serviceCenterContainer" : "hide"}>
          <div className="serviceCenterCards">
            <div className="serrviceCenterCardDetails">
              <img src={Ford} alt="Ford" />
              <p>Cauvery Ford</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Mahindra} alt="Mahindra" />
              <p>Anant Cars</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Hyundai} alt="Hyundai" />
              <p>Lakshmi Hyundai</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Volkswagen} alt="Volkswagen" />
              <p>Apple Auto</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Skoda} alt="Skoda" />
              <p>Raja Skoda</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Toyoto} alt="Toyota" />
              <p>Nandhi Toyota</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="Suzuki" />
              <p>Mandovi Motors</p>
            </div>

            <div className="serrviceCenterCardDetails">
              <img src={Kia} alt="Kia" />
              <p>Jsp Kia</p>
            </div>

            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Honda} alt="Honda" />
              <p>Brigade Honda</p>
            </div>
            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Tata} alt="Tata" />
              <p>Prerana Motors </p>
            </div>
            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Honda} alt="Honda" />
              <p>Magnum Honda </p>
            </div>
          </div>
          <Link
            to=""
            className="viewMore"
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            {toggle ? (
              <span>
                View 3 less <img style={{ transform: "rotate(180deg)" }} src={Arrow} alt="Arrow" />{" "}
              </span>
            ) : (
              <span>
                View 3 more <img src={Arrow} alt="Arrow" />
              </span>
            )}
          </Link>
        </div>

        {/* Thumkur Service Centers */}
        <div className={option === "Tumkuru" ? "serviceCenterContainer" : "hide"}>
          <div className="serviceCenterCards">
            <div className="serrviceCenterCardDetails">
              <img src={Tata} alt="Tata" />
              <p>Sree Auto</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Honda} alt="Honda" />
              <p>Shantinath Honda</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Honda} alt="Honda" />
              <p>Magnum Honda</p>
            </div>

            <div className="serrviceCenterCardDetails">
              <img src={Hyundai} alt="Hyundai" />
              <p>Panvan Hyundai</p>
            </div>

            <div className="serrviceCenterCardDetails">
              <img src={Renault} alt="renault" />
              <p>Renault</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Kia} alt="Kia" />
              <p>Pps Kia</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="Suzuki" />
              <p>Saketh Automobiles</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Ford} alt="Ford" />
              <p>Honnasiri Ford</p>
            </div>
          </div>
        </div>

        {/* Mysore Service Centers */}
        <div className={option === "Mysore" ? "serviceCenterContainer" : "hide"}>
          <div className="serviceCenterCards">
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="Suzuki" />
              <p>Friendly Motors</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Honda} alt="Honda" />
              <p>Inspire Honda</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Honda} alt="Honda" />
              <p>Palace Honda</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Renault} alt="Renault" />
              <p>Renault</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Renault} alt="Renault" />
              <p>Renault Hebbal</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Toyoto} alt="Toyota" />
              <p>Palace Toyota</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Kia} alt="Kia" />
              <p>Volanzo Kia</p>
            </div>

            <div className="serrviceCenterCardDetails">
              <img src={Tata} alt="Chevrolet" />
              <p>Dyuthi Chevrolet</p>
            </div>

            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Mahindra} alt="Mahindra" />
              <p>Indian Garage</p>
            </div>
            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Ford} alt="Ford" />
              <p>Cauvery Ford</p>
            </div>
            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Tata} alt="Tata" />
              <p>Tata Motors</p>
            </div>
            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Hyundai} alt="Hyundai" />
              <p>Advaith Hyundai</p>
            </div>
            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Skoda} alt="Skoda" />
              <p>Palace Skoda</p>
            </div>
            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Suzuki} alt="Suzuki" />
              <p>Mandovi Chamrajnagar</p>
            </div>
          </div>
          <Link
            to=""
            className="viewMore"
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            {toggle ? (
              <span>
                View 6 less <img style={{ transform: "rotate(180deg)" }} src={Arrow} alt="Arrow" />{" "}
              </span>
            ) : (
              <span>
                View 6 more <img src={Arrow} alt="Arrow" />
              </span>
            )}
          </Link>
        </div>

        {/* Mangalore Service Centers */}
        <div className={option === "Mangalore" ? "serviceCenterContainer" : "hide"}>
          <div className="serviceCenterCards">
            <div className="serrviceCenterCardDetails">
              <img src={Kia} alt="kia" />
              <p>Arm Kia Motors</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Volkswagen} alt="volkswagen" />
              <p>Volkswagen</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Toyoto} alt="Toyota" />
              <p>United Toyota</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Hyundai} alt="Hyundai" />
              <p>Advaith Hyundai</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Hyundai} alt="Hyundai" />
              <p>Kanchana Hyundai</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="Suzuki" />
              <p>Maruti Suzuki</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="Suzuki" />
              <p>Bharath Auto Cars, Kudroli</p>
            </div>

            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="Suzuki" />
              <p>Bharath Auto Cars, Thokkot</p>
            </div>

            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Renault} alt="Renault" />
              <p>Renault</p>
            </div>
            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Cheverlot} alt="Cheverlot" />
              <p>Vibrant Chevrolet</p>
            </div>
            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Ford} alt="Ford" />
              <p>Cauvery Ford</p>
            </div>
            <div className={toggle ? "serrviceCenterCardDetails" : "serrviceCenterCardDetails viewMoreCard"}>
              <img src={Honda} alt="honda" />
              <p>Arrta Honda</p>
            </div>
          </div>
          <Link
            to=""
            className="viewMore"
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            {toggle ? (
              <span>
                View 4 less <img style={{ transform: "rotate(180deg)" }} src={Arrow} alt="Arrow" />{" "}
              </span>
            ) : (
              <span>
                View 4 more <img src={Arrow} alt="Arrow" />
              </span>
            )}
          </Link>
        </div>

        {/* Chitradurga Service Centers */}
        <div className={option === "Chitradurga" ? "serviceCenterContainer" : "hide"}>
          <div className="serviceCenterCards">
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="suzuki" />
              <p>Saketh Automobiles</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Hyundai} alt="Hyundai" />
              <p>KJ Hyundai</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Tata} alt="tata" />
              <p>Sree Auto - Tata Motora</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Renault} alt="Renault" />
              <p>Renault</p>
            </div>
          </div>
        </div>

        {/* Kolar Service Centers */}
        <div className={option === "Kolar" ? "serviceCenterContainer" : "hide"}>
          <div className="serviceCenterCards">
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="suzuki" />
              <p>Mandovi Motors</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Tata} alt="Tata" />
              <p>Tata Service</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Hyundai} alt="hyundai" />
              <p>Advaith Hyundai</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Honda} alt="Honda" />
              <p>Kausthub Honda</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Kia} alt="Kia" />
              <p>Epitome Automobiles</p>
            </div>
          </div>
        </div>

        {/* Chikkaballapur Service Centers */}
        <div className={option === "Chikkaballapur" ? "serviceCenterContainer" : "hide"}>
          <div className="serviceCenterCards">
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="suzuki" />
              <p>Sukove</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Tata} alt="Tata" />
              <p>Tata Motors ltd</p>
            </div>
          </div>
        </div>

        {/* Ramanagara Service Centers */}
        <div className={option === "Ramanagara" ? "serviceCenterContainer" : "hide"}>
          <div className="serviceCenterCards">
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="suzuki" />
              <p>Kalyani Motors</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Hyundai} alt="Hyundai" />
              <p>Advaith Hyundai</p>
            </div>
          </div>
        </div>

        {/* Davanagere Service Centers */}
        <div className={option === "Davanagere" ? "serviceCenterContainer" : "hide"}>
          <div className="serviceCenterCards">
            <div className="serrviceCenterCardDetails">
              <img src={Ford} alt="Ford" />
              <p>Metro Ford</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Tata} alt="Tata" />
              <p>Adishakti Cars</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Honda} alt="honda" />
              <p>Global Honda</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Hyundai} alt="Hyundai" />
              <p>KJ Hyundai</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="Suzuki" />
              <p>Shruti Motors</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Renault} alt="Renault" />
              <p>Renault</p>
            </div>
          </div>
        </div>

        {/* Dharwad Service Centers */}
        <div className={option === "Dharwad" ? "serviceCenterContainer" : "hide"}>
          <div className="serviceCenterCards">
            <div className="serrviceCenterCardDetails">
              <img src={Honda} alt="Honda" />
              <p>Lakeview Honda</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Hyundai} alt="Hyundai" />
              <p>Bellad Hyundai</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Toyoto} alt="Toyoto" />
              <p>Shodha Toyota</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Ford} alt="Ford" />
              <p>Metro Ford</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Suzuki} alt="Suzuki" />
              <p>Mysore Garage</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Kia} alt="Kia" />
              <p>Nagshanti Kia</p>
            </div>
            <div className="serrviceCenterCardDetails">
              <img src={Renault} alt="renault" />
              <p>Renault Services Hubli</p>
            </div>
          </div>
        </div>

        {/* <Link to="" className="viewMore" onClick={() => {
            setToggle(!toggle)
        }}>
          {
            toggle ? <span>View less <img style={{transform:"rotate(180deg)"}} src={Arrow} alt="Arrow" /> </span>:
            <span>View more <img src={Arrow} alt="Arrow" /></span>
          }
        </Link> */}
      </div>
    </section>
  );
}

export default ServiceCenter;
