import React from "react";
import Banner from "../../assets/images/blog/blogBanner.png";
import BannerImage from "../../assets/images/blog/warrantyCars.png";
import Banner1 from "../../assets/images/blog/blogBanner1.png";
import ArrowLeft from "../../assets/images/blog/arrowLeft.svg";
import twitter from "../../assets/images/blog/twitter.svg";
import link from "../../assets/images/blog/link.svg";
import fb from "../../assets/images/blog/fb.svg";

import { Link } from "react-router-dom";

function Description() {
  return (
    <section className="blogSection">
      <div className="container">
        <div className="blogContainer">
          <div className="blogTop">
            <Link to="/blog">
              <img src={ArrowLeft} alt="Car" />
              back
            </Link>
            <div className="head">
              {/* <h3>FEB 9, 2023</h3> */}
              {/* <label></label> */}
              {/* <span>Volta</span> */}
            </div>
            <h1>What is an extended warranty on a car?</h1>
            <div className="blogImg" style={{ minHeight: "40px" }}>
              <img src={BannerImage} alt="Banner" style={{ borderRadius: "12px" }} />
            </div>
            <p>An extended warranty on a car is an optional agreement that provides additional coverage beyond the standard factory warranty. These warranties are designed to offer peace of mind to car owners, as they can protect against unexpected repairs and costly breakdowns.</p>
            <p>In this blog post, we will discuss what extended warranties are, how they work, and the pros and cons of purchasing one. We will also examine the factors that should be considered when deciding whether to buy an extended warranty for your car.</p>
          </div>

          <div className="blogText">
            <h2>What is an Extended Warranty?</h2>
            <p>An extended warranty, also known as a vehicle service contract, is a type of insurance that provides additional coverage for your car beyond the manufacturer's warranty. Typically, an extended warranty will cover the cost of repairs or replacements for specific components of your vehicle for a set period of time or mileage limit.</p>
            <p>The length and coverage of an extended warranty can vary depending on the provider and the specific terms of the contract. Some warranties may cover only major components like the engine and transmission, while others may provide comprehensive coverage for all parts of the car. Extended warranties can also have different deductibles and exclusions, so it's important to read the contract carefully before purchasing.</p>
          </div>

          <div className="blogText">
            <h2>How Does an Extended Warranty Work?</h2>
            <p>When you purchase an extended warranty for your car, you pay a one-time fee upfront or in installments over time. In exchange, the warranty provider agrees to cover the cost of certain repairs or replacements during the term of the contract.</p>
            <p>If your car needs a repair that is covered by the extended warranty, you will typically need to take it to a licensed repair facility. The repair facility will diagnose the problem and submit a claim to the warranty provider for payment. Depending on the terms of the contract, you may need to pay a deductible or a portion of the repair cost out of pocket.</p>
          </div>

          <div className="blogText">
            <h2>Pros of Purchasing an Extended Warranty</h2>
            <p>There are several advantages to purchasing an extended warranty for your car. Here are some of the most significant benefits:</p>
            <p>
              <b>Peace of Mind:</b> One of the primary advantages of an extended warranty is the peace of mind it provides. When you have an extended warranty, you know that you're covered if your car experiences a major breakdown or requires expensive repairs.
            </p>
            <p>
              <b>Cost Savings:</b> Car repairs can be expensive, and an extended warranty can help you save money in the long run. Instead of paying out of pocket for unexpected repairs, you can rely on your warranty to cover the cost.
            </p>
            <p>
              <b> Transferable:</b> In some cases, an extended warranty can be transferred to a new owner if you decide to sell your car before the warranty expires. This can increase the resale value of your car and make it a more attractive purchase for potential buyers.
            </p>
          </div>

          <div className="blogText">
            <h2>Cons of Purchasing an Extended Warranty</h2>
            <p>While there are several advantages to purchasing an extended warranty, there are also some potential downsides to consider. Here is the most significant disadvantage:</p>
            <p>
              <b> Manufacturer Warranty Overlap:</b> If you purchase an extended warranty, you may end up paying for coverage that overlaps with the manufacturer's warranty. This can result in paying for double coverage.
            </p>
          </div>
          <div className="blogText">
            <h2>Factors to Consider When Deciding Whether to Buy an Extended Warranty</h2>
            <p>If you're considering purchasing an extended warranty for your car, there are several factors to consider before making a decision. Here are a few of the most important factors:</p>
            <p>
              <b>Age and Mileage of Your Car:</b> If your car is relatively new and has a manufacturer's warranty that provides coverage for several years, it may not be necessary to purchase an extended warranty. On the other hand, if your car is older or has a high mileage, an extended warranty may provide valuable coverage for unexpected repairs.
            </p>
            <p>
              <b> Cost of Repairs: </b>Consider the average cost of repairs for your make and model of car. If repairs tend to be expensive, an extended warranty may be a good investment.{" "}
            </p>
            <p>
              <b> Driving Habits:</b> If you drive your car frequently or for long distances, it may be more likely to experience wear and tear that can lead to breakdowns or repairs. In this case, an extended warranty may be a wise investment to protect against unexpected expenses.
            </p>
            <p>
              <b>Warranty Provider Reputation:</b> When considering an extended warranty, it's important to research the reputation of the warranty provider. Look for reviews from other car owners to ensure that the provider has a track record of excellent service and coverage.
            </p>
            <p>
              <b>Terms and Conditions:</b> Finally, it's essential to read the contract carefully to understand the terms and conditions of the&nbsp;
              <a href="https://www.wisedrive.in/warranty-benefits" target="_blank" rel="noreferrer">
                extended warranty.
              </a>
              &nbsp; Pay attention to the coverage limits, exclusions, and deductibles to ensure that the warranty provides the coverage you need at a price that is reasonable.
            </p>
          </div>
          <div className="blogText">
            <h2>Conclusion</h2>
            <p>
              An extended warranty can provide valuable coverage and peace of mind for car owners, but it's important to carefully consider the costs and&nbsp;
              <a href="https://www.wisedrive.in/warranty-benefits" target="_blank" rel="noreferrer">
                benefits
              </a>
              &nbsp; before making a purchase. By understanding the coverage options,&nbsp;
              <a href="/owner-pricing-plans" target="_blank" rel="noreferrer">
                costs,
              </a>
              &nbsp; and terms and conditions of an extended warranty, you can make an informed decision about whether this type of warranty is right for you and your car.
            </p>
          </div>
        </div>
        <div className="share">
          <h1>Share via</h1>
          <ul>
            <li>
              {/* <Link to=""> */}
              <a href="https://twitter.com/wisedrivehq" target="_blank" rel="noreferrer">
                <img src={twitter} alt="Twitter" />
              </a>
              {/* </Link> */}
            </li>
            <li>
              {/* <Link to=""> */}
              <a href="https://in.linkedin.com/company/wisedrivewarranty" target="_blank" rel="noreferrer">
                <img src={link} alt="Link" />
              </a>
              {/* </Link> */}
            </li>
            <li>
              {/* <Link to=""> */}
              <a href="https://www.facebook.com/Wisedrivewarranty" target="_blank" rel="noreferrer">
                <img src={fb} alt="Facebook" />
              </a>
              {/* </Link> */}
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Description;
