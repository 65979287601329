import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import CircleTick from "../../assets/images/reportDetails/circleTick.svg";
import CarImages from "../report/car-images";
import {
  FinanceDetailsApi,
  FinedetilsApi,
  QuestionApi,
  ReportDataApi,
  RtoDetailsApi,
  getObdReportApi,
} from "../../Api/Apis";
import { useState } from "react";
import { useEffect } from "react";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";
import { Logger } from "logging-library";

import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";

const ReportDetails = (props) => {
  const [question, setQuestion] = useState([]);
  const [image, setImage] = useState([]);
  const [video, setVideo] = useState([]);
  const [obdPdf, setObdPdf] = useState(null);
  const [fineList, setFineList] = useState([]);
  const [insuranceFinanceDetails, setInsuranceFinanceDetails] = useState([]);
  const [rtoDetails, setRtoDetails] = useState([]);
  const [rtoLoader, setRtoLoader] = useState(false);

  const datas = useParams();

  const getQuestion = async () => {
    try {
      const resp = await QuestionApi(
        datas.vehicleId === "nodata" ? "" : datas.vehicleId,
        datas.leadvehicleId === "nodata" ? "" : datas.leadvehicleId,
        datas.insprequvehiId === "nodata" ? "" : datas.insprequvehiId,
        datas.moduleId === "nodata" ? "" : datas.moduleId
      );
      if (resp.status === 200) {
        setQuestion(resp.data.response.QuesAnsDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getQuestion();
  }, []);

  const getReportData = async () => {
    try {
      const resp = await ReportDataApi(
        datas.vehicleId === "nodata" ? "" : datas.vehicleId,
        datas.leadvehicleId === "nodata" ? "" : datas.leadvehicleId,
        datas.insprequvehiId === "nodata" ? "" : datas.insprequvehiId,
        datas.moduleId === "nodata" ? "" : datas.moduleId
      );
      if (resp.status === 200) {
        setImage(resp.data.response.ImageDetails);
        setVideo(resp.data.response.VideoDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getReportData();
  }, []);

  const getObdReport = async () => {
    try {
      const headers = {
        origin: "https://www.wisedrive.in",
      };
      const resp = await getObdReportApi(
        datas.vehicleId === "nodata" ? "" : datas.vehicleId,
        datas.leadvehicleId === "nodata" ? "" : datas.leadvehicleId,
        datas.insprequvehiId === "nodata" ? "" : datas.insprequvehiId,
        datas.moduleId === "nodata" ? "" : datas.moduleId,
        headers
      );
      if (resp.status === 200) {
        const originalUrl = resp.data.response.OBDDetails.obd_report_pdf || "";
        setObdPdf(originalUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getObdReport();
  }, []);

  const onError = (e) => {
    Logger.logError(e, "error in file-viewer");
  };
  const type = "pdf";

  const financeDetails = async () => {
    try {
      const resp = await FinanceDetailsApi(
        datas.vehicleId === "nodata" ? "" : datas.vehicleId,
        datas.leadvehicleId === "nodata" ? "" : datas.leadvehicleId,
        datas.insprequvehiId === "nodata" ? "" : datas.insprequvehiId
      );
      if (resp.status === 200) {
        setInsuranceFinanceDetails(resp.data.response.InsuranceFinanceDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FineDetails = async () => {
    try {
      const resp = await FinedetilsApi(
        datas.vehicleId === "nodata" ? "" : datas.vehicleId,
        datas.leadvehicleId === "nodata" ? "" : datas.leadvehicleId,
        datas.insprequvehiId === "nodata" ? "" : datas.insprequvehiId
      );
      if (resp.status === 200) {
        setFineList(resp.data.response.FineDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    FineDetails();
    financeDetails();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) {
      return "--";
    }

    const date = new Date(dateString);
    if (isNaN(date)) {
      return "Invalid date";
    }

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    let daySuffix;

    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    const formattedDate = `${day}${daySuffix} ${month} ${year}`;

    return formattedDate;
  };

  const getRtoDetails = async () => {
    try {
      setRtoLoader(true);
      const resp = await RtoDetailsApi(
        datas.vehicleId === "nodata" ? "" : datas.vehicleId,
        datas.leadvehicleId === "nodata" ? "" : datas.leadvehicleId,
        datas.insprequvehiId === "nodata" ? "" : datas.insprequvehiId
      );
      if (resp.status === 200) {
        setRtoDetails(resp.data.response.RTOdetails);
        setRtoLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRtoDetails();
  }, []);

  return (
    <section className="history" style={{ paddingTop: "90px" }}>
      {datas.moduleId === "1" && (
        <div className="container">
          <div className="historyDetails">
            <CarImages reportData={"reportData"} image={image} video={video} />
          </div>

          <div className="historyDetails">
            {question &&
              question.map((each, index) => {
                return (
                  <div key={index} className="historyDetailsWrapper">
                    <p>{each.inspection_question}</p>
                    <div className="historyDetailsLabel">
                      <img src={CircleTick} alt="tick" />
                      <span>{each.inspection_answer}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {datas.moduleId === "2" && (
        <div className="container">
          <div className="historyDetails">
            <CarImages reportData={"reportData"} image={image} video={video} />
          </div>

          <div className="historyDetails">
            {question &&
              question.map((each, index) => {
                return (
                  <div key={index} className="historyDetailsWrapper">
                    <p>{each.inspection_question}</p>
                    <div className="historyDetailsLabel">
                      <img src={CircleTick} alt="tick" />
                      <span>{each.inspection_answer}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      {datas.moduleId === "3" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <h3 style={{ marginLeft: "55px" }}> OBD Report</h3>
            <br />
          </div>
        </div>
      )}

      {datas.moduleId === "3" && (
        <div>
          {obdPdf && (
            <div onContextMenu={(e) => e.preventDefault()}>
              <FileViewer
                fileType={type}
                filePath={obdPdf}
                errorComponent={CustomErrorComponent}
                onError={onError}
              />
            </div>
          )}
        </div>
      )}
      {/* {datas.moduleId === "3" && (
        <div style={{ width: "100%" }}>
          {obdPdf && (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
            >
              <Viewer fileUrl={obdPdf} />
            </Worker>
          )}
        </div>
      )} */}

      {datas.moduleId === "4" && (
        <div className="container">
          <div className="newCommonHeading">
            <h2
              className="mobileAndDesktopView"
              style={{ color: "#262c6c", textAlign: "center" }}
            >
              Blacklist status and Traffic fines details
            </h2>
            {/* <span style={{ color: "#262c6c" }}>Status:No</span> */}
            <div className="historyDetails">
              <div className="historyDetailsWrapper">
                <p>Blacklisted </p>
                <div className="historyDetailsLabel">
                  {/* <img src={CircleTick} alt="tick" /> */}
                  <span>
                    {datas.blackList === "nodata" ||
                    datas.blackList === "undefined"
                      ? "No"
                      : datas.blackList}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="historyDetails">
            <h2
              className="mobileAndDesktopView"
              style={{ color: "#262c6c", textAlign: "center" }}
            >
              Traffic fines
            </h2>
            <br />
            {fineList.length === 0 ? (
              <h4
                style={{
                  color: "rgba(38, 44, 110, 0.8)",
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "400",
                }}
              >
                No Traffic fines
              </h4>
            ) : (
              <>
                {fineList &&
                  fineList.map((each) => {
                    return (
                      <div
                        key={each.challan_type_id}
                        className="historyDetailsWrapper"
                      >
                        <span>
                          {formatDate(each.date_of_challan)} -{" "}
                          {each.challan_location} - {each.challan_type_name}
                        </span>
                        <div
                          className="historyDetailsLabel"
                          style={{ marginTop: "11px" }}
                        >
                          <span>₹ {each.challan_amount}</span>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </div>
      )}

      {datas.moduleId === "5" && (
        <>
          <div
            style={{
              border: "1px solid lightgray",
              margin: "9px",
              borderRadius: "11px",
              marginTop: "29px",
            }}
          >
            <div className="container" style={{ paddingTop: "11px" }}>
              <div
                className="newCommonHeading"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: "7px",
                }}
              >
                <h2
                  className="mobileAndDesktopView"
                  style={{ color: "#262c6c" }}
                >
                  FINANCER DETAILS
                </h2>
              </div>

              <div
                className="historyDetailsWrapper"
                style={{ borderBottom: "none" }}
              >
                <p>FINANCER NAME</p>
                <div className="historyDetailsLabel">
                  <img src={CircleTick} alt="tick" />
                  <span>
                    {insuranceFinanceDetails.financer_name ||
                      "No Hypothecation"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              margin: "9px",
              borderRadius: "11px",
              marginTop: "29px",
            }}
          >
            <div className="container" style={{ paddingTop: "11px" }}>
              <div
                className="newCommonHeading"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: "7px",
                }}
              >
                <h2
                  className="mobileAndDesktopView"
                  style={{ color: "#262c6c" }}
                >
                  POLLUTION
                </h2>
              </div>

              <div
                className="historyDetailsWrapper"
                style={{ borderBottom: "none" }}
              >
                <p>POLLUTION UPTO</p>
                <div className="historyDetailsLabel">
                  <img src={CircleTick} alt="tick" />
                  <span>
                    {formatDate(insuranceFinanceDetails.pollution_upto) ||
                      "Not Available"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              margin: "9px",
              borderRadius: "11px",
              marginTop: "29px",
            }}
          >
            <div className="container" style={{ paddingTop: "11px" }}>
              <div
                className="newCommonHeading"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: "7px",
                }}
              >
                <h2
                  className="mobileAndDesktopView"
                  style={{ color: "#262c6c" }}
                >
                  INSURANCE DETAILS
                </h2>
              </div>

              <div className="historyDetailsWrapper">
                <p>POLICY NUMBER</p>
                <div className="historyDetailsLabel">
                  <img src={CircleTick} alt="tick" />
                  <span>
                    {insuranceFinanceDetails.vehicleInsurancePolicyNumber ||
                      "No Active Insurance"}
                  </span>
                </div>
              </div>
              <div className="historyDetailsWrapper">
                <p>INSURANCE COMPANY</p>
                <div className="historyDetailsLabel">
                  <img src={CircleTick} alt="tick" />
                  <span>
                    {insuranceFinanceDetails.vehicleInsuranceCompanyName ||
                      "--"}
                  </span>
                </div>
              </div>
              <div
                className="historyDetailsWrapper"
                style={{ borderBottom: "none" }}
              >
                <p>EXPIRY DATE</p>
                <div className="historyDetailsLabel">
                  <img src={CircleTick} alt="tick" />
                  <span>
                    {formatDate(insuranceFinanceDetails.vehicleInsuranceUpto) ||
                      "--"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {datas.moduleId === "7" && (
        <div className="container">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <h3 style={{ color: "#262c6c" }}>RTO</h3>
              <br />
            </div>
          </div>
        </div>
      )}

      {rtoLoader && datas.moduleId === "7" ? (
        <h1 style={{ textAlign: "center" }}>Loading...</h1>
      ) : rtoDetails && rtoDetails.length === 0 && datas.moduleId === "7" ? (
        <h5 style={{ color: "#262c6c", textAlign: "center" }}>Not Available</h5>
      ) : (
        <>
          {datas.moduleId === "7" && (
            <div className="container">
              <div className="historyDetails">
                {rtoDetails &&
                  rtoDetails.map((each, index) => {
                    return (
                      <div key={index} className="historyDetailsWrapper">
                        <p>{each.column_name}</p>
                        <div className="historyDetailsLabel">
                          <img src={CircleTick} alt="tick" />
                          <span>{each.regDate || "Not Available"}</span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </>
      )}
      {/* {rtoDetails && rtoDetails.length === 0 ? (
        <h5 style={{ color: "#262c6c", textAlign: "center" }}>NA</h5>
      ) : (
        <>
          {datas.moduleId === "7" && (
            <div className="container">
              <div className="historyDetails">
                {rtoDetails &&
                  rtoDetails.map((each, index) => {
                    return (
                      <div key={index} className="historyDetailsWrapper">
                        <p>{each.column_name}</p>
                        <div className="historyDetailsLabel">
                          <img src={CircleTick} alt="tick" />
                          <span>{each.column_value || "--"}</span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </>
      )} */}
      {/* {datas.moduleId === "7" && (
        <div className="container">
          <div className="historyDetails">
            {rtoDetails &&
              rtoDetails.map((each, index) => {
                return (
                  <div key={index} className="historyDetailsWrapper">
                    <p>{each.column_name}</p>
                    <div className="historyDetailsLabel">
                      <img src={CircleTick} alt="tick" />
                      <span>{each.column_value || "--"}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )} */}
    </section>
  );
};

export default ReportDetails;
