import React, { useState } from "react";
import styles from "../../containers/searchcars/SearchPage.module.css";
import FilterListIcon from "@mui/icons-material/FilterList";

const Suggestions = [
  { id: 1, name: "New" },
  { id: 2, name: "Used" },
  { id: 3, name: "Certified Used" },
  { id: 4, name: "No Accidents" },
];

function SearchLabels({ toggleFilter }) {
  const [activeLabels, setActiveLabels] = useState([]);

  // console.log(activeLabels);

  function updateSuggestions(Id) {
    if (activeLabels.includes(Id)) {
      setActiveLabels((activeLabels) => activeLabels.filter((id) => id !== Id));
    } else {
      setActiveLabels([...activeLabels, Id]);
    }
  }

  return (
    <div className={styles.SearchBarLabels}>
      <div onClick={toggleFilter} className={styles.openFilterIcon}>
        <FilterListIcon />
      </div>
      <div className={styles.labelItems}>
        {Suggestions.map((item) => {
          return (
            <div
              className={activeLabels.includes(item.id) ? styles.SearchLabelActive : styles.SearchLabel}
              key={item.id}
              onClick={() => {
                updateSuggestions(item.id);
              }}
            >
              <span>{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SearchLabels;
