import React, { useState, useEffect } from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import styles from "../../../containers/searchcars/SearchPage.module.css";
import { updateObjectOnSession } from "./helpers";

const MultiSelectDropdown = ({
  label,
  itemsList,
  boxHeight,
  paddingHeight,
  selectedItems,
  setSelectedItems,
  id_name,
  value_name,
  hasSearch = false,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchedItemsList, setSearchedItemsList] = useState([]);

  // console.log(searchedItemsList);

  useEffect(() => {
    if (hasSearch) {
      if (searchInput === "") {
        setSearchedItemsList(itemsList);
      } else {
        const newItems = itemsList.filter((item) => item[value_name].toLowerCase().includes(searchInput.toLowerCase()));
        setSearchedItemsList(newItems);
      }
    } else {
      setSearchedItemsList(itemsList);
    }
  }, [searchInput, itemsList]);

  useEffect(() => {
    setSearchedItemsList(itemsList);
  }, []);

  function updateSelectedItems(id) {
    // console.log(selectedItems, id);
    if (selectedItems.includes(id)) {
      setSelectedItems((selectedItems) => selectedItems.filter((item) => item !== id));

      const items = selectedItems.filter((item) => item !== id);
      updateObjectOnSession("filters", label, items);
    } else {
      // console.log("first id", id);
      setSelectedItems([...selectedItems, id]);

      const items = [...selectedItems, id];
      updateObjectOnSession("filters", label, items);
    }
  }

  // console.log(itemsList);

  function toggleFilter() {
    setIsFilterOpen((openState) => !openState);
  }

  function getHeight() {
    if (isFilterOpen) {
      if (hasSearch) {
        if (searchedItemsList.length === 0) {
          return 55;
        } else {
          return boxHeight * searchedItemsList.length + paddingHeight + 45;
        }
      } else {
        return boxHeight * searchedItemsList.length + paddingHeight;
      }
    } else {
      return 0;
    }
  }

  return (
    <div className={styles.filterMenuItem}>
      <div className={styles.filterMenuItemTop} style={{ borderColor: isFilterOpen ? "#fff" : "#ede8e9" }}>
        <span className={styles.headerSmall}>{label}</span>
        <div>
          {!isFilterOpen && <ControlPointIcon className={styles.filterIcon} onClick={toggleFilter} />}
          {isFilterOpen && (
            <RemoveCircleOutlineIcon
              className={styles.filterIcon}
              style={{ color: "#9A9EA7" }}
              onClick={toggleFilter}
            />
          )}
        </div>
      </div>
      <div className={styles.openFilter}>
        <ul
          className={styles.filterDropdown}
          style={{
            height: getHeight(),
            opacity: isFilterOpen ? 1 : 0,
            visibility: isFilterOpen ? "visible" : "hidden",
          }}
        >
          {hasSearch && (
            <input
              type="text"
              className={styles.searchFilterInput}
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          )}
          {searchedItemsList &&
            searchedItemsList.map((item) => (
              <li className={styles.dropDownItem} key={item[id_name]}>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <div
                    className={selectedItems.includes(item[id_name]) ? styles.checkBoxActive : styles.checkBox}
                    onClick={() => {
                      updateSelectedItems(item[id_name]);
                    }}
                  >
                    <CheckIcon style={{ color: "#fff", fontSize: 18 }} />
                  </div>
                  <span className={selectedItems.includes(item[id_name]) ? styles.checkNameActive : styles.fadeText}>
                    {item[value_name]}
                  </span>
                </div>
                {/* <span className={styles.fadeText}>{item.value}</span> */}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
