import React, { useEffect } from "react";
import styles from "./CarbyModel.module.css";
import Suv from "../../assets/images/carModel/suv1.png";
import Sedan from "../../assets/images/carModel/sedan1.png";
import Hatchback from "../../assets/images/carModel/hatchback1.png";
import EV from "../../assets/images/carModel/ev1.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import logo1 from "../../assets/CarImages/logo/logo1.png";
import logo2 from "../../assets/CarImages/logo/logo2.png";
import logo3 from "../../assets/CarImages/logo/logo3.png";
import logo4 from "../../assets/CarImages/logo/logo4.png";
import { useState } from "react";
import { useContext } from "react";
import { PortalContext } from "../../context/carPortalContext";

import Toyoto from "../../assets/images/partnerLocation/toyota.svg";
import Honda from "../../assets/images/service-network/honda.svg";
import HondaBrand from "../../assets/images/partnerLocation/honda.svg";
import Hyundai from "../../assets/images/partnerLocation/hyundai.svg";
import Ford from "../../assets/images/service-network/ford.svg";
import Force from "../../assets/images/partnerLocation/force.svg";
import { GetCarPortalLandingSummary } from "../../Api/PortalApis";
import { Key } from "@mui/icons-material";

function sum(obj) {
  var sum = 0;
  for (var el in obj) {
    if (obj.hasOwnProperty(el)) {
      sum += parseFloat(obj[el]);
    }
  }

  return sum;
}

const CarbyModel = () => {
  const [carType, setCarType] = useState(1);
  const [carBrandSummary, setCarBrandSummary] = useState({});
  const [carTypeSummary, setCarTypeSummary] = useState({});

  const navigate = useNavigate();

  const portalCtx = useContext(PortalContext);

  // console.log(portalCtx.totalCars);

  async function getCarsSummary() {
    try {
      const resp = await GetCarPortalLandingSummary();
      if (resp.status === 200) {
        // console.log(resp.data.Brands);
        // console.log(resp.data["Car Body"]);
        setCarBrandSummary(resp.data.brand);
        setCarTypeSummary(resp.data.carBody);
        const totalCars = sum(resp.data.carBody);
        portalCtx.setTotalCars(totalCars);

        // setCarBrandSummary(resp.data)
      }
    } catch (error) {
      console.error("error while fetching cars summary", error);
    }
  }

  useEffect(() => {
    getCarsSummary();
  }, []);

  function toggleCarType() {
    if (carType === 1) {
      setCarType(2);
    } else {
      setCarType(1);
    }
  }

  return (
    <section className={styles.CarByModel}>
      <div className="container">
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={styles.heading}>
              <span style={{ color: "#262c6e", fontWeight: "400" }}>Find car </span>
              <span style={{ color: "#eb6d47", fontWeight: "700" }}>{carType === 1 ? "by type" : "by make"}</span>
              <button className={styles.typeMakeToggle} onClick={toggleCarType}>
                {carType === 1 ? "by make" : "by type"}
              </button>
            </div>
            <Link to="/search-cars" style={{ position: "relative", textAlign: "end" }}>
              <div className={styles.cardButton}>View All Cars</div>
            </Link>
          </div>
          {carType === 1 && (
            <div className={styles.headingContainer}>
              <div className={styles.card}>
                <div className={styles.typeImage}>
                  <img src={Hatchback} height="102.67" width="220px" alt="hatchback" />
                </div>
                <div
                  // style={{ fontWeight: "700", fontSize: "17px", marginTop: "28.33px" }}
                  className={styles.labelHatchBack}
                >
                  Hatchback
                </div>
                {/* <Link to="/search-cars">
                  <button className={styles.cardButton}>{carTypeSummary.Hatch || 0}</button>
                </Link> */}
                <div
                  to="/search-cars"
                  onClick={() => {
                    navigate("/search-cars", { state: { category: "Hatch", make: null } });
                  }}
                >
                  <button className={styles.cardButton}>{carTypeSummary.Hatch || 0}</button>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.typeImage}>
                  <img src={Sedan} height="117px" width="206px" alt="sedan" />
                </div>
                <div
                  // style={{ fontWeight: "700", fontSize: "17px", marginTop: "18px" }}
                  className={styles.labelSedan}
                >
                  Sedan
                </div>
                {/* <Link to="/search-cars">
                  <button className={styles.cardButton}>{carTypeSummary.Sedan || 0}</button>
                </Link> */}
                <div
                  to="/search-cars"
                  onClick={() => {
                    navigate("/search-cars", { state: { category: "Sedan", make: null } });
                  }}
                >
                  <button className={styles.cardButton}>{carTypeSummary.Sedan || 0}</button>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.typeImage}>
                  <img src={Suv} height="98.33" width="220px" alt="suv" />
                </div>
                <div
                  // style={{ fontWeight: "700", fontSize: "17px", marginTop: "50px" }}
                  className={styles.labelSUV}
                >
                  SUV
                </div>
                {/* <Link to="/search-cars">
                  <button className={styles.cardButton}>{carTypeSummary.SUV || 0}</button>
                </Link> */}
                <div
                  to="/search-cars"
                  onClick={() => {
                    navigate("/search-cars", { state: { category: "SUV", make: null } });
                  }}
                >
                  <button className={styles.cardButton}>{carTypeSummary.SUV || 0}</button>
                </div>
              </div>
              <div className={styles.card} style={{ height: 270 }}>
                <div className={styles.typeImage}>
                  <img src={EV} height="122px" width="234px" alt="ev" />
                </div>
                <div
                  // style={{ fontWeight: "700", fontSize: "17px", marginTop: "56px" }}
                  className={styles.labelEV}
                >
                  EV
                </div>
                {/* <Link to="/search-cars">
                  <button className={styles.cardButton}>{carTypeSummary.MUV || 0}</button>
                </Link> */}
                <div
                  to="/search-cars"
                  onClick={() => {
                    navigate("/search-cars", { state: { category: "MUV", make: null } });
                  }}
                >
                  <button className={styles.cardButton}>{carTypeSummary.MUV || 0}</button>
                </div>
              </div>
              <div>
                <Link to="/search-cars">
                  <div className={styles.cardButtonMobile}>View All Cars</div>
                </Link>
              </div>
            </div>

            // Expirimental
            // <div className={styles.headingContainer3}>
            //   <div className={styles.card3}>
            //     <div className={styles.typeImage3}>
            //       <img src={Hatchback} height="102.67" width="220px" alt="hatchback" />
            //     </div>
            //     <div style={{ fontWeight: "700", fontSize: "17px", marginTop: "28.33px" }}>Hatchback</div>
            //     <Link to="/search-cars">
            //       <button className={styles.cardButton3}>23,457</button>
            //     </Link>
            //   </div>
            //   <div className={styles.card3}>
            //     <div className={styles.typeImage3}>
            //       <img src={Sedan} height="117px" width="206px" alt="sedan" />
            //     </div>
            //     <div style={{ fontWeight: "700", fontSize: "17px", marginTop: "18px" }}>Sedan</div>
            //     <Link to="/search-cars">
            //       <button className={styles.cardButton3}>23,457</button>
            //     </Link>
            //   </div>
            //   <div className={styles.card3}>
            //     <div className={styles.typeImage3}>
            //       <img src={Suv} height="98.33" width="220px" alt="suv" />
            //     </div>
            //     <div style={{ fontWeight: "700", fontSize: "17px", marginTop: "50px" }}>SUV</div>
            //     <Link to="/search-cars">
            //       <button className={styles.cardButton3}>23,457</button>
            //     </Link>
            //   </div>
            //   <div className={styles.card3} style={{ height: 270}}>
            //     <div className={styles.typeImage3} >
            //       <img src={EV} height="122px" width="234px" alt="ev" />
            //     </div>
            //     <div style={{ fontWeight: "700", fontSize: "17px", marginTop: "56px" }}>EV</div>
            //     <Link to="/search-cars">
            //       <button className={styles.cardButton3}>23,457</button>
            //     </Link>
            //   </div>
            //   <div>
            //     <Link to="/search-cars">
            //       <div className={styles.cardButtonMobile}>View All Cars</div>
            //     </Link>
            //   </div>
            // </div>
          )}
          {carType === 2 && (
            <div className={styles.headingContainer2}>
              {Object.keys(carBrandSummary).map((brandName, i) => (
                <div className={styles.makeCard} key={i}>
                  <div className={styles.makeImageContainer}>
                    <div>
                      <img src={carBrandSummary[brandName].icon} alt={brandName} />
                    </div>
                    <p>{brandName}</p>
                  </div>
                  {/* <Link to="/search-cars">
                    <button className={styles.cardButtonMake}>
                      {carBrandSummary[brandName].count} {carBrandSummary[brandName].count == 1 ? "Car" : "Cars"}
                    </button>
                  </Link> */}
                  <div
                    to="/search-cars"
                    onClick={() => {
                      navigate("/search-cars", { state: { category: null, make: brandName } });
                    }}
                  >
                    <button className={styles.cardButtonMake}>
                      {carBrandSummary[brandName].count} {carBrandSummary[brandName].count == 1 ? "Car" : "Cars"}
                    </button>
                  </div>
                </div>
              ))}
              {/* OLD STYLE OF CARD */}
              {/* <div className={styles.makeCard}>
                  <div className={styles.makeImage}>
                    <img src={carBrandSummary[key].icon} alt={key} />
                  </div>
                  <Link to="/search-cars">
                    <button className={styles.cardButtonMake}>{carBrandSummary[key].count} Cars</button>
                  </Link>
                </div> */}
              <div style={{ gridColumn: "1 / -1", height: "min-content", marginTop: -15 }}>
                <Link to="/search-cars">
                  <div className={styles.cardButtonMobile}>View All Cars</div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CarbyModel;
