import React, { useState, useRef } from "react";
import style from "../../styles/contact-us.module.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useEffect } from "react";
import { AddServiceLeadApi, vehicleListApi } from "../../Api/Apis";
import Batery from "../../assets/images/warranty-benefits/battery.png";

function Form() {
  const inputRef = useRef(null);

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [paymnetDoneModal, setPaymentDoneModal] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [makeModalError, setMakeModalError] = useState(false);
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [modifiedVehicleList, setModifiedVehicleList] = useState([]);
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmTerm, setUtmTerm] = useState("");
  const [utmContent, setUtmContent] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const [makeId, setMakeId] = useState("");
  const [modalId, setModalId] = useState("");

  const userData = {
    phoneNum: phoneNum,
    name: name,
    area: "",
    utmId: "",
    utmSource: utmSource,
    utmMedium: utmMedium,
    utmCampaign: utmCampaign,
    utmTerm: utmTerm,
    utmContent: utmContent,
    isBot: "",
    sourceType: "",
    ip: "",
    type: "",
    site: "",
    makeId: makeId,
    modelId: modalId,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!phoneNum) {
      return setPhoneError(true);
    }
    if (phoneNum.length < 10) {
      return setInvalidNumber(true);
    }
    if (!makeId && !modalId) {
      return setMakeModalError(true);
    } else {
      setDisableButton(true);
      try {
        const response = await AddServiceLeadApi(userData);
        if (response.status === 200) {
          // console.log(response);
          setName("");
          setPhoneNum("");
          setNameError(false);
          setPhoneError(false);
          setDisableButton(false);
          navigate(`/submission_success`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setDisableButton(false);
      }
    }
  };

  const closeKycPopup = () => {
    setPaymentDoneModal(false);
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[6-9]\d{0,9}$/;
    if (inputValue === "" || regex.test(inputValue)) {
      setPhoneError(false);
      setInvalidNumber(false);
      setPhoneNum(inputValue);
    }
  };

  const getVehicleList = async () => {
    try {
      const resp = await vehicleListApi();
      if (resp.status === 200) {
        // console.log(resp);
        setVehicleList(resp.data.response.MakeModelList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVehicleList();
  }, []);

  useEffect(() => {
    const newData = [];
    vehicleList &&
      vehicleList.forEach((each) => {
        newData.push({
          value: each.model_id || "",
          label: each.combined ?? "",
          id: each.brand_id,
        });
      });
    //
    setModifiedVehicleList(newData);
  }, [vehicleList && vehicleList.length > 0]);

  function handleTypeList(selectedOption) {
    setMakeModalError(false);
    setMakeId(selectedOption.id);
    setModalId(selectedOption.value);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get("utm_source");
    const utmMedium = queryParams.get("utm_medium");
    const utmCampaign = queryParams.get("utm_campaign");
    const utmTerm = queryParams.get("utm_term");
    const utmContent = queryParams.get("utm_content");
    setUtmSource(utmSource);
    setUtmMedium(utmMedium);
    setUtmCampaign(utmCampaign);
    setUtmTerm(utmTerm);
    setUtmContent(utmContent);
  }, []);
  const handleLabelClick = () => {
    inputRef.current.focus();
  };

  return (
    <section className={style.contactUsBanner}>
      <div>
        <img className={style.ImageContainer} src={Batery} />
      </div>
      <ToastContainer />
      <div className={`${style.container} container`}>
        <form className={style.contactUsForm} onSubmit={handleSubmit}>
          <div className={style.formGroup}>
            <div className={style.formField}>
              {/* <label for="name">Name</label> */}
              <input
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError(false);
                }}
                name="name"
                value={name}
                type="text"
                placeholder="Enter your name"
                id="name"
              />
              {nameError ? (
                <p
                  style={{
                    marginTop: "4px",
                    color: "#ed3709",
                    padding: "8px",
                  }}
                >
                  Please enter your name
                </p>
              ) : (
                ""
              )}
            </div>
            {/* <div className={style.formField}>
              <label>
                Please Select Vehicle Make<span style={{ color: "#eb6d47", fontSize: "16px" }}>*</span>
              </label>
              <select
                onChange={handleInputChange}
                name="cityId"
                value={userData.cityId}
                className="contactUsForm-select "
              >
                <option hidden>Your Vehicle</option>
                {cityList.map((item) => (
                  <option key={item.city_id} value={item.city_id}>
                    {item.city}
                  </option>
                ))}
                <option>BMW</option>
                <option>MARUTI</option>
                <option>AUDI</option>
                <option>HONDA</option>
                <option>TATA</option>
                <option>MAHINDRA</option>
              </select>
            </div> */}
          </div>
          <div className={style.formGroup}>
            <div className={style.formField}>
              {/* <label for="phoneNum">
                Phone<span style={{ color: "#eb6d47", fontSize: "16px" }}>*</span>
              </label> */}
              <input
                onChange={handleChange}
                name="phoneNum"
                value={phoneNum}
                type="text"
                placeholder="Enter your number"
                id="phoneNum"
              />
              {phoneError ? (
                <p
                  style={{
                    marginTop: "4px",
                    color: "#ed3709",
                    padding: "8px",
                  }}
                >
                  Please enter your phone no
                </p>
              ) : (
                ""
              )}
              {invalidNumber ? (
                <p
                  style={{
                    marginTop: "4px",
                    color: "#ed3709",
                    padding: "8px",
                  }}
                >
                  Invalid Phone no
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={style.formGroup}>
            <div className={style.formField}>
              {/* <label for="make&modal" onClick={handleLabelClick}>
                Select vehicle<span style={{ color: "#eb6d47", fontSize: "16px" }}>*</span>
              </label> */}
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    lineHeight: "38px",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "16px", // Change the font size here
                    color: "#9798a8",
                  }),
                }}
                onChange={handleTypeList}
                id="make&modal"
                options={modifiedVehicleList}
                isSearchable={true}
                placeholder="Select make and model"
                name="make&modal"
                ref={inputRef}
              />
              {makeModalError && (
                <p
                  style={{
                    marginTop: "4px",
                    color: "#ed3709",
                    padding: "8px",
                  }}
                >
                  Please select vehicle
                </p>
              )}
            </div>
          </div>

          <div className={style.formGroup} style={{ margin: "0 auto", marginTop: "24px" }}>
            <input
              type="submit"
              value="Check Price for free"
              style={{ paddingLeft: "3rem", paddingRight: "3rem", cursor: disableButton ? "not-allowed" : "pointer" }}
              disabled={disableButton}
            />
          </div>
        </form>

        <div className="bannerHeading" style={{ marginBottom: "-70px" }}>
          <h1>
            <p style={{ marginTop: "12px" }}>
              For any inquiries, you can reach us by phone at <a href="tel:+91-80509-25379"> +91 8050925379</a> or by
              email at{" "}
              <a href="mailto:customercare@wisedrive.in" target="_blank">
                customercare@wisedrive.in
              </a>{" "}
              Alternatively, you can fill out a form, and we will get back to you as soon as possible.
            </p>
          </h1>
        </div>
      </div>
    </section>
  );
}

export default Form;
