import React, { useState } from "react";
import "./engineProtection.css";
import engineView from "../../assets/images/engineProtection/engineView.png";
import expandMore from "../../assets/images/engineProtection/expandMore.svg";
import engineView2 from "../../assets/images/engineProtection/engineView2.png";
import engineView3 from "../../assets/images/engineProtection/engineView3.png";
import engineView4 from "../../assets/images/engineProtection/engineView4.png";
import engineView5 from "../../assets/images/engineProtection/engineView5.png";
import engineView6 from "../../assets/images/engineProtection/engineView6.png";
import engineView7 from "../../assets/images/engineProtection/engineView7.png";

function EngineProtection() {
  const [openContent, setOpenContent] = useState(0);

  const handleTabOpen = (v) => {
    setOpenContent(v);
  };
  return (
    <section className="engineProtection">
      <div className="container">
        <div className="engineDetails">
          <div className="engineView">
            <div className="commonHeading">
              <h2>
                <span>Complete Protection</span> for your engine & transmission
              </h2>
            </div>
            <div className="desktop">
              {openContent === 0 ? <img src={engineView} alt="engineView" /> : ""}
              {openContent === 1 ? <img src={engineView} alt="engineView" /> : ""}
              {openContent === 2 ? <img src={engineView2} alt="engineView" /> : ""}
              {openContent === 3 ? <img src={engineView3} alt="engineView" /> : ""}
              {openContent === 4 ? <img src={engineView4} alt="engineView" /> : ""}
              {openContent === 5 ? <img src={engineView5} alt="engineView" /> : ""}
              {openContent === 6 ? <img src={engineView6} alt="engineView" /> : ""}
              {openContent === 7 ? <img src={engineView7} alt="engineView" /> : ""}
            </div>
          </div>

          <div className="engineAccordion">
            <div className="accordions" onClick={() => handleTabOpen(0)}>
              <h3>
                <span>Engine Warranty</span>
                <img className={openContent === 0 ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {openContent === 0 ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Oil Pump</li>
                    <li>Connecting rods</li>
                    <li>Inlet and exhaust valves</li>
                    <li>Cylinder head</li>
                    <li>Timing gears</li>
                    <li>Crankshaft & Pulley</li>
                    <li>Big end and main bearings</li>
                    <li>Gudgeon Pins</li>
                    <li>Piston and Rings</li>
                    <li>Inlet and exhaust valves (Excluding burnt and pitted valves) Spring and guides</li>
                    <li>Cylinder block</li>
                    <li>Cylinder Head</li>
                    <li>Camshaft</li>
                    <li>rocker arms</li>
                    <li>shaft</li>
                    <li>Timing gears</li>
                    <li>Tensioner bearings</li>
                    <li>water pump (Failure due to external damage or corrosion is not covered)</li>
                    <li>inlet and exhaust manifold.</li>
                    {/* <li>+20 More</li> */}
                  </ul>
                  <div className="mobile accordionsCompleteImg">
                    <img src={engineView} alt="expandMore" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="accordions" onClick={() => handleTabOpen(1)}>
              <h3>
                <span>Comprehensive Engine Warranty </span>
                <img className={openContent === 1 ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {openContent === 1 ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Oil Pump</li>
                    <li>Connecting rods</li>
                    <li>Inlet and exhaust valves</li>
                    <li>Cylinder head</li>
                    <li>Timing gears</li>
                    <li>Crankshaft & Pulley</li>
                    <li>Big end and main bearings</li>
                    <li>Gudgeon Pins</li>
                    <li>Piston and Rings</li>
                    <li>Inlet and exhaust valves (Excluding burnt and pitted valves) Spring and guides</li>
                    <li>Cylinder block</li>
                    <li>Cylinder Head</li>
                    <li>Camshaft</li>
                    <li>rocker arms</li>
                    <li>shaft</li>
                    <li>Timing gears</li>
                    <li>Tensioner bearings</li>
                    <li>water pump (Failure due to external damage or corrosion is not covered)</li>
                    <li>inlet and exhaust manifold.</li>
                    <li>Starter motor</li>
                    <li>Starter Bendix</li>
                    <li>Timing Belt</li>
                    <li>Spark Plug</li>
                    <li>Timing Belt Tensioner</li>
                    <li>Timing Belt Pulley and Multi v Belt</li>
                  </ul>
                  <div className="mobile accordionsCompleteImg">
                    <img src={engineView2} alt="expandMore" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="accordions" onClick={() => handleTabOpen(2)}>
              <h3>
                <span>Transmission (Manual)</span>
                <img className={openContent === 2 ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {openContent === 2 ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Gears</li>
                    <li>Shaft</li>
                    <li>Synchromesh hubs</li>
                    <li>Selectors</li>
                    <li>Bearings</li>
                    <li>Transfer Gears</li>

                    {/* <li>+20 More</li> */}
                  </ul>
                  <div className="mobile accordionsCompleteImg">
                    <img src={engineView2} alt="expandMore" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="accordions" onClick={() => handleTabOpen(3)}>
              <h3>
                <span>Transmission (Automatic)</span>
                <img className={openContent === 3 ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {openContent === 3 ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Shaft</li>
                    <li>Gears</li>
                    <li>Brake/Clutch bands</li>
                    <li>oil pump</li>
                    <li>Bearings</li>
                    <li>Bushes</li>
                    <li>Valves</li>
                    <li>Drive plates</li>
                    <li>Transfer Gears</li>
                    <li>Transmission Gears</li>
                  </ul>
                  <div className="mobile accordionsCompleteImg">
                    <img src={engineView3} alt="expandMore" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="accordions" onClick={() => handleTabOpen(4)}>
              <h3>
                <span>Turbo</span>
                <img className={openContent === 4 ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {openContent === 4 ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Turbo warranty coverage includes the Turbine wheel</li>
                    <li>Compressor wheel</li>
                    <li>Turboshaft</li>
                    <li>Turbo Core</li>
                    <li>Only for cars that have driven a max of up to 70,000 km</li>
                  </ul>
                  <div className="mobile accordionsCompleteImg">
                    <img src={engineView4} alt="expandMore" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="accordions" onClick={() => handleTabOpen(5)}>
              <h3>
                <span>Electrical Wiring</span>
                <img className={openContent === 5 ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {openContent === 5 ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Covers the wiring of the vehicle including Headlight wiring</li>
                    <li>Parking light wiring</li>
                    <li>Roof lights wiring</li>
                    <li>Electrical parts wiring</li>
                    <li>Infotainment system</li>
                    <li>Extra wire charges during repair or replacement are included in the warranty</li>
                  </ul>
                  <div className="mobile accordionsCompleteImg">
                    <img src={engineView5} alt="expandMore" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="accordions" onClick={() => handleTabOpen(6)}>
              <h3>
                <span>AC compressor</span>
                <img className={openContent === 6 ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {openContent === 6 ? (
                <div>
                  <p></p>
                  <ul>
                    <li>AC compressor warranty includes Compressor</li>
                    <li>Thermal Expansion valve</li>
                    <li>Seals at every connection</li>
                    <li>Condenser</li>
                    <li>Receiver/Dryer or Accumulator</li>
                    <li>Magnetic Clutch</li>
                    <li>Piston</li>
                    <li>Compressor pulley</li>
                    <li>Bearings</li>
                    <li>Electrical Coil</li>
                  </ul>
                  <div className="mobile accordionsCompleteImg">
                    <img src={engineView6} alt="expandMore" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="accordions" onClick={() => handleTabOpen(7)}>
              <h3>
                <span>Battery</span>
                <img className={openContent === 7 ? "activeRotate" : ""} src={expandMore} alt="expandMore" />
              </h3>
              {openContent === 7 ? (
                <div>
                  <p></p>
                  <ul>
                    <li>Battery Warranty Includes Cells Repair/Replacement</li>
                    <li>Terminal Clean and Corrosions Check</li>
                    <li>Plates repair/Replacement</li>
                    <li>Battery Water replacement</li>
                  </ul>
                  <div className="mobile accordionsCompleteImg">
                    <img src={engineView7} alt="expandMore" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default EngineProtection;
