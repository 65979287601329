import React, { useState } from "react";
import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";
import { cashfreeProd } from "cashfree-pg-sdk-javascript";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PaymentDoneModal from "../../components/popups/paymentDoneModal";
import PaymentFailModal from "../../components/popups/PaymentFailModal";
import { completePaymentApi } from "../../Api/Apis";
import { useNavigate } from "react-router-dom";

const CheckoutWiseAssist = () => {
  const navigate = useNavigate();
  const data = useParams();
  const orderToken = data.seId;
  const [paymnetDoneModal, setPaymentDoneModal] = useState(false);
  const [paymentFailModal, setPaymentFailModal] = useState(false);
  const quantity = sessionStorage.getItem("quantity");
  const customerId = sessionStorage.getItem("customer_Id");

  function handlePayNowClick() {
    let cashfree = new cashfreeProd.Cashfree(orderToken);
    cashfree.drop(document.getElementById("payment-form"), {
      orderToken: orderToken,
      onSuccess: function (response) {
        // setPaymentDoneModal(true);
        console.log(response, "pass");
        completePayment(response.transaction.transactionAmount, response.order.orderId, response.order.status);
      },
      onFailure: function (error) {
        setPaymentFailModal(true);
        console.log(error, "fail");
        completePayment(
          error.transaction.transactionAmount,
          error.order.orderId,
          error.order.status === "ACTIVE" ? "FAILED" : ""
        );
      },
      components: ["order-details", "card", "netbanking", "upi", "app"],
      style: { theme: "Bubble Gum" },
    });
  }

  const closeKycPopup = () => {
    setPaymentDoneModal(false);
  };

  useEffect(() => {
    handlePayNowClick();
  }, []);

  const completePayment = async (amount, orderId, txStatus) => {
    const datas = {
      leadId: sessionStorage.getItem("lead_Id"),
      customerId: customerId === "null" ? "" : customerId,
      mainPackId: sessionStorage.getItem("packageId"),
      amount: amount, //
      payStatus: txStatus,
      payType: "online",
      mode: "",
      refNo: sessionStorage.getItem("refeId"),
      orderId: orderId,
      discount: 0, // temp
      qty: quantity,
      gatewayId: "",
      paymentFrom: "",
    };

    console.log(datas, "qwerty");

    try {
      const resp = await completePaymentApi(datas);
      if (resp.status === 200) {
        var jsonString = JSON.stringify(resp.data.response.paymentData.PackageDescription);
        sessionStorage.setItem("data", jsonString);
        sessionStorage.setItem("expiary", resp.data.response.paymentData.ExpiresOn);
        sessionStorage.setItem("activated", resp.data.response.paymentData.date);

        navigate(`/payment-success-wiseassist`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ background: "#90D3EA" }}>
      <form id="payment-form" style={{ maxWidth: "800px", margin: "auto" }}>
        {/* Add form fields for payment information */}
      </form>
      {paymnetDoneModal && <PaymentDoneModal onClose={closeKycPopup} />}
      {paymentFailModal && <PaymentFailModal onClose={closeKycPopup} />}
    </div>
  );
};

export default CheckoutWiseAssist;
