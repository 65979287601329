import React, { useEffect } from "react";
import "./hero.css";
import { useState } from "react";
import { Button } from "@mui/material";
import audi from "../../assets/images/hero/audi.png";
import ford from "../../assets/images/hero/ford.png";
import honda from "../../assets/images/hero/honda.png";
import hyundai from "../../assets/images/hero/hyundai.png";
import kia from "../../assets/images/hero/kia.png";
import mahindra from "../../assets/images/hero/mahindra.png";
import merc from "../../assets/images/hero/merc.png";
import mg from "../../assets/images/hero/mg.png";
import nissan from "../../assets/images/hero/nissan.png";
import skoda from "../../assets/images/hero/skoda.png";
import suzuki from "../../assets/images/hero/suzuki.png";
import tata from "../../assets/images/hero/tata.png";
import toyota from "../../assets/images/hero/toyota.png";
import volkswagen from "../../assets/images/hero/Volkswagen.png";
import volvo from "../../assets/images/hero/volvo.png";
// import HeroImageNew from "./heroImaheNew.png";
import Slider from "react-slick";
// import HeroRightImg from "../../assets/images/hero/heroright.png";
import HeroRightImg from "../../assets/images/hero/HeroImg.png";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
var settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 25000,
  slidesToShow: 5,
  slidesToScroll: 6,
  autoplaySpeed: 0,
  cssEase: "linear",
  autoplay: true,
  variableWidth: true,
  infiniteScroll: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Hero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="hero-left-section">
          <div>
            <h2 style={{ fontFamily: "gilroySemiBold" }}>
              Purchase your
              {/* <span style={{ color: "#ff7858" }}> used Car </span>with */}
              <br />
              Pre-owned Car with
              <br />
              <span style={{ color: "#ff7858" }}>Confidence</span> and <br />
              <span style={{ color: "#ff7858" }}> Warranty Assurance </span>
            </h2>
            <p style={{ fontFamily: "gilroySemibold", fontSize: "18px" }}>
              Secure your pre-owned car with FREE Engine and Transmission
              warranty of up to Rs 10,00,000 with our premium Inspection plan.
            </p>
          </div>
          <div className="heroImg-mobile">
            <img src={HeroRightImg} width="90%" alt="SVG" />
          </div>
          <div className="left-btn-container">
            <a href="#inspection-plans">
              <Button
                sx={{ fontFamily: "gilroyMedium" }}
                startIcon={<FeaturedPlayListOutlinedIcon />}
                className="hero-button-green"
                variant="outlined"
              >
                View Inspection Plans
              </Button>
            </a>
            <a href="#how-it-works">
              <Button
                sx={{ fontFamily: "gilroyMedium" }}
                className="hero-button-white"
              >
                How it works
              </Button>
            </a>
          </div>
        </div>
        <div className="hero-right-section">
          <img src={HeroRightImg} width="90%" alt="SVG" />
        </div>
      </div>
      <div
        className="container hero-note-container "
        style={{ display: "block" }}
      >
        <p className="hero-note">We provide inspection for all brands</p>

        <div className="hero-logos">
          <img src={suzuki} />
          <img src={hyundai} />
          <img src={honda} />
          <img src={toyota} />
          <img src={skoda} />
          <img src={tata} />
          <img src={ford} />
          <img src={kia} />
          <img src={mahindra} />
          <img src={volkswagen} />
          <img src={volvo} />
          <img src={merc} />
          <img src={audi} />
          <img src={mg} />
        </div>
      </div>

      <div className="userBannerSlicker">
        <Slider {...settings}>
          <img src={suzuki} />
          <img src={hyundai} />
          <img src={honda} />
          <img src={toyota} />
          <img src={skoda} />
          <img src={tata} />
          <img src={ford} />
          <img src={kia} />
          <img src={mahindra} />
          <img src={volkswagen} />
          <img src={volvo} />
          <img src={merc} />
          <img src={audi} />
          <img src={mg} />
        </Slider>
      </div>
    </section>
  );
}

export default Hero;
