import React, { useState, useRef } from "react";
import SearchPagination from "./SearchPagination";
import SearchLabels from "./SearchLabels";
import styles from "../../containers/searchcars/SearchPage.module.css";
import ResultCardSquare from "./ResultCardSquare";
import ResultCard2 from "./ResultCard2";
import styles2 from "./card2.module.css";
import styles1 from "./card1.module.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useContext } from "react";
import { PortalContext } from "../../context/carPortalContext";
import { GetCarPortalLandingSummary, GetDynamicData, GetLinkListedCars } from "../../Api/PortalApis";
import { useEffect } from "react";
import useWindowDimensions from "../util-hooks/useWindowDimentions";
import { useParams } from "react-router-dom";
import NoResultsFound from "./child-components/NoResultsFound";
// import { useLocation } from "react-router-dom";

function sum(obj) {
  var sum = 0;
  for (var el in obj) {
    if (obj.hasOwnProperty(el)) {
      sum += parseFloat(obj[el]);
    }
  }
  return sum;
}

const SearchResultsLink = React.forwardRef((props, ref) => {
  // console.log(ref);
  const { allFilteredData, isAllFilterEmpty, setTotalCars, totalCars } = useContext(PortalContext);
  const [squareDesignActive, setSquareDesignActive] = useState(true);
  const { width, height } = useWindowDimensions();
  const [linkListLoading, setLinkListLoading] = useState(false);

  const [linkCarList, setLinkCarList] = useState([]);

  const [buttonData, setButtonData] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // console.log(containerRef.current.scrollTop);
      if (containerRef.current) {
        setScrollPosition(containerRef.current.scrollTop);
      }
    };

    containerRef.current.addEventListener("scroll", handleScroll);

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerRef.current]);

  useEffect(() => {
    const storedScrollPosition = localStorage.getItem("scrollPosition");
    if (storedScrollPosition) {
      setScrollPosition(parseInt(storedScrollPosition, 10));
      if (containerRef.current) {
        containerRef.current.scrollTo(0, parseInt(storedScrollPosition, 10));
      }
    }
  }, [containerRef.current]);

  function setScroll() {
    localStorage.setItem("scrollPosition", scrollPosition.toString());
  }

  // console.log(buttonData);

  async function getCarsSummary() {
    try {
      const resp = await GetCarPortalLandingSummary();
      if (resp.status === 200) {
        const totalCars = sum(resp.data.carBody) + resp.data.additionalCount;
        setTotalCars(totalCars);

        // setCarBrandSummary(resp.data)
      }
    } catch (error) {
      console.error("error while fetching cars summary", error);
    }
  }

  useEffect(() => {
    getCarsSummary();
  }, []);

  async function getDynamicData() {
    try {
      const resp = await GetDynamicData();
      if (resp.status === 200) {
        // console.log(resp.data);
        const btnData = {
          toplabel: resp.data.warrantybutton,
          bottomLabel: resp.data.packageName,
          toplabelMob: resp.data.warrantybuttonmobile,
          bottomLabeMob: resp.data.packagenamemobile,
          bottomLabelMob: resp.data.packagenamemobile,
        };
        setButtonData(btnData);
      }
    } catch (error) {
      console.error("error getting dynamic data", error);
    }
  }

  useEffect(() => {
    getDynamicData();
  }, []);

  // const location = useLocation();
  // console.log(window.location.pathname.split("/")[2], "path");

  // console.log("link list from new comp", linkCarList);

  //   console.log(allFilteredData);

  async function getLinkListedCars() {
    try {
      setLinkListLoading(true);
      const resp = await GetLinkListedCars(props.lid);
      if (resp.status === 200) {
        // console.log("link cars", resp);
        // setAllCarsList(resp.data);
        // setAllFilteredData(resp.data);
        setLinkCarList(resp.data);
        setLinkListLoading(false);
      }
    } catch (error) {
      setLinkListLoading(false);
      console.error("error getting link based car list", error);
    }
  }

  useEffect(() => {
    if (props.lid) {
      getLinkListedCars();
    }
  }, [props.lid]);

  useEffect(() => {
    if (!isAllFilterEmpty()) {
      setLinkCarList(allFilteredData);
    } else {
      getLinkListedCars();
    }
  }, [allFilteredData]);

  // const params = useParams();

  function toggleDesign() {
    setSquareDesignActive((isActive) => !isActive);
  }

  useEffect(() => {
    // if (width <= 1440) {
    //   setSquareTempRows("repeat(2, 375px)");
    // }
    // if (width <= 1234) {
    //   setSquareTempRows("repeat(2, 315px)");
    // }

    // if (width <= 1054) {
    //   setSquareTempRows("repeat(3, 375px)");
    // }
    // if (width <= 940) {
    //   setSquareTempRows("repeat(2, 375px)");
    // }
    // if (width <= 762) {
    //   setSquareTempRows("repeat(3, 375px)");
    // }

    // if (width <= 510) {
    //   setSquareTempRows(`repeat(${allFilteredData.length}, 375px)`);
    // }
    if (width <= 762) {
      setSquareDesignActive(false);
    } else {
      setSquareDesignActive(true);
    }
  }, [width, linkCarList]);

  return (
    <div
      className={styles.searchBody}
      ref={containerRef}
      style={{ maxHeight: width <= 510 ? "none" : "calc(100vh - 6rem)" }}
    >
      <div className={styles.designToggleBtn}>
        {/* <button onClick={toggleDesign}>Toggle Design</button> */}
        <div onClick={props.toggleFilter} className={styles.openFilterIcon}>
          <FilterListIcon />
        </div>
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>{totalCars}</span> warranty cars with buyback guarantee available in{" "}
            <span style={{ fontWeight: "bold" }}>Bangalore</span>
          </p>
        </div>
        {/* <label className={styles.switch}>
          <input className={styles.toggleInput} type="checkbox" onClick={toggleDesign} />
          <span className={`${styles.slider} ${styles.round}`}></span>
        </label> */}
      </div>
      {/* <SearchLabels toggleFilter={toggleFilter} /> */}
      {/* </div> */}
      <br />
      {/* {linkCarList.length === 0 && <NoResultsFound />} */}
      {squareDesignActive && (
        <div
          // className={width <= 400 ? styles1.SearchResultsGridMob : styles1.SearchResultsGrid}
          className={styles1.SearchResultsGrid}
          style={{
            gridTemplateColumns: linkCarList.length < 3 && "repeat(auto-fit, minmax(280px, 340px))",
            // gridTemplateRows: squareTempRows,
          }}
        >
          {linkCarList.length === 0
            ? null
            : linkCarList.map((carInfo, i) => (
                <ResultCardSquare key={i} carInfo={carInfo} btnData={buttonData} setScroll={setScroll} />
              ))}
        </div>
      )}

      {!squareDesignActive && (
        <div className={styles2.SearchResultsRect2}>
          {linkCarList.length === 0
            ? null
            : linkCarList.map((carInfo, i) => (
                <ResultCard2
                  key={i}
                  carInfo={carInfo}
                  btnData={buttonData}
                  setScroll={setScroll}
                  // shortListVehicle={shortListVehicle}
                  // shortListedVehIds={shortListedVehIds}
                />
              ))}
        </div>
      )}
      <div className={styles.searchPagination}>{/* <SearchPagination /> */}</div>
    </div>
  );
});

export default SearchResultsLink;
