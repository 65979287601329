import React from "react";

function Banner() {
  return (
    <section className="pricePlanBanner">
      <div className="container">
        <h1>
          <span>Save money</span> with every km you drive
        </h1>
      </div>
    </section>
  );
}

export default Banner;
