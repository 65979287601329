import React, { useState, useEffect } from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RangeSlider from "./RangeSlider";
import styles from "../../../containers/searchcars/SearchPage.module.css";
import { formatPrice } from "../../../utils/commonFunctions";

function SliderDropdown({ label, minVal, maxVal, values, setValues }) {
  const valueDiff = maxVal - minVal;
  function getSliderValue(diff, per, minVal) {
    return Math.round(minVal + (per * diff) / 100);
  }

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  function toggleFilter() {
    setIsFilterOpen((openState) => !openState);
  }

  return (
    <div className={styles.filterMenuItem}>
      <div
        className={styles.filterMenuItemTop}
        style={{ borderColor: isFilterOpen ? "#fff" : "#ede8e9", borderWidth: 1.6 }}
      >
        <span className={styles.headerSmall}>{label}</span>
        <div>
          {!isFilterOpen && <ControlPointIcon className={styles.filterIcon} onClick={toggleFilter} />}
          {isFilterOpen && (
            <RemoveCircleOutlineIcon
              className={styles.filterIcon}
              style={{ color: "#9A9EA7" }}
              onClick={toggleFilter}
            />
          )}
        </div>
      </div>
      <div
        className={styles.openFilter}
        style={{
          height: isFilterOpen ? 94 : 0,
          opacity: isFilterOpen ? 1 : 0,
          visibility: isFilterOpen ? "visible" : "hidden",
          transition: "all 0.3s ease-in-out",
          width: "90%",
          overflowX: "hidden",
        }}
      >
        <RangeSlider value={values} setValue={setValues} label={label} />
        <div className={styles.sliderValues}>
          <p style={{ display: "flex", alignItems: "center" }}>
            {/* <CurrencyRupeeIcon style={{ fontSize: 12 }} /> */}
            <span className={styles.smallStrongText}>
              {label === "Price"
                ? formatPrice(`${getSliderValue(valueDiff, values[0], minVal)}`)
                : getSliderValue(valueDiff, values[0], minVal)}
            </span>
          </p>
          <p style={{ display: "flex", alignItems: "center" }}>
            {/* <CurrencyRupeeIcon style={{ fontSize: 12 }} /> */}
            <span className={styles.smallStrongText}>
              {label === "Price"
                ? formatPrice(`${getSliderValue(valueDiff, values[1], minVal)}`)
                : getSliderValue(valueDiff, values[1], minVal)}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SliderDropdown;
