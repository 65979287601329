import React from "react";
import "./claim-process.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ContactUs from "../../components/contact-us/contact-us";
import ClaimSteps from "./ClaimSteps";

const ClaimProcess = () => {
  
  return (
    <div>
      <Header />
      <ClaimSteps />
      <ContactUs />
      <Footer />
    </div>
  );

};

export default ClaimProcess;
